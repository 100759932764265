import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ManageCampaign } from './ManageCampaign';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Table, Tabs, Empty } from 'antd';

import { getCampaignsByUser, userCampaigns } from '../../services/CampaignService';
import SkeletonTable from '../../helpers/SkeletonTable';
import { activeUserPermissions, userInfo } from '../../services/UsersService';
import { Constants } from '../../config/globals';

export function CampaignDashboard(props) {

    const [uInfo, setUInfo] = useState({});
    const uPermissions = useRecoilValue(activeUserPermissions);
    const [showNewCampaign, setShowNewCampaign] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const [campaigns, setCampaigns] = useState({
        userCampaigns: [],
        companyCampaigns: [],
        loaded: false
    });

    useEffect(() => {
        if (uInfo?.users_ID) {
            getCampaignsByUser(uInfo.users_ID).then(data => {
                userCampaigns.next(data);
            });
        }
    }, [uInfo]);

    useEffect(() => {
        var alive = true;
        setLoading(true);


        combineLatest(
            userCampaigns,
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data, userInfo]) => {

            if (userInfo?.users_ID) {
                if (!uInfo.users_ID) { //prevent reload of user info
                    setUInfo(userInfo);
                }
            }

            if (data?.userCampaigns) {
                setLoading(false);

                setCampaigns({
                    userCampaigns: data.userCampaigns || [],
                    companyCampaigns: data.companyCampaigns || [],
                    loaded: true
                });
            } else {

                setCampaigns({
                    ...campaigns,
                    loaded: false
                });
            }
            
        });

        return () => { alive = false; }
    }, []);

    function openCampaign(record) {
        setSelectedCampaign(record);
        setShowNewCampaign(record);
    }


    const columns = [
        {
            title: 'Campaign Name',
            dataIndex: 'campaignName',
            key: 'campaignName',
        }
    ];

    const tabs = [
        {
            label: <div className="campaign-text">My Campaigns</div>,
            key: 'myCampaigns',
            children: (
                <>
                    <div>
                        {loading ?
                            <SkeletonTable columns={columns} rowCount={5} />
                            :
                            <div>
                                {campaigns.userCampaigns.length > 0 ?
                                    <div className="email-table">
                                        <Table
                                            rowKey='campaigns_ID'
                                            dataSource={campaigns.userCampaigns}
                                            columns={columns}
                                            size='small'
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: event => { openCampaign(record) }, // click row
                                                    onDoubleClick: event => { openCampaign(record) }, // double click row
                                                    onContextMenu: event => { }, // right button click row
                                                    onMouseEnter: event => { }, // mouse enter row
                                                    onMouseLeave: event => { }, // mouse leave row
                                                };
                                            }} />
                                    </div>
                                    :
                                    <Empty description={<p>You currently have no campaigns - to begin adding a campaign, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Campaign'.</p>} />
                                }
                            </div>
                        }
                    </div>
                </>
            ),
        },
        {
            label: <div className="campaign-text">{Constants.COMPANY_TEXT} Campaigns</div>,
            key: 'companyCampaigns',
            children: (
                <>
                    <div>
                        {loading ?
                            <SkeletonTable columns={columns} rowCount={5} />
                            :
                            <div>
                                {campaigns.companyCampaigns.length > 0 ?
                                    <div className="email-table">
                                        <Table
                                            rowKey='campaigns_ID'
                                            dataSource={campaigns.companyCampaigns}
                                            columns={columns}
                                            size='small'
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: event => { openCampaign(record) }, // click row
                                                    onDoubleClick: event => { openCampaign(record) }, // double click row
                                                    onContextMenu: event => { }, // right button click row
                                                    onMouseEnter: event => { }, // mouse enter row
                                                    onMouseLeave: event => { }, // mouse leave row
                                                };
                                            }} />
                                    </div>
                                    :
                                    <Empty description={<p>You currently have no campaigns - to begin adding a campaign, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Campaign'.</p>} />
                                }
                            </div>
                        }
                    </div>
                </>
            ),
        }
    ];

    if (uPermissions.users_ID && uPermissions.readCampaigns !== 'company') {
        let companyIndex = tabs.findIndex(item => item.key === 'companyCampaigns');
        tabs.splice(companyIndex, 1);
    }

    return (
        <>
            {(uPermissions.users_ID && uPermissions.readCampaigns === 'none') ?
                <Empty description={<p>You do you have the appropriate access to view campaigns. Please contact your system administrator if you believe this is a mistake.</p>} />
                :
                <>
                    <div className="d-flex justify-content-end">
                        {(uPermissions.users_ID && uPermissions.createCampaigns) &&
                            <button className="ant-btn ant-btn-primary float-end" onClick={() => { setSelectedCampaign(null); setShowNewCampaign(true); }}>
                                <i className="far fa-plus-square"></i>
                                &nbsp;
                                Add Campaign
                            </button>
                        }
                    </div>
                    <div className="card-container">
                        <Tabs type="card" items={tabs} />
                    </div>

                    {showNewCampaign && <ManageCampaign hideModal={() => setShowNewCampaign(false)} showModal={showNewCampaign} selectedCampaign={selectedCampaign} users_ID={uInfo.users_ID} />}
                </>
            }
        </>
    );
}