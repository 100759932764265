import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import recruiterbalmLogo from '../../img/RecruiterBalmLogo.jpg';
import nchcrLogo from '../../img/nchcr_brand_banner.png';
import './login.css';
import { toast } from '@rickylandino/react-messages';

import Globals from '../../config/globals';

import { UserOutlined, LoadingOutlined, LockOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, Alert } from 'antd';

import { useForm, Controller } from "react-hook-form";
import { validateUser } from '../../services/TokenService';

import { useNavigate } from "react-router-dom";
import { updateOrgInfo } from '../../services/OrganizationsService';
import { sendResetPasswordLink, userInfo, userPermissions, activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { getIPv4, insertAuditLogin } from '../../services/AuditLoginService';
import { v4 as uuidv4 } from 'uuid';
import { bandwidthNumbers } from '../../services/SmsService';
import NchcrLogo from '../../helpers/NchcrLogo';

const logo = Globals.brand === 'nchcr' ? nchcrLogo : recruiterbalmLogo;

export function Login(props) {

    const { register, watch, setValue, getValues, control } = useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const [uPermissions, setUPermissions] = useRecoilState(activeUserPermissions);

    const [showResetPasswordView, setShowResetPasswordView] = useState(false);
    const [showEmailSentAlert, setShowEmailSentAlert] = useState(false);

    const setBandwidthNumbers = useSetRecoilState(bandwidthNumbers);

    function resetPassword() {

        let postdata = {
            emailAddress: getValues().username
        }

        sendResetPasswordLink(postdata).then(data => {
            if (data) {
                setShowEmailSentAlert(true);
                setShowResetPasswordView(false);
            }
        });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);

        //var ipv4 = await getIPv4();
        validateUser(getValues().username, getValues().password).then(data => {
            if (data?.message === 'Password Change Required') {

                navigate({ pathname: "/reset-password", search: `?${data.resetToken.accessToken}` }, { replace: true, state: { showReason: true } });
            } else if (data === 'Disabled') {
                setLoading(false);
                toast.error("Your account has been disabled");
            } else if (data?.tokenResponse) {
                setLoading(false);
                if (data?.tokenResponse) {
                    Globals.bearer = data.tokenResponse.accessToken;
                    Globals.tokenStuff = data.tokenResponse;
                }
                if (data?.user) {
                    Globals.userInfo = data.user.user;
                    userInfo.next(data.user.user);
                    userPermissions.next(data.user.userPermissions);

                    //recoiljs implementation
                    setUInfo(data.user.user);
                    setUPermissions(data.user.userPermissions);
                }
                if (data?.organizationInfo) {
                    updateOrgInfo(data.organizationInfo);
                }

                if (data?.colors) {
                    Globals.colors = data.colors;
                }

                if (data?.bandwidthNumbers) {
                    setBandwidthNumbers(data.bandwidthNumbers);
                }

                let auditLoginItem = {
                    auditLogin_ID: uuidv4(),
                    users_ID: data?.user?.user.users_ID,
                    ipv4: '',
                    bearer: data.token
                };

                insertAuditLogin(auditLoginItem);
                window.sessionStorage.setItem("uid", auditLoginItem.auditLogin_ID);

                navigate("/dashboard", { replace: true });
            } else {
                console.log(data);
            }
        }).catch(error => {
            console.log(error);
            setLoading(false);
            toast.error("Inavlid Login Credentials");
        });
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <div className="">
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4 site-card-border-less-wrapper">
                    <img src={logo} height="65px" alt="Logo" aria-roledescription="logo" />
                </div>

                {showResetPasswordView ?
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        layout='vertical'
                        autocomplete="yes"
                    >
                        <Controller
                            name="username"
                            control={control}
                            render={({ field }) => <Form.Item label="Username" tooltip={{ title: 'The email tied to your account', icon: <InfoCircleOutlined /> }}>
                                <Input autocomplete prefix={<UserOutlined className="site-form-item-icon" />} onChange={(e) => setValue('username', e.target.value)} placeholder="Username" />
                            </Form.Item>
                            }
                        />

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" onClick={resetPassword}>
                                Send Reset Link
                            </Button>
                        </Form.Item>

                        <p className="a hover text-icon" onClick={() => setShowResetPasswordView(false)}><i className="fa fa-arrow-left" /> Back</p>
                    </Form>
                    :
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Controller
                            name="username"
                            control={control}
                            render={({ field }) => <Form.Item>
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} onChange={(e) => setValue('username', e.target.value)} placeholder="Username" />
                            </Form.Item>
                            }
                        />

                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => <Form.Item>
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} onChange={(e) => setValue('password', e.target.value)} placeholder="Password" />
                            </Form.Item>
                            }
                        />

                        <Form.Item>
                            {/*<Form.Item name="remember" noStyle>*/}
                            {/*    <Checkbox>Remember me</Checkbox>*/}
                            {/*</Form.Item>*/}

                            <span className="a hover login-form-forgot" onClick={() => setShowResetPasswordView(true)}>
                                Forgot password
                            </span> 
                        </Form.Item>

                        <Form.Item>
                            {loading ?
                                <Spin indicator={antIcon} />
                                :
                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={handleSubmit}>
                                    Secure Log in
                                </Button>
                            }
                        </Form.Item>
                    </Form>
                }

                {showEmailSentAlert ? (
                    <Alert className="mt-3" style={{ width: '450px' }} message="If your email was found, you will receive an email shortly with a link to reset your password. The link is valid for one hour." type="success" closable afterClose={() => setShowEmailSentAlert(false)} />
                ) : null}
                
            </div>
            <div style={{"position":"absolute","bottom":"20px","transform":"translate(-50%, -50%)","left":"50%"}}>
                <NchcrLogo />
            </div>
        </div>
    );
}