import React, { useState, useEffect } from 'react';
import { Badge, Calendar, List, Popover, Divider } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './planner.css';
import { useNavigate } from "react-router-dom";
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userInfo } from '../../services/UsersService';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import { eventTypes } from '../../services/OrganizationsService';
import { emptyEvent, userEvents } from '../../services/EventsService';
import Moment from 'moment';
import { EventSlider } from './EventSlider';
import { useForm, Controller } from "react-hook-form";

const progressPopover = (<Popover content="In Progress"><i className="fa-solid fa-repeat"></i></Popover>);
const completedPopover = (<Popover content="Completed"><i className="fa-solid fa-check text-success"></i></Popover>);
const highPriorityPopover = (<Popover content="High Priority"><i className="fa-solid fa-circle-exclamation text-danger"></i></Popover>);
const reminderPopover = (item) => (<Popover content={`You have a reminder scheduled for ${Moment(item.events.eventReminder).format("L LT")}`}><i className="fa-regular fa-bell"></i></Popover>);
const overduePopover = (<Popover content={`Overdue`}><i className="fa-solid fa-hourglass-end icon-sm"></i></Popover>);

export function Planner(props) {

    const { register, watch, setValue, getValues, control } = useForm();

    const navigate = useNavigate();
    const [uInfo, setUInfo] = useState({});
    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [types, setTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(Moment(new Date));
    const [filterValues, setFilterValues] = useState({
        status: 'not completed',
        eventType: '',
        priority: ''
    });

    const [tempState, setTempState] = useState(false);

    const [viewListData, setViewListData] = useState([]);

    const [eventList, setEventList] = useState({
        data: [],
        loaded: false
    });

    useEffect(() => {
        var listData = eventList.data.filter(event => Moment(event.events.eventDateTime).isSame(selectedDate, 'month'));

        if (filterValues.status === 'not completed') {
            listData = listData.filter(event => event.events.status !== 'completed');
        } else if (filterValues.status !== '') {
            listData = listData.filter(event => event.events.status === filterValues.status);
        }
        if (filterValues.eventType !== '') {
            listData = listData.filter(event => event.eventTypes?.eventTypes_ID.toString() === filterValues.eventType);
        }
        if (filterValues.priority !== '') {
            listData = listData.filter(event => event.events.priority === filterValues.priority);
        }
        setViewListData(listData);

    }, [filterValues, eventList, selectedDate, tempState]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo,
            eventTypes,
            userEvents
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo, eventTypes, userEvents]) => {
            if (userInfo?.users_ID) {
                setUInfo(userInfo);
            }

            if (eventTypes) {
                setTypes(eventTypes);
            }

            if (userEvents !== null) {

                var modifiedEvents = userEvents.map(item => ({
                    ...item,
                    type: 'rgb(45, 183, 245)',
                    content: item.events.subject
                }))

                setEventList({
                    data: modifiedEvents,
                    loaded: true
                });
            }
        });

        return () => { alive = false; }
    }, []);

    function getListData(value) {
        let listData = eventList.data.filter(event => Moment(event.events.eventDateTime).isSame(value, 'day'));
        if (filterValues.status === 'not completed') {
            listData = listData.filter(event => event.events.status !== 'completed');
        } else if (filterValues.status !== '') {
            listData = listData.filter(event => event.events.status === filterValues.status);
        }
        if (filterValues.eventType !== '') {
            listData = listData.filter(event => event.eventTypes?.eventTypes_ID.toString() === filterValues.eventType);
        }
        if (filterValues.priority !== '') {
            listData = listData.filter(event => event.events.priority === filterValues.priority);
        }
        return listData || [];
    };

    function editEvent(item) {
        setSelectedEvent(item);
        setShowModal(true);
    }

    function addEvent(value) {

        let eventToAdd = {
            events: {
                ...emptyEvent,
                eventDateTime: value.toDate(),
                eventTypes_ID: types.find(type => type.eventType === 'To-Do')?.eventTypes_ID
            },
            eventTypes: types.find(type => type.eventType === 'To-Do')
        };

        setSelectedEvent(eventToAdd);
        setShowModal(true);
    }

    function handleCalendarChange(date) {
        setSelectedDate(date);
    }

    function changeMonth(e, number) {
        e.preventDefault();

        let dt = selectedDate;
        dt = dt.add(number, 'months');

        setSelectedDate(dt);
        setTempState(!tempState);
    }

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <>
                <div className="calendar-item-hover add-new-item" onClick={() => addEvent(value)}>
                    <i className="fa-solid fa-plus text-success" onClick={() => addEvent(value)}></i>
                </div>
                <ul className="events" style={{ paddingTop: '5px' }}>
                    {listData.map((item) => (
                        <li className="calendar-item-hover" key={item.events.events_ID} onClick={() => editEvent(item)}>
                            <Badge className="ps-3" status={item.type} text={item.content} />
                        </li>
                    ))}
                </ul>
            </>
        );
    };



    const renderIcon = (status) => {
        switch (status) {
            case 'in progress':
                return progressPopover;
            case 'completed':
                return completedPopover;
            default:
                return null;
        }
    }

    const renderEntityIcon = (entity) => {
        switch (entity) {
            case 'job':
                return <i className="fa-solid fa-briefcase"></i>;
            case 'candidate':
                return <Popover content="This event is tied to a candidate"><i className="fa-regular fa-user candidate-text"></i></Popover>;
            case 'organization':
                return <i className="fa-solid fa-building"></i>;
            case 'event':
                return <i className="fa-solid fa-calendar"></i>;
            case 'message':
                return <i className="fa-solid fa-envelope"></i>;
            case 'task':
                return <i className="fa-solid fa-tasks"></i>;
            default:
                return null;
        }
    }

    const renderEventType = (item) => {
        var value = '';
        if(item.events.eventType) {
            value = item.events.eventType;
        } else if (item.eventTypes?.eventType) {
            value = item.eventTypes?.eventType;
        }

        return value ? <span><u>{value}</u>:</span> : null;
    }

    return (
        <>
            {eventList.loaded &&
                <div className="row">
                    <Divider orientation="left">Filter By</Divider>
                    <div className="form-group col-lg-3 col-12">
                        <label>Event Type</label>
                        <select className="ant-input" value={filterValues.eventType} onChange={(e) => setFilterValues({ ...filterValues, eventType: e.target.value })}>
                            <option value="">All</option>
                            {types.map((type, idx) => <option key={idx} value={type.eventTypes_ID}>{type.eventType}</option>)}
                        </select>
                    </div>
                    <div className="form-group col-lg-3 col-12">
                        <label>Status</label>
                        <select className="ant-input" value={filterValues.status} onChange={(e) => setFilterValues({ ...filterValues, status: e.target.value })}>
                            <option value="">All</option>
                            <option value="not completed">Not Completed</option>
                            <option value="not started">Not Started</option>
                            <option value="in progress">In Progress</option>
                            <option value="completed">Completed</option>
                            <option value="waiting">Waiting on someone else</option>
                        </select>
                    </div>
                    <div className="form-group col-lg-3 col-12">
                        <label>Priority</label>
                        <select className="ant-input" value={filterValues.priority} onChange={(e) => setFilterValues({ ...filterValues, priority: e.target.value })}>
                            <option value="">All</option>
                            <option value="low">Low</option>
                            <option value="normal">Normal</option>
                            <option value="high">High</option>
                        </select>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="float-end">
                            <button className="ant-btn me-2" onClick={(e) => changeMonth(e, -1)}><LeftOutlined /> Prev Month</button>
                            <button className="ant-btn" onClick={(e) => changeMonth(e, 1)}>Next Month <RightOutlined /></button>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <List
                            size="small"
                            className="event-list"
                            bordered
                            dataSource={viewListData}
                            header={<div>Events for {selectedDate.format("MMMM YYYY")} <button className="ant-btn ant-btn-primary float-end mb-2" onClick={() => addEvent(Moment(new Date()))}><i className="fa-solid fa-plus hover" onClick={() => addEvent(Moment(new Date()))}></i> Add Event</button></div>}
                            renderItem={item => (
                                <List.Item onClick={() => editEvent(item)} className={(Moment(item.events.eventDateTime).isBefore(new Date()) && !item.events.completedDate) ? "hover text-danger d-flex flex-column" : "hover d-flex flex-column"}>
                                    <List.Item.Meta
                                        title={<p>{renderEventType(item)} {item.content}
                                            &nbsp;
                                            {renderEntityIcon(item.eventsRelatedRecords?.recordType)}
                                            &nbsp;
                                            {renderIcon(item.events.status)}
                                            &nbsp;
                                            {item.events.priority === 'high' && highPriorityPopover}</p>}
                                        description={item.events.details}
                                        className="col-12"
                                    />
                                    <div className="col-12">
                                        <div>Due Date: {Moment(item.events.eventDateTime).format("L LT")}</div>
                                        <div>{item.events.eventReminder && <>Reminder: {Moment(item.events.eventReminder).format("L LT")} <i className="fa-regular fa-bell"></i></>}</div>
                                    </div>
                                    <div className="col-12">
                                        &nbsp;
                                        {(Moment(item.events.eventDateTime).isBefore(new Date()) && !item.events.completedDate) && overduePopover}
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div className="col-lg-8 col-12">
                        <h4 className="float-start ms-3">{selectedDate.format("MMMM YYYY")}</h4>
                        <Calendar dateCellRender={dateCellRender} value={selectedDate} onChange={handleCalendarChange} />
                    </div>
                </div>
            }
            <EventSlider showModal={showModal} hideModal={() => setShowModal(false)} selectedEvent={selectedEvent} eventTypes={types} selectedDate={selectedDate} users_ID={uInfo.users_ID} />
        </>
    );
}