import React, { useState, useEffect } from 'react';
import { Table, Descriptions, Empty, Divider } from 'antd';
import useSWR from 'swr';
import { fetcher } from '../../swr/fetcher';
import { useRecoilState } from 'recoil';
import { clientJobPlacementTableParams } from '../../services/ClientsService';
import { useNavigate } from "react-router-dom";

export default function JobPlacements({clients_ID}) {
    const navigate = useNavigate();

    const [placementStatus, setPlacementStatus] = useState('Active');
    const [tableParams, setTableParams] = useRecoilState(clientJobPlacementTableParams);

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });

    const key = `/api/GetJobPlacementsByClient?clients_ID=${clients_ID}&placementStatus=${placementStatus}&pageSize=${tableParams.pagination.pageSize}&offset=${tableParams.pagination.current - 1}&sortBy=${sortStuff.sortBy}&sortDirection=${sortStuff.sortDirection}`;
    const { data, error, isLoading, isValidating } = useSWR(key, fetcher);

    useEffect(() => {
        if(data) {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.length > 0 ? data[0].totalCount : 0
                }
            });
        }
    }, [data]);

    function navigateToJobDashboard(row) {
        navigate(`/job-dashboard/${row.jobs_ID}`,
            {
                replace: true
            });
    }

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if(sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    var jobColumns = [
        {
            title: 'Job Name',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'jobTitle' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'jobTitle' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Recruiter',
            dataIndex: 'recruiter',
            key: 'recruiter',
        }
    ];

    const columns = jobColumns.filter(c => !c.hide);
    
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    const dropdownRender = (record) => {
        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>Specialty:</label>
                        &nbsp;
                        {record.parentSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Sub Specialty:</label>
                        &nbsp;
                        {record.subSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>City:</label>
                        &nbsp;
                        {record.city}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>State:</label>
                        &nbsp;
                        {record.state}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Descriptions
                title="Job Placements"
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size='small'
            >
            </Descriptions>
            <div className="row">
                <div className="col-lg-6 col-12">
                    <label>Filter by Placement Status</label>
                    <select className="ant-input" value={placementStatus} onChange={(e) => setPlacementStatus(e.target.value)}>
                        <option>Active</option>
                        <option value="Inactive">On Hold/Inactive</option>
                        <option>Terminated</option>
                        <option>Completed</option>
                    </select>
                </div>
            </div>
            <Divider />
            {data?.length > 0 ?
                <>
                    <Table
                        rowKey={(record) => record.jobs_ID}
                        dataSource={data}
                        columns={columns}
                        size='small'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToJobDashboard(record) : console.log("Selected") }, // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                        expandable={{
                            expandedRowRender: (record) => dropdownRender(record),
                            rowExpandable: (record) => record.jobs_ID !== 'Not Expandable',
                        }}
                        onChange={handleTableChange}
                        pagination={tableParams.pagination}
                    />
                </>
                :
                <Empty description={<p>No found association between jobs and placement workflow</p>} />
            }
        </>
    )
}