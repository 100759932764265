import React, { useState, useEffect, useRef } from 'react';

import { Descriptions, Drawer, Alert, Select } from 'antd';
import statesList from '../../../helpers/StatesList';
import countryStatesList from '../../../helpers/CountriesStates';
import Globals from '../../../config/globals';

import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { canSelectedClientBeUpdated, updateClient } from '../../../services/ClientsService';
import { useRecoilValue } from 'recoil';
import { CapitalizeFirstLetter } from '../../../helpers/ManipulateText';
import { getChangesToTrack } from '../../../helpers/DetectChanges';

export function ContactInformationCard(props) {

    const { register, getValues, setValue, control, watch, formState } = useForm();
    
    const canUpdate = useRecoilValue(canSelectedClientBeUpdated);

    const isDirty = useRef(false);
    const [state, setState] = useState({
        selectedClient: props.selectedClient.client,
        states: statesList.filter(obj => { return obj.country_code === 'US' }),
        persistentContactInformation: props.selectedClient.client
    });

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (edit) {
            let ff = { ...props.selectedClient.client };
            setValue('formFields', ff);
        }
    }, [edit]);

    useEffect(() => {
        setState({
            ...state,
            selectedClient: props.selectedClient.client,
            persistentInformation: props.selectedClient.client
        });

        setValue('formFields', props.selectedClient.client);
    }, [props.selectedClient]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
                Globals.isDirtyPage = true;
            }

            if (name === 'formFields.country') {
                var states = statesList.filter(obj => {
                    return obj.country_code === value.formFields.country
                });

                setState({
                    ...state,
                    states
                });

                setValue('formFields.state', '');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setValue('formFields', state.persistentContactInformation);

        var states = statesList.filter(obj => {
            return obj.country_code === state.persistentContactInformation.country
        });

        setState({
            ...state,
            states,
            formFields: { ...state.persistentContactInformation },
        });

        setEdit(false);
        isDirty.current = false;
    }

    function handleSubmit() {
        let postdata = {
            client: { ...getValues().formFields },
            auditChangesList: [getChangesToTrack(state.persistentContactInformation, { ...getValues().formFields }, "Clients", "Client Details")]
        };

        updateClient(postdata).then(data => {
            if (data) {
                toast.success("Client information has been updated");
                setState({
                    ...state,
                    persistentContactInformation: postdata.client
                });
                isDirty.current = false;
                setEdit(false);

                props.updateClientInformation(data);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    return (
        <>
            {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square client-text icon-md hover" onClick={openEdit}></i>}

            <Descriptions
                title="General Information"
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size='small'
                labelStyle={{ width: 300 }}
            >
                <Descriptions.Item label="Client Type">{CapitalizeFirstLetter(state.persistentContactInformation?.clientType || '')}</Descriptions.Item>
                <Descriptions.Item label="Cell Phone">
                    {state.persistentContactInformation?.cellPhone &&
                        <a href={"tel:" + state.persistentContactInformation.cellPhone}>{state.persistentContactInformation.cellPhone}</a>
                    }
                </Descriptions.Item>
                <Descriptions.Item label="Work Phone">
                    {state.persistentContactInformation?.workPhone &&
                        <a href={"tel:" + state.persistentContactInformation.workPhone}>{state.persistentContactInformation.workPhone}</a>
                    }
                </Descriptions.Item>
                <Descriptions.Item label="Work Phone Extension">{state.persistentContactInformation?.workExtension}</Descriptions.Item>
                <Descriptions.Item label="Email Address">{state.persistentContactInformation?.emailAddress}</Descriptions.Item>
                <Descriptions.Item label="Country">{state.persistentContactInformation?.country}</Descriptions.Item>
                <Descriptions.Item label="Address">
                    <p className="no-bottom-margin line-height-1-5 xs-margin-top">{state.persistentContactInformation.address1}</p>
                    <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.address2}</p>
                    {state.persistentContactInformation.city && <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.city}, {state.persistentContactInformation.state} {state.persistentContactInformation.zip}</p>}
                    {(!state.persistentContactInformation.city && state.persistentContactInformation.state) && <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.state} {state.persistentContactInformation.zip}</p>}
                </Descriptions.Item>
            </Descriptions>

            <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title='Edit Contact Information'
                placement={'right'}
                onClose={handleClose}
                open={edit}
                key='slider'
                size='large'
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                        </div>
                    </div>
                }
            >
                <div className="row">
                    <div className="form-group col-12">
                        <label>Client Name</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.name')} />
                    </div>

                    <div className="form-group col-lg-4 col-12">
                        <label className="form-label">Cell Phone Number</label>
                        <Controller
                            control={control}
                            name="formFields.cellPhone"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                            }
                        />
                    </div>

                    <div className="form-group col-lg-4 col-12">
                        <label className="form-label">Work Phone Number</label>
                        <Controller
                            control={control}
                            name="formFields.workPhone"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                            }
                        />
                    </div>

                    <div className="form-group col-lg-3 col-12">
                        <label className="form-label">Work Extension</label>
                        <input maxLength="10" className="ant-input" type="text" {...register('formFields.workExtension')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label className="d-block">Email</label>
                        <input maxLength="100" className="ant-input" type="email" {...register('formFields.emailAddress')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label className="d-block">Client Type</label>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('formFields.clientType')} value='client' />
                            <label> Client</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('formFields.clientType')} value='prospect' />
                            <label> Prospect</label>
                        </div>
                    </div>

                    <div className="form-group col-12">
                        <label>Country</label>
                        <select className="ant-input" {...register('formFields.country')} >
                            {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Address</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.address1')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Address 2</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.address2')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>City</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.city')} />
                    </div>

                    <div className="form-group col-lg-3 col-12">
                        <label>State</label>
                        <select className="ant-input" {...register('formFields.state')}>
                            <option></option>
                            {state.states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-3 col-12">
                        <label>Zip</label>
                        <input maxLength="10" className="ant-input" {...register('formFields.zip')} />
                    </div>
                </div>
            </Drawer>
        </>
    );
}