import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from "react-router-dom";
import { Descriptions, Drawer, Input } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useForm, Controller } from 'react-hook-form';
import Globals from '../../../config/globals';
import { activeUserCandidatesState, canSelectedCandidateBeUpdated, editCandidate, userCandidates } from '../../../services/CandidateService';
import { NumericFormat } from 'react-number-format';
import { useMediaQuery } from 'react-responsive';
import { getChangesToTrack } from '../../../helpers/DetectChanges';

export function CompensationCard(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);

    const { register, getValues, setValue, control, watch, formState } = useForm();

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        formFields: props.selectedCandidate.candidates,
        persistentInformation: props.selectedCandidate.candidates
    });

    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const [currentCompensationType, setCurrentCompensationType] = useState('');
    const [desiredCompensationType, setDesiredCompensationType] = useState('');

    const [isActive, setIsActive] = useState(false);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
                setValue('formFields', value.formFields);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        let formFields = { ...props.selectedCandidate.candidates };

        setValue('formFields', formFields);
        setCurrentCompensationType(formFields.currentCompensationType);
        setDesiredCompensationType(formFields.desiredCompensationType);
    }, [edit]);

    function handleRadioChange(e) {
        let { target } = e;
        let { name, value } = target;

        isDirty.current = true;

        switch (name) {
            case 'desiredCompensationType':
                setDesiredCompensationType(value);
                break;
            case 'currentCompensationType':
                setCurrentCompensationType(value);
                break;
            default:
                break;
        }
    }

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setEdit(false);
                        isDirty.current = false;

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function handleSubmit() {
        let { formFields } = { ...getValues() };

        if (formFields.currentCompensation.includes('$')) {
            formFields.currentCompensation = formFields.currentCompensation.slice(1);
        }
        if (formFields.desiredCompensation.includes('$')) {
            formFields.desiredCompensation = formFields.desiredCompensation.slice(1);
        }

        formFields.currentCompensationType = currentCompensationType;
        formFields.desiredCompensationType = desiredCompensationType;

        let postdata = {
            candidatesModel: formFields,
            auditChangesList: [getChangesToTrack(state.persistentInformation, formFields, "Candidates", "Compensation")]
        };

        toast.loading("Updating candidate...", { key: 'loading' });
        editCandidate(postdata).then(data => {
            toast.destroy('loading');
            toast.success("Candidate has been updated");

            setState({
                ...state,
                persistentInformation: formFields
            });

            setEdit(false);

            isDirty.current = false;
            Globals.isDirtyPage = false;

            var currentCandidate = data.userCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentInformation?.candidates_ID);
            if (!currentCandidate) {
                currentCandidate = data.companyCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentInformation?.candidates_ID);
            }

            if (currentCandidate) {
                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: currentCandidate, from: location.state.from, activeTab: 'compensation' }
                });
            }

            userCandidates.next(data);
            setActiveUserCandidates(data);
        }).catch(error => {
            toast.error("Something went wrong");

            isDirty = false;
            Globals.isDirtyPage = false;
        });
    }

    return (
        <>
            {isActive &&
                <>
                    {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    <Descriptions
                        title="Compensation Information"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size='small'
                    labelStyle={{ width: 300 }}
                    layout={isDesktop ? 'horizontal' : 'vertical'}
                    >
                        <Descriptions.Item label='Current Compensation'>{state.persistentInformation?.currentCompensation ? `$${state.persistentInformation?.currentCompensation}` : 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Desired Compensation'>{state.persistentInformation?.desiredCompensation ? `$${state.persistentInformation?.desiredCompensation}` : 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Desired Position'>{state.persistentInformation?.desiredPosition ? `${state.persistentInformation?.desiredPosition}` : 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label='Compensation Comments'>{state.persistentInformation?.compensationComments}</Descriptions.Item>
                    </Descriptions>

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Candidate Compensation'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="form-group col-lg-6 col-12">
                                <label>Current Compensation</label>
                                <Controller
                                    control={control}
                                    name="formFields.currentCompensation"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <NumericFormat className="ant-input" thousandSeparator="," prefix={'$'} onChange={onChange} value={value} />
                                    }
                                />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className='d-block'>Compensation Type</label>
                                <div className="inline-custom-radio-container">
                                <input type="radio" className="custom-radio" name='currentCompensationType' value='annual' onChange={handleRadioChange} checked={currentCompensationType === 'annual'} />
                                    <label> Annually</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" name='currentCompensationType' value='hourly' onChange={handleRadioChange} checked={currentCompensationType === 'hourly'}  />
                                    <label> Hourly</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Desired Compensation</label>
                                <Controller
                                    control={control}
                                    name="formFields.desiredCompensation"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <NumericFormat className="ant-input" thousandSeparator="," prefix={'$'} onChange={onChange} value={value} />
                                    }
                                />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className='d-block'>Compensation Type</label>
                            <div className="inline-custom-radio-container">
                                <input type="radio" className="custom-radio" name='desiredCompensationType' value='annual' onChange={handleRadioChange} checked={desiredCompensationType === 'annual'} />
                                    <label> Annually</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                <input type="radio" className="custom-radio" name='desiredCompensationType' value='hourly' onChange={handleRadioChange} checked={desiredCompensationType === 'hourly'} />
                                    <label> Hourly</label>
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <label>Desired Position</label>
                                <input maxLength="300" className="ant-input" {...register('formFields.desiredPosition')} />
                            </div>

                            <div className="form-group col-12">
                                <label>Additional Comments</label>
                                <textarea maxLength="2000" className="ant-input" {...register('formFields.compensationComments')} />
                            </div>
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}