import React, { useEffect, useState } from 'react';
import { Messenger } from '../SMS/Messenger';
import { Drawer } from 'antd';

export function MessageCampaignMembers(props) {

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    useEffect(() => {
        if (props.showModal) {
            setModalInfo({
                show: true
            });
        }
    }, [props.showModal]);

    function handleClose() {
        setModalInfo({
            show: false
        });

        setTimeout(() => {
            props.hideModal();
        }, 1000);
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Text Campaign Members'
            placement={'right'}
            onClose={handleClose}
            open={modalInfo.show}
            key='drawer'
            width={'100%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div>
                        <button className="btn btn-outline-primary float-right">Close</button>
                    </div>
                </div>
            }
        >
            <Messenger from='campaigns' selectedCandidates={props.selectedCandidates} selectedUsers={props.selectedUsers} />
        </Drawer>
    )
}