import Axios from '../config/axios';

export function getArchivedEventTypesByOrganization(org_ID) {
    return Axios.get(`/api/GetArchivedEventTypesByOrganization`, { params: { org_ID } }
    ).then(response => response.data).catch(error => error);
}

export function updateEventTypes(postdata) {
    return Axios.post(`/api/UpdateEventTypes`, postdata
    ).then(response => response.data).catch(error => error);
}

export function insertEventType(postdata) {
    return Axios.post(`/api/AddEventType`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateEventType(postdata) {
    return Axios.post(`/api/UpdateEventType`, postdata
    ).then(response => response.data).catch(error => error);
}

export function archiveEventType(postdata) {
    return Axios.post(`/api/ArchiveEventType`, postdata
    ).then(response => response.data).catch(error => error);
}

