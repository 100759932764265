import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Tag, Select } from 'antd';
import { confirm, toast } from '@rickylandino/react-messages';
import { MultiSelectInput } from '../../../helpers/MultiSelectInput';
import Globals from '../../../config/globals';
import { activeUserCandidatesState, canSelectedCandidateBeUpdated, editCandidate, userCandidates } from '../../../services/CandidateService';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { activeOrgTagsState, orgTags } from '../../../services/OrganizationsService';
import { insertTag } from '../../../services/TagsService';
import { getChangesToTrack } from '../../../helpers/DetectChanges';
import { activeUserInfo } from '../../../services/UsersService';

export function TagsCard(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);
    
    const [activeOrgTags, setActiveOrgTags] = useRecoilState(activeOrgTagsState);

    const isDirty = useRef(false);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        candidateInformation: props.selectedCandidate.candidates,
        persistentCandidateInformation: props.selectedCandidate.candidates
    });

    let theseTags = props.dashboardInformation.clientAssociatedTags.map(tg => (tg.tags_ID));

    const [selectedTags, setSelectedTags] = useState([...theseTags]);
    const [stickyTags, setStickyTags] = useState([...theseTags]);

    const [allTags, setAllTags] = useState({
        show: false,
        tagsList: [],
        unModifiedList: []
    });

    const [edit, setEdit] = useState(false);
    const [update, setUpdate] = useState(false);

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            orgTags
        ).pipe(takeWhile(() => alive)).subscribe(([orgTags]) => {
            setAllTags({
                show: true,
                tagsList: [...orgTags.map(ot => ({ label: ot.tag, value: ot.tags_ID }))],
                unModifiedList: orgTags
            });
        });


        return () => { alive = false; }
    }, []);

    function createTag(e) {
        if (e.keyCode === 13) {
            if (!allTags.tagsList.find(tg => tg.label.includes(e.target.value))) {
                //let postdata = {
                //    tagsModel: {}
                //};

                let tag = {
                    tag: e.target.value,
                    organizations_ID: Globals.userInfo.organizations_ID
                }

                //postdata.tagsModel.tag = e.target.value;
                //postdata.tagsModel.organizations_ID = Globals.userInfo.organizations_ID;

                insertTag(tag).then(data => {
                    if (data === 'duplicate') {
                        toast.error("This tag already exists");
                    } else {
                        let st = [...selectedTags];
                        let tagsList = [...allTags.unModifiedList];

                        let tags_ID = data;

                        let t = {
                            tags_ID,
                            organizations_ID: Globals.userInfo.organizations_ID,
                            tag: e.target.value
                        };

                        st.push(tags_ID);
                        tagsList.push(t);

                        setSelectedTags([...st]);
                        orgTags.next(tagsList);
                        setActiveOrgTags(tagsList);

                        //setUpdate(!update);
                        e.target.value = '';

                        isDirty.current = true;

                    }
                }).catch(error => {
                    toast.error("Something went wrong");
                });
            }
        }
        
    }

    function updateSelectedItems(st) {
        isDirty.current = true;

        setSelectedTags([...st]);

        setUpdate(!update);
    }

    function openEdit() {
        setUpdate(!update);
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setSelectedTags([...stickyTags]);

                        setEdit(false);
                        isDirty.current = false;                        

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function handleSubmit(e) {
        let candidateInformation = { ...state.candidateInformation };

        let tags = "";
        for (var i = 0; i < selectedTags.length; i++) {
            tags += selectedTags[i] + "|";
        }
        candidateInformation.tags = tags;

        let postdata = {
            candidatesModel: candidateInformation,
            auditChangesList: [getChangesToTrack(state.persistentCandidateInformation, candidateInformation, "Candidates", "Tags")]
        };

        toast.loading("Updating candidate...", { key: 'loading' });
        editCandidate(postdata).then(data => {
            toast.destroy('loading');
            toast.success("Candidate has been updated");

            setState({
                ...state,
                candidateInformation,
                persistentCandidateInformation: { ...candidateInformation }
            });

            setStickyTags([...selectedTags]);

            setEdit(false);

            isDirty.current = false;
            Globals.isDirtyPage = false;

            var currentCandidate = data.userCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentCandidateInformation?.candidates_ID);
            if (!currentCandidate) {
                currentCandidate = data.companyCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentCandidateInformation?.candidates_ID);
            }

            if (currentCandidate) {
                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: currentCandidate, from: location.state.from, activeTab: 'tags' }
                });
            }

            userCandidates.next(data);
            setActiveUserCandidates(data);
        }).catch(error => {
            toast.error("Something went wrong");

            isDirty.current = false;
            Globals.isDirtyPage = false;
        });
    }

    return (
        <>
            {isActive &&
                <>
                {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    <Descriptions
                        title="Tags"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size='small'
                        labelStyle={{ width: 300 }}
                    >
                        <Descriptions.Item label="Tags">
                            {stickyTags?.map((tag) => {
                                if (tag) {
                                    return (
                                        <Tag key={tag}>
                                            {allTags.tagsList.find(tg => tg.value === tag)?.label}
                                        </Tag>
                                    );
                                }
                            })}
                        </Descriptions.Item>
                    </Descriptions>

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Candidate Tags'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="col col-12">
                                <label>Tags</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedTags}
                                    onChange={updateSelectedItems}
                                    options={allTags.tagsList}
                                    optionFilterProp='label'
                                    onInputKeyDown={createTag}
                                    notFoundContent='Press enter to create a new item'
                                />
                            </div>
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}