import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { hashwordConversion, insertSingleUser, updateUser, updateUserProfilePicture, userInfo, userPermissions, activeUserPermissions, generateNewPassword } from '../../../services/UsersService';
import { Card, Switch, List, Button, Upload, Popover, Divider, Modal, Tooltip, Popconfirm, Empty, Collapse, Radio, Select, Input, Form } from 'antd';
import { CopyOutlined, InfoCircleOutlined, LockOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BlockPicker } from 'react-color';
import Globals from '../../../config/globals';
import { getColorShade, newShade } from '../../../helpers/ColorShades';
import $ from 'jquery';
import { getAvailableNumbers, bandwidthNumbers, orderNumber } from '../../../services/SmsService';

export const defaultRecruiterPermissions = {
    manageTags: false,
    manageEventTypes: false,
    manageWorkflowItems: false,
    manageJobTypes: false,
    manageSpecialties: false,
    createCandidates: true,
    readCandidates: 'company',
    updateCandidates: 'personal',
    deleteCandidates: 'none',
    deleteAttachments: false,
    exportCandidates: false,
    createCampaigns: true,
    readCampaigns: 'company',
    updateCampaigns: 'personal',
    deleteCampaigns: 'none',
    createClients: true,
    readClients: 'company',
    updateClients: 'personal',
    deleteClients: 'none',
    createJobs: true,
    readJobs: 'company',
    updateJobs: 'personal',
    deleteJobs: 'none'
};

export const defaultAdminPermissions = {
    manageTags: true,
    manageEventTypes: true,
    manageWorkflowItems: true,
    manageJobTypes: true,
    manageSpecialties: true,
    createCandidates: true,
    readCandidates: 'company',
    updateCandidates: 'company',
    deleteCandidates: 'company',
    deleteAttachments: true,
    exportCandidates: true,
    createCampaigns: true,
    readCampaigns: 'company',
    updateCampaigns: 'company',
    deleteCampaigns: 'company',
    createClients: true,
    readClients: 'company',
    updateClients: 'company',
    deleteClients: 'company',
    createJobs: true,
    readJobs: 'company',
    updateJobs: 'company',
    deleteJobs: 'company'
};

export function ManageProfile(props) {
    const { register, getValues, setValue, control, watch, formState } = useForm();
    const [uInfo, setUInfo] = useState({});

    const [uPermissions, setUPermissions] = useRecoilState(activeUserPermissions)

    const [img, setImg] = useState(null);
    const [fullImgUrl, setFullImgUrl] = useState(null);

    const [showFindNumbersModal, setShowFindNumbersModal] = useState(false);

    const [selectedNumberToPurchase, setSelectedNumberToPurchase] = useState(null);
    const [telephoneNumberList, setTelephoneNumberList] = useState([]);

    const [newPasswordModal, setNewPasswordModal] = useState({
        show: false,
        showNewPassword: false,
        newPassword: '',
    });

    const [selectedColor, setSelectedColor] = useState({
        color: props.user?.user?.color || '',
        background: getColorShade(props.user?.user?.color)
    });

    const [file, setFile] = useState([
    ]);

    var userPermissionsTouched = useRef(false);

    const availableBandwidthNumbers = useRecoilValue(bandwidthNumbers);
    const [selectableNumbers, setSelectableNumbers] = useState([]);

    const { Panel } = Collapse;

    useEffect(() => {
        if (props.user?.user?.users_ID && uInfo.users_ID) {
            if (props.user.user.profilePicture) {
                setFile([
                    {
                        uid: "-1",
                        name: "image.png",
                        status: "done",
                        url: `data:image/jpeg;base64,${props.user.user.profilePicture}`
                    }
                ])
                setImg(props.user.user.profilePicture);
            }

            if (availableBandwidthNumbers) {
                const sn = availableBandwidthNumbers?.filter(bwNum => !bwNum.user || bwNum.user.users_ID === props.user.user.users_ID)?.map(bwNum => ({
                    label: bwNum.phoneNumber,
                    value: bwNum.phoneNumber
                }));

                sn.unshift({
                    label: '',
                    value: ''
                });

                setSelectableNumbers(sn);
            }
        }

        let user = { ...props.user };
        if (!user.user?.users_ID) {
            user.userPermissions = defaultRecruiterPermissions;
        }

        setValue('formFields', user);


    }, [props.user, uInfo]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data]) => {
            if (data?.users_ID) {
                setUInfo(data);
            }
        });

        return () => {
            alive = false;
        }
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                if (name === 'formFields.user.userType') {
                    if (userPermissionsTouched.current || value.formFields.user.users_ID) {
                        showChangePermissionsModal(value.formFields.user.userType);
                    } else {
                        setDefaultPermissions(value.formFields.user.userType);
                    }
                }

                if (name.includes('formFields.userPermissions')) {
                    userPermissionsTouched.current = true;
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function showChangePermissionsModal(userType) {
        Modal.confirm({
            title: 'Reset User Permissions',
            content: 'Would you like to reset the user permissions to align with the default permissions assigned to this User Type?',
            onOk() {
                setDefaultPermissions(userType);
            },
            onCancel() {
            },
            okText: 'Yes',
            cancelText: 'No'
        });
    }

    function setDefaultPermissions(userType) {
        switch (userType) {
            case 'Recruiter':
                setValue('formFields.userPermissions', defaultRecruiterPermissions);
                break;
            case 'OrgAdmin':
                setValue('formFields.userPermissions', defaultAdminPermissions);
                break;
            default:
                break;
        }
    }

    function handleSubmit() {
        //let user = { ...getValues().formFields.user };

        let postdata = { ...getValues().formFields }
        if (!postdata.user?.emailAddress) {
            Modal.warning({
                title: "Please make sure all required fields are filled in",
            });
        } else {
            if (postdata.user?.users_ID) {
                updateUser(postdata).then(data => {
                    if (data > 0) {
                        toast.success("User has been updated");

                        //if current user who is logged in
                        if (postdata.user.users_ID === uInfo.users_ID) {
                            let newUserInfo = postdata.user;
                            let newUserPermissions = postdata.userPermissions;

                            userInfo.next(newUserInfo);

                            userPermissions.next(newUserPermissions);
                            setUPermissions(newUserPermissions);
                        } else {
                            if (props.updateUsers) {
                                props.updateUsers(postdata);
                            }
                        }

                    } else if (data === -1) {
                        toast.error("This email already exists in our system");
                    } else {
                        toast.error("Something went wrong");
                    }
                });
            } else {
                insertSingleUser(postdata).then(data => {
                    if (data) {
                        postdata.user.users_ID = data.users_ID;
                        postdata.userPermissions.users_ID = data.users_ID;

                        if (props.updateUsers) {
                            props.updateUsers(postdata);
                        }

                        Modal.success({
                            title: 'User has been added.',
                            content: (
                                <div>
                                    <p>Below is their password. If this user was assigned a random password,then they will be prompted to change their password on first login.</p>
                                    <p className="hover copy" title="Copy to clipboard" onClick={() => {
                                        navigator.clipboard.writeText(data.tempPassword);
                                        toast.success("Copied to clipboard");
                                    }}><strong>{data.tempPassword}</strong> <CopyOutlined className="float-end" /></p>
                                </div>
                            ),
                            onOk() { },
                        });

                    } else if (data?.message) {
                        toast.error(data.message);
                    } else {
                        toast.error("Something went wrong");
                    }
                });
            }
        }
    }

    function encryptPassword() {
        let postdata = props.user.user;

        hashwordConversion(postdata).then(data => {
        });
    }

    function handleColorChange(color, event) {
        if (color) {
            //let foundColor = Globals.colors.find(col => col.color === color.hex);
            let foundColor = {
                color: color.hex,
                background: getColorShade(color.hex)
            }
            if (!foundColor) {
                foundColor = {
                    color: color.hex,
                    background: getColorShade(color.hex)
                }
            }

            setValue('formFields.user.color', foundColor.color);
            setSelectedColor(foundColor);
        }
    }

    function handleUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let imgUrl = reader.result?.split("base64,");
            if (imgUrl) {
                setFullImgUrl(imgUrl);
                let imgSplit = imgUrl[1];
                setImg(imgSplit);
                setValue('formFields.user.profilePicture', imgSplit);
            }
            setFile((prev) => [{ url: reader.result }]);
        };

        // then upload `file` from the argument manually
        return false;
    }

    function onRemove(file) {
        setFile([]);
        setImg(null);
        setValue('formFields.user.profilePicture', null);
    }

    function handleGetPhoneNumbers() {
        getAvailableNumbers(getValues().phoneSearch.areaCode).then(data => {
            setTelephoneNumberList(data?.telephoneNumberList || []);
        });
    }

    function selectPhoneNumberToPurchase() {

    }

    function orderBandwidthNumber() {

        let postdata = {
            number: selectedNumberToPurchase,
            users_ID: uInfo.users_ID
        }

        orderNumber(postdata).then(data => {
            let formattedNumber = formatPhoneNumber(selectedNumberToPurchase);
            let foundNumber = data.find(item => item.phoneNumber === formattedNumber);

            if (foundNumber) {
                setValue('formFields.user.smsNumber', formattedNumber);
                handleSubmit();

                setShowFindNumbersModal(false);
            } else {
                toast.error("Something went wrong");
            }

        });
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return null;
    }

    function giveNewPassword() {
        let thisUser = { ...getValues().formFields }
        let tempPassword = getValues().temporaryPassword
        let users_ID = thisUser.user?.users_ID;

        let postdata = {
            users_ID,
            newPassword: tempPassword ? tempPassword : ''
        }

        generateNewPassword(postdata).then(data => {
            if (data === 'fail') {
                toast.error("Something went wrong");
            } else {
                setNewPasswordModal({
                    ...newPasswordModal,
                    showNewPassword: true,
                    newPassword: data
                });

                setValue('temporaryPassword', '');
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    const optionsAvailable = [
        {
            label: 'Personal',
            value: 'personal'
        },
        {
            label: 'Organizational',
            value: 'company'
        },
        {
            label: 'None',
            value: 'none'
        },
    ];

    return (
        <>
            {props.user?.user?.users_ID !== null &&
                <div className="row">
                    <div className="col-xl-3 col-12">
                        <Card className="m-2" title="User Profile">
                            <div className="row">
                                <div className="col-12">
                                    <ImgCrop rotate>
                                        <Upload
                                            accept="image/*"
                                            listType="picture-card"
                                            fileList={file}
                                            maxCount={1}
                                            beforeUpload={handleUpload}
                                            onRemove={onRemove}
                                            showUploadList={{ showPreviewIcon: false }}
                                        >
                                            {file?.length < 1 && ' + Upload'}
                                            {/*img ? <img src={`data:image/jpeg;base64,${img}`} alt="avatar" style={{ width: '100%' }} /> : ' + Upload'*/}
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                {img &&
                                    <div className="col-12">
                                        <ImgCrop rotate>
                                            <Upload accept="image/*" beforeUpload={handleUpload} showUploadList={false}>
                                                <Button>Edit Image</Button>
                                            </Upload>
                                        </ImgCrop>
                                    </div>
                                }
                                {!img &&
                                    <div className="col-12">
                                        <Divider>OR</Divider>
                                        <Popover
                                            content={<BlockPicker triangle="hide" onChange={handleColorChange} colors={Globals.colors} color={selectedColor.color} />}
                                            trigger="click"
                                        >
                                            <button className="ant-btn ant-btn-outline" type="primary">Choose avatar color</button>
                                        </Popover>
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                    <div className="col-xl-9 col-12">
                        <Card className="m-2" title="User Information">
                            <div className="row">
                                <div className="form-group col-lg-6 col-12">
                                    <label>First Name</label>
                                    <input maxLength="36" className="ant-input" {...register('formFields.user.firstName')} />
                                </div>
                                <div className="form-group col-lg-6 col-12">
                                    <label>Last Name</label>
                                    <input maxLength="36" className="ant-input" {...register('formFields.user.lastName')} />
                                </div>
                                <div className="form-group col-lg-5 col-12">
                                    <label className="">Cell Phone #</label>
                                    <Controller
                                        control={control}
                                        name="formFields.user.cellPhone"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                                        }
                                    />
                                </div>
                                <div className="form-group col-lg-5 col-12">
                                    <label className="">Work Phone #</label>
                                    <Controller
                                        control={control}
                                        name="formFields.user.workPhone"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                                        }
                                    />
                                </div>
                                <div className="form-group col-lg-2 col-12">
                                    <label>Extension</label>
                                    <input maxLength="10" className="ant-input" {...register('formFields.user.extension')} />
                                </div>
                                <div className="form-group col-lg-6 col-12">
                                    <label>Email <span className="text-danger">*</span></label>
                                    <input maxLength="100" className="ant-input" {...register('formFields.user.emailAddress')} />
                                </div>
                                <div className="form-group col-lg-6 col-12">
                                    <label>User Type</label>
                                    <select className="ant-input" {...register('formFields.user.userType')} disabled={!(uInfo.userType === 'OrgAdmin' || uInfo.userType === 'SuperAdmin')}>
                                        <option value="Recruiter">Recruiter</option>
                                        <option value="OrgAdmin">Admin</option>
                                        {(uInfo.userType === 'SuperAdmin' && props.isAdminOrg) && <option value="SuperAdmin">Super Admin</option>}
                                    </select>
                                </div>
                                {(uInfo.users_ID !== props.user?.user?.users_ID && (uInfo.userType === 'OrgAdmin' || uInfo.userType === 'SuperAdmin')) &&
                                    <>
                                        <div className="form-group col-4">
                                            <label className="d-block">Force Password Reset</label>
                                            <Controller
                                                control={control}
                                                name="formFields.user.forcePasswordChange"
                                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                                    <Switch checked={value} onChange={onChange} />
                                                }
                                            />
                                        </div>
                                        <div className="form-group col-4">
                                            <label className="d-block">Disable User</label>
                                            <Controller
                                                control={control}
                                                name="formFields.user.disabled"
                                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                                    <Switch checked={value} onChange={onChange} />
                                                }
                                            />
                                        </div>
                                    </>
                                }

                                <div className="form-group col-lg-5 col-12">
                                    <label className="">SMS Phone # <Tooltip title={uInfo.userType === 'SuperAdmin' ? "Bandwidth phone number that will be assigned to this recruiter." : "Your assigned phone number that can be used to text throughout the app."}><InfoCircleOutlined /></Tooltip></label>


                                    <Controller
                                        control={control}
                                        name="formFields.user.smsNumber"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                showSearch
                                                htmlFor="smsNumber"
                                                placeholder="Select a number"
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').includes(input)
                                                }
                                                options={selectableNumbers}
                                                className="ant-input"
                                                value={value}
                                                disabled={uInfo.userType !== 'SuperAdmin'}
                                            />
                                        }
                                    />
                                </div>

                                

                                <div className="form-group col-6">
                                    {!props.user?.user?.users_ID ?
                                        <>
                                            <label className="d-block">Password</label>
                                            <Controller
                                                name="formFields.providedPassword"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                                    <Input.Password htmlFor="provided" prefix={<LockOutlined className="site-form-item-icon" />} defaultValue={value} value={value} autoComplete='new-password' onChange={onChange} />
                                                }
                                            />
                                        </>
                                        :
                                        <>
                                            {uInfo.userType === 'SuperAdmin' && <button className="ant-btn ant-btn-primary mt-3" onClick={() => setNewPasswordModal({ ...newPasswordModal, show: true })}>Reset Password</button>}
                                        </>
                                    }

                                </div>
                                {uInfo.userType === 'SuperAdmin' &&
                                    <Card className="m-2" title="Super Admin Controls">
                                        <>
                                            <div className="row">
                                                <div className="form-group col-4">
                                                    <label className="d-block">NCHCR Member</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.user.nchcrMember"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-4">
                                                    <label className="d-block">Disable Texting</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.user.disableTexting"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-lg-2 col-12">
                                                    <label>Monthly Email Limit</label>
                                                    <input type="number" maxLength="10" className="ant-input" {...register('formFields.user.emailMonthlyLimit')} />
                                                </div>

                                                <div className="form-group col-lg-7 col-12">
                                                    <button className="ant-btn ant-btn-primary mt-3" onClick={() => setShowFindNumbersModal(true)}>Search and Assign a Phone Number</button>
                                                </div>
                                            </div>
                                        </>
                                    </Card>
                                }   
                            </div>
                        </Card>
                    </div>



                    {(uInfo.userType === 'OrgAdmin' || uInfo.userType === 'SuperAdmin') &&
                        <>
                            <div className="d-flex justify-content-between" key="1">
                                <div>
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                </div>
                            </div>

                            <div className="col-12">
                                <Collapse defaultActiveKey={['1']}>
                                    <Panel header="All Permissions" key="1">
                                        <Collapse defaultActiveKey={['1', '2', '3', '4', '5']}>
                                            <Panel header="User Permissions" key="1">
                                                <div className="form-group col-12">
                                                    <label className="d-block">Manage Workflow Items</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.manageWorkflowItems"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Manage Specialties</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.manageSpecialties"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Manage Tags</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.manageTags"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Manage Job Types</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.manageJobTypes"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Manage Event Types</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.manageEventTypes"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Manage Organizational Placement Workflow</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.manageOrgPlacementWorkflow"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>
                                            </Panel>

                                            <Panel header="Records" key="2">

                                                <div className="form-group col-12">
                                                    <label className="d-block">Add Candidates</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.createCandidates"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">View Candidates</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.readCandidates"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Edit Candidates</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.updateCandidates"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Delete Candidates</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.deleteCandidates"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Delete Attachments</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.deleteAttachments"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                {/*<div className="form-group col-12">*/}
                                                {/*    <label className="d-block">Export Candidates</label>*/}
                                                {/*    <Controller*/}
                                                {/*        control={control}*/}
                                                {/*        name="formFields.userPermissions.exportCandidates"*/}
                                                {/*        render={({ field: { onChange, onBlur, value, ref } }) =>*/}
                                                {/*            <Switch checked={value} onChange={onChange} />*/}
                                                {/*        }*/}
                                                {/*    />*/}
                                                {/*</div>*/}
                                            </Panel>
                                            <Panel header="Campaigns" key="3">

                                                <div className="form-group col-12">
                                                    <label className="d-block">Add Campaigns</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.createCampaigns"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">View Campaigns</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.readCampaigns"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Edit Campaigns</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.updateCampaigns"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Delete Campaigns</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.deleteCampaigns"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Panel>
                                            <Panel header="Clients" key="4">

                                                <div className="form-group col-12">
                                                    <label className="d-block">Add Clients</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.createClients"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">View Clients</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.readClients"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Edit Clients</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.updateClients"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Delete Clients</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.deleteClients"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Panel>
                                            <Panel header="Jobs" key="5">

                                                <div className="form-group col-12">
                                                    <label className="d-block">Add Jobs</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.createJobs"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Switch checked={value} onChange={onChange} />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">View Jobs</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.readJobs"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Edit Jobs</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.updateJobs"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="form-group col-12">
                                                    <label className="d-block">Delete Jobs</label>
                                                    <Controller
                                                        control={control}
                                                        name="formFields.userPermissions.deleteJobs"
                                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                                            <Radio.Group
                                                                options={optionsAvailable}
                                                                onChange={onChange}
                                                                value={value}
                                                                optionType="button"
                                                                buttonStyle="solid"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </Panel>
                                </Collapse>
                            </div>
                        </>
                    }
                </div>
            }
            <div className="d-flex justify-content-between" key="1">
                <div>
                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                </div>
            </div>

            <Modal
                open={showFindNumbersModal}
                title="Search and Assign a Phone Number"
                onClose={() => setShowFindNumbersModal(false)}
                footer={[
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <Popconfirm placement="top" title={"Please confirm the purchase of the following number: " + formatPhoneNumber(selectedNumberToPurchase)} onConfirm={orderBandwidthNumber} okText="Confirm" cancelText="Cancel">
                                {selectedNumberToPurchase && <button className="ant-btn ant-btn-primary m-1">Purchase Phone Number</button>}
                            </Popconfirm>

                            <button className="ant-btn ant-btn-outline m-1" onClick={() => { setShowFindNumbersModal(false); setSelectedNumberToPurchase(null); setValue('phoneSearch.areaCode', ''); setTelephoneNumberList([]); }}>Cancel</button>
                        </div>
                    </div>
                ]}
            >
                <div className="row form-group">
                    <div className="col-lg-4 col-12">
                        <label>Area Code</label>
                        <input className="ant-input" {...register('phoneSearch.areaCode')} />
                    </div>
                    <div className="col-lg-8 col-12" style={{ paddingTop: '1.3rem' }}>
                        <button className="ant-btn ant-btn-primary" onClick={handleGetPhoneNumbers}>Search</button>
                    </div>
                </div>

                {telephoneNumberList.length > 0 ?
                    <List
                        size="small"
                        bordered
                        dataSource={telephoneNumberList}
                        renderItem={item =>
                            <List.Item
                                onClick={() => setSelectedNumberToPurchase(item)}
                                actions={selectedNumberToPurchase === item ? [<em className="text-success">Selected</em>] : [<a className="a hover">Select</a>]}
                                className={selectedNumberToPurchase === item ? "hover-bg selected" : "hover-bg"}>{formatPhoneNumber(item)}
                            </List.Item>
                        }
                    />
                    :
                    <Empty description="No available numbers found" />
                }
            </Modal>

            <Modal
                open={newPasswordModal.show}
                title="Generate Temporary Password"
                onClose={() => setNewPasswordModal({ show: false, showNewPassword: false, newPassword: '' })}
                footer={[
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-outline m-1" onClick={() => setNewPasswordModal({ show: false, showNewPassword: false, newPassword: '' })}>Close</button>
                        </div>
                    </div>
                ]}
            >
                <div className="row form-group">
                    {newPasswordModal.showNewPassword ?
                        <div>
                            <p>Below is a their password. If you generated a random password, they will be prompted to change their password upon logging in.</p>
                            <p className="hover copy" title="Copy to clipboard" onClick={() => {
                                navigator.clipboard.writeText(newPasswordModal.newPassword);
                                toast.success("Copied to clipboard");
                            }}><strong>{newPasswordModal.newPassword}</strong> <CopyOutlined className="float-end" /></p>
                        </div>
                        :
                        <div>
                            <div className="row">
                                <div className="form-group col-lg-6 col-12">
                                    <label>Enter a New Password</label>
                                    <input maxLength="36" className="ant-input" {...register('temporaryPassword')} />
                                </div>
                                <div className="form-group col-lg-6 col-12">
                                    <button className="ant-btn ant-btn-primary mt-4" onClick={giveNewPassword}>Save</button>
                                </div>
                            </div>

                            <Divider plain>OR</Divider>

                            <button className="ant-btn ant-btn-primary mt-3" onClick={giveNewPassword}>Generate Random Password</button>
                        </div>
                    }
                </div>
            </Modal>
        </>
    );
}
