import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, Input, Alert } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import './login.css';
import { resetPassword, validateResetToken } from '../../services/TokenService';
import recruiterbalmLogo from '../../img/RecruiterBalmLogo.jpg';
import nchcrLogo from '../../img/nchcr_brand_banner.png';
import { toast } from '@rickylandino/react-messages';
import Globals from '../../config/globals';
const logo = Globals.brand === 'nchcr' ? nchcrLogo : recruiterbalmLogo;


export function ResetPassword(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, getValues, watch, setError, clearErrors,
        formState: { errors }, control } = useForm();

    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');

    const [mismatch, setMismatch] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'password') {
                if (getValues().passwordConfirm !== '') {
                    setValue('passwordConfirm', '');
                }
            }
            if (name === 'passwordConfirm') {
                if (value.passwordConfirm !== '') {
                    if (value.password !== value.passwordConfirm) {
                        setMismatch(true);
                    } else {
                        setMismatch(false);
                    }
                }
                
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (location.search) {
            validateResetToken(location.search.substring(1)).then(data => {
                if (data) {
                    setFullName(data.fullName);
                    setUsername(data.username);
                }

                if (location.state?.showReason) {
                    setShowAlert(true);
                }
            });
        } else if (location.state.username) {
            setFullName(location.state.fullName);
            setUsername(location.state.username);
        }

    }, []);

    function submitPassword(e) {
        e.preventDefault();

        if (getValues().password !== getValues().passwordConfirm) {
            toast.error("Passwords do not match");
        } else {
            let userInfo = {
                username,
                password: getValues().passwordConfirm
            };

            resetPassword(userInfo).then(data => {
                if (data) {
                    toast.success("Password has been updated");

                    directToLogin();
                }
            });
        }

        

        
    }

    function directToLogin() {
        navigate("/", { replace: true });
    }

    return (
        <>
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4 site-card-border-less-wrapper">
                    <img src={logo} height="65px" alt="Logo" aria-roledescription="logo" />
                </div>
                {fullName ? 
                    <>
                        <p>Welcome, {fullName}</p>
                        <Form
                            name="normal_login"
                            className="login-form"
                        >
                            <div className="form-group">
                                <label>New Password</label>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => <Form.Item>
                                        <Input prefix={<LockOutlined className="site-form-item-icon" />} value={value} type="password" onChange={onChange} />
                                    </Form.Item>
                                    }
                                />
                            </div>

                            <div className="form-group">
                                <label>Confirm New Password</label>
                                <Controller
                                    name="passwordConfirm"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => <Form.Item
                                        validateStatus={mismatch ? 'error' : ''}
                                        help={mismatch ? 'Passwords do not match' : ''}>
                                        <Input prefix={<LockOutlined className="site-form-item-icon" />} value={value} type="password" onChange={onChange} />
                                    </Form.Item>
                                    }
                                />
                            </div>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button" onClick={submitPassword}>
                                    Reset Password
                                </Button>
                            </Form.Item>

                            <p className="a hover text-icon" onClick={directToLogin}><i className="fa fa-arrow-left" /> Go To Login</p>
                        </Form>
                    </>
                    :
                    <>
                        <h5 className="text-center pt-3">This link is no longer valid</h5>
                        <p className="a hover text-icon" onClick={directToLogin}><i className="fa fa-arrow-left" /> Go To Login</p>
                    </>
                    
                }

                {showAlert ? (
                    <Alert className="mt-3" style={{ width: '450px' }} message="Your password has expired. You must create a new password before logging in." type="info" closable afterClose={() => setShowAlert(false)} />
                ) : null}
            </div>  
        </>
    );
}