import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Route, useLocation, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import './App.less';

import { Login } from './components/Auth/Login';
import { toast } from '@rickylandino/react-messages';
import chalk from 'chalk';
import { Dashboard } from './components/Dashboard/Dashboard';
import { Layout } from './components/Layout';
import { CandidatesHome } from './components/Candidates/CandidatesHome';
import Globals from './config/globals';
import { updateOrgInfo } from './services/OrganizationsService';
import { CandidateDashboard } from './components/Candidates/CandidateDashboard';
import DetectActivity from './helpers/DetectActivity';
import { Messenger } from './components/SMS/Messenger';
import { CampaignDashboard } from './components/Campaigns/CampaignDashboard';
import { AccountSettingsHome } from './components/AccountSettings/AccountSettingsHome';
import { activeRoute, userInfo, activeUserInfo, userPermissions, activeUserPermissions, uncaughtError } from './services/UsersService';
import { bandwidthNumbers } from './services/SmsService';
import { validateUserByUid } from './services/TokenService';
import { ResetPassword } from './components/Auth/ResetPassword';
import { OrganizationsHome } from './components/Organizations/OrganizationsHome';
import { OrganizationDashboard } from './components/Organizations/OrganizationDashboard';
import { getVersion, insertNewVersion } from './services/CachingService';
import { ReleaseNotes } from './components/AccountSettings/ReleaseNotes';
import { EmailDashboard } from './components/Email/EmailDashboard';
import { AdminHome } from './components/Admin/AdminHome';
import { ClientsHome } from './components/Clients/ClientsHome';
import { ClientDashboard } from './components/Clients/ClientDashboard';
import { JobsHome } from './components/Jobs/JobsHome';
import { JobDashboard } from './components/Jobs/JobDashboard';
import FAQ from './components/FAQ/faq';
import { OverviewDashboard } from './components/Admin/OverviewDashboard';
import CandidateOpt from './components/Candidates/CandidateOpt';

function App(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    //const [uInfo, setUInfo] = useState({});
    const [refreshCache, setRefreshCache] = useState(false);

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const setUPermissions = useSetRecoilState(activeUserPermissions);
    const setBandwidthNumbers = useSetRecoilState(bandwidthNumbers);

    const [version, setVersion] = useState(null);

    useEffect(() => {
        window.onerror = function (message, file, line, col, error) {
            let postdata = {
                emailAddress: uInfo.emailAddress,
                stackTrace: error.stack
            }

            uncaughtError(postdata); // Send error data to the server
            console.log("An error has occurred. Our team has been notified.");

            return false;
         };
    }, [uInfo]);

    useEffect(() => {
        getVersionInformation();
        console.log(location);
        window.sessionStorage.setItem("theme", "light");
        if (window.sessionStorage.getItem("uid") || location.search.includes("uid=")) {
            let uid = window.sessionStorage.getItem("uid") || location.search.split("uid=")[1];
            validateUserByUid(uid).then(data => {

                setSearchParams("")

                if (data?.tokenResponse) {
                    Globals.bearer = data.tokenResponse.accessToken;
                }

                if (data?.user) {
                    Globals.userInfo = data.user.user;
                    userInfo.next(data.user.user);
                    userPermissions.next(data.user.userPermissions);

                    //recoiljs implementation
                    setUInfo(data.user.user);
                    setUPermissions(data.user.userPermissions);
                }

                if (data?.organizationInfo) {
                    updateOrgInfo(data.organizationInfo);
                }

                if (data?.colors) {
                    Globals.colors = data.colors;
                }

                if (data?.bandwidthNumbers) {
                    setBandwidthNumbers(data.bandwidthNumbers);
                }
            }).catch(error => {
                navigate("/", { replace: true });    
            });
        } else {
            if(!(location.pathname === '/reset-password' || location.pathname.includes('/candidate-opt/'))) {
                navigate("/", { replace: true });
            }
        }
    }, []);

    useEffect(() => {
        activeRoute.next(location.pathname);
    }, [location]);

    async function emptyCache(appVersion) {
        window.localStorage.setItem("RB_APP_VERSION", appVersion);

        if ('caches' in window) {
            // Service worker cache should be cleared with caches.delete()
            const cacheKeys = await window.caches.keys();
            await Promise.all(cacheKeys.map(key => {
              window.caches.delete(key)
            }));
          }
      
          window.location.replace(window.location.href);
    }

    async function getVersionInformation() {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response?.json();

        if (!window.sessionStorage.getItem("cacheRefreshed")) {
            getVersion().then(data => {
                if (data && !data.isAxiosError) {
                    if (`${meta?.version}` !== data?.cacheVersion?.version) {
                        toast.loading("Update in progress");
                        setRefreshCache(true);

                        let postdata = {
                            version: meta.version,
                            webVersion: data.cacheVersion?.webVersion
                        }

                        insertNewVersion(postdata).then(data2 => {
                            window.sessionStorage.setItem("cacheRefreshed", true);
                            emptyCache(meta.version);
                        });
                    }
                    else if ((window?.localStorage?.RB_APP_VERSION && (window.localStorage.RB_APP_VERSION !== data.cacheVersion?.version)) || !window?.localStorage?.RB_APP_VERSION) {
                        toast.loading("Update in progress");
                        setRefreshCache(true);

                        window.sessionStorage.setItem("cacheRefreshed", true);
                        emptyCache(data.cacheVersion.version);
                    }

                } else {
                    toast.warning("Cache Refresh Failed");
                }
            }).catch(e => {
                toast.warning("Cache Refresh Failed");
            });
        } else {
            console.log(chalk.green("Cache Refreshed"));
        }
        
        console.log(chalk.blueBright("Version: " + window.localStorage?.RB_APP_VERSION));
    }

    return (
            <div className="App main fade-in h-100">
                {(location.pathname === '/' || location.pathname === '/reset-password' || location.pathname.includes('/candidate-opt/')) ?
                    (
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/candidate-opt/:emailHash" element={<CandidateOpt />} />
                        </Routes>
                    ) :
                    <>
                        {uInfo.users_ID &&
                            <Layout>
                                <DetectActivity>
                                    <Routes>
                                        <Route path="/dashboard" element={<Dashboard />} />
                                        <Route path="/candidates" element={<CandidatesHome />} />
                                        <Route path="/candidate-dashboard/:candidates_ID" element={<CandidateDashboard />} />
                                        <Route path="/clients" element={<ClientsHome />} />
                                        <Route path="/client-dashboard/:clients_ID" element={<ClientDashboard />} />
                                        <Route path="/jobs" element={<JobsHome />} />
                                        <Route path="/job-dashboard/:jobs_ID" element={<JobDashboard />} />
                                        {!uInfo?.disableTexting && <Route path="/sms-dashboard" element={<Messenger />} />}
                                        <Route path="/campaigns" element={<CampaignDashboard />} />
                                        <Route path="/account-settings" element={<AccountSettingsHome />} />
                                        <Route path="/organizations" element={<OrganizationsHome />} />
                                        <Route path="/organization-dashboard/:org_ID" element={<OrganizationDashboard />} />
                                        <Route path="/release-notes" element={<ReleaseNotes />} />
                                        <Route path="/email-dashboard" element={<EmailDashboard />} />
                                        <Route path="/admin-settings" element={<AdminHome />} />
                                        <Route path="/summary-overview" element={<OverviewDashboard />} />
                                        <Route path="/faq" element={<FAQ />} />
                                    </Routes>

                                </DetectActivity>
                            </Layout>
                        }
                    </>
                }

                {refreshCache && <div className="refresh-overlay" />}
            </div>
    );
}

export default App;
