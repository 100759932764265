import Axios from '../config/axios';

export function insertPlacementWorkflow(postdata) {
    return Axios.post(`/api/InsertPlacementWorkflow`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updatePlacementWorkflow(postdata) {
    return Axios.post(`/api/UpdatePlacementWorkflow`, postdata
    ).then(response => response.data).catch(error => error);
}
