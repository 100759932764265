import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Drawer, Upload, Progress, Typography, Result, Card, Col, Row, Statistic, Alert } from 'antd';
import { InboxOutlined, CloseCircleOutlined, LikeOutlined } from '@ant-design/icons';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { downloadCandidateTemplate, importCandidates } from '../../services/ImportService';
import { activeUserInfo } from '../../services/UsersService';
import { getOrganizationInfo } from '../../services/OrganizationsService';

const { Paragraph, Text } = Typography;

const { Dragger } = Upload;

var urlend = window.api.slice(-1) === '/' ? 'hubs/import' : '/hubs/import';
const url = window.api + urlend;
var connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.None)
    .withUrl(url)
    .withAutomaticReconnect()
    .build();

export function ImportCandidates(props) {
    const uInfo = useRecoilValue(activeUserInfo);

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const [result, setResult] = useState({
        loaded: false
    });

    const [file, setFile] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [processCount, setProcessCount] = useState(0);
    const [importing, setImporting] = useState(false);

    useEffect(() => {
        connection.start()
            .then(result => {
                connection.on('ReceiveCount', count => {
                    setProcessCount(count);
                });

                connection.on('ReceiveTotalCount', totalCount => {
                    setTotalCount(totalCount);
                });

                connection.on('IsComplete', isComplete => {
                    setImporting(!isComplete);
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }, []);

    useEffect(() => {
        setModalInfo({
            ...modalInfo,
            show: props.showModal
        });
    }, [props.showModal]);

    function handleSubmit() {
        setImporting(true);
        importCandidates(file, uInfo.users_ID).then(data => {
            if (data?.name === 'AxiosError') {
                setResult({
                    loaded: true,
                    error: true
                });
            } else {
                setResult({
                    loaded: true,
                    ...data
                });

                setFile(null);

                getOrganizationInfo();
            }
        }).catch(error => {
            console.log(error);
            setResult({
                loaded: true,
                error: true
            });
        });
    }

    function handleClose() {
        setModalInfo({
            show: false
        });

        setTimeout(() => {
            props.hideModal();
        }, 1000);
    }

    function handleUpload(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let imgUrl = reader.result?.split("base64,");
            
            setFile(file);
        };

        // then upload `file` from the argument manually
        return false;
    }

    function handleDownloadCandidateTemplate() {
        downloadCandidateTemplate().then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = "Candidate Import Template.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(error => {
            console.log(error);
        });
    }

    const Instructions = () => {
        return (
            <div>
                <ol>
                    <li>Please begin by downloading the import template <strong className="a hover" onClick={handleDownloadCandidateTemplate}>here</strong></li>
                    <ul>
                        <li>IMPORTANT: Do not change the header row or any default formatting</li>
                    </ul>
                    <li>Fill in the spreadsheet by adding all relevant information</li>
                    <li>Upon clicking "Import Candidates", you will be shown the results of the import. If any errors occur, please read the error details closely, and make the listed adjustments</li>
                </ol>

                <h5>Spreadsheet Rules</h5>
                <ul>
                    <li>First Name, Last Name, Specialty, Sub-Specialty, State, Email Address and Recruiter Email are all required</li>
                    <li>In-House ID must be unique to the system, if it is added</li>
                    <li>All phone numbers must be unique to the system and follow the format of XXX-XXX-XXXX</li>
                    <li>Remove any hyperlinks. Only plain text is allowed.</li>
                    <li>The following fields can accept multiple values, delimited by a pipe | character:</li>
                    <ul>
                        <li>Sub Specialty</li>
                        {/*<li>Preferred States</li>*/}
                        {/*<li>Job Types</li>*/}
                        {/*<li>Certifications</li>*/}
                        {/*<li>Types Of Practice</li>*/}
                    </ul>
                    {/*<li>Years of Experience needs to be a number</li>*/}
                    <li>All states references should be done using the state code. Example: Connecticut = CT</li>
                    {/*<li>All dates should be in a format of YYYY-MM-DD</li>*/}
                </ul>
            </div>    
        );
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Import Candidates'
            placement={'right'}
            onClose={handleClose}
            open={modalInfo.show}
            key='importCandidateSlider'
            width={'50%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        {!result.loaded && <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Import Candidates</button>}
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
        >
            {!result.loaded &&
                <>
                <Alert
                    message="Import Candidate Instructions"
                    description={<Instructions />}
                    type="info"
                    showIcon
                    className="mb-3"
                />

                <Dragger
                    beforeUpload={handleUpload}
                    maxCount={1}
                    accept=".xlsx"
                    fileList={file ? [file] : []}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag spreadsheet to this area to upload</p>
                    <p className="ant-upload-hint">
                        Supports a single upload, .xlsx file only.
                    </p>
                </Dragger>
                </>
            }
            {importing &&
                <>
                    <hr className="pt-4 pb-4" />

                    <div className="form-group">
                        <p>Total Candidates Created: {processCount}</p>
                    </div>

                <Progress percent={parseInt(processCount / totalCount * 100)} />
                </>
            }

            {result.loaded &&
                <>
                {result.error ?
                    <>
                        <Result
                            status="error"
                            title="Candidate Import Could Not Complete"
                        >
                            <div className="desc">
                                <Paragraph>
                                    <Text
                                        strong
                                        style={{
                                            fontSize: 16,
                                        }}
                                    >
                                        This usually means that the format of your spreadsheet is invalid or corrupt. Please try redownloading the template, and pasting in the data you would like to import.
                                    </Text>
                                </Paragraph>
                            </div>
                        </Result>
                    </>
                    :
                    <>
                        <div className="site-statistic-demo-card">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Card>
                                        <Statistic
                                            title="Successfully Imported"
                                            value={result.totalSuccess}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            prefix={<LikeOutlined style={{ verticalAlign: '0' }} />}
                                            suffix=" Candidates"
                                        />
                                    </Card>
                                </Col>
                                {result.failedImports?.length > 0 &&
                                    <Col span={12}>
                                        <Card>
                                            <Statistic
                                                title="Failed to Import"
                                                value={result.totalFailed}
                                                valueStyle={{
                                                    color: '#cf1322',
                                                }}
                                                prefix={<CloseCircleOutlined style={{ verticalAlign: '0' }} />}
                                                suffix=" Candidates"
                                            />
                                        </Card>
                                    </Col>
                                }
                            </Row>
                        </div>

                        <Result
                            status="success"
                            title="Candidate Import Has Completed"
                            subTitle={result.failedImports?.length > 0 ? "Please check any errors below" : ""}
                        >
                            <div className="desc">
                                <Paragraph>
                                    <Text
                                        strong
                                        style={{
                                            fontSize: 16,
                                        }}
                                    >
                                        {result.failedImports?.length > 0 ? 'The content you submitted has the following error:' : 'No errors found'}
                                    </Text>
                                </Paragraph>
                                {result.failedImports?.map((item, idx) =>
                                    <Paragraph key={idx}>
                                        <CloseCircleOutlined style={{ verticalAlign: '0' }} className="red-x" />
                                        &nbsp;
                                        <u>{item.candidate?.firstName} {item.candidate?.lastName}</u>
                                        &nbsp;
                                        <strong>Row Number:</strong> {item.rowNumber}.
                                        &nbsp;
                                        <strong>Reason:</strong> {item.reason}
                                    </Paragraph>
                                )}
                            </div>
                        </Result>
                    </>
                }
                
                </>
            }
        </Drawer>
    );
}