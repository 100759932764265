import { BehaviorSubject } from 'rxjs';
import Axios from '../config/axios';
import { atom } from 'recoil';
import Globals from '../config/globals';
import axios from 'axios';

export const activeRoute = new BehaviorSubject('');

export const userInfo = new BehaviorSubject({});

export const activeUserInfo = atom({
    key: 'activeUserInfo',
    default: {}
});

export const activeUserPermissions = atom({
    key: 'activeUserPermissions',
    default: {}
});


export const userPermissions = new BehaviorSubject({});

export const realUserInfoWhileImpersonationIsOn = new BehaviorSubject(null);
export const imitationMode = new BehaviorSubject(false);

export function insertSingleUser(postdata) {
    return Axios.post(`/api/InsertSingleUser`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateUser(postdata) {
    return Axios.post(`/api/UpdateUser`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateUserPassword(postdata) {
    return Axios.post(`/api/UpdateUserPassword`, postdata
    ).then(response => response.data).catch(error => error);
}

export function generateNewPassword(postdata) {
    return Axios.post(`/api/GenerateNewPassword`, postdata
    ).then(response => response.data).catch(error => error);
}


export function hashwordConversion(postdata) {
    return Axios.post(`/api/HashwordConversion`, postdata
    ).then(response => response.data).catch(error => error);
}

export function sendResetPasswordLink(postdata) {
    return Axios.post('/api/auth/SendResetPasswordLink', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function updateUserProfilePicture(postdata) {
    return axios.post(`${window.api}api/UpdateUserProfilePicture`, postdata, {
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then(response => response.data);
}

export function uncaughtError(postdata) {
    return Axios.post(`/api/UncaughtError`, postdata
    ).then(response => response.data).catch(error => error);
}

export function insertUserFromNchcr(postdata) {
    return Axios.post(`/api/InsertUserFromNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}