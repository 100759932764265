import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import L from "leaflet";
import { useLocation } from "react-router-dom";

export default function LeafletControlGeocoder({ handleSearch, radius, existingPoly, updatePoly }) {
    const map = useMap();
    const location = useLocation();

    useEffect(() => {
        var geocoder = L.Control.Geocoder.nominatim({
            geocodingQueryParams: {
                "countrycodes": "us"
            }});
        if (typeof URLSearchParams !== "undefined" && location.search) {
            // parse /?geocoder=nominatim from URL
            var params = new URLSearchParams(location.search);
            var geocoderString = params.get("geocoder");
            if (geocoderString && L.Control.Geocoder[geocoderString]) {
                geocoder = L.Control.Geocoder[geocoderString]();
            } else if (geocoderString) {
                console.warn("Unsupported geocoder", geocoderString);
            }
        }

        var group1 = L.featureGroup();

        if (existingPoly) {
            group1.clearLayers();
            var poly = L.circle(existingPoly.getLatLng(), {
                radius: radius + existingPoly.options?.length || 0,
                color: '#00ffff',
                opacity: 0,
                length: existingPoly.options?.length || 0
            });
            updatePoly(poly);

            poly.addTo(group1);
            map.addLayer(group1);

            // //var poly = L.circle().addTo(map);
            map.fitBounds(poly.getBounds());
        }

        L.Control.geocoder({
            query: "",
            placeholder: "Search here...",
            defaultMarkGeocode: false,
            geocoder,
            geocodingQueryParams: {
                "countrycodes": "us"
            }
        })
            .on('markgeocode', function (e) {
                var bbox = e.geocode.bbox;
                let _length = map.distance(bbox.getSouthEast(), bbox.getSouthWest());
                handleSearch(e.geocode.properties, e.geocode.center, _length + radius);

                // var poly2 = L.polygon([
                //   bbox.getSouthEast(),
                //   bbox.getNorthEast(),
                //   bbox.getNorthWest(),
                //   bbox.getSouthWest()
                // ]).addTo(map);

                group1.clearLayers();

                var poly = L.circle(e.geocode.center, {
                    radius: radius + _length,
                    color: '#00ffff',
                    opacity: 0,
                    length: _length
                });
                updatePoly(poly);

                poly.addTo(group1);
                map.addLayer(group1);

                // //var poly = L.circle().addTo(map);
                map.fitBounds(poly.getBounds());
            })
            .addTo(map);
    }, []);

    return null;
}
