import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/global.css';
import './css/entity-styles.css';
import './css/antd-override.css';
import './css/forms.css';
import './css/table.css';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { RecoilRoot } from 'recoil';
import RecoilNexus from "recoil-nexus";
import useSWR, { SWRConfig } from 'swr';
import { register, unregister } from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};



root.render(
    <BrowserRouter>
        <RecoilRoot>
            <RecoilNexus />
            <SWRConfig value={{ provider: () => new Map() }}>
                <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
                    <App />
                </ThemeSwitcherProvider>
            </SWRConfig>
        </RecoilRoot>
    </BrowserRouter>
);

unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
