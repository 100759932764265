import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { Table, Empty, Input, Space, Tree, Modal, Spin, Alert, Select, Divider } from 'antd';
import { activeParentSpecialtiesState, activeSubSpecialtiesState, parentSpecialties, subSpecialties } from '../../../services/OrganizationsService';
import { filterTable } from '../../../helpers/FilterTable';
import SkeletonTable from '../../../helpers/SkeletonTable';
import { activeUserInfo, userInfo } from '../../../services/UsersService';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bulkReassignSpecialties, deleteSpecialty, getCandidatesOrJobsWithAssignedSpecialty, getSpecialtiesByOrganization, insertSpecialty, updateSpecialty } from '../../../services/SpecialtiesService';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from '../../../swr/fetcher';
import { useNavigate } from 'react-router-dom';

export function ManageSpecialties(props) {
    const navigate = useNavigate();
    const { mutate } = useSWRConfig()

    const { register, getValues, setValue, control, watch } = useForm();
    const uInfo = useRecoilValue(activeUserInfo);

    const [activeParentSpecialties, setActiveParentSpecialties] = useRecoilState(activeParentSpecialtiesState);
    const [activeSubSpecialties, setActiveSubSpecialties] = useRecoilState(activeSubSpecialtiesState);

    const [specialties, setSpecialties] = useState({
        filteredParentSpecialties: activeParentSpecialties,
        filteredSubSpecialties: []
    });

    const [selectedSpecialty, setSelectedSpecialty] = useState(null);
    const [selectedSubSpecialty, setSelectedSubSpecialty] = useState(null);

    const [modalInfo, setModalInfo] = useState({
        show: false,
        title: '',
        btnAction: '', //Add or Update,
        type: '' //parent or child
    });

    const [beforeDeleteModal, setBeforeDeleteModal] = useState({
        show: false,
        candidateTreeData: [],
        jobTreeData: []
    });

    const [loadingBulkUpdate, setLoadingBulkUpdate] = useState(false);

    const [updatingSpecialty, setUpdatingSpecialty] = useState(false);

    const key = `/api/GetSpecialtiesByOrganization?organizations_ID=${uInfo.organizations_ID}`;

    const { data, error, isLoading, isMutating } = useSWR(key, fetcher);

    useEffect(() => {
        if(data?.parentSpecialties && data?.subSpecialties) {
            if(selectedSpecialty) {
                setSelectedSpecialty(data.parentSpecialties.find(s => s.specialties_ID === selectedSpecialty.specialties_ID));
                setSpecialties({
                    ...specialties,
                    filteredParentSpecialties: data.parentSpecialties,
                    filteredSubSpecialties: data.subSpecialties[selectedSpecialty.specialties_ID] || []
                });
            }

            setActiveParentSpecialties(data.parentSpecialties);
            setActiveSubSpecialties(data.subSpecialties);
            
            parentSpecialties.next(data.parentSpecialties);
            subSpecialties.next(data.subSpecialties);
        }
    }, [data]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change' && name === 'beforeDeleteFormFields.specialty') {                
                setSelectedSpecialty(activeParentSpecialties.find(spec => spec.specialties_ID === value.beforeDeleteFormFields.specialty));
                setSelectedSubSpecialty(null);
                setValue('beforeDeleteFormFields.subSpecialty', null);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function handleSubmit() {
        let specialty = getValues().formFields;

        
        if (specialty.specialties_ID && specialty.organizations_ID) {
            updateSpecialty(specialty).then(data => {
                if (data) {

                    if(modalInfo.type === 'parent') {
                        setSelectedSpecialty(specialty);
                    } else {
                        setSelectedSubSpecialty(specialty);
                    }

                    toast.success("Specialty has been updated");
                    setModalInfo({show: false, title: '', btnAction: '', type: ''});

                    mutate(key);
                } else {
                    toast.error("This specialty cannot be updated");
                }
            });
        } else {
            
            delete specialty.specialties_ID;

            specialty.organizations_ID = uInfo.organizations_ID;

            if(modalInfo.type === 'child') {
                specialty.parentID = selectedSpecialty.specialties_ID;
            }

            insertSpecialty(specialty).then(data => {
                if (data === -1) {
                    toast.error("This specialty already exists");
                } else {
                    specialty.specialties_ID = data;
                    if(modalInfo.type === 'parent') {
                        setSelectedSpecialty(specialty);
                    } else {
                        setSelectedSubSpecialty(specialty);
                    }
                    setValue('formFields', specialty);
                    toast.success("Specialty has been added");
                    setModalInfo({show: false, title: '', btnAction: '', type: ''});

                    mutate(key);
                }
            })
        }
    }

    function handleSearch(value, type) {
        let list = type === 'parent' ? activeParentSpecialties : activeSubSpecialties[selectedSpecialty?.specialties_ID];
        const filteredSpecialties = filterTable([...list], value);

        if (type === 'parent') {
            setSpecialties({
                ...specialties,
                filteredParentSpecialties: filteredSpecialties
            });
        } else {
            setSpecialties({
                ...specialties,
                filteredSubSpecialties: filteredSpecialties
            });
        }
    }

    function addSpecialty(type) {
        let specialty = {
            specialty: '',
            organizations_ID: uInfo.organizations_ID
        }

        if(type === 'child') { 
            specialty.parentID = selectedSpecialty.specialties_ID;
        }

        setValue('formFields', specialty);

        //setSelectedSpecialty(specialty);

        setModalInfo({
            show: true,
            title: type === 'child' ? 'Add Sub Specialty' : 'Add Specialty',
            btnAction: 'Add',
            type
        });
    }

    function removeSpecialty(index, type) {
        let specialtyToRemove = type === 'parent' ? specialties.filteredParentSpecialties[index] : specialties.filteredSubSpecialties[index];
        if(type === 'parent') { 
            if(activeSubSpecialties[specialtyToRemove.specialties_ID]?.length > 0) {
                Modal.warning({
                    title: 'You cannot delete this specialty yet',
                    content: 'Please remove all sub specialties before deleting this specialty.',
                  });

                return;
            }
        }
        //setSelectedSubSpecialty(specialtyToRemove);

        getCandidatesOrJobsWithAssignedSpecialty(specialtyToRemove.specialties_ID).then(data => { 
            let candidateTreeData = [];
            let jobTreeData = [];

            if(data.specialtyCandidates?.length > 0) {
                let specialtyCandidates = {
                    title: 'Candidates (Specialty)',
                    key: '0-0',
                    children: data.specialtyCandidates.map(c => ({
                        title: c.firstName + ' ' + c.lastName,
                        key: c.candidates_ID
                    })),
                    ids: data.specialtyCandidates.map(c => c.candidates_ID)
                }

                candidateTreeData.push(specialtyCandidates);
            }
            if(data.subSpecialtyCandidates?.length > 0) {
                let subSpecialtyCandidates = {
                    title: 'Candidates (Sub Specialty)',
                    key: '0-1',
                    children: data.subSpecialtyCandidates.map(c => ({
                        title: c.firstName + ' ' + c.lastName,
                        key: c.candidates_ID
                    })),
                    ids: data.subSpecialtyCandidates.map(c => c.candidates_ID)
                }
                
                candidateTreeData.push(subSpecialtyCandidates);
            }
            if(data.specialtyJobs?.length > 0) {
                let specialtyJobs = {
                    title: 'Jobs (Specialty)',
                    key: '1-0',
                    children: data.specialtyJobs.map(j => ({
                        title: j.jobTitle,
                        key: j.jobs_ID
                    })),
                    ids: data.specialtyJobs.map(j => j.jobs_ID)
                }

                jobTreeData.push(specialtyJobs);
            }
            if(data.subSpecialtyJobs?.length > 0) {
                let subSpecialtyJobs = {
                    title: 'Jobs (Sub Specialty)',
                    key: '1-1',
                    children: data.subSpecialtyJobs.map(j => ({
                        title: j.jobTitle,
                        key: j.jobs_ID
                    })),
                    ids: data.subSpecialtyJobs.map(j => j.jobs_ID)
                }

                jobTreeData.push(subSpecialtyJobs);
            }

            if(candidateTreeData.length > 0 || jobTreeData.length > 0) {
                
                if(selectedSpecialty) {
                    setValue('beforeDeleteFormFields.specialty', selectedSpecialty.specialties_ID);
                }
                // if(selectedSubSpecialty) {
                //     setValue('beforeDeleteFormFields.subSpecialty', selectedSubSpecialty.specialties_ID);
                // }
                
                setBeforeDeleteModal({
                    show: true,
                    candidateTreeData,
                    jobTreeData
                });
            } else {
                confirm({
                    title: "You are about to delete this specialty.",
                    content: "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            deleteSpecialty(specialtyToRemove).then(data => {
                                if (data) {
                                    toast.success("Specialty has been deleted");
                                    
                                    mutate(key);
                                } else {
                                    toast.error("This specialty cannot be deleted at this time");
                                }
                            });

                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            return 0;
                        }
                    }
                );
            }
        });
    }

    function bulkReassign() {
        let postdata = {
            specialty: getValues().beforeDeleteFormFields.specialty,
            subSpecialty: getValues().beforeDeleteFormFields.subSpecialty,
            candidateIDs: beforeDeleteModal.candidateTreeData.map(c => c.ids).flat(),
            jobIDs: beforeDeleteModal.jobTreeData.map(j => j.ids).flat()
        }

        setLoadingBulkUpdate(true);

        bulkReassignSpecialties(postdata).then(data => {
            setLoadingBulkUpdate(false);
            if(data) {
                toast.success("Candidates and Jobs have been reassigned.");
                setBeforeDeleteModal({ show: false, candidateTreeData: [], jobTreeData: [] });
            } else {
                toast.error("Something went wrong. Please manually update these specialties.")
            }

            mutate(key);
        })
    }

    const parentColumns = [
        {
            title: 'Parent Specialty',
            dataIndex: 'specialty',
            key: 'specialty',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="edit" className="a hover">Edit</span>
                    {record.organizations_ID ? <span id="delete" className="a hover">Delete</span> : <span style={{opacity: 0}}>Delete</span>}
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    const childColumns = [
        {
            title: 'Sub Specialty',
            dataIndex: 'specialty',
            key: 'specialty',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="edit" className="a hover">Edit</span>
                    {record.organizations_ID ? <span id="delete" className="a hover">Delete</span> : <span style={{opacity: 0}}>Delete</span>}
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    return (
        <>
            
            <div className="row">
                <div className="col-lg-6 col-12">
                    <div className="d-flex justify-content-end mb-3">
                        <button className="ant-btn ant-btn-primary float-end" onClick={() => addSpecialty('parent')}>
                            <i className="far fa-plus-square"></i>
                            &nbsp;
                            Add Specialty
                        </button>
                    </div>
                    <Controller
                        control={control}
                        name="tableSearchValueParent"
                        render={({ field: { onChange, onBlur, value, ref } }) =>
                            <Input.Search
                                placeholder="Search for specialties..."
                                onSearch={(value) => handleSearch(value, 'parent')}
                                value={value}
                                onChange={(e) => { handleSearch(e.target.value, 'parent'); setValue('tableSearchValueParent', e.target.value); }}
                            />
                        }
                    />

                    <hr />
                    {specialties?.filteredParentSpecialties.length > 0 ?
                        <>
                            <Table
                                width={'50vw'}
                                rowKey='specialties_ID'
                                dataSource={specialties.filteredParentSpecialties}
                                columns={parentColumns}
                                size='small'
                                pagination={false}
                                rowClassName={(record, index) => record.specialties_ID === selectedSpecialty?.specialties_ID ? 'row-selected' : ''}
                                scroll={{
                                    y: '50vh',
                                }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            if (event.target.id === 'delete') {
                                                setSelectedSpecialty(record); 
                                                setValue('beforeDeleteFormFields.specialty', record.specialties_ID);
                                                removeSpecialty(rowIndex, 'parent');
                                            } else if(event.target.id === 'edit') {
                                                setModalInfo({
                                                    show: true,
                                                    title: 'Edit Specialty',
                                                    btnAction: 'Update',
                                                    type: 'parent'
                                                });

                                                setSelectedSpecialty(record); 
                                                setValue('formFields', record);
                                            } else {
                                                setSpecialties({
                                                    ...specialties,
                                                    filteredSubSpecialties: activeSubSpecialties[record.specialties_ID] || []
                                                });

                                                setSelectedSpecialty(record); setValue('formFields', record);
                                            }

                                        }
                                    };
                                }} />
                        </>
                        :
                        <Empty description={<p>No Results Found - to add a specialty, click the "<i className="far fa-plus-square" /> Add Specialty" button above.</p>} />
                    }
                </div>
                {selectedSpecialty &&
                    <div className="col-lg-6 col-12">
                        <div className="d-flex justify-content-end mb-3">
                            <button className="ant-btn ant-btn-primary float-end" onClick={() => addSpecialty('child')}>
                                <i className="far fa-plus-square"></i>
                                &nbsp;
                                Add Sub Specialty
                            </button>
                        </div>
                        <Controller
                            control={control}
                            name="tableSearchValueSub"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <Input.Search
                                    placeholder="Search for sub specialties..."
                                    onSearch={(value) => handleSearch(value, 'child')}
                                    value={value}
                                    onChange={(e) => { handleSearch(e.target.value, 'child'); setValue('tableSearchValueSub', e.target.value); }}
                                />
                            }
                        />

                        <hr />

                        {specialties?.filteredSubSpecialties?.length > 0 ?
                            <>
                                <Table
                                    width={'50vw'}
                                    rowKey='specialties_ID'
                                    dataSource={specialties.filteredSubSpecialties}
                                    columns={childColumns}
                                    size='small'
                                    pagination={false}
                                    rowClassName={(record, index) => record.specialties_ID === selectedSubSpecialty?.specialties_ID ? 'row-selected' : ''}
                                    scroll={{
                                        y: '50vh',
                                    }}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {
                                                if (event.target.id === 'delete') {
                                                    setSelectedSubSpecialty(record); 
                                                    setValue('beforeDeleteFormFields.subSpecialty', record.specialties_ID);
                                                    removeSpecialty(rowIndex, 'child');
                                                } else {
                                                    setModalInfo({
                                                        show: true,
                                                        title: 'Edit Sub Specialty',
                                                        btnAction: 'Update',
                                                        type: 'child'
                                                    });

                                                    setSelectedSubSpecialty(record); 
                                                    setValue('formFields', record);
                                                }

                                            }
                                        };
                                    }} />
                            </>
                            :
                            <Empty description={<p>No Results Found - to add a sub specialty, click the "<i className="far fa-plus-square" /> Add Sub Specialty" button above.</p>} />
                        }
                    </div>
                }
                <Modal
                    open={modalInfo.show}
                    title={modalInfo.title}
                    footer={[
                        <div className="text-start" key="1">
                            {updatingSpecialty ?
                                <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                                :
                                <button className="ant-btn ant-btn-primary text-start" onClick={handleSubmit}>{modalInfo.btnAction}</button>
                            }
                            <button className="ant-btn ant-btn-default text-start" onClick={() => { setModalInfo({show: false, title: '', btnAction: '', type: ''}); setUpdatingSpecialty(false); }}>Cancel</button>
                        </div>
                    ]}
                >
                    <div className="row">
                        {modalInfo.type === 'child' &&
                            <div className="col-12">
                                <label>Selected Parent Specialty:</label>
                                <p><em>{selectedSpecialty?.specialty}</em></p>
                            </div>
                        }
                        <div className="col-12">
                            <label>{modalInfo.type === 'parent' ? 'Specialty' : 'Sub Specialty'}</label>
                            <input maxLength="100" className="ant-input" {...register('formFields.specialty')} />
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={beforeDeleteModal.show}
                    title='Warning'
                    onCancel={() => setBeforeDeleteModal({ show: false, candidateTreeData: [], jobTreeData: [] })}
                    footer={null}
                >
                    <div className="row">
                        <div className="col-12">
                            <Alert 
                                message="This Specialty Cannot Be Deleted Yet"
                                description="This specialty is currently assigned to one or more candidates or jobs. Please reassign these candidates or jobs to another specialty before deleting this specialty."
                                type="warning" 
                            />
                        </div>

                        <Divider />

                        <p>You can click on each individual Candidate and Job to change their specialties.</p>

                        <div className="col-lg-6 col-12">
                            <Tree
                                rootClassName='candidate-text'
                                showIcon
                                defaultExpandAll
                                switcherIcon={<DownOutlined />}
                                treeData={beforeDeleteModal.candidateTreeData}
                                onSelect={(selectedKeys, info) => !info.node.children && navigate(`/candidate-dashboard/${selectedKeys[0]}`,
                                    {
                                        replace: true,
                                        state: { from: 'manage-specialties' }
                                    })}
                            />    
                        </div>
                        <div className="col-lg-6 col-12">
                            <Tree
                                rootClassName='job-text'
                                showIcon
                                defaultExpandAll
                                switcherIcon={<DownOutlined />}
                                treeData={beforeDeleteModal.jobTreeData}
                                onSelect={(selectedKeys, info) => !info.node.children && navigate(`/job-dashboard/${selectedKeys[0]}`,
                                    {
                                        replace: true,
                                        state: { from: 'manage-jobs' }
                                    })}
                            />    
                        </div>
                    </div>

                    <Divider>OR</Divider>

                    <p>You can bulk update these Candidate(s) and Job(s) below.</p>

                    <div className="row">
                        <div className="form-group col-lg-6">
                            <label>Specialty <span className="text-danger">*</span></label>
                            {activeParentSpecialties?.length > 0 ?
                                <Controller
                                    control={control}
                                    name="beforeDeleteFormFields.specialty"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Select
                                            id="specialty"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            value={value}
                                            onChange={onChange}
                                            showSearch
                                            options={activeParentSpecialties}
                                            filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                            optionFilterProp='specialty'
                                            fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                        />
                                    }
                                />
                                :
                                <p>Issue loading specialties</p>
                            }
                        </div>

                        <div className="col-lg-6 col-12">
                            <label>Sub Specialty <span className="text-danger">*</span></label>
                            {selectedSpecialty &&
                                <Controller
                                    control={control}
                                    name="beforeDeleteFormFields.subSpecialty"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Select
                                            id="subSpecialty"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            value={value}
                                            onChange={onChange}
                                            showSearch
                                            options={activeSubSpecialties[selectedSpecialty.specialties_ID]}
                                            filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                            optionFilterProp='specialty'
                                            fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                        />
                                    }
                                />
                            }
                        </div>

{loadingBulkUpdate ?
    <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
    :
    <button className="ant-btn ant-btn-primary m-1" onClick={bulkReassign}>Bulk Re-Assign Specialty</button>
}
                    </div>
                </Modal>

                {/*selectedSpecialty &&
                    <div className="col-lg-6 col-12">
                        <Card title={selectedSpecialty?.specialties_ID ? "Edit Specialty" : "Add Specialty"}>

                            <div className="form-group col-lg-6 col-12">
                                <label>Specialty</label>
                                <input maxLength="100" className="ant-input" {...register('formFields.specialty')} />
                            </div>

                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-outline m-1" onClick={() => setSelectedSpecialty(null)}>Cancel</button>
                                </div>
                            </div>
                        </Card>
                    </div>
                */}
            </div>


        </>
    );
}
