import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { organizationInfo, updateOrganization } from '../../../services/OrganizationsService';
import statesList from '../../../helpers/StatesList';
import countryStatesList from '../../../helpers/CountriesStates';


export function ManageOrganization(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [states, setStates] = useState([]);

    var isDirty = useRef(false);

    useEffect(() => {
        var alive = true;

        combineLatest(
            organizationInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data]) => {
            if (data.organizations_ID) {
                setValue('formFields', data);
                let sList = statesList.filter(obj => { return obj.country_code === data.country });
                if(sList.length === 0) {
                    //default to US
                    sList = statesList.filter(obj => { return obj.country_code === 'US' });
                }
                setStates(sList);
            }
        });

        return () => { alive = false; }
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
            }

            if (name === 'formFields.country') {
                var states = statesList.filter(obj => {
                    return obj.country_code === value.formFields.country
                });

                setStates(states);
                if (type === 'change') {
                    setValue('formFields.state', '');
                }
                
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function handleSubmit() {
        let org = { ...getValues().formFields };

        updateOrganization(org).then(data => {
            if (data) {
                organizationInfo.next(org);
                toast.success("Organization information has been updated");
            } else {
                toast.error("Something went wrong");
            }
            isDirty.current = false;
        });
    }

    return (
        <>
            <div className="row">
                <div className="form-group col-12">
                    <label>Organization Name</label>
                    <input maxLength="200" className="ant-input" {...register('formFields.name')} />
                </div>
                <div className="form-group col-12">
                    <label>Country</label>
                    <select className="ant-input" {...register('formFields.country')} >
                        <option></option>
                        {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                    </select>
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>Address</label>
                    <input maxLength="100" className="ant-input" {...register('formFields.address1')} />
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>Address 2</label>
                    <input maxLength="100" className="ant-input" {...register('formFields.address2')} />
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>City</label>
                    <input maxLength="100" className="ant-input" {...register('formFields.city')} />
                </div>

                <div className="form-group col-lg-3 col-12">
                    <label>State</label>
                    {states?.length > 0 &&
                        <select className="ant-input" {...register('formFields.state')}>
                            <option></option>
                            {states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                        </select>
                    }
                </div>

                <div className="form-group col-lg-3 col-12">
                    <label>Zip</label>
                    <input maxLength="10" className="ant-input" {...register('formFields.zip')} />
                </div>
                <div className="form-group col-lg-6 col-12">
                    <label className="form-label">Phone #</label>
                    <Controller
                        control={control}
                        name="formFields.phone"
                        render={({ field: { onChange, onBlur, value, ref } }) =>
                            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                        }
                    />
                </div>
                {/*<div className="form-group col-lg-6 col-12">*/}
                {/*    <label className="form-label">SMS #</label>*/}
                {/*    <Controller*/}
                {/*        control={control}*/}
                {/*        name="formFields.smsNumber"*/}
                {/*        render={({ field: { onChange, onBlur, value, ref } }) =>*/}
                {/*            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />*/}
                {/*        }*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="form-group col-12">
                    <label>URL</label>
                    <input maxLength="200" className="ant-input" {...register('formFields.url')} />
                </div>

                <div className="form-group col-12">
                    <label>Comments</label>
                    <textarea className="ant-input" {...register('formFields.comments')} />
                </div>
            </div>

            <div className="d-flex justify-content-between" key="1">
                <div className="text-center">
                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </>
    );
}
