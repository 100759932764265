import React, { useState, useEffect } from 'react';

import { Table } from 'antd';
import Moment from 'moment';
import { ManipulateChangeList } from '../../helpers/ManipulateText';

import { jobTypes, typesOfPractice, visaTypes } from '../../services/OrganizationsService';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from '../../swr/fetcher';

export function ChangeLog(props) {
    const { mutate } = useSWRConfig()
    const [isActive, setIsActive] = useState(false);

    const [auditChangeList, setAuditChangeList] = useState([]);

    const [listData, setListData] = useState({});

    const [expandedKey, setExpandedKey] = useState(null);
    const key = `/api/GetAuditChangesByCandidate?candidates_ID=${props.candidates_ID}`;
    const { data, error, isLoading, isMutating } = useSWR(key, fetcher);

    useEffect(() => {
        var alive = true;

        combineLatest(
            visaTypes,
            jobTypes,
            typesOfPractice
        ).pipe(takeWhile(() => alive)).subscribe(([visaTypes, jobTypes, typesOfPractice]) => {
            setListData({
                visaTypes,
                jobTypes,
                typesOfPractice
            });
        });


        return () => { alive = false; }
    }, []);

    useEffect(() => {
        setAuditChangeList(props.listData);
    }, [props.listData]);

    useEffect(() => {
        setIsActive(props.isActive);
        if(props.isActive) {
            mutate(key);
        }
    }, [props.isActive]);


    const onExpand = (_, key) => {
        setExpandedKey((prev) => {
            const newKey = key.auditChange.auditChanges_ID;
            if (prev !== newKey) {
                return newKey;
            }
            return null;
        });
    };

    var candidateColumns = [
        {
            title: 'What Changed?',
            dataIndex: ['auditChange', 'oldValues'],
            key: 'changeDate',
            render: (cell, row) => <ManipulateChangeList changeLocation={row?.auditChange?.changeLocation} oldValues={row?.auditChange?.oldValues} changeValues={row?.auditChange?.changeValues} listData={listData} onlyShowFirst={true} />
        },
        {
            title: 'Change Location',
            dataIndex: ['auditChange', 'changeLocation'],
            key: 'changeLocation',
            align: 'center'
        },
        {
            title: 'Change Date',
            dataIndex: ['auditChange', 'changeDate'],
            key: 'changeDate',
            render: (cell) => Moment(new Date(cell)).format('L LT'),
            align: 'center'
        },
        {
            title: 'Changed By',
            dataIndex: ['changedBy'],
            key: 'changedBy',
            align: 'center'
        }
    ];

    const columns = candidateColumns.filter(c => !c.hide);

    return (
        <>
            {isActive &&
                <>
                    <Table
                        rowKey={(record) => record.auditChange.auditChanges_ID}
                        dataSource={data}
                        columns={columns}
                        size='small'
                        expandable={{
                            expandedRowRender: (record) => {
                                if (expandedKey === record?.auditChange?.auditChanges_ID && record?.auditChange?.changeValues) {
                                    return (
                                        <ManipulateChangeList changeLocation={record?.auditChange?.changeLocation} oldValues={record?.auditChange?.oldValues} changeValues={record?.auditChange?.changeValues} listData={listData} onlyShowFirst={false} />
                                    )
                                }
                            },
                            rowExpandable: (record) => (record?.auditChange?.changeValues && (record?.auditChange?.changeLocation === 'Candidate Details' || record?.auditChange?.changeLocation === 'Compensation' || record?.auditChange?.changeLocation === "Candidate Settings")) && Object.keys(JSON.parse(record?.auditChange?.changeValues))?.length > 1,
                            onExpand: onExpand,
                            expandedRowKeys: [expandedKey]
                        }}
                    />
                </>
            }
        </>
    );
}