import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Table, Tabs, Input, Empty, Divider, Alert, Popover } from 'antd';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { bulkArchiveClients, bulkRestoreClients, clientTableParams } from '../../services/ClientsService';
import { AddClient } from './AddClient';
import { useClients } from '../../swr/clients';
import { useForm } from "react-hook-form";
import { toast } from '@rickylandino/react-messages';

export function ClientsHome(props) {

    const navigate = useNavigate();
    const { register, watch } = useForm();

    const isDesktop = useMediaQuery({ minWidth: Globals.lgScreen });

    const [loading, setLoading] = useState(false);

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [activeTab, setActiveTab] = useState('myClients');
    const [clientOwnership, setClientOwnership] = useState('user');

    const [allClientIDs, setAllClientIDs] = useState([]);

    const [tableParams, setTableParams] = useRecoilState(clientTableParams);

    const [clientLists, setClientLists] = useState({
        allUserClients: [],
        filterUserClients: [],
        allClients: [],
        loaded: false
    });

    const [showSelectBulkArchive, setShowSelectBulkArchive] = useState(false);

    const [showAddClient, setShowAddClient] = useState(false);

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });
    const [searchValue, setSearchValue] = useState('');
    const [showArchived, setShowArchived] = useState(false);

    const [selectAllActive, setSelectAllActive] = useState(false);

    const { data, isLoading, error } = useClients(
        uInfo.organizations_ID, 
        uInfo.users_ID, 
        tableParams.pagination.pageSize, 
        tableParams.pagination.current - 1, 
        searchValue,
        sortStuff.sortBy,
        sortStuff.sortDirection,
        showArchived,
        clientOwnership
    );

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(name === 'clientOwnership') {
                setClientOwnership(value.clientOwnership);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }, [showArchived]);

    useEffect(() => {
        if (!loading && data) {
            let clientsToSet = data.userClients ? [...data.userClients] : [];

            if(activeTab === 'companyClients') { 
                clientsToSet = data.companyClients ? [...data.companyClients] : [];
            }

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: clientsToSet.length > 0 ? clientsToSet[0].totalCount : 0
                }
            });

            setClientLists({
                allUserClients: data.userClients ? [...data.userClients] : [],
                filterUserClients: clientsToSet,
                allClients: data.companyClients ? [...data.companyClients] : [],
                loaded: true
            });
        }

        setLoading(false);
    }, [data, activeTab, loading]);

    useEffect(() => {
        setAllClientIDs(data?.userClientIDs);
    }, [data?.userClientIDs]);

    // useEffect(() => {
    //     setLoading(true);

    //     getClientsByOrganization(uInfo.organizations_ID, uInfo.users_ID).then(data => {
    //         let clientsToSet = data.userClients ? [...data.userClients] : [];

    //         setClientLists({
    //             allUserClients: data.userClients ? [...data.userClients] : [],
    //             filterUserClients: clientsToSet,
    //             allClients: data.companyClients ? [...data.companyClients] : [],
    //             loaded: true
    //         });

    //         setClientsToFilterAgainst(clientsToSet);

    //         setTableParams({
    //             ...tableParams,
    //             pagination: {
    //                 ...tableParams.pagination,
    //                 total: clientsToSet.length
    //             }
    //         })

    //         setLoading(false);
    //     });

    // }, []);

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if(sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    function navigateToClientDashboard(row) {
        navigate(`/client-dashboard/${row.clients_ID}`,
            {
                replace: true
            });
    }

    function handleBulkArchive() {
        bulkArchiveClients(selectedRowKeys).then(data => {
            if (data) {
                setSelectedRowKeys([]);
                setShowSelectBulkArchive(false);
                toast.success("Clients have been archived");

                let newClients = clientLists.filterUserClients.filter(c => !selectedRowKeys.includes(c.clients_ID));

                setClientLists({
                    filterUserClients: newClients
                });

            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function handleBulkRestore() {
        bulkRestoreClients(selectedRowKeys, 'restore').then(data => {
            if (data) {
                setSelectedRowKeys([]);
                setShowSelectBulkArchive(false);
                toast.success("Clients have been archived");

                let newClients = clientLists.filterUserClients.filter(c => !selectedRowKeys.includes(c.clients_ID));

                setClientLists({
                    filterUserClients: newClients
                });

            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function iconFormatter(cell, row) {
        return (
            <>
                {row.confidential &&
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>You have configured this client to be confidential, meaning it can only be seen by you.</p>}>
                        <i className="fa-solid fa-key"></i>
                    </Popover>
                }
                {row.users_ID !== uInfo.users_ID &&
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This client is owned by {row.recruiter}</p>}>
                        <i className="client-text fa-regular fa-hospital"></i>
                    </Popover>
                }
            </>
        );
    }

    var clientColumns = [
        {
            title: '',
            dataIndex: 'confidential',
            key: 'confidential',
            render: iconFormatter,
            responsive: ['md'],
            width: 50
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'clientName' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'clientName' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'city' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'city' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['lg'],
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'state' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'state' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['lg'],
        },
        {
            title: 'Client Type',
            dataIndex: 'clientType',
            key: 'clientType',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'clientType' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'clientType' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['lg'],
        },
        {
            title: 'Email Address',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'emailAddress' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'emailAddress' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['lg'],
        },
        {
            title: 'Recruiter',
            dataIndex: 'recruiter',
            key: 'recruiter',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'recruiter' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'recruiter' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Job Count',
            dataIndex: 'jobCount',
            key: 'jobCount'
        }
    ];

    function handleSearch(value) {
        setSearchValue(value);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    const columns = clientColumns.filter(c => !c.hide);

    const dropdownRender = (record) => {
        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>City:</label>
                        &nbsp;
                        {record.city}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>State:</label>
                        &nbsp;
                        {record.state}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Client Type:</label>
                        &nbsp;
                        {record.clientType}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Email Address:</label>
                        &nbsp;
                        {record.emailAddress}
                    </div>
                </div>
            </div>
        );
    }

    
    const onSelectChange = (selectedRow, selected, selectedRows) => {
        let foundIdx = selectedRowKeys.findIndex(id => id === selectedRow.clients_ID);
        if (foundIdx >= 0) {
            setSelectedRowKeys(selectedRowKeys.filter(id => id !== selectedRow.clients_ID));
        } else {
            setSelectedRowKeys([...selectedRowKeys, selectedRow.clients_ID]);
        }
    };

    const onSelectAllChange = (selected, selectedRows, changeRows) => {

        var selRows = allClientIDs?.length > 0 ? allClientIDs : selectedRows.map(row => row.jobs_ID);

        setSelectAllActive(selected);
        setSelectedRowKeys(selected ? selRows : []);
    }

    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectChange,
        onSelectAll: onSelectAllChange
    };

    const selectedLength = selectedRowKeys?.length;
    const pushDisabled = selectedRowKeys?.length > 0;

    var tabs = [
        {
            label: <div className="">Clients</div>,
            key: 'myClients',
            children: (
                <>
                    {loading ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            <div className="row">
                                {uPermissions?.users_ID && uPermissions?.readClients === 'company' &&
                                    <div className="col-xl-2 col-lg-3 col-12">
                                        <label>Filter by Client Ownership</label>
                                        <select className="ant-input" {...register('clientOwnership')}>
                                            <option value="user">My Clients</option>
                                            <option value="org">Organizational Clients</option>
                                        </select>
                                    </div>
                                }
                                <div className="form-group col-xl-2 col-lg-3 col-6">
                                    <label className="ms-1 d-block">Show Archived Clients</label>
                                    <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                                </div>

                                {tableParams.pagination.total >= 0 &&
                                    <div className="form-group col-lg-2 col-6 mx-3 align-items-center">
                                        Total:&nbsp;<strong>{tableParams.pagination.total} Client(s)</strong>
                                    </div>
                                }
                            </div>
                            <Divider></Divider>
                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider>
                            {clientLists.filterUserClients?.length > 0 ?
                                <>
                                    {showSelectBulkArchive ?
                                        <div className="d-flex justify-content-between">
                                            <button className="ant-btn ant-btn-outline mb-3" onClick={() => { setShowSelectBulkArchive(false); setSelectedRowKeys([]); }}>
                                                Cancel Selection
                                            </button>

                                            {showArchived ?
                                                <button className="ant-btn ant-btn-info float-end mb-3" onClick={handleBulkRestore} disabled={!pushDisabled}>
                                                    Restore {selectedLength} Selected Clients
                                                </button>
                                                :
                                                <button className="ant-btn ant-btn-remove float-end mb-3" onClick={handleBulkArchive} disabled={!pushDisabled}>
                                                    Archive {selectedLength} Selected Clients
                                                </button>
                                            }

                                        </div>
                                        :
                                        <div>
                                            <button className={"ant-btn mb-3 " + (showArchived ? "ant-btn-info" : "ant-btn-remove float-start me-3")} onClick={() => setShowSelectBulkArchive(true)}>
                                                {showArchived ? 'Select Clients For Bulk Restoral' : 'Select Clients For Bulk Archival'}

                                            </button>
                                        </div>
                                    }

                                    <Table
                                        rowKey={(record) => record.clients_ID}
                                        dataSource={clientLists.filterUserClients}
                                        rowSelection={(showSelectBulkArchive) ? rowSelection : null}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { event.target.id === 'myCheckbox' ? console.log("checkbox clicked") : navigateToClientDashboard(record) }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        expandable={!isDesktop && {
                                            expandedRowRender: (record) => dropdownRender(record),
                                            rowExpandable: (record) => record.clients_ID !== 'Not Expandable',
                                        }}
                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no clients - to begin adding a client, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Client'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        }
        // , {
        //     label: <div className="client-text">Organizational Clients</div>,
        //     key: 'companyClients',
        //     children: (
        //         <>
        //             {loading ?
        //                 <SkeletonTable columns={columns} rowCount={5} />
        //                 :
        //                 <>
        //                     <div className="d-flex">
        //                         <div className="form-group mx-1 d-flex">
        //                             <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
        //                             <label className="ms-1 align-self-center">Show Archived Clients</label>
        //                         </div>

        //                         {tableParams.pagination.total >= 0 &&
        //                             <div className="form-group mx-3 ps-4 d-flex align-items-center">
        //                                 Total:&nbsp;<strong>{tableParams.pagination.total} Client(s)</strong>
        //                             </div>
        //                         }
        //                     </div>
        //                     <Divider></Divider>
        //                     <Input.Search
        //                         placeholder="Search for keywords..."
        //                         onSearch={handleSearch}
        //                         onChange={(e) => { handleSearch(e.target.value) }}
        //                     />
        //                     <Divider></Divider>
        //                     {clientLists.filterUserClients?.length > 0 ?
        //                         <>

        //                             <Table
        //                                 rowKey={(record) => record.clients_ID}
        //                                 dataSource={clientLists.filterUserClients}
        //                                 columns={columns}
        //                                 size='small'
        //                                 onRow={(record, rowIndex) => {
        //                                     return {
        //                                         onClick: event => { event.target.id === 'myCheckbox' ? console.log("checkbox clicked") : navigateToClientDashboard(record) }, // click row
        //                                         onDoubleClick: event => { }, // double click row
        //                                         onContextMenu: event => { }, // right button click row
        //                                         onMouseEnter: event => { }, // mouse enter row
        //                                         onMouseLeave: event => { }, // mouse leave row
        //                                     };
        //                                 }}
        //                                 expandable={!isDesktop && {
        //                                     expandedRowRender: (record) => dropdownRender(record),
        //                                     rowExpandable: (record) => record.clients_ID !== 'Not Expandable',
        //                                 }}
        //                                 onChange={handleTableChange}
        //                                 pagination={tableParams.pagination}
        //                             />
        //                         </>
        //                         :
        //                         <Empty description={<p>You currently have no clients - to begin adding a client, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Client'.</p>} />
        //                     }
        //                 </>
        //             }
        //         </>
        //     ),
        // }
    ];

    return (
        <>
            {(uPermissions.users_ID && uPermissions.readClients === 'none') ?
                <Empty description={<p>You do you have the appropriate access to view clients. Please contact your system administrator if you believe this is a mistake.</p>} />
                :
                <>
                    {uPermissions?.users_ID && uPermissions?.createClients &&
                        <div className="d-flex justify-content-between">
                            <Alert className="float-start mb-3" message="Full client functionality is still in progress. More robust features are on the way." type="info" showIcon closable />
                            <button className="ant-btn ant-btn-primary float-end" onClick={() => setShowAddClient(true)}>
                                <i className="far fa-plus-square"></i>
                                &nbsp;
                                Add Client
                            </button>
                        </div>
                    }
                    <div className="card-container">
                        <Tabs activeKey={activeTab} type="card" className="client-tabs" items={tabs} onChange={handleTabChange} />
                    </div>

                    <AddClient show={showAddClient} from='clients-dashboard'  hidePane={() => setShowAddClient(false)} />
                </>
            }
        </>
    );
}