import Axios from '../config/axios';
import { atom } from 'recoil';

export const activeUserClientsState = atom({
    key: 'activeUserClientsState',
    default: {}
});

export const clientFilterFields = atom({
    key: 'clientFilterFields',
    default: {
        
    }
});

export const clientTableParams = atom({
    key: 'clientTableParams',
    default: {
        pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        },
    }
});

export const clientJobPlacementTableParams = atom({
    key: 'clientJobPlacementTableParams',
    default: {
        pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        },
    }
});

export const clientJobsTableParams = atom({
    key: 'clientJobsTableParams',
    default: {
        pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        },
    }
});

export const canSelectedClientBeUpdated = atom({
    key: 'canSelectedClientBeUpdated',
    default: false
});

export const canSelectedClientBeDeleted = atom({
    key: 'canSelectedClientBeDeleted',
    default: false
});

export function getClientsByOrganization(org_ID, users_ID) {
    return Axios.get('/api/GetClientsByOrganization', {
        params: {
            org_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getClientDashboardInformation(clients_ID, users_ID) {
    return Axios.get('/api/GetClientDashboardInformation', {
        params: {
            clients_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getClientsByRecruiter(org_ID, users_ID) {
    return Axios.get('/api/GetClientsByRecruiter', {
        params: {
            org_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function addClient(postdata) {
    return Axios.post(`/api/AddClient`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateClient(postdata) {
    return Axios.post(`/api/UpdateClient`, postdata
    ).then(response => response.data).catch(error => error);
}

export function archiveClient(postdata) {
    return Axios.post(`/api/ArchiveClient`, postdata
    ).then(response => response.data).catch(error => error);
}

export function restoreClient(postdata) {
    return Axios.post(`/api/RestoreClient`, postdata).then(response => response.data).catch(error => error);
}

export function bulkArchiveClients(idList) {
    return Axios.post(`/api/BulkArchiveClients`, idList
    ).then(response => response.data).catch(error => error);
}

export function bulkRestoreClients(idList) {
    return Axios.post(`/api/BulkRestoreClients`, idList
    ).then(response => response.data).catch(error => error);
}

export function deleteAllClientInformation(postdata) {
    return Axios.post(`/api/DeleteAllClientInformation`, postdata
    ).then(response => response.data).catch(error => error);
}