import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Descriptions, Drawer, Alert, Select } from 'antd';
import statesList from '../../helpers/StatesList';
import countryStatesList from '../../helpers/CountriesStates';
import Globals from '../../config/globals';

import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { insertOrganization, updateOrganization } from '../../services/OrganizationsService';

export function AddOrganization(props) {

    const ffDefault = {
        name: '',
        country: 'US',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        url: '',
        comments: ''
    }

    const navigate = useNavigate();
    const { register, getValues, setValue, control, watch, formState } = useForm();

    const isDirty = useRef(false);
    const [state, setState] = useState({
        states: statesList.filter(obj => { return obj.country_code === 'US' })
    });

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        if (show) {
            setValue('formFields', ffDefault);
        }
    }, [show]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
                Globals.isDirtyPage = true;
            }

            if (name === 'formFields.country') {
                var states = statesList.filter(obj => {
                    return obj.country_code === value.formFields.country
                });

                setState({
                    ...state,
                    states
                });

                setValue('formFields.state', '');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setValue('formFields', ffDefault);

        var states = statesList.filter(obj => {
            return obj.country_code === 'US'
        });

        setState({
            ...state,
            states
        });

        setShow(false);
        props.hidePane();
        isDirty.current = false;
    }

    function handleSubmit() {
        let postdata = { ...getValues().formFields };

        insertOrganization(postdata).then(data => {
            if (data?.organizations_ID) {
                toast.success("Organization has been sucessfully created.");

                navigate(`/organization-dashboard/${data.organizations_ID}`,
                    {
                        replace: true
                    });
                performClose();
            } else {
                toast.error("Something went wrong")
            }
        });

        //updateOrganization(postdata).then(data => {
        //    if (data) {
        //        toast.success("Organization information has been updated");
        //        setState({
        //            ...state,
        //            persistentContactInformation: postdata
        //        });
        //        isDirty.current = false;
        //        setEdit(false);

        //        props.updateOrganizationInformation(postdata);
        //    }
        //});

    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Add New Organization'
            placement={'right'}
            onClose={handleClose}
            open={show}
            key='slider'
            size='large'
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
        >
            <div className="row">
                <div className="form-group col-12">
                    <label>Organization Name</label>
                    <input maxLength="200" className="ant-input" {...register('formFields.name')} />
                </div>
                <div className="form-group col-lg-6 col-12">
                    <label className="form-label">Contact Number</label>
                    <Controller
                        control={control}
                        name="formFields.phone"
                        render={({ field: { onChange, onBlur, value, ref } }) =>
                            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                        }
                    />
                </div>
                {/*<div className="form-group col-lg-6 col-12">*/}
                {/*    <label className="form-label">SMS Number</label>*/}
                {/*    <Controller*/}
                {/*        control={control}*/}
                {/*        name="formFields.smsNumber"*/}
                {/*        render={({ field: { onChange, onBlur, value, ref } }) =>*/}
                {/*            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />*/}
                {/*        }*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="form-group col-12">
                    <label>Country</label>
                    <select className="ant-input" {...register('formFields.country')} >
                        {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                    </select>
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>Address</label>
                    <input maxLength="100" className="ant-input" {...register('formFields.address1')} />
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>Address 2</label>
                    <input maxLength="100" className="ant-input" {...register('formFields.address2')} />
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>City</label>
                    <input maxLength="100" className="ant-input" {...register('formFields.city')} />
                </div>

                <div className="form-group col-lg-3 col-12">
                    <label>State</label>
                    <select className="ant-input" {...register('formFields.state')}>
                        <option></option>
                        {state.states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                    </select>
                </div>

                <div className="form-group col-lg-3 col-12">
                    <label>Zip</label>
                    <input maxLength="10" className="ant-input" {...register('formFields.zip')} />
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>URL</label>
                    <input maxLength="200" className="ant-input" {...register('formFields.url')} />
                </div>

                <div className="form-group col-12">
                    <label>Comments</label>
                    <textarea maxLength="2000" className="ant-input" {...register('formFields.comments')} />
                </div>
            </div>
        </Drawer>
    );
}