import React, { useEffect, useState } from 'react';
import CandidatePlacementWorkflow from '../CandidatePlacementWorkflow';

export function WorkflowCard(props) {

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    return (
        <>
            {isActive &&
                <>
                    <CandidatePlacementWorkflow showSingleCandidateID={props.selectedCandidate.candidates.candidates_ID} isArchived={props.selectedCandidate.candidates.archive === 1} />
                </>
            }
        </>
    );
}