import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { Table, Empty, Modal, Space, Card } from 'antd';
import { DragOutlined } from '@ant-design/icons';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { activeJobTypesState, activeWorkflowItemsState, jobTypes } from '../../../services/OrganizationsService';
import SkeletonTable from '../../../helpers/SkeletonTable';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { archiveJobType, getArchivedJobTypesByOrganization, insertJobType, updateJobType, updateJobTypes } from '../../../services/JobTypesService';
import { activeUserInfo, userInfo } from '../../../services/UsersService';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../../config/globals';
import { useRecoilState, useRecoilValue } from 'recoil';
import { addWorkflowItem, archiveWorkflowItem, getArchivedWorkflowItemsByOrganization, getWorkflowByOrganization, insertWorkflowItem, updateWorkflowItem, updateWorkflowOrder } from '../../../services/WorkflowItemsService';
const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{
                cursor: 'move',
                ...style,
            }}
            {...restProps}
        />
    );
};

export function ManageWorkflow(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const uInfo = useRecoilValue(activeUserInfo);

    const [loaded, setLoaded] = useState(false);

    const [data, setData] = useState([]);

    const [selectedWorkflowItem, setSelectedWorkflowItem] = useState(null);

    const [archivedWorkflowItems, setArchivedWorkflowItems] = useState([]);
    const [selectedArchivedWorkflowItem, setSelectedArchivedWorkflowItem] = useState(null);

    const [activeWorkflowItems, setActiveWorkflowItems] = useRecoilState(activeWorkflowItemsState);

    //const [activeJobTypes, setActiveJobTypes] = useRecoilState(activeJobTypesState);

    var isDirty = useRef(false);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        var alive = true;

        getWorkflowByOrganization(uInfo.organizations_ID).then(data => { 
            setLoaded(true);
            if(data?.length > 0) {
                setData([...data]);
            } else {
                toast.error("Something went wrong");
            }
            
        });

        getArchivedWorkflowItemsByOrganization(uInfo.organizations_ID).then(data => { 
            setArchivedWorkflowItems(data || []);
        });
    }, []);

    function handleOrderUpdate(workflowItemsList) {
        let postdata = {
            workflowItemsList,
            id: uInfo.organizations_ID
        }

        updateWorkflowOrder(postdata).then(data => {
            if (data) {
                setData(data);
                setActiveWorkflowItems(data);
                toast.success("Order has been updated");
            }
        });
    }

    function pushWorkflowItem(wi) {
        let dt = [...data];

        setValue('formFields', wi);
        dt.push(wi);
        setActiveWorkflowItems(dt);
        setData(dt);
    }

    function handleSubmit() {
        let wi = getValues().formFields;
        if (wi.workflowItems_ID) {
            updateWorkflowItem(wi).then(response => {
                if (response > 0) {
                    let dt = [...data];
                    let wiIdx = dt.findIndex(t => t.workflowItems_ID === wi.workflowItems_ID);
                    if (wiIdx >= 0) {
                        dt[wiIdx] = wi;

                        setData([...dt]);
                        setActiveWorkflowItems([...dt]);
                    }

                    setSelectedWorkflowItem(null)

                    toast.success("Workflow Item has been updated");
                } else {
                    toast.error("Something went wrong");
                }
            });
        } else {
            insertWorkflowItem(wi).then(response => {
                
                if (response > 0) {
                    wi.workflowItems_ID = response;
                    pushWorkflowItem(wi);

                    setSelectedWorkflowItem(wi);

                    setSelectedWorkflowItem(null)
                    toast.success("Workflow Item has been added");
                } else {
                    toast.error("Something went wrong");
                }

                
            })
        }
    }

    function addWorkflowItem() {
        let workflowItem = {
            workflowItem: '',
            organizations_ID: uInfo.organizations_ID,
            active: true,
            order: data.length + 1
        }

        setValue('formFields', workflowItem);

        setSelectedWorkflowItem(workflowItem);
    }

    function restoreWorkflowItem(index) {
        let ajt = [...archivedWorkflowItems];

        let restoredObj = {
            ...archivedWorkflowItems[index],
            active: true,
            order: data.length + 1
        };

        updateWorkflowItem(restoredObj).then(response => {
            if (response >= 0) {
                pushWorkflowItem(restoredObj);

                ajt.splice(index, 1);
                setArchivedWorkflowItems(ajt);

                toast.success("Workflow Item has been restored");
            } else {
                toast.error("Something went wrong");
            }
        });

    }

    function removeWorkflowItem(index) {
        let dt = [...data];
        let ajt = [...archivedWorkflowItems];
        confirm({
            title: "You are about to archive this workflow item.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    setSelectedWorkflowItem(null);

                    let wfToRemove = dt[index];

                    archiveWorkflowItem(wfToRemove).then(response => {
                        if (response >= 0) {
                            ajt.push(wfToRemove);

                            dt.splice(index, 1);

                            let dtCopy = [];

                            dt.forEach((item, index) => {
                                let itemCopy = {...item};
                                itemCopy.order = index + 1;
                                dtCopy.push(itemCopy);
                            });

                            setArchivedWorkflowItems(ajt);
                            setData(dtCopy);
                            setActiveWorkflowItems(dtCopy);

                            toast.success("Workflow Item has been archived");
                        } else {
                            toast.error("Something went wrong");
                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    const jtColumns = [
        {
            title: 'Reorder',
            key: 'reorder',
            render: (_, record) => (
                <DragOutlined />
            ),
            width: 50,
            className: 'text-center move-cursor',
            hide: !isDesktop
        },
        {
            title: 'Workflow Item',
            dataIndex: 'workflowItem',
            key: 'workflowItem',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="edit" className="a hover">Edit</span>
                    <span id="delete" className="a hover">Archive</span>
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    const archiveColumns = [
        {
            title: 'Archived Workflow Item',
            dataIndex: 'workflowItem',
            key: 'workflowItem',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="restore" className="a hover">Restore</span>
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];

            let tempList = update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });

            tempList.forEach((item, idx) => {
                item.order = idx + 1;
            });

            setData(tempList);
            handleOrderUpdate(tempList);

        },
        [data],
    );

    const columns = jtColumns.filter(c => !c.hide);

    return (
        <>
            <div className="d-flex justify-content-end mb-3">
                <button className="ant-btn ant-btn-primary float-end" onClick={addWorkflowItem}>
                    <i className="far fa-plus-square"></i>
                    &nbsp;
                    Add Workflow Item
                </button>
            </div>

            <div className="row">
                <div className="col-lg-6 col-12 pb-4">
                    {loaded ?
                        <>
                            {data.length > 0 &&
                                <DndProvider backend={HTML5Backend}>
                                    <Table
                                        width={'50vw'}
                                        rowKey='workflowItems_ID'
                                        dataSource={data}
                                        columns={columns}
                                        size='small'
                                        pagination={false}
                                        rowClassName={(record, index) => record.workflowItems_ID === selectedWorkflowItem?.workflowItems_ID ? 'row-selected' : ''}
                                        components={components}
                                        onRow={(record, index) => {
                                            const attr = {
                                                index,
                                                moveRow,
                                            };

                                            return {
                                                onClick: event => {
                                                    if (event.target.id === 'delete') {
                                                        removeWorkflowItem(index);
                                                    } else {
                                                        setSelectedWorkflowItem(record); setValue('formFields', record);
                                                    }

                                                },
                                                index,
                                                moveRow
                                            };
                                        }}
                                    />
                                </DndProvider>
                            }                       
                        </>
                        :
                        <SkeletonTable rowCount={5} columns={columns} />
                    }
                </div>

                <div className="col-lg-6 col-12">
                    {archivedWorkflowItems?.length > 0 &&
                        <Table
                            width={'50vw'}
                            rowKey='workflowItems_ID'
                            dataSource={archivedWorkflowItems}
                            columns={archiveColumns}
                            size='small'
                            pagination={false}
                            rowClassName={(record, index) => record.workflowItems_ID === selectedArchivedWorkflowItem?.workflowItems_ID ? 'row-selected' : ''}
                            onRow={(record, index) => {
                                return {
                                    onClick: event => {
                                        if (event.target.id === 'restore') {
                                            restoreWorkflowItem(index);
                                        }
                                    }
                                };
                            }}
                        />
                    }
                </div>

                <Modal
                    open={selectedWorkflowItem}
                    title={selectedWorkflowItem?.workflowItems_ID ? "Edit Workflow Item" : "Add Workflow Item"}
                    footer={[
                        <div className="text-start" key="1">
                            <button className="ant-btn ant-btn-primary text-start" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-outline m-1" onClick={() => setSelectedWorkflowItem(null)}>Cancel</button>
                        </div>
                    ]}
                >
                    <div className="form-group col-lg-6 col-12">
                                <label>Workflow Item</label>
                                <input maxLength="200" className="ant-input" {...register('formFields.workflowItem')} />
                            </div>
                </Modal>
            </div>


        </>
    );
}
