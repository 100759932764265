import axios from 'axios';
import Axios from '../config/axios';
import Globals from '../config/globals';

export function addCandidateAttachment(formdata) {
    return axios.post(`${window.api}api/AddAttachment`, formdata, {
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then(response => response.data);
}

export function getAssociatedCandidateFiles(candidates_ID) {
    return Axios.get('/api/GetAssociatedFiles', { params: { candidates_ID } }).then(response => response.data).catch(error => error);
}

export function editCandidateAttachment(formdata) {
    return axios.post(`${window.api}api/EditAttachment`, formdata, {
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then(response => response.data);
}

export function convertToPDF(formdata) {
    return axios.post(`${window.api}api/ConvertToPDF`, formdata, {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then(response => response.data);
}

export function deleteCandidateAttachment(postdata) {
    return Axios.post(`/api/DeleteAttachment`, postdata).then(response => response.data);
}

export function downloadCandidateAttachment(postdata) {
    return Axios.post(`/api/DownloadAttachment`, postdata, { responseType: 'blob' }).then(response => response.data);
}

export function downloadCandidateResume(postdata) {
    return Axios.post(`/api/DownloadCandidateResume`, postdata, { responseType: 'blob' }).then(response => response.data);
}