import { BehaviorSubject } from 'rxjs';
import Axios from '../config/axios';

export const userCampaigns = new BehaviorSubject({});

export function insertCampaignAndMembers(postdata) {
    return Axios.post(`/api/InsertCampaignAndMembers`, postdata).then(response => response.data).catch(error => error);
}

export function updateCampaignAndMembers(postdata) {
    return Axios.post(`/api/UpdateCampaignAndMembers`, postdata).then(response => response.data).catch(error => error);
}

export function getCampaignMembersSearchResults(searchValue, users_ID) {
    return Axios.get('/api/GetCampaignMembersSearchResults', {
        params: {
            searchValue,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getCampaignsByUser(users_ID) {
    return Axios.get('/api/GetCampaignsByUser', {
        params: {
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getCampaignMembersByCampaign(campaigns_ID) {
    return Axios.get('/api/GetCampaignMembersByCampaign', {
        params: {
            campaigns_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function deleteCampaign(postdata) {
    return Axios.post(`/api/DeleteCampaign`, postdata).then(response => response.data).catch(error => error);
}