import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Input, Empty } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { modifyEducationHistory } from '../../../services/EducationHistoryService';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../../config/globals';
import { activeUserInfo } from '../../../services/UsersService';
import { getChanges } from '../../../helpers/DetectChanges';
import { canSelectedCandidateBeUpdated } from '../../../services/CandidateService';

export function EducationCard(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        formFields: props.selectedCandidate.candidates,
        persistentInformation: props.selectedCandidate.candidates
    });

    const [educationHistory, setEducationHistory] = useState({
        education: [...props.dashboardInformation.educationHistory]
    });

    const [stickyEducation, setStickyEducation] = useState([...props.dashboardInformation.educationHistory]);

    const [noEducationHistory, setNoEducationHistory] = useState(false);

    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const [isActive, setIsActive] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        manipulateEducationHistory();
    }, []);

    function manipulateEducationHistory(education = null) {
        if (!education) {
            education = [...educationHistory.education];
        }

        if (education.length === 0) {
            education.push({
                schoolName: "",
                schoolDate: null,
                degree: "",
                isCurrent: false,
                description: "",
                areaOfStudy: "",
                fellowshipType: '',
                residencyType: ''
            });

            setNoEducationHistory(true);
        } else {
            for (var i = 0; i < education.length; i++) {
                education[i].isDirty = true;
            }
            setNoEducationHistory(false);
        }

        for (var i = 0; i < education.length; i++) {
            education[i].schoolDate = education[i].schoolDate ? Moment(education[i].schoolDate).toDate() : null;
        }
        setEducationHistory({
            education: [...education]
        });

        setStickyEducation([...education]);
    }

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setEdit(false);
                        isDirty.current = false;

                        setEducationHistory({
                            education: [...stickyEducation]
                        });

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function addOrRemoveEducationHistory(e, i) {
        e.preventDefault();

        let education = [...educationHistory.education];

        isDirty.current = true;
        switch (e.target.id) {
            case 'add':

                education.push({
                    schoolName: "",
                    schoolDate: null,
                    degree: "",
                    isCurrent: false,
                    description: "",
                    areaOfStudy: "",
                    fellowshipType: '',
                    residencyType: ''
                });
                setEducationHistory({
                    ...educationHistory,
                    education
                });
                break;
            case 'remove':
                var history = { ...education[i] };

                history.delete = true;

                education[i] = { ...history };

                setEducationHistory({
                    ...educationHistory,
                    education
                });
                break;
            default:
                break;
        }
    }

    function handleEducationHistoryChange(event, i) {
        //event.preventDefault();
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var history = { ...educationHistory.education[i] };
        history[name] = value;
        history["isDirty"] = true;
        isDirty.current = true;
        let education = [...educationHistory.education];
        education[i] = history;

        setEducationHistory({
            ...educationHistory,
            education
        });
    }

    function handleEducationHistoryDateChange(date, dateName, i) {

        var history = { ...educationHistory.education[i] };
        history[dateName] = date;
        history["isDirty"] = true;
        isDirty.current = true;
        let education = [...educationHistory.education];
        education[i] = history;

        setEducationHistory({
            ...educationHistory,
            education
        });
    }

    function handleSubmit() {
        let education = [...educationHistory.education];
        let educationHistoryList = [];

        for (var i = 0; i < education.length; i++) {
            if (education[i].isDirty) {

                education[i].candidates_ID = state.selectedCandidate.candidates.candidates_ID;

                educationHistoryList.push(education[i]);

                education[i].isDirty = false;
            }            
        }

        let postdata = {
            id: state.selectedCandidate.candidates.candidates_ID,
            id2: uInfo.users_ID,
            educationHistoryList,
            auditChangesList: []
        };

        let informationChanges = {
            oldValues: {},
            changeValues: {}
        };

        postdata.educationHistoryList.forEach((item, idx) => {
            let oldObj = { ...stickyEducation[idx] };
            let change = {};

            if (oldObj?.candidates_ID) {
                change = getChanges(oldObj, item, `_educationHistory_ID_${oldObj.educationHistory_ID}`);
            } else if (item?.candidates_ID) {
                change = {
                    oldValues: {},
                    changeValues: {
                        educationItemAdded: item.schoolName
                    }
                }
            }

            if (change?.changeValues?.delete) {
                change = {
                    oldValues: {},
                    changeValues: {
                        educationItemRemoved: item.schoolName
                    }
                }
            }

            for (var values in change) {
                for (var changedItems in change[values]) {
                    informationChanges[values][changedItems] = change[values][changedItems];
                }
            }
        });

        if (informationChanges) {
            informationChanges.oldValues = JSON.stringify(informationChanges.oldValues);
            informationChanges.changeValues = JSON.stringify(informationChanges.changeValues);
            informationChanges.changeType = "EducationHistory";
            informationChanges.changeLocation = "Education";

            postdata.auditChangesList.push(informationChanges);
        }

        modifyEducationHistory(postdata).then(data => {
            if (data) {
                manipulateEducationHistory(data);
                //setStickyEducation([...education]);

                toast.success("Education history has been updated");

                setEdit(false);
                isDirty.current = false;

                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: location.state.selectedCandidate, from: location.state.from, activeTab: 'education' }
                });

            } else {
                toast.error("Something went wrong")
            }
        }).catch(error => toast.error("Something went wrong"));
        
    }

    return (
        <>
            {isActive &&
                <>
                    {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    {!noEducationHistory ?
                        <>
                            {stickyEducation.map((history, i) =>
                                <div key={i}>
                                    {!history.delete &&
                                        <>
                                            <Descriptions
                                                title={i === 0 ? 'Education' : null}
                                                bordered
                                                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                                size='small'
                                                labelStyle={{ width: 300 }}
                                            key={i}
                                            layout={isDesktop ? 'horizontal' : 'vertical'}
                                            >
                                                <Descriptions.Item label='School'>{history.schoolName}</Descriptions.Item>
                                                <Descriptions.Item label='Degree Type'>{history.degree}</Descriptions.Item>
                                                <Descriptions.Item label='Residency Type'>{history.residencyType}</Descriptions.Item>
                                                <Descriptions.Item label='Fellowship Type'>{history.fellowshipType}</Descriptions.Item>
                                                <Descriptions.Item label='Date of Degree Received'>
                                                    {history.isCurrent ?
                                                        <span>Degree in Progress</span>
                                                        :
                                                        history.schoolDate ? Moment(new Date(history.schoolDate)).format('MM/YYYY') : <span>N/A</span>
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>

                                            {i + 1 !== stickyEducation.filter(se => !se.delete).length &&
                                                <div className="horizontal-candidates-divider" />
                                            }
                                        </>
                                    }
                                </div>
                            )}
                        </>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Education Listed' />
                    }

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Education History'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="">
                            <span className="float-end"><i className="hover far fa-plus-square icon-md candidate-text" id="add" onClick={addOrRemoveEducationHistory} /></span>
                            {educationHistory.education.map((history, i) =>
                                <div className="row" key={i}>
                                    {!history.delete &&
                                        <>
                                            <div className="col-12">
                                                <div className="float-end">
                                                    <i className="hover far fa-trash-alt icon-md" id="remove" onClick={(e) => addOrRemoveEducationHistory(e, i)} />
                                                </div>
                                            </div>
                                            <div className="form-group col-12">
                                                <label className="form-label">School</label>
                                                <input maxLength="100" type="text" name="schoolName" value={history.schoolName || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                            </div>
                                            <div className="form-group col-12">
                                                <label className="form-label">Degree Type</label>
                                                <input maxLength="50" type="text" name="degree" value={history.degree || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label className="form-label">Residency Type</label>
                                                <input maxLength="250" type="text" name="residencyType" value={history.residencyType || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label className="form-label">Fellowship Type</label>
                                                <input maxLength="250" type="text" name="fellowshipType" value={history.fellowshipType || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                            </div>
                                            {history.isCurrent ?
                                                <div className="form-group col-lg-4">
                                                    <label className="form-label">Degree Date</label>
                                                    <input className="ant-input" value="Degree in Progress" readOnly />
                                                </div>
                                                :
                                                <div className="form-group col-lg-4">
                                                    <label className="form-label">Degree Date</label>
                                                    <DatePicker
                                                        selected={history.schoolDate}
                                                        onChange={(date, dateName, index) => handleEducationHistoryDateChange(date, "schoolDate", i)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        className="ant-input"
                                                    />
                                                </div>
                                            }

                                            <div className="col-lg-4">
                                                <label className="d-block">In Progress?</label>
                                                <input type="checkbox" className="custom-checkbox" id="isCurrent" name="isCurrent" value={history.isCurrent} onChange={(e) => handleEducationHistoryChange(e, i)} checked={history.isCurrent} />

                                            </div>
                                            <div className="form-group col-12">
                                                <label className="form-label">Description</label>
                                                <textarea maxLength="250" type="text" name="description" value={history.description || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                            </div>
                                            {(educationHistory.education.filter(se => !se.delete).length > 1 && i !== educationHistory.education.filter(se => !se.delete).length - 1) && <div className="horizontal-candidates-divider" />}
                                        </>
                                    }

                                </div>
                            )}
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}