import moment from 'moment';

// not required for this example but aid readability of the main function
const typeOf = o => Object.prototype.toString.call(o);
const isObject = o => o !== null && !Array.isArray(o) && typeOf(o).split(" ")[1].slice(0, -1) === "Object";
const isArray = o => Array.isArray(o);

const keysToIgnore = ['isDirty', 'candidates_ID'];

const isPrimitive = o => {
    switch (typeof o) {
        case "object": {
            return false;
        }
        case "function": {
            return false;
        }
        default: {
            return true;
        }
    }
};

export const getChanges = (previous, current, nameToAppendToKey = "") => {
    if (isPrimitive(previous) && isPrimitive(current)) {
        if (previous === current) {
            return "";
        }

        return current;
    }

    if (isObject(previous) && isObject(current)) {
        const diff = getChanges(Object.entries(previous), Object.entries(current));
        if (diff) {
            return {
                oldValues: diff.oldValues?.reduce((merged, [key, value]) => {
                    if (!keysToIgnore.includes(key)) {
                        return {
                            ...merged,
                            [key + nameToAppendToKey]: value
                        }
                    } else {
                        return {
                            ...merged
                        }
                    }
                }, {}),
                changeValues: diff.changes.reduce((merged, [key, value]) => {
                    if (!keysToIgnore.includes(key)) {
                        return {
                            ...merged,
                            [key + nameToAppendToKey]: value
                        }
                    } else {
                        return {
                            ...merged
                        }
                    }
                }, {})
            };
        }
    }

    const oldValues = [];
    const changes = [];

    if (JSON.stringify(previous) === JSON.stringify(current)) {
        return null;
    }

    for (let i = 0; i < current.length; i++) {
        const item = current[i];
        if (previous[i] && moment(previous[i][1], moment.ISO_8601).isValid()) {
            if (JSON.stringify(moment(previous[i][1]).format("L LT")) !== JSON.stringify(moment(item[1]).format("L LT"))) {
                oldValues.push(previous[i]);
                changes.push(item);
            }
        } else {
            if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
                if (item && previous[i]) {

                    oldValues.push(previous[i]);
                    changes.push(item);
                }
            }
        }
    }
    return { changes, oldValues };
};

export function getChangesToTrack(previous, current, changeType, changeLocation, nameToAppendToKey = "") {
    let informationChanges = getChanges(previous, current, nameToAppendToKey);
    
    if (informationChanges) {
        informationChanges.oldValues = JSON.stringify(informationChanges.oldValues);
        informationChanges.changeValues = JSON.stringify(informationChanges.changeValues);
        informationChanges.changeType = changeType;
        informationChanges.changeLocation = changeLocation;

        return informationChanges;
    }

    return null;
}