import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Layout as AntLayout, Menu } from 'antd';


import {
    ImportOutlined
} from '@ant-design/icons';
import { ImportCandidates } from '../Import/ImportCandidates';
import { activeUserInfo } from '../../services/UsersService';

const AntSider = AntLayout.Sider;

export function MainSider(props) {
    const [mainSiderCollapsed, setMainSiderCollapsed] = useState(true);
    const [showImportCandidates, setShowImportCandidates] = useState(false);

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const items = [
        getItem('Import', 'sub1', <ImportOutlined />, [
            getItem(<span onClick={() => setShowImportCandidates(true)}>Import Candidates</span>, '3')
        ]),
    ];

    return (
        <>
            <AntSider collapsible collapsed={mainSiderCollapsed} onCollapse={(value) => setMainSiderCollapsed(value)} className="main-slider">
                <div className="logo" />
                <Menu defaultSelectedKeys={['1']} mode="inline" items={items} />
            </AntSider>

            {showImportCandidates && <ImportCandidates hideModal={() => setShowImportCandidates(false)} showModal={showImportCandidates} users_ID={uInfo.users_ID} />}
        </>
    );
}