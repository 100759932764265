import React, { useEffect, useState, Fragment, useRef } from 'react';
import Moment from 'moment';
import { useRecoilValue } from 'recoil';
import { smsHistory, sendSMS, updateReadMessagesByNumber, textableCandidates, doesUserHaveNumber, getSMSOnLoadInfo, textableUsers } from '../../services/SmsService';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { useNavigate, useLocation } from "react-router-dom";

import MediaQuery from 'react-responsive';

import { Select, List, Popover, Skeleton, Tooltip, Button, Empty, Modal, Spin, Alert } from 'antd';
import { SendOutlined, LoadingOutlined } from '@ant-design/icons';
import Globals, { Constants } from '../../config/globals';
import '../../css/sms.css';
import '../../css/chatbox.css';
import { Controller, useForm } from 'react-hook-form';
import { activeUserInfo } from '../../services/UsersService';
import { toast } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { addAnonymousCandidate, isPhoneNumberUnique } from '../../services/CandidateService';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { Option } = Select;
const content = (date) => (
    <div>
        <p>{date}</p>
    </div>
);

const EMPTY_CANDIDATE = { 
    firstName: '',
    lastName: '',
    phoneNumber: ''
};

function getArrDifference(array1, array2) {
    return array1.filter(object1 => {
        return !array2.some(object2 => {
            return object1.candidates.candidates_ID === object2.candidates.candidates_ID;
        });
    });
}

export function Messenger(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const location = useLocation();
    const navigate = useNavigate();

    var messageList = useRef();

    const uInfo = useRecoilValue(activeUserInfo);
    const [smsHistoryList, setSmsHistory] = useState([]);
    const [selectedThread, setSelectedThread] = useState({});
    const [textableCandidatesList, setTextableCandidates] = useState([]);
    const [textableUsersList, setTextableUsers] = useState([]);
    const [selectedkey, setSelectedkey] = useState(null);
    const [userHasNumber, setUserHasNumber] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [selectedCandidatesFromCampaign, setSelectedCandidatesFromCampaign] = useState([]);
    const [selectedUsersFromCampaign, setSelectedUsersFromCampaign] = useState([]);

    const [showAddCandidateModal, setShowAddCandidateModal] = useState(false);
    const [addingCandidate, setAddingCandidate] = useState(false);
    const [phoneNumberNotUnique, setPhoneNumberNotUnique] = useState(false);

    useEffect(() => {
        if (uInfo?.users_ID) {
            getSMSOnLoadInfo();
        }
    }, [uInfo]);

    useEffect(() => {
        let sms = [...smsHistoryList];

        if (sms?.length > 0) {
            let st = { ...selectedThread };
            if (!st.key) {
                if (location?.state?.selectedCandidates_ID) {
                    st = sms.find(item => parseInt(item.key) === location?.state?.selectedCandidates_ID);
                    location.state.selectedCandidates_ID = null;
                }
                //check if valid. If not, check against props, if props id, populate thread to show
                if (!st.value) {
                    st = sms.find(item => item.key === st?.key?.toString()) || {};
                }
                if (props.from !== 'campaigns') {
                    updateSelectedThread(st);
                }
            } else {
                st = sms.find(item => item.key === st?.key?.toString()) || {};
                updateSelectedThread(st);
            }
        }
    }, [smsHistoryList]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            smsHistory,
            textableCandidates,
            textableUsers,
            doesUserHaveNumber
        ).pipe(takeWhile(() => alive)).subscribe(([sms, tc, tu, uNum]) => {
            if (uNum !== null && uNum !== '') {
                setUserHasNumber(uNum);
                setLoaded(true);
            }

            setSmsHistory(sms);
            setTextableCandidates(tc);
            setTextableUsers(tu);

        });

        if (props.from === 'campaigns') {
            handleCandidateSelect('msgAll', null);

            if (uInfo.userType === 'SuperAdmin') {
                setSelectedUsersFromCampaign(props.selectedUsers);
            } else {
                setSelectedCandidatesFromCampaign(props.selectedCandidates);
            }
        } else if (props.from === 'manageUser') {
            handleCandidateSelect(props.userKey);
        }

        return () => alive = false;
    }, []);

    useEffect(() => {
        if (messageList) {
            const scrollHeight = messageList.scrollHeight;
            const height = messageList.clientHeight;
            const maxScrollTop = scrollHeight - height;
            messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }, [selectedThread]);

    useEffect(() => {
            const subscription = watch((value, { name, type }) => {

            if (name === 'formFields.phoneNumber') {
                if(value.formFields.phoneNumber) {
                    isPhoneNumberUnique(value.formFields.phoneNumber, 0).then(data => {
                        setPhoneNumberNotUnique(!data);
                    });
                }
            }
        });


        return () => subscription.unsubscribe();
        
    }, [watch]);

    function updateSelectedThread(st) {
        if (st?.value) {

            st.value.smsItems.filter(item => item.messageRead === false).forEach(item => {
                item.messageRead = true
            });

            for (var i = 0; i < st.value.smsItems.length; i++) {
                if (i === 0) {
                    st.value.smsItems[i].showTime = true;
                } else {
                    var currentMessageDate = new Date(new Date(st.value.smsItems[i].messageDate).getFullYear(), new Date(st.value.smsItems[i].messageDate).getMonth(), new Date(st.value.smsItems[i].messageDate).getDate());
                    var prevMessageDate = new Date(new Date(st.value.smsItems[i - 1].messageDate).getFullYear(), new Date(st.value.smsItems[i - 1].messageDate).getMonth(), new Date(st.value.smsItems[i - 1].messageDate).getDate());

                    if (currentMessageDate.getTime() !== prevMessageDate.getTime()) {
                        st.value.smsItems[i].showTime = true;
                    }
                }
            }
            setSelectedThread(st);

            if (st.value.smsNumber) {

                let postdata = {
                    s1: st.value.smsNumber,
                    id: Globals.userInfo.users_ID
                };

                updateReadMessagesByNumber(postdata);
            }
        }
    }

    function selectThread(event, idx) {

        let st = smsHistoryList[idx];

        setSelectedkey(parseInt(st.key));

        updateSelectedThread(st);
    }

    function sendMessage(e) {
        e.preventDefault();

        if (uInfo.userType === 'SuperAdmin') {
            let smsHistoryItem = {
                messageType: 'Out',
                messageContent: getValues().message,
                organizations_ID: Globals.userInfo.organizations_ID,
                users_ID: Globals.userInfo.users_ID,
                messageRead: true,
                withCampaigns: props.from === 'campaigns' ? true : false
            }

            if (selectedThread?.key === 'msgAll') {
                smsHistoryItem.usersList = selectedUsersFromCampaign.filter(u => u.user.cellPhone).map(tc => (tc.user.users_ID));
            } else {
                smsHistoryItem.receivingUsers_ID = selectedThread?.value?.user?.users_ID;
            }

            sendSMS(smsHistoryItem).then(data => {
                if (selectedThread?.key === 'msgAll') {
                    toast.success("Your message has been sent to each individual user. Select a thread to view your chat history.", { duration: 5 });
                }
            });
        } else {
            let smsHistoryItem = {
                messageType: 'Out',
                messageContent: getValues().message,
                organizations_ID: Globals.userInfo.organizations_ID,
                users_ID: Globals.userInfo.users_ID,
                messageRead: true,
                withCampaigns: props.from === 'campaigns' ? true : false,
                candidates_ID: selectedThread?.value?.candidate?.candidates_ID
            }

            if (selectedThread?.key === 'msgAll') {
                smsHistoryItem.candidatesList = selectedCandidatesFromCampaign.map(tc => (tc.candidates.candidates_ID));
            } else {
                smsHistoryItem.candidates_ID = selectedThread.value.candidate.candidates_ID;
            }

            sendSMS(smsHistoryItem).then(data => {
                if (selectedThread?.key === 'msgAll') {
                    toast.success("Your message has been sent to each individual candidate. Select a thread to view your chat history.", { duration: 5 });
                }
            });
        }
        setValue('message', '');
    }

    function goToCandidateDashboard(candidate = null) {

        let canObj = {};

        if (candidate) {
            canObj = {
                candidates: candidate,
                candidateNumber: selectedThread.value.candidatePhoneNumber
            }
        } else {
            canObj = {
                candidates: selectedThread.value.candidate,
                candidateNumber: selectedThread.value.candidatePhoneNumber
            };
        }

        navigate(`/candidate-dashboard/${canObj.candidates.candidates_ID}`,
            {
                replace: true,
                state: { from: 'sms-dashboard' }
            });
    }

    function clearThread() {
        setSelectedThread([]);
    }

    function handleCandidateFilter(input, option) {
        var retval = false;

        retval = option?.key?.indexOf(input) >= 0 && true;
        if (!retval) {
            retval = option?.key?.replaceAll('-', '').indexOf(input) >= 0 && true;
        }
        // if (!retval && option?.children && option.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0) {
        //     retval = true;
        // }

        !retval && option?.children?.every(c => {
           if (c.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
               retval = true;
           };
        })

        return retval;
    }

    function handleCandidateSelect(key, option) {
        if (key === 'msgAll') {
            let st = {
                key: key,
                value: {
                    allCandidates: true,
                    smsItems: [],
                    client: null,
                    smsNumber: true
                }
            };

            setSelectedThread({ ...st });
            setSelectedkey(key);

        } else {
            let st = smsHistoryList.find(h => parseInt(h.key) === key);
            setSelectedkey(key);
            if (st) {
                updateSelectedThread(st);
            } else {
                if (uInfo.userType === 'SuperAdmin') {
                    let textableCandidate = textableUsersList.find(u => u.user.users_ID === key);
                    st = {
                        key: key,
                        value: {
                            ...textableCandidate,
                            user: textableCandidate?.user,
                            smsNumber: textableCandidate?.smsNumber,
                            smsItems: [],
                            client: null,
                            textingAllowed: true
                        }
                    };
                } else {
                    let textableCandidate = textableCandidatesList.find(c => c.candidates.candidates_ID === key);
                    st = {
                        key: key,
                        value: {
                            ...textableCandidate,
                            candidate: textableCandidate?.candidates,
                            smsNumber: textableCandidate?.smsNumber,
                            smsItems: [],
                            client: null,
                            textingAllowed: true
                        }
                    };
                }
            }

            setSelectedThread({ ...st });
        }
    }

    function createCandidateViaNumber(e) {
        
        let id = e.target.id;
        let value = e.target.value;

        if (e.keyCode === 13 && (id === 'subSpecialty' || id === 'specialty') && value) {

        }
    }

    function handleAddCandidate() {
        let postdata = { ...getValues().formFields };
        postdata.users_ID = uInfo.users_ID;
        postdata.receiveSMS = true;

        if(postdata.phoneNumber) {
            addAnonymousCandidate(postdata).then(data => { 
                if(data) {
                    toast.success("Candidate added successfully.");
                    setShowAddCandidateModal(false);
                    setAddingCandidate(false);
                    let textableCandidate = data.textableCandidates.find(c => c.candidates.candidates_ID === data.addedCandidate);
    
                    let st = {
                        key: data.addedCandidate,
                        value: {
                            ...textableCandidate,
                            candidate: textableCandidate?.candidates,
                            smsNumber: textableCandidate?.smsNumber,
                            smsItems: [],
                            client: null,
                            textingAllowed: true
                        }
                    };
    
                    setSelectedThread({ ...st });
                    setSelectedkey(data.addedCandidate);
    
                    textableCandidates.next(data.textableCandidates);
                    
                    updateSelectedThread(st);

                    //console.log(st);
    
                    setValue('formFields', EMPTY_CANDIDATE);
                }
            });
        } else {
            toast.warning("Please enter a valid phone number.");
        }
        
        
    }

    return (
        <Fragment>
            {loaded ?
                <Fragment>
                    <div className="d-flex justify-content-end">
                        <button className="ant-btn ant-btn-primary float-end" onClick={() => setShowAddCandidateModal(true)}>
                            <i className="far fa-plus-square"></i>
                            &nbsp;
                            Add Candidate via Phone Number
                        </button>
                    </div>
                    {userHasNumber ?
                        <Fragment>
                            {/*<button onClick={simulateReceiveMessage} className="ant-btn ant-btn-primary">Test Message</button>*/}
                            {(textableCandidatesList?.length > 0 || uInfo.userType === 'SuperAdmin') ?
                                <Fragment>
                                    <MediaQuery minWidth={992}>
                                        <div className="row messenger-container">
                                            {uInfo.userType === 'SuperAdmin' ?
                                                <div className="col col-lg-3 col-6 messenger-list">
                                                    <Fragment>
                                                        <Select
                                                            showSearch
                                                            className="w-100 mt-3 mb-3"
                                                            placeholder="Find a User"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => handleCandidateFilter(input, option)}
                                                            onSelect={(input, option) => handleCandidateSelect(input, option)}
                                                        >
                                                            {props.from === 'campaigns' &&
                                                                <Option key='msgAll' value='msgAll'>Select All Selected Users</Option>
                                                            }
                                                            {textableUsersList?.filter(item => item.user.cellPhone).map((u, idx) =>
                                                                <Option key={u.user.cellPhone} value={u.user.users_ID}>
                                                                    {u.user.firstName + " " + u.user.lastName}
                                                                </Option>
                                                            )}

                                                        </Select>
                                                        {smsHistoryList.length > 0 && smsHistoryList.map((item, idx) => (
                                                            <div key={idx} className={selectedThread?.value?.user?.users_ID === parseInt(item.key) ? "selectedThread messenger-list-item" : "messenger-list-item"} onClick={(event) => selectThread(event, idx)}>
                                                                <div className="messenger-list-item-name">{!item.value.smsItems[item.value.smsItems.length - 1].messageRead && <i className="fas fa-circle me-2 sms-blue"></i>}{item.value.user?.firstName + " " + item.value.user?.lastName}</div>
                                                                <div className="messenger-list-item-content"><span className={!item.value.smsItems[item.value.smsItems.length - 1].messageRead ? 'font-weight-bold' : ''}>{item.value.smsItems[item.value.smsItems.length - 1].messageContent}</span></div>
                                                            </div>
                                                        ))}
                                                    </Fragment>
                                                </div>
                                                :
                                                <div className="col col-lg-3 col-6 messenger-list">
                                                    <Fragment>
                                                        <Select
                                                            showSearch
                                                            className="w-100 mt-3 mb-3"
                                                            placeholder="Find a Candidate"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => handleCandidateFilter(input, option)}
                                                            onSelect={(input, option) => handleCandidateSelect(input, option)}
                                                            value={selectedkey}
                                                            // onInputKeyDown={createCandidateViaNumber}
                                                            // notFoundContent='Press enter to text and add this candidate'
                                                        >
                                                            {props.from === 'campaigns' &&
                                                                <Option key='msgAll' value='msgAll'>Select All Selected Candidates</Option>
                                                            }
                                                            {textableCandidatesList?.map((c, idx) =>
                                                                <Option key={c.smsNumber} value={c.candidates.candidates_ID}>
                                                                    {c.candidates?.anonymous && !c.candidates?.firstName && !c.candidates?.lastName ? c.smsNumber : c.candidates.firstName + " " + c.candidates.lastName}
                                                                    {c.candidates.users_ID !== uInfo.users_ID &&
                                                                        <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This is an {Constants.companyCandidateText}</p>}>
                                                                            &nbsp;<i className="candidate-text fa-solid fa-users-between-lines"></i>
                                                                        </Popover>
                                                                    }
                                                                </Option>
                                                            )}

                                                        </Select>
                                                        {smsHistoryList.length > 0 && smsHistoryList.map((item, idx) => (
                                                            <div key={idx} className={selectedThread?.value?.candidate?.candidates_ID === parseInt(item.key) ? "selectedThread messenger-list-item" : "messenger-list-item"} onClick={(event) => selectThread(event, idx)}>
                                                                <div className="messenger-list-item-name">{!item.value.smsItems[item.value.smsItems.length - 1].messageRead && <i className="fas fa-circle me-2 sms-blue"></i>}{(item.value?.candidate?.anonymous && !item.value?.candidate?.firstName && !item.value?.candidate?.lastName) ? item?.value?.smsNumber : item.value?.candidate?.firstName + " " + item.value?.candidate?.lastName}</div>
                                                                <div className="messenger-list-item-content"><span className={!item.value.smsItems[item.value?.smsItems?.length - 1].messageRead ? 'font-weight-bold' : ''}>{item.value.smsItems[item.value?.smsItems?.length - 1].messageContent}</span></div>
                                                            </div>
                                                        ))}
                                                    </Fragment>
                                                </div>
                                            }
                                            <div className="col col-lg-9 col-6 mb-3 ind-message-container">
                                                {selectedThread?.value?.allCandidates &&
                                                    <>
                                                        {uInfo.userType === 'SuperAdmin' ?
                                                            <div className="message-header row">
                                                                <div className="col col-lg-6 col-12">
                                                                    <h4>All Available, Selected Users</h4>
                                                                </div>
                                                                <div className="col col-lg-6 col-12">
                                                                    <Popover
                                                                        content={
                                                                            <div>
                                                                                <hr />
                                                                                <div className="d-flex justify-content-center">
                                                                                    <List
                                                                                        size="small"
                                                                                        header={<u>Users that will be messaged</u>}
                                                                                        bordered
                                                                                        dataSource={selectedUsersFromCampaign.filter(item => item.user.cellPhone)}
                                                                                        renderItem={item => <List.Item>
                                                                                            {item.user.firstName + " " + item.user.lastName}
                                                                                        </List.Item>
                                                                                        }
                                                                                    />
                                                                                    <List
                                                                                        size="small"
                                                                                        header={<u>Users that <b>will not</b> be messaged</u>}
                                                                                        bordered
                                                                                        dataSource={selectedUsersFromCampaign.filter(item => !item.user.cellPhone)}
                                                                                        renderItem={item => <List.Item>
                                                                                            {item.user.firstName} {item.user.lastName}
                                                                                        </List.Item>
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <hr />
                                                                                <label className="form-label"><span className="text-warning">**</span> A user will not be messaged if they do not have a valid cell # that can be used for SMS. <span className="text-warning">**</span></label>
                                                                            </div>
                                                                        }
                                                                        placement={"left"}
                                                                    >
                                                                        <span className="a hover float-end">Who am I messaging?</span>
                                                                    </Popover>

                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="message-header row">
                                                                <div className="col col-lg-6 col-12">
                                                                    <h4>All Available, Selected Candidates</h4>
                                                                </div>
                                                                <div className="col col-lg-6 col-12">
                                                                    <Popover
                                                                        content={
                                                                            <div>
                                                                                <hr />
                                                                                <div className="d-flex justify-content-center">
                                                                                    <List
                                                                                        size="small"
                                                                                        header={<u>Candidates that will be messaged</u>}
                                                                                        bordered
                                                                                        dataSource={selectedCandidatesFromCampaign.filter(item => item.smsNumber)}
                                                                                        renderItem={item => <List.Item actions={[<a key="list-loadmore-edit" className="a" onClick={() => goToCandidateDashboard(item.candidates)}>View Candidate</a>]}>
                                                                                            {(item.candidates?.anonymous && !item.candidates?.firstName && !item.candidates?.lastName) ? 
                                                                                                item.smsNumber : item.candidates.firstName + " " + item.candidates.lastName}
                                                                                        </List.Item>
                                                                                        }
                                                                                    />
                                                                                    <List
                                                                                        size="small"
                                                                                        header={<u>Candidates that <b>will not</b> be messaged</u>}
                                                                                        bordered
                                                                                        dataSource={getArrDifference(selectedCandidatesFromCampaign, textableCandidatesList)}
                                                                                        renderItem={item => <List.Item actions={[<a key="list-loadmore-edit" className="a" onClick={() => goToCandidateDashboard(item.candidates)}>View Candidate</a>]}>
                                                                                            {item.candidates.firstName} {item.candidates.lastName}
                                                                                        </List.Item>
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <hr />
                                                                                <label className="form-label"><span className="text-warning">**</span> A candidate will not be messaged if they do not have a valid cell # that can be used for SMS. <span className="text-warning">**</span></label>
                                                                            </div>
                                                                        }
                                                                        placement={"left"}
                                                                    >
                                                                        <span className="a hover float-end">Who am I messaging?</span>
                                                                    </Popover>

                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }

                                                {selectedThread?.value?.candidate &&
                                                    <div className="message-header row">
                                                        <div className="col col-lg-6 col-12">
                                                        {(selectedThread.value?.candidate?.anonymous && !selectedThread.value?.candidate?.firstName && !selectedThread.value?.candidate?.lastName) ?
                                                            <h4>{selectedThread.value.smsNumber}</h4>
                                                            :
                                                            <h4>{selectedThread.value.candidate.firstName} {selectedThread.value.candidate.lastName}</h4>
                                                        }
                                                        </div>
                                                        <div className="col col-lg-6 col-12">
                                                            <span className="a hover float-end" onClick={() => goToCandidateDashboard(selectedThread.value.candidate)}>{selectedThread.value?.candidate?.anonymous && !selectedThread.value?.candidate?.firstName && !selectedThread.value?.candidate?.lastName ? 'Add Candidate Details' : 'View Candidate'}</span>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="message-conversation-container" id='message-conversation-container' ref={(div) => messageList = div}>
                                                    {selectedThread?.value?.smsItems.length > 0 && selectedThread.value.smsItems.map((item, idx) => (
                                                        <Fragment key={idx}>
                                                            <div className="row text-center"><small>{item.showTime && Moment(item.messageDate).format("ddd, MMMM Do YYYY, h:mm a")}</small></div>
                                                            <li className="clearfix">
                                                                <Tooltip title={() => content(Moment(item.messageDate).format("ddd, MMMM Do YYYY, h:mm a"))} placement={item.messageType === 'Out' ? "topRight" : "topLeft"}>
                                                                    <div className={`message p-2 ${item.messageType === 'Out' ? 'my-message float-end' : 'other-message float-start'}`}>
                                                                        {item.messageContent}
                                                                    </div>
                                                                </Tooltip>
                                                            </li>
                                                        </Fragment>
                                                    ))}
                                                </div> 
                                                <div className="type-message">

                                                    <form className="d-flex align-items-center" onSubmit={sendMessage}>
                                                        <textarea className="ant-input" {...register('message')}
                                                            onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    sendMessage(event)
                                                                }
                                                            }}
                                                            style={{ resize: 'none', borderRadius: '15px', margin: '0 10px' }}
                                                            disabled={(selectedThread?.value?.smsNumber || selectedThread?.value?.user?.cellPhone) ? false : true}
                                                            placeholder={(selectedThread?.value?.smsNumber || selectedThread?.value?.user?.cellPhone) ? 'Type a message...' : 'This user cannot currently be messaged.'}
                                                        />

                                                        <Button type="primary" size="large" title="Send Message" shape="circle" icon={<SendOutlined />} onClick={sendMessage} />
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </MediaQuery>

                                    <MediaQuery maxWidth={991}>
                                        <div className="row messenger-container">
                                            <div className="col col-12 messenger-list">

                                                {!selectedThread?.value?.smsItems.length > 0 &&
                                                    <Fragment>
                                                        <Select
                                                            showSearch
                                                            className="w-100 mt-3 mb-3"
                                                            placeholder="Find a Candidate"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => handleCandidateFilter(input, option)}
                                                            onSelect={(input, option) => handleCandidateSelect(input, option)}
                                                        >
                                                            {props.from === 'campaigns' &&
                                                                <Option key='msgAll' value='msgAll'>Select All Selected Candidates</Option>
                                                            }
                                                            {textableCandidatesList.map((c, idx) =>
                                                                <Option key={c.smsNumber} value={c.candidates.candidates_ID}>
                                                                    {c.candidates.firstName} {c.candidates.lastName}
                                                                    {c.candidates.users_ID !== uInfo.users_ID &&
                                                                        <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This is an {Constants.companyCandidateText}</p>}>
                                                                            &nbsp;<i className="candidate-text fa-solid fa-users-between-lines"></i>
                                                                        </Popover>
                                                                    }
                                                                </Option>
                                                            )}

                                                        </Select>
                                                        {smsHistoryList.length > 0 && smsHistoryList.map((item, idx) => (
                                                            <div key={idx} className="messenger-list-item" onClick={(event) => selectThread(event, idx)}>
                                                                <div className="messenger-list-item-name">{!item.value.smsItems[item.value.smsItems.length - 1].messageRead && <i className="fas fa-circle me-2 sms-blue"></i>}{item.value.candidate?.firstName + " " + item.value.candidate?.lastName}</div>
                                                                <div className="messenger-list-item-content"><span className={!item.value.smsItems[item.value.smsItems.length - 1].messageRead ? 'font-weight-bold' : ''}>{item.value.smsItems[item.value.smsItems.length - 1].messageContent}</span></div>
                                                            </div>
                                                        ))}

                                                    </Fragment>
                                                }
                                            </div>

                                            {selectedThread?.value?.smsItems.length > 0 &&

                                                <div className="col col-12 mb-3 ind-message-container">

                                                    {selectedThread?.value?.candidate?.candidates_ID &&
                                                        <Fragment>
                                                            <div className="message-header">
                                                                <i className="float-start fa-2x fas fa-chevron-left hover" onClick={clearThread}></i>
                                                                <div className="col text-center">
                                                                    <h2 className="text-center d-inline">{selectedThread.value.candidate?.firstName} {selectedThread.value.candidate?.lastName}</h2>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                    <div className="message-conversation-container" id='message-conversation-container' ref={(div) => messageList = div}>
                                                        {selectedThread?.value?.smsItems.length > 0 && selectedThread.value.smsItems.map((item, idx) => (
                                                            <Fragment key={idx}>
                                                                <div className="row text-center"><small>{item.showTime && Moment(item.messageDate).format("ddd, MMMM Do YYYY, h:mm a")}</small></div>
                                                                <li className="clearfix">
                                                                    <Tooltip title={() => content(Moment(item.messageDate).format("ddd, MMMM Do YYYY, h:mm a"))} placement={item.messageType === 'Out' ? "topRight" : "topLeft"}>
                                                                        <div className={`message p-2 ${item.messageType === 'Out' ? 'my-message float-end' : 'other-message float-start'}`}>
                                                                            {item.messageContent}
                                                                        </div>
                                                                    </Tooltip>
                                                                </li>
                                                            </Fragment>
                                                        ))}
                                                    </div>
                                                    <div className="d-flex type-message">

                                                        <form className="d-flex w-100 align-items-center" onSubmit={sendMessage}>
                                                            <textarea className="ant-input" {...register('message')}
                                                                onkeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        sendMessage(event)
                                                                    }
                                                                }}
                                                                style={{ resize: 'none', borderRadius: '15px' }}
                                                                disabled={(selectedThread?.value?.smsNumber || selectedThread?.value?.user?.cellPhone) ? false : true}
                                                                placeholder={(selectedThread?.value?.smsNumber || selectedThread?.value?.user?.cellPhone) ? 'Type a message...' : 'This user cannot currently be messaged.'}
                                                            />

                                                            <Button type="primary" size="large" title="Send Message" shape="circle" icon={<SendOutlined />} onClick={sendMessage} />
                                                        </form>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </MediaQuery>
                                </Fragment>
                                :
                                <Empty className="ignore-height" image={<i className="fa-solid fa-user-slash icon-lg"></i>} description="You do not have any candidates that can be messaged. Please add a candidate with a valid cell phone number to get started." />
                            }
                        </Fragment>
                        :
                        <Empty className="ignore-height" image={<i className="fa-solid fa-phone-slash icon-lg"></i>} description="You do not have a valid number to use for texting. Please contact your system administrator to get set up." />
                    }
                </Fragment>
                :
                <Skeleton active />
            }

            <Modal
                open={showAddCandidateModal}
                title="Add Candidate via Phone Number"
                footer={[
                    <div className="text-start" key="1">
                        {addingCandidate ?
                            <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                            :
                            <button className="ant-btn ant-btn-primary text-start" onClick={handleAddCandidate} disabled={phoneNumberNotUnique}>Add</button>
                        }
                        <button className="ant-btn ant-btn-default text-start" onClick={() => { setShowAddCandidateModal(false); setAddingCandidate(false); setValue('formFields', EMPTY_CANDIDATE); setPhoneNumberNotUnique(false); }}>Cancel</button>
                    </div>
                ]}
            >
                <div className="row">
                    <div className="form-group col-lg-6 col-12">
                        <label>First Name</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.firstName')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Last Name</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.lastName')} />
                    </div>

                    {phoneNumberNotUnique &&
                        <Alert className="col-12 mb-3" message="This number is already in use. Please select another." type="error" showIcon />
                    }

                    <div className="form-group col-12">
                        <label>Phone Number <span className="text-danger">*</span></label>
                        <Controller
                            control={control}
                            name="formFields.phoneNumber"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <PhoneInput
                                    numberInputProps={{
                                        className: 'ant-input'
                                    }}
                                    name="phoneNumber"
                                    defaultCountry={'US'}
                                    value={value} onChange={onChange}
                                />
                            }
                        />
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}