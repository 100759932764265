import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Layout as AntLayout, Alert, Affix, Menu } from 'antd';



import { TopNav } from './Nav/TopNav';
import Globals from '../config/globals';

import MediaQuery, { useMediaQuery } from 'react-responsive';
import { Sider } from './Nav/Sider';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import recruiterbalmLogo from '../img/RecruiterBalmLogo.jpg';
import nchcrLogo from '../img/nchcr_brand_banner.png';
import { imitationMode, realUserInfoWhileImpersonationIsOn, userInfo, userPermissions, activeUserInfo } from '../services/UsersService';

import { useNavigate } from "react-router-dom";
import { getOrganizationInfo } from '../services/OrganizationsService';
import { setEventsAsNotSeenBySystem, setEventsAsSeenBySystem, setNotificationsActive, userEvents } from '../services/EventsService';
import { AlertsPopover } from './Nav/AlertsPopover';
import { BottomNav } from './Nav/BottomNav';
import { MainSider } from './Nav/MainSider';
import Footer from './Nav/Footer/Footer';

const { Content, Header } = AntLayout;

const AntSider = AntLayout.Sider;
const logo = Globals.brand === 'nchcr' ? nchcrLogo : recruiterbalmLogo;




export function Layout(props) {
    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(true);
    
    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    const [toImitate, setToImitate] = useState(false);
    const [realUser, setRealUser] = useState(null);

    const [events, setEvents] = useState([]);
    const [notificationEvents, setNotificationEvents] = useState([]);
    const [timeoutList, setTimeoutList] = useState([]);

    //let imitationMode = window.sessionStorage.getItem("imitationMode");

    useEffect(() => {
        let filteredList = events.filter(e => e.events.completedDate === null);

        let evListForInitialLoad = filteredList.filter(e => (e.events.notificationTriggered && e.events.notificationActive) || (!e.events.notificationActive && !e.events.notificationTriggered) || (!e.events.systemSeen && e.events.notificationTriggered));
        triggerReminderOnInitialLoad(evListForInitialLoad);

    }, []);

    useEffect(() => {
        let filteredList = events.filter(e => e.events.completedDate === null);

        let timeouts = [];

        //let evListForInitialLoad = filteredList.filter(e => (e.events.notificationTriggered && e.events.notificationActive) || (!e.events.notificationActive && !e.events.notificationTriggered) || (!e.events.systemSeen && e.events.notificationTriggered));
        //triggerReminderOnInitialLoad(evListForInitialLoad);

        let eventIDsThatNeedToBeReset = [];
        filteredList.forEach(item => {
            var today = new Date();
            if (item.events.eventReminder !== null) {
                var thisEventDate = new Date(item.events.eventReminder);
                var diffMs = (thisEventDate - today);

                if (diffMs > 0) {
                    eventIDsThatNeedToBeReset.push(item.events.events_ID);
                    var t = setTimeout(() => triggerReminder(item, "Reminder"), diffMs);
                    timeouts.push(t);
                }
            } else if (item.events.eventDateTime !== null) {
                var thisEventDate = new Date(item.events.eventDateTime);
                var diffMs = (thisEventDate - today);

                if (diffMs > 0) {
                    eventIDsThatNeedToBeReset.push(item.events.events_ID);
                    var t = setTimeout(() => triggerReminder(item, "Due Date"), diffMs);
                    timeouts.push(t);
                }
            }
            
        });

        let postdata = {
            eventIDList: eventIDsThatNeedToBeReset
        };

        //api call to reset systemseen status
        setEventsAsNotSeenBySystem(postdata);

        setTimeoutList(timeouts);
    }, [events]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo,
            realUserInfoWhileImpersonationIsOn,
            imitationMode,
            userEvents
        ).pipe(takeWhile(() => alive)).subscribe(([data, realUser, imitationMode, userEvents]) => {

            if (realUser?.users_ID) {
                setRealUser(realUser);
            }
            
            setToImitate(imitationMode);
            
            if (userEvents?.length > 0) {
                setTimeoutList([]);

                setEvents(userEvents);
            }
            
        });

        return () => { alive = false; }
    }, []);

    useEffect(() => {
        if (isDesktop) {
            setCollapsed(true);
        }
    }, [isDesktop]);

    function triggerReminder(event, whatTriggered) {
        let eventList = [...notificationEvents];
        
        let ev = {
            ...event.events,
            type: 'event',
            content: event.events.subject,
            details: event.events.details,
            date: event.events.eventDateTime,
            alert: true,
            whatTriggered
        }

        eventList.unshift(ev);

        setNotificationEvents(eventList);
    }

    function triggerReminderOnInitialLoad(events) {

        let evList = events.map(ev => ({
            ...ev.events,
            type: 'event',
            content: ev.events.subject,
            details: ev.events.details,
            date: ev.events.eventDateTime,
            alert: !ev.events.systemSeen,
            notificationActive: true
        }));

        let idList = events.map(e => e.events.events_ID);
        let postdata = {
            eventIDList: idList
        };
        //api call to set notifications to active
        setNotificationsActive(postdata);

        setNotificationEvents(evList);
    }

    function cancelImpersonate() {
        Globals.userInfo = realUser;
        userInfo.next(realUser);

        setUInfo(realUser);

        realUserInfoWhileImpersonationIsOn.next(null);
        imitationMode.next(false);

        getOrganizationInfo();

        navigate("/candidates", { replace: true });
    }

    function updateSystemSeen(events) {

        let idList = events.map(e => e.events_ID);
        let postdata = {
            eventIDList: idList
        };

        //api call to update events as seen by system
        setEventsAsSeenBySystem(postdata);
    }

    function updateNotificationEvents(evList) {
        setNotificationEvents(evList);
    }

    function getUserTypeText(userType) {
        var retval = userType;

        switch (userType) {
            case 'SuperAdmin':
                retval = 'Super Admin';
                break;
            case 'OrgAdmin':
                retval = 'Organization Admin';
                break;
            default:
                break;
        }

        return retval;
    }


    return (
        <AntLayout className="layout h-100">

            <TopNav triggerEventNotification={notificationEvents} updateSystemSeen={updateSystemSeen} updateNotificationEvents={updateNotificationEvents} handleSider={(isCollapse) => setCollapsed(isCollapse)} />
            

            {/*<MediaQuery maxWidth={Globals.mobileWidth}>*/}
            {/*    <Header className="d-flex mobile-header ant-menu-mobile justify-content-center">*/}
            {/*        <div>*/}
            {/*            <img src={logo} style={{ height: '50px' }} />*/}
            {/*        </div>*/}
            {/*    </Header>*/}
            {/*</MediaQuery>*/}

            <Affix style={isDesktop ? { position: 'absolute', bottom: 100, right: 32, zIndex: 4 } : { position: 'absolute', bottom: 63, right: 12, zIndex: 4 }}>
                <AlertsPopover placement='bottomLeft' triggerEventNotification={notificationEvents} updateSystemSeen={updateSystemSeen} updateNotificationEvents={updateNotificationEvents} />
            </Affix>

            <AntLayout className="layout">

                {uInfo?.userType === 'SuperAdmin' && <MainSider /> }

                <Content className="h-100-calc m-2" style={isDesktop ? { padding: '0 50px' } : { padding: '10px 0px' }}>
                        {toImitate &&
                            <Alert
                            message={<><i className="icon-md text-center fas fa-user-secret"></i>
                                &nbsp; You are in impersonation mode. You are currently impersonating <strong>{Globals.userInfo.firstName} {Globals.userInfo.lastName}</strong>.
                                &nbsp; This allows you to act as this user throughout RecruiterBalm &trade;</>}
                                description={<>
                                            <hr />
                                            <div onClick={cancelImpersonate} className="hover">Click here or click &times; to exit impersonation mode.</div></>}
                                type="info"
                                closable
                            onClose={cancelImpersonate}
                            className="m-3"
                            />
                        }

                    {/* <i title="Go Back" onClick={() => navigate(-1)} className="me-4 mt-2 float-start hover icon-md fa-solid fa-arrow-left" style={{ 'color': '#1890ff'}}></i> */}
                    <div className="d-flex justify-content-between">
                        <h3 className="">Welcome, {uInfo?.firstName}</h3>
                        <div className="float-end">Logged in as: <br /> <strong><em>{getUserTypeText(uInfo?.userType)}</em></strong></div>
                    </div>
                        <div className="site-layout-content">{props.children}</div>
                </Content>

               
                
                <Sider collapsed={collapsed} closeSider={() => setCollapsed(true)} />
                    
            </AntLayout>

            <MediaQuery maxWidth={Globals.mobileWidth}>
                <BottomNav handleSider={(isCollapse) => setCollapsed(isCollapse)} />
            </MediaQuery>
            
            
            {/*<Footer style={{ textAlign: 'center' }}>RecruiterBalm &copy;2022</Footer>*/}
        </AntLayout>
    );
}