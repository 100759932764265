import React, { useEffect, useState } from 'react';
import { Drawer, Switch, Alert, Select, Modal } from 'antd';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import { toast, confirm } from '@rickylandino/react-messages';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { dateWithNoTimezone } from '../../helpers/DateFormat';
import { addEvent, deleteEvent, editEvent, emptyEvent, userEvents } from '../../services/EventsService';
import { getCandidate } from '../../services/CandidateService';
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';
import { activeWorkflowItemsState } from '../../services/OrganizationsService';
import { set } from 'lodash';

export function EventSlider(props) {

    const uInfo = useRecoilValue(activeUserInfo);

    const { register, watch, setValue, getValues, control } = useForm();

    const location = useLocation();
    const navigate = useNavigate();

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const activeWorkflowItems = useRecoilValue(activeWorkflowItemsState);

    const [hasReminder, setHasReminder] = useState(false);
    const eventStatus = watch("formFields.status", props.drivers);

    const [relatedRecord, setRelatedRecord] = useState(null);
    const [entity, setEntity] = useState(null);

    const readOnly = props.readOnly;

    useEffect(() => {
        if (props.showModal) {
            setValue('formFields', props.selectedEvent?.events || emptyEvent);

            if (props.selectedEvent?.events?.eventReminder) {
                setHasReminder(true);
            }

            if (props.selectedEvent?.eventsRelatedRecords?.recordType === 'candidate') {
                //if existing event
                getCandidate(props.selectedEvent?.eventsRelatedRecords?.relatedRecordID).then(data => {
                    setEntity(data);
                });
            } else if (props.candidates_ID) {

                //if new event
                setValue('formFields.users_ID', props.selectedCandidate.users.users_ID.toString());

                setRelatedRecord({
                    recordType: 'candidate',
                    relatedRecordID: props.candidates_ID
                });
            }

            setModalInfo({
                show: true
            });
        }
    }, [props.showModal]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'formFields.status') {
                if (value.formFields.status === 'completed') {
                    setValue('formFields.completedDate', new Date());
                    setValue('formFields.eventReminder', null);
                } else {
                    setValue('formFields.completedDate', null);
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function handleClose() {
        setModalInfo({
            show: false
        });
        setHasReminder(false);
        setValue('formFields', { ...emptyEvent });
        props.hideModal();
    }

    function handleSubmit() {
        if (!getValues().formFields.eventDateTime || !getValues().formFields.subject) {
            Modal.warning({
                title: "Please make sure all required fields are filled in",
            });
        } else {
            let postdata = {
                events: getValues().formFields,
                eventsRelatedRecords: relatedRecord,
                users_ID: props.users_ID
            }

            if (postdata.events.users_ID) {
                postdata.events.users_ID = parseInt(postdata.events.users_ID);
            } else {
                postdata.events.users_ID = props.users_ID;
            }

            if(postdata.events.eventType) {
                if(postdata.events.eventType.includes('wf:')) {
                    postdata.events.eventTypes_ID = null;
                    postdata.events.eventType = postdata.events.eventType.replace('wf:', '');
                } else {
                    postdata.events.eventTypes_ID = props.eventTypes.find(type => type.eventType === postdata.events.eventType)?.eventTypes_ID;
                }
            } else {
                postdata.events.eventTypes_ID = null;
            }

            if (postdata.events.events_ID) {
                editEvent(postdata).then(data => {
                    if (data) {
                        toast.success("Event has been updated");
                        if (postdata.events.users_ID === props.users_ID || !relatedRecord) {
                            userEvents.next(data);
                        }
                        handleClose();

                    } else {
                        toast.error("Something went wrong");
                    }
                }).catch(e => console.log(e));
            } else {
                addEvent(postdata).then(data => {
                    if (data) {
                        toast.success("Event has been added");
                        if (postdata.events.users_ID === props.users_ID || !relatedRecord) {
                            userEvents.next(data);
                        }
                        handleClose();
                    } else {
                        toast.error("Something went wrong");
                    }
                }).catch(e => console.log(e));
            }
        }
    }

    function toggleReminder() {
        if (hasReminder) {
            setHasReminder(false);
            setValue('formFields.eventReminder', null);
        } else {
            setHasReminder(true);
        }
    }

    function deleteEv() {
        confirm({
            title: "You are about to delete this event.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                deleteEvent(getValues().formFields).then(data => {
                    if (data) {
                        toast.success("Event has been deleted");
                        userEvents.next(data);
                        handleClose();
                    } else {
                        toast.error("Something went wrong");
                    }

                });
                return 0;
            } else {
                return 0;
            }
        });
    }

    function addCustomEventType(e) {

        let id = e.target.id;
        let value = e.target.value;

        if (id === 'eventType' && value) {
            if (!props.eventTypes?.find(et => et.eventType.toLowerCase().includes(value.toLowerCase())) || 
                !activeWorkflowItems?.find(wf => wf.workflowItem.toLowerCase().includes(value.toLowerCase()))) {
                setValue('formFields.eventType', value);
            }
        }

    }

    function goToCandidateDashboard(candidate) {
        navigate(`/candidate-dashboard/${candidate.candidates_ID}`,
            {
                replace: true,
                state: { from: 'dashboard' }
            });
    }

    const RenderTitle = () => {
        if (relatedRecord) {
            if (relatedRecord.recordType === 'candidate') {
                return <span className="candidate-text">Candidate Event</span>;
            }
        }

        return 'Event';
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title={<div>{RenderTitle()} &nbsp; {(getValues()?.formFields?.events_ID && !readOnly) && <button className="ant-btn ant-btn-danger float-end" onClick={deleteEv}><i className='far fa-trash-alt'></i> &nbsp; Delete Event</button>}</div>}
            placement={'right'}
            onClose={handleClose}
            open={modalInfo.show}
            key='eventSlider'
            size="large"
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div className="text-center">
                        {!readOnly && <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>}
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>

                </div>
            }
        >
            <div className="row align-items-center">
                {entity?.candidates_ID && <Alert className="mb-3" message={<span><i className="fa-regular fa-user candidate-text"></i> {entity.firstName} {entity.lastName}</span>} type="error" action={
                    <span className="a hover float-end" onClick={() => goToCandidateDashboard(entity)}>View Candidate</span>
                } />}
                <div className="form-group col-12">
                    <label>Type</label>

                    <Controller
                        control={control}
                        name="formFields.eventType"
                        render={({ field: { onChange, onBlur, value, ref } }) =>
                            <Select
                                className="ant-input"
                                id="eventType"
                                value={value}
                                onChange={onChange}
                                onBlur={addCustomEventType}
                                showSearch
                                disabled={readOnly}
                                options={[
                                    {
                                        label: 'Event Types',
                                        options: props.eventTypes.map((type, idx) => ({ label: type.eventType, value: type.eventType}))
                                    },
                                    {
                                        label: 'Workflow Items',
                                        options: activeWorkflowItems?.map((item, idx) => ({ label: item.workflowItem, value: `wf:${item.workflowItem}`}))
                                    },
                                ]}
                                onInputKeyDown={addCustomEventType}
                                notFoundContent='Press enter to use your custom event type'
                            />
                        }
                    />
                </div>
                <div className="form-group col-12">
                    <label>Subject <span className="text-danger">*</span></label>
                    <input maxLength="100" className="ant-input" {...register('formFields.subject')} disabled={readOnly} />
                </div>

                <div className="form-group col-12">
                    <label>Details</label>
                    <textarea className="ant-input" {...register('formFields.details')} disabled={readOnly} />
                </div>

                <div className="form-group col-12">
                    <label>Priority</label>
                    <select className="ant-input" {...register('formFields.priority')} disabled={readOnly}>
                        <option value="low">Low</option>
                        <option value="normal">Normal</option>
                        <option value="high">High</option>
                    </select>
                </div>

                <div className="form-group col-12">
                    <label>Due Date <span className="text-danger">*</span></label>
                    <Controller
                        control={control}
                        name="formFields.eventDateTime"
                        render={({ field: { onChange, onBlur, value, ref } }) =>
                            <DatePicker
                                selected={value ? new Date(value) : new Date()}
                                value={value ? new Date(value) : new Date()}
                                disabled={readOnly}
                                onChange={onChange}
                                className="ant-input"
                                showTimeSelect
                                dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        }
                    />
                </div>

                <div className="form-group col-12">
                    <label>Status</label>
                    <select className="ant-input" {...register('formFields.status')} disabled={readOnly}>
                        <option value="not started">Not Started</option>
                        <option value="in progress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="waiting">Waiting on someone else</option>
                    </select>
                </div>

                {eventStatus === 'completed' ?
                    <div className="form-group col-12">
                        <label>Completion Date</label>
                        <Controller
                            control={control}
                            name="formFields.completedDate"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <DatePicker
                                    selected={value ? new Date(value) : null}
                                    disabled={readOnly}
                                    onChange={onChange}
                                    className="ant-input"
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                />
                            }
                        />
                    </div>
                    :
                    <>
                        <div className="form-group col-5">
                            <label className="d-block">Add Reminder?</label>
                            <Switch checked={hasReminder} onChange={toggleReminder} disabled={readOnly} />
                        </div>

                        <div className="form-group col-7">
                            <label>Event Reminder</label>
                            <Controller
                                control={control}
                                name="formFields.eventReminder"
                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                    <DatePicker
                                        selected={value ? new Date(value) : null}
                                        onChange={onChange}
                                        className="ant-input"
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        disabled={!hasReminder || readOnly}
                                    />
                                }
                            />
                        </div>
                    </>
                }

                {uInfo.userType === 'OrgAdmin' && relatedRecord?.recordType === 'candidate' && props.selectedCandidate?.users?.users_ID !== props.users_ID &&
                    <div className="form-group col-12">
                        <label className="d-block">Create Event For</label>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('formFields.users_ID')} value={props.users_ID} />
                            <label> Myself</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('formFields.users_ID')} value={props.selectedCandidate?.users?.users_ID} />
                            <label> {props.selectedCandidate?.users?.firstName} {props.selectedCandidate?.users?.lastName}</label>
                        </div>
                    </div>
                }
            </div>
        </Drawer>
    );
}