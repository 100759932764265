import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { getOrganizationInfo, getUsersByOrganization } from '../../../services/OrganizationsService';
import { useMediaQuery } from 'react-responsive';
import { Table, Card, Drawer, Modal } from 'antd';
import Globals from '../../../config/globals';
import { defaultRecruiterPermissions, ManageProfile } from './ManageProfile';
import { useNavigate } from "react-router-dom";
import { imitationMode, realUserInfoWhileImpersonationIsOn, userInfo, userPermissions, activeUserInfo, activeUserPermissions, insertUserFromNchcr } from '../../../services/UsersService';
import { MobileOutlined } from '@ant-design/icons';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Messenger } from '../../SMS/Messenger';
import { syncFromNchcr, syncWithNchcr } from '../../../services/NchcrService';

export function ManageUsers(props) {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const [selectedUser, setSelectedUser] = useState(null);
    const [messengerOptions, setMessengerOptions] = useState({
        show: false,
        key: ''
    });

    const [users, setUsers] = useState({
        data: [],
        loaded: false
    });

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const [uPermissions, setUPermissions] = useRecoilState(activeUserPermissions);

    const [nchcrId, setNchcrId] = useState(null);
    const [showNchcrModal, setShowNchcrModal] = useState(false);

    useEffect(() => {
        let org_ID = null;

        if (props?.organizations_ID) {
            org_ID = props?.organizations_ID;
        } else if (uInfo.organizations_ID) {
            org_ID = uInfo.organizations_ID;
        }

        if (org_ID) {
            getUsersByOrganization(org_ID).then(data => {
                setUsers({
                    data: data.filter(u => u.user.users_ID !== uInfo.users_ID),
                    loaded: true
                });
            });
        }
    }, [props, uInfo])

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data]) => {
            if (data?.users_ID) {
                setUInfo(data);
            }
        });

        return () => { alive = false; }
    }, []);

    function updateUsers(user) {
        let usersList = [...users.data];
        let userIdx = usersList.findIndex(u => u.user.users_ID === user.user.users_ID);
        if (userIdx >= 0) {
            usersList[userIdx] = user;
        } else {
            usersList.push(user);
        }

        setSelectedUser(user);

        setUsers({
            ...users,
            data: usersList
        });
    }

    function impersonateUser(impersonatee) {
        let realUser = { ...uInfo };
        Globals.userInfo = impersonatee.user;

        userInfo.next(impersonatee.user);
        userPermissions.next(impersonatee.userPermissions);

        setUInfo(impersonatee.user);
        setUPermissions(impersonatee.userPermissions);

        realUserInfoWhileImpersonationIsOn.next(realUser);
        imitationMode.next(true);

        getOrganizationInfo();

        navigate("/candidates", { replace: true });
    }

    function addNewUser() {

        setSelectedUser(null);

        let org_ID = null;

        if (props?.organizations_ID) {
            org_ID = props?.organizations_ID;
        } else if (uInfo.organizations_ID) {
            org_ID = uInfo.organizations_ID;
        }

        let newUser = {
            user: {
                users_ID: 0,
                organizations_ID: org_ID,
                firstName: "",
                lastName: "",
                workPhone: "",
                extension: "",
                cellPhone: "",
                emailAddress: "",
                userType: "Recruiter",
                tfaEnabled: false,
                smsNumber: "",
                color: '#fff',
                profilePicture: null,
                disabled: false
            },
            userPermissions: {
                users_ID: 0,
                manageTags: false,
                manageEventTypes: false,
                manageWorkflowItems: false,
                manageJobTypes: false,
                manageSpecialties: false
            }
        }

        setSelectedUser(newUser);
    }

    function openMessenger(row) {
        setMessengerOptions({
            show: true,
            key: row.user.users_ID.toString()
        });
    }

    function handleAddNchcrUser() {
        let postdata = {
            nchcrRecruiters_ID: parseInt(nchcrId),
            user: {
                organizations_ID: props.organizations_ID
            },
            userPermissions: defaultRecruiterPermissions
        }

        insertUserFromNchcr(postdata).then(data => {
            console.log(data);
        });
    }

    function handleSyncToNchcr() { 
        let postdata = {
            users_ID: selectedUser?.user?.users_ID
        }

        syncFromNchcr(postdata).then(data => {
            console.log(data);
        });
    }

    var columns = [
        {
            title: 'Name',
            dataIndex: ['user', 'firstName'],
            key: 'firstName',
            render: (cell, row) => <span>{row.user.firstName} {row.user.lastName}</span>,
            ellipsis: true
        }, {
            title: 'Cell Phone',
            dataIndex: ['user', 'cellPhone'],
            key: 'cellPhone',
            ellipsis: true,
            width: 150,
            responsive: ['xl']
        }, {
            title: 'Work Phone',
            dataIndex: ['user', 'workPhone'],
            key: 'workPhone',
            ellipsis: true,
            width: 150,
            responsive: ['xl']
        }, {
            title: 'Email',
            dataIndex: ['user', 'emailAddress'],
            key: 'emailAddress',
            ellipsis: true,
            responsive: ['lg']
        }, {
            title: 'User Type',
            dataIndex: ['user', 'userType'],
            key: 'userType',
            ellipsis: true,
            width: 150,
            responsive: ['lg'],
            render: (cell, row) => cell === 'OrgAdmin' ? 'Admin' : cell
        },
        {
            title: 'Text User',
            dataIndex: ['user', 'cellPhone'],
            key: 'y',
            className: 'text-center',
            render: (cell, row) => cell ? <MobileOutlined className="icon-md" onClick={() => openMessenger(row)} /> : <i title="This user does not have a valid cell phone tied to their account" className="fa-solid fa-phone-slash icon-sm"></i>
            ,
            width: 100
        },
        {
            title: 'Impersonate',
            dataIndex: '',
            key: 'x',
            className: 'text-center',
            render: (cell, row) => <i className="fa-solid fa-user-secret icon-md" onClick={() => impersonateUser(row)}></i>,
            width: 100
        }
    ];

    const dropdownRender = (record) => {
        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-12">
                        <label>Cell Phone:</label>
                        &nbsp;
                        {record.user.cellPhone}
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <label>Work Phone:</label>
                        &nbsp;
                        {record.user.workPhone}
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <label>Email:</label>
                        &nbsp;
                        {record.user.emailAddress}
                    </div>
                    <div className="col col-lg-3 col-md-6 col-12">
                        <label>User Type:</label>
                        &nbsp;
                        {record.user.userType}
                    </div>
                </div>
            </div>
        );
    }

    if (!(uInfo.userType === 'OrgAdmin' || uInfo.userType === 'SuperAdmin')) {
        columns = columns.filter(c => c.title !== 'Impersonate');
    }

    if (uInfo.userType !== 'SuperAdmin') {
        columns = columns.filter(c => c.title !== 'Text User');
    }

    return (
        <div>
            <div className="d-flex justify-content-end mb-3">
                {props.nchcrOrg &&
                    <button className="ant-btn ant-btn-success float-end me-4" onClick={() => setShowNchcrModal(true)}>
                        Add User From NCHCR ID
                    </button>
                }
                {uInfo.userType === 'SuperAdmin' &&
                    <button className="ant-btn ant-btn-primary float-end" onClick={addNewUser}>
                        <i className="far fa-plus-square"></i>
                        &nbsp;
                        Add User
                    </button>
                }
            </div>
            {users.loaded &&
                <Table
                    rowKey={(row) => row.user.users_ID}
                    dataSource={users.data}
                    columns={columns}
                    size='small'
                    rowClassName={(record, index) => record.user.users_ID === selectedUser?.user?.users_ID ? 'row-selected' : ''}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { setSelectedUser(record) }, // click row
                            onDoubleClick: event => { }, // double click row
                            onContextMenu: event => { }, // right button click row
                            onMouseEnter: event => { }, // mouse enter row
                            onMouseLeave: event => { }, // mouse leave row
                        };
                    }}
                    expandable={!isDesktop && {
                        expandedRowRender: (record) => dropdownRender(record),
                        rowExpandable: (record) => record.candidates_ID !== 'Not Expandable',
                    }}
                />
            }
            {selectedUser?.user &&
                <Card title={selectedUser?.user?.users_ID ? 'Edit User' : 'Add User'}>
                    {uInfo.emailAddress === 'ricky@landino.net' && selectedUser?.user?.nchcrMember && <button className="ant-btn ant-btn-success" onClick={handleSyncToNchcr}>Sync NCHCR Candidates</button>}
                    <ManageProfile user={selectedUser} updateUsers={updateUsers} from='orgUsers' isAdminOrg={props.isAdminOrg} />

                    <button className="ant-btn ant-btn-outline m-1 float-end" onClick={() => setSelectedUser(null)}>Cancel</button>
                </Card>
            }

            {messengerOptions.show &&
                <Drawer
                    closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                    title='Message Recruiter'
                    placement={'right'}
                    onClose={() => setMessengerOptions({ show: false, key: '' })}
                    open={messengerOptions.show}
                    key='drawer'
                    width={'100%'}
                    className="d-flex"
                    footer={
                        <div className="d-flex justify-content-between" key="1">
                            <div>
                                <button className="btn btn-outline-primary float-right" onClick={() => setMessengerOptions({ show: false, key: '' })}>Close</button>
                            </div>
                        </div>
                    }
                >
                    <Messenger from="manageUser" userKey={messengerOptions.key} />
                </Drawer>
            }

            <Modal
                title="Add via Recruiter ID"
                open={showNchcrModal}
                onOk={handleAddNchcrUser}
                onCancel={() => {
                    setNchcrId(null);
                    setShowNchcrModal(false);
                }}
            >
                <div className="form-group">
                    <label>NCHCR Recruiter ID</label>
                    <input className="ant-input" defaultValue={null} value={nchcrId} onChange={e => setNchcrId(e.target.value)} />
                </div>
            </Modal>
        </div>
    );
}