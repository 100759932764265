import React, { useState, useEffect } from 'react';
import { Dropdown, Layout, Menu, Switch, Tabs, Avatar, Badge, Popover, List, Typography } from 'antd';
import { NotificationOutlined, MobileOutlined, UserOutlined, BellOutlined } from '@ant-design/icons';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { confirm, toast } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import Moment from 'moment';
import { dismissEvents, userEvents } from '../../services/EventsService';
import { getSMSHistoryByUser, smsHistory, unreadMessages, updateReadMessagesByUser } from '../../services/SmsService';
import { userInfo } from '../../services/UsersService';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
const { Text } = Typography;

//placement="bottomRight"
export function AlertsPopover(props) {

    const [uInfo, setUInfo] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    const [alerts, setAlerts] = useState({
        list: [],
        showNotification: false
    });
    const [alertToPush, setAlertToPush] = useState(null);

    const [events, setEvents] = useState({
        list: [],
        showNotification: false
    });

    const [activeNotificationKey, setActiveNotificationKey] = useState('alerts');

    var urlend = window.api.slice(-1) === '/' ? 'hubs/chat' : '/hubs/chat';
    const url = window.api + urlend;
    var connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.None)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        if (uInfo.users_ID) {
            var uid = uInfo.users_ID;
            connection.start()
                .then(result => {
                    connection.on('NotifyOfMessage', response => {
                        if (response.users_ID === uid) {
                            getSMSHistoryByUser(response.users_ID).then(data => {
                                smsHistory.next(data);

                                if (location.pathname !== '/sms-dashboard') {
                                    let candidateThread = data.find(item => item.key === response.candidates_ID.toString());

                                    if (candidateThread) {
                                        let receivedMessage = candidateThread.value.smsItems[candidateThread.value.smsItems.length - 1]

                                        let alert = {
                                            ...receivedMessage,
                                            type: 'sms',
                                            content: receivedMessage.messageContent,
                                            date: receivedMessage.messageDate,
                                            relatedEntity: 'candidate',
                                            entityObject: candidateThread.value.candidate,
                                            alert: true,
                                            candidates_ID: receivedMessage.candidates_ID
                                        }

                                        setAlertToPush(alert);
                                    }
                                }
                            });
                        }

                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [uInfo]);

    useEffect(() => {
        if (props.triggerEventNotification?.length > 0) {
            let eventThatHasAlert = props.triggerEventNotification.find(e => e.alert);
            setEvents({
                list: [...props.triggerEventNotification],
                showNotification: eventThatHasAlert ? true : false
            });
            setActiveNotificationKey('events');
        }
    }, [props.triggerEventNotification]);

    useEffect(() => {
        if (alertToPush) {
            let alertList = [...alerts.list];
            if (!alertList.find(al => al.smsHistory_ID === alertToPush.smsHistory_ID)) {
                alertList.unshift(alertToPush);
                setAlerts({
                    list: alertList,
                    showNotification: true
                });

                setActiveNotificationKey('alerts');

                setAlertToPush(null);
            }
            
        }
    }, [alertToPush]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo,
            unreadMessages,
            smsHistory
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo, unreadMessages, smsHist]) => {
            
            if (userInfo.users_ID) {
                setUInfo(userInfo);
            }

            if (unreadMessages?.length > 0) {
                let alertsList = unreadMessages.map(sms => ({
                    ...sms,
                    type: 'sms',
                    content: sms.messageContent,
                    date: sms.messageDate,
                    relatedEntity: 'candidate',
                    entityObject: smsHist.find(m => m.key === sms.candidates_ID.toString())?.value.candidate,
                    alert: true
                }));

                setAlerts({
                    ...alerts,
                    list: alertsList
                });
            }
        });

        //connection.start()
        //    .then(result => {
        //        connection.on('ReceiveMessage', sms => {
        //            smsHistory.next(sms.allMessages);
        //            console.log(sms);
        //            let alert = {
        //                ...sms.receivedMessage,
        //                type: 'sms',
        //                content: sms.receivedMessage.messageContent,
        //                date: sms.receivedMessage.messageDate,
        //                relatedEntity: 'candidate',
        //                entityObject: sms.allMessages.find(m => m.key === sms.receivedMessage.candidates_ID.toString())?.value.candidate,
        //                alert: true,
        //                candidates_ID: sms.receivedMessage.candidates_ID
        //            }

        //            setAlertToPush(alert);
        //        });
        //    })
        //    .catch(e => console.log('Connection failed: ', e));

        return () => alive = false;
    }, []);
    

    function openNotifications() {
        setAlerts({ ...alerts, showNotification: false });
        setEvents({ ...events, showNotification: false });

        props.updateSystemSeen(events.list);
    }

    function directToMessage(selectedCandidates_ID, idx) {

        unreadMessages.next([]);
        navigate("/sms-dashboard", { replace: true, state: { selectedCandidates_ID } });

        let alertList = [...alerts.list];
        alertList.splice(idx, 1);

        setAlerts({
            list: alertList,
            showNotification: false
        });
    }

    function dismissEvent(event) {
        let postdata = {
            eventIDList: [event.events_ID],
            users_ID: uInfo.users_ID
        }

        dismissEvents(postdata).then(data => {
            if (data) {
                userEvents.next(data);
                let evList = [...events.list];
                let evIdx = evList.findIndex(ev => ev.events_ID === event.events_ID);
                evList.splice(evIdx, 1);

                setEvents({
                    list: evList,
                    showNotification: false
                });

                props.updateNotificationEvents(evList);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function dismissAllEvents() {
        let postdata = {
            eventIDList: events.list.map(e => (e.events_ID)),
            users_ID: uInfo.users_ID
        }

        dismissEvents(postdata).then(data => {
            if (data) {
                userEvents.next(data);

                setEvents({
                    list: [],
                    showNotification: false
                });

                props.updateNotificationEvents([]);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function dismissAllAlerts() {
        let postdata = {
            id: uInfo.users_ID
        };

        updateReadMessagesByUser(postdata);

        setAlerts({
            list: [],
            showNotification: false
        });
    }

    const alertList = (
        <List
            style={isDesktop ? { width: '400px' } : { width: '85vw' }}
            footer={alerts.list.length > 0 && <div><a className="float-end" key="list-loadmore-more" onClick={dismissAllAlerts}>Dismiss All</a></div>}
            itemLayout="horizontal"
            dataSource={alerts.list}
            size="small"
            renderItem={(item, idx) => (
                <List.Item onClick={() => directToMessage(item.candidates_ID, idx)} className="hover">
                    <List.Item.Meta
                        avatar={<MobileOutlined />}
                        title={(item.entityObject?.candidates_ID && !item.entityObject?.anonymous) ? <span><strong>{item.entityObject?.firstName} {item.entityObject?.lastName}</strong> sent you a message.</span> : <span>You have a new message</span>}
                        description={<Text ellipsis={true}>{item.content}</Text>}
                    />
                </List.Item>
            )}
        />
    );

    const eventList = (
        <List
            style={isDesktop ? { width: '400px' } : { width: '85vw' }}
            footer={events.list.length > 0 && <div><a className="float-end" key="list-loadmore-more" onClick={dismissAllEvents}>Dismiss All</a></div>}
            itemLayout="horizontal"
            dataSource={events.list}
            size="small"
            renderItem={(item, idx) => (
                <List.Item className="hover"
                    actions={[<a key="list-loadmore-more" onClick={() => dismissEvent(item)}>Dismiss</a>]}
                >
                    <List.Item.Meta
                        avatar={<BellOutlined />}
                        title={item.whatTriggered === 'Reminder' ? "Event Reminder" : <>{item.content} is due</>}
                        description={item.whatTriggered === 'Reminder' ? <Text>{item.content} due on {Moment(item.date).format("L LT")}</Text> : <Text>{item.details}</Text>}
                    />
                </List.Item>
            )}
        />
    );

    const tabItems = [
        { label: <>Alerts ({alerts.list.length})</>, key: 'alerts', children: alertList },
        { label: <>Events ({events.list.length})</>, key: 'events', children: eventList }
    ];

    const content = (
        <Tabs items={tabItems} activeKey={activeNotificationKey} onChange={(key) => setActiveNotificationKey(key)} />
    );


    return (
        <Popover placement={props.placement}
            content={content}
            overlayInnerStyle={{ maxHeight: '50vh', overflowY: 'auto' }}
            trigger="click"
            onClick={openNotifications}>
            <div className="hover ">
                <Badge count={alerts.list.length + events.list.length} className={(alerts.showNotification || events.showNotification) ? "bounce-6 bounce-icon fixed-widgets" : "fixed-widgets"}>
                    <Avatar className="ant-dropdown-trigger fixed-widgets-avatar" style={{
                        width: '44px', height: '44px', lineHeight: '44px', fontSize: '22px'
                    }} icon={<BellOutlined style={{verticalAlign: '5px !important'}} />} />
                </Badge>
            </div>
        </Popover>    
    );
}