import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { Table, Empty, Input, Space, Modal } from 'antd';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { activeOrgTagsState, orgTags } from '../../../services/OrganizationsService';
import { filterTable } from '../../../helpers/FilterTable';
import { deleteTag, insertTag, updateTag } from '../../../services/TagsService';
import SkeletonTable from '../../../helpers/SkeletonTable';
import { userInfo } from '../../../services/UsersService';
import { useRecoilState } from 'recoil';

export function ManageTags(props) {
    const { register, getValues, setValue, control, watch } = useForm();
    const [uInfo, setUInfo] = useState({});

    const [tags, setTags] = useState({
        allTags: [],
        loaded: false,
        filteredTags: []
    });

    const [selectedTag, setSelectedTag] = useState(null);
    
    const [activeOrgTags, setActiveOrgTags] = useRecoilState(activeOrgTagsState);

    var isDirty = useRef(false);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data]) => {
            if (data?.users_ID) {
                setUInfo(data);
            }
        });

        return () => { alive = false; }
    }, []);

    useEffect(() => {
        let searchVal = getValues().tableSearchValue;

        if (searchVal) {
            handleSearch(searchVal);
        } else {
            setTags({
                ...tags,
                filteredTags: tags.allTags,
                loaded: true
            });
        }
    }, [tags.allTags]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            orgTags
        ).pipe(takeWhile(() => alive)).subscribe(([data]) => {
            if (data?.length > 0) {
                setTags({
                    ...tags,
                    allTags: [...data]
                });
            }
        });

        return () => { alive = false; }
    }, []);


    function handleSubmit() {
        let tag = getValues().formFields;
        if (tag.tags_ID) {
            updateTag(tag).then(data => {
                if (data > 0) {
                    let allTags = [...tags.allTags];
                    let tagIdx = allTags.findIndex(t => t.tags_ID === tag.tags_ID);
                    if (tagIdx >= 0) {
                        allTags[tagIdx] = tag;

                        orgTags.next(allTags);
                        setActiveOrgTags(allTags);
                    }

                    setSelectedTag(null)

                    toast.success("Tag has been updated");
                } else {
                    toast.error("Something went wrong");
                }
            });
        } else {
            insertTag(tag).then(data => {
                if (data === 'duplicate') {
                    toast.error("This tag already exists");
                } else {
                    if (data > 0) {
                        let allTags = [...tags.allTags];
                        tag.tags_ID = data;
                        setSelectedTag(tag);
                        setValue('formFields', tag);
                        allTags.push(tag);
                        orgTags.next(allTags);
                        setActiveOrgTags(allTags);
                        setSelectedTag(null)
                        toast.success("Tag has been added");
                    } else {
                        toast.error("Something went wrong");
                    }
                    
                }
            })
        }
    }

    function handleSearch(value) {
        const filteredTags = filterTable(tags.allTags, value);

        setTags({
            ...tags,
            filteredTags,
            loaded: true
        });
    }

    function addTag() {
        let tag = {
            tag: '',
            organizations_ID: uInfo.organizations_ID
        }

        setValue('formFields', tag);

        setSelectedTag(tag);
    }

    function removeTag(index) {
        let tagToRemove = tags.filteredTags[index];

        confirm({
            title: "You are about to delete this tag.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    deleteTag(tagToRemove).then(data => {
                        if (data > 0) {
                            toast.success("Tag has been deleted");
                            let allTags = [...tags.allTags];
                            let allTagsIdx = allTags.findIndex(t => t.tags_ID === tagToRemove.tags_ID);
                            allTags.splice(allTagsIdx, 1);
                            orgTags.next(allTags);
                            setActiveOrgTags(allTags);
                            setSelectedTag(null);
                            setValue('formFields', null);

                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );

        
    }

    const columns = [
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="edit" className="a hover">Edit</span>
                    <span id="delete" className="a hover">Delete</span>
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    return (
        <>
            <div className="d-flex justify-content-end mb-3">
                <button className="ant-btn ant-btn-primary float-end" onClick={addTag}>
                    <i className="far fa-plus-square"></i>
                    &nbsp;
                    Add Tag
                </button>
            </div>

            <div className="row">
                <div className="col-lg-6 col-12">
                    {tags.loaded ?
                        <>
                            {tags.allTags.length > 0 ?
                                <>
                                    <Controller
                                        control={control}
                                        name="tableSearchValue"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Input.Search
                                                placeholder="Search for tags..."
                                                onSearch={handleSearch}
                                                value={value}
                                                onChange={(e) => { handleSearch(e.target.value); setValue('tableSearchValue', e.target.value); }}
                                            />
                                        }
                                    />

                                    <hr />
                                    {tags.filteredTags.length > 0 ?
                                        <>
                                            <Table
                                                width={'50vw'}
                                                rowKey='tags_ID'
                                                dataSource={tags.filteredTags}
                                                columns={columns}
                                                size='small'
                                                pagination={false}
                                                rowClassName={(record, index) => record.tags_ID === selectedTag?.tags_ID ? 'row-selected' : ''}
                                                scroll={{
                                                    y: '50vh',
                                                }}
                                                onRow={(record, rowIndex) => {
                                                    return {
                                                        onClick: event => {
                                                            if (event.target.id === 'delete') {
                                                                removeTag(rowIndex);
                                                            } else {
                                                                setSelectedTag(record); setValue('formFields', record);
                                                            }
                                                            
                                                        }
                                                    };
                                                }} />
                                        </>
                                        :
                                        <Empty description={<p>Your search found no results - to add a tag, click the "<i className="far fa-plus-square" /> Add Tag" button above.</p>} />
                                    }
                                </>
                                :
                                <Empty description={<p>Your organization currently has no tags - to begin adding a tag, click the "<i className="far fa-plus-square" /> Add Tag" button above.</p>} />
                            }
                        </>
                        :
                        <SkeletonTable rowCount={5} columns={columns} />
                    }
                </div>


                <Modal
                    open={selectedTag}
                    title={selectedTag?.tags_ID ? "Edit Tag" : "Add Tag"}
                    footer={[
                        <div className="text-start" key="1">
                            <button className="ant-btn ant-btn-primary text-start" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-outline m-1" onClick={() => setSelectedTag(null)}>Cancel</button>
                        </div>
                    ]}
                >
                    <div className="form-group col-12">
                        <label>Tag</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.tag')} />
                    </div>
                </Modal>
            </div>

            
        </>
    );
}
