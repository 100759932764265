import React, { useEffect, useState } from 'react';
import JobPlacementWorkflow from '../JobPlacementWorkflow';

export function WorkflowCard(props) {

    const [jobID, setJobID] = useState(null);

    useEffect(() => {
        if(props.showSingleJobID) {
            setJobID(props.showSingleJobID);
        }
    }, [props.showSingleJobID])

    return (
        <>
            {jobID && <JobPlacementWorkflow showSingleJobID={jobID} isArchived={props.selectedJob.job.archive === 1} />}
        </>
    );
}