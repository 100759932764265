import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Table, Input, Empty, Divider } from 'antd';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { useForm, Controller } from 'react-hook-form';

import SkeletonTable from '../../helpers/SkeletonTable';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { clientJobsTableParams } from '../../services/ClientsService';
import Globals from '../../config/globals';
import useSWR from 'swr';
import { fetcher } from '../../swr/fetcher';

export default function AssociatedJobs({ clients_ID }) {

    const { register, getValues, setValue, control, watch, formState } = useForm({
        defaultValues: {
            filterFields: {
                clientStatus: 'all'
            },
          }
    });

    const navigate = useNavigate();

    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [placementStatus, setPlacementStatus] = useState('Active');
    const [searchValue, setSearchValue] = useState('');

    const [tableParams, setTableParams] = useRecoilState(clientJobsTableParams);

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });

    const [loading, setLoading] = useState(true);

    const [filterString, setFilterString] = useState('');

    const key = `/api/GetJobsByClient?clients_ID=${clients_ID}&users_ID=${uInfo.users_ID}&placementStatus=${placementStatus}&pageSize=${tableParams.pagination.pageSize}&offset=${tableParams.pagination.current - 1}&searchValue=${searchValue}&sortBy=${sortStuff.sortBy}&sortDirection=${sortStuff.sortDirection}${filterString ? filterString : ''}`;
    const { data, error, isLoading, isValidating } = useSWR(key, fetcher);

    useEffect(() => {
        if(loading && !isLoading) {
            setLoading(false);
        }
    }, [loading, isLoading]);

    useEffect(() => {
        if (data) {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.length > 0 ? data[0].totalCount : 0
                }
            });
        }
    }, [data]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            let ff = '';

            let { filterFields } = value;

            for (const property in filterFields) {
                if (filterFields[property]) {
                    if ((Array.isArray(filterFields[property]) && filterFields[property].length > 0)) {
                        ff += `&filters=${property}:in:${filterFields[property]}`;
                    } else if (!Array.isArray(filterFields[property])) {
                        if (name === 'filterFields.clientStatus') {
                            ff += `&filters=${property}:eq:${filterFields[property]}`;
                        } else {
                            ff += `&filters=${property}:cn:${filterFields[property]}`;
                        }
                    }
                }
            }

            setFilterString(ff);
        });

        return () => subscription.unsubscribe();
    }, [watch, filterString]);

    useEffect(() => {
        console.log(filterString);
    }, [filterString]);

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if (sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    function navigateToJobDashboard(row) {
        navigate(`/job-dashboard/${row.jobs_ID}`,
            {
                replace: true
            });
    }

    var jobColumns = [
        {
            title: 'Confidential',
            dataIndex: 'confidential',
            key: 'confidential',
            render: (cell) => cell ? <i class="fa-solid fa-key"></i> : <span>No</span>,
            responsive: ['xl']
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'clientName' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'clientName' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({ sortBy, sortDirection: direction })
                    }
                };
            },
            responsive: ['xl']
        },
        {
            title: 'Job Name',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'jobTitle' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'jobTitle' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({ sortBy, sortDirection: direction })
                    }
                };
            },
        },
        {
            title: 'Specialty',
            dataIndex: 'parentSpecialty',
            key: 'parentSpecialty',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'parentSpecialty' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'parentSpecialty' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({ sortBy, sortDirection: direction })
                    }
                };
            },
            responsive: ['xl']
        },
        {
            title: 'Sub-Specialty',
            dataIndex: 'subSpecialty',
            key: 'subSpecialty',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'subSpecialty' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'subSpecialty' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({ sortBy, sortDirection: direction })
                    }
                };
            },
            responsive: ['xl']
        },
        {
            dataIndex: 'city',
            title: 'City',
            key: 'city',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'city' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'city' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({ sortBy, sortDirection: direction })
                    }
                };
            },
            responsive: ['xl']
        },
        {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'state' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'state' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => {
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({ sortBy, sortDirection: direction })
                    }
                };
            },
            responsive: ['xl']
        },
        {
            title: 'Recruiter',
            dataIndex: 'recruiter',
            key: 'recruiter',
            responsive: ['xl']
        }
    ];

    function handleSearch(value) {
        setSearchValue(value);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    const columns = jobColumns.filter(c => !c.hide);

    const dropdownRender = (record) => {
        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>Confidential:</label>
                        &nbsp;
                        {record.confidential}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Client:</label>
                        &nbsp;
                        {record.clientName}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Specialty:</label>
                        &nbsp;
                        {record.parentSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Sub-Specialty:</label>
                        &nbsp;
                        {record.subSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>City:</label>
                        &nbsp;
                        {record.city}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>State:</label>
                        &nbsp;
                        {record.state}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Recruiter:</label>
                        &nbsp;
                        {record.recruiter}
                    </div>
                </div>
            </div>
        );
    }

    const filterFieldsDisplay = () => {
        return (
            <>
                <Divider orientation="left">Filter By</Divider>

                <div className="row">
                    <div className="form-group col-lg-6 col-12">
                        <label className="d-block">Client Status</label>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('filterFields.clientStatus')} value='all' />
                            <label> All Clients</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('filterFields.clientStatus')} value='active' />
                            <label> Active Clients</label>
                        </div>
                        <div className="inline-custom-radio-container">
                            <input type="radio" className="custom-radio" {...register('filterFields.clientStatus')} value='archived' />
                            <label> Archived Clients</label>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className="d-flex">
                {tableParams.pagination.total >= 0 &&
                    <div className="form-group mx-3 ps-4 d-flex align-items-center">
                        Total:&nbsp;<strong>{tableParams.pagination.total} Job(s)</strong>
                    </div>
                }
            </div>
            
            {filterFieldsDisplay()}

            <Divider></Divider>
            <Input.Search
                placeholder="Search for keywords..."
                onSearch={handleSearch}
                onChange={(e) => { handleSearch(e.target.value) }}
            />
            <Divider></Divider>
            {(loading) ?
                <SkeletonTable columns={columns} rowCount={5} />
                :
                <>

                    {data?.length > 0 ?
                        <>
                            <Table
                                rowKey={(record) => record.jobs_ID}
                                dataSource={data}
                                columns={columns}
                                size='small'
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToJobDashboard(record) : console.log("Selected") }, // click row
                                        onDoubleClick: event => { }, // double click row
                                        onContextMenu: event => { }, // right button click row
                                        onMouseEnter: event => { }, // mouse enter row
                                        onMouseLeave: event => { }, // mouse leave row
                                    };
                                }}
                                expandable={!isDesktop && {
                                    expandedRowRender: (record) => dropdownRender(record),
                                    rowExpandable: (record) => record.jobs_ID !== 'Not Expandable',
                                }}
                                onChange={handleTableChange}
                                pagination={tableParams.pagination}
                            />
                        </>
                        :
                        <Empty description={<p>No jobs associated to this client are found</p>} />
                    }
                </>
            }
        </>
    );
}