import { BehaviorSubject } from 'rxjs';
import Axios from '../config/axios';
import Globals from '../config/globals';
import { atom } from 'recoil';

export const canSelectedCandidateBeUpdated = atom({
    key: 'canSelectedCandidateBeUpdated',
    default: false
});

export const canSelectedCandidateBeDeleted = atom({
    key: 'canSelectedCandidateBeDeleted',
    default: false
});

export const activeCandidateTab = atom({
    key: 'activeCandidateTab',
    default: 'myCandidates'
});

export const activeExpandCandidateWorkflow = atom({
    key: 'activeExpandCandidateWorkflow',
    default: false
});

export const candidateTableParams = atom({
    key: 'candidateTableParams',
    default: {
        pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        },
    }
});

export const candidatePlacementTableParams = atom({
    key: 'candidatePlacementTableParams',
    default: {
        pagination: {
            current: 1,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            pageSize: 10
        }
    }
})

export const candidateFilterFields = atom({
    key: 'candidateFilterFields',
    default: {
        inHouseID: '',
        preferredStates: [],
        parentSpecialty: null,
        subSpecialties: [],
        jobTypes: [],
        visaTypes: [],
        licensure: []
    }
});

export const userCandidates = new BehaviorSubject({});

export const activeUserCandidatesState = atom({
    key: 'activeUserCandidatesState',
    default: {}
});

export function getCandidate(candidates_ID) {
    return Axios.get('/api/GetCandidate', {
        params: {
            candidates_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getCandidateDashboardInformation(candidates_ID) {
    return Axios.get('/api/GetCandidateDashboardInformation', {
        params: {
            candidates_ID,
            users_ID: Globals.userInfo.users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function isPhoneNumberUnique(number, candidates_ID) {
    return Axios.get('/api/IsPhoneNumberUnique', {
        params: {
            number,
            candidates_ID,
            users_ID: Globals.userInfo.users_ID
        }
    }).then(response => response.data);
}

export function editCandidate(postdata) {

    postdata.id = Globals.userInfo.organizations_ID;
    postdata.id2 = Globals.userInfo.users_ID;
    postdata.s1 = Globals.userInfo.userType;

    return Axios.post(`/api/EditCandidate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function addCandidate(postdata) {
    return Axios.post(`/api/AddCandidate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function addAnonymousCandidate(postdata) {
    return Axios.post(`/api/AddAnonymousCandidate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function archiveCandidate(postdata) {
    return Axios.post(`/api/ArchiveCandidate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function bulkArchiveCandidates(idList) {
    return Axios.post(`/api/BulkArchiveCandidates`, idList
    ).then(response => response.data).catch(error => error);
}

export function restoreCandidate(postdata) {
    return Axios.post(`/api/RestoreCandidate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function bulkRestoreCandidates(idList) {
    return Axios.post(`/api/BulkRestoreCandidates`, idList
    ).then(response => response.data).catch(error => error);
}

export function deleteCandidate(postdata) {
    return Axios.post(`/api/DeleteAllCandidateInformation`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getCandidateSearchResults(searchValue) {
    return Axios.get(`/api/GetCandidateSearchResults`, {
        params: {
            users_ID: Globals.userInfo.users_ID,
            searchValue: searchValue
        }
    }).then(response => response.data).catch(error => error);
}

export function getCandidatesByRadius(postdata) {
    return Axios.post(`/api/GetCandidatesByRadius`, postdata).then(response => response.data).catch(error => error);
}

export function flagCandidatesForNCHCR(idList, placementStatus) {
    let postdata = {
        org_ID: Globals.userInfo.organizations_ID,
        users_ID: Globals.userInfo.users_ID,
        userType: Globals.userInfo.userType,
        idList,
        placementStatus
    }

    return Axios.post(`/api/FlagCandidatesForNCHCR`, postdata).then(response => response.data).catch(error => error);
}

export function unFlagCandidatesForNCHCR(idList, placementStatus) {
    let postdata = {
        org_ID: Globals.userInfo.organizations_ID,
        users_ID: Globals.userInfo.users_ID,
        userType: Globals.userInfo.userType,
        idList,
        placementStatus
    }

    return Axios.post(`/api/UnFlagCandidatesForNCHCR`, postdata).then(response => response.data).catch(error => error);
}

export function getCandidatesByGroup(groups_ID) {
    return Axios.get(`/api/GetCandidatesByGroup`, {
        params: {
            groups_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getCandidateKeywordSearch(searchValue) {
    return Axios.get(`/api/GetCandidateKeywordSearch`, {
        params: {
            users_ID: Globals.userInfo.users_ID,
            searchValue
        }
    }
    ).then(response => response.data).catch(error => error);
}

export function getCompletedEventsByCandidate(candidates_ID) {
    return Axios.get(`/api/GetCompletedEventsByCandidate`, {
        params: {
            candidates_ID
        }
    }).then(response => response.data).catch(error => error);
}


export function getMatchedCandidates(jobs_ID, users_ID) {
    return Axios.get(`/api/GetMatchedCandidates`, {
        params: {
            jobs_ID,
            users_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getCandidatesWithPlacementsByUser(org_ID, users_ID, placementStatus) {
    return Axios.get('/api/GetCandidatesWithPlacementsByUser', {
        params: {
            org_ID,
            users_ID,
            placementStatus
        }
    }).then(response => response.data).catch(error => error);
}

export function getEmailCandidates(users_ID, org_ID, searchValue) {
    return Axios.get('/api/GetEmailCandidates', {
        params: {
            users_ID,
            org_ID,
            searchValue
        }
    }).then(response => response.data).catch(error => error);
}

export function unsubscribeCandidateFromEmailCommunications(postdata) {
    return Axios.post(`/api/auth/UnsubscribeCandidateFromEmailCommunications`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getCandidateComments(candidates_ID) {
    return Axios.get('/api/GetCandidateComments', {
        params: {
            candidates_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function checkForPossibleDuplicateCandidate(firstName, lastName, emails, users_ID, org_ID) {
    return Axios.get('/api/CheckForPossibleDuplicateCandidate', {
        params: {
            firstName,
            lastName,
            emails: emails?.join(',') || '',
            users_ID,
            org_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function insertCandidateComment(postdata) {
    return Axios.post(`/api/InsertCandidateComment`, postdata
    ).then(response => response.data).catch(error => error);
}