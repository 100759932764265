import axios from 'axios';
import Axios from '../config/axios';
import Globals from '../config/globals';

export function sendEmail(formData) {

    return axios.post(`${window.api}api/SendTestEmail`, formData, {
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then(response => response.data);
}

export function sendBrevoEmail(formData) {

    return axios.post(`${window.api}api/SendBrevoEmail`, formData, {
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }).then(response => response.data);
}

export function getEmailHistory() {
    return Axios.get(`/api/GetEmailHistory`).then(response => response.data).catch(error => error);


} export function getEmailOnLoadInfo(users_ID) {
    return Axios.get(`/api/GetEmailOnLoadInfo`, { params: {users_ID}}).then(response => response.data).catch(error => error);
}

export function insertEmailTemplate(postdata) {
    return Axios.post(`/api/InsertEmailTemplate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateEmailTemplate(postdata) {
    return Axios.post(`/api/UpdateEmailTemplate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteEmailTmplate(postdata) {
    return Axios.post(`/api/DeleteEmailTemplate`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getEmailTemplate(emailTemplates_ID) {
    return Axios.get(`/api/GetEmailTemplate`, { params: { emailTemplates_ID } }).then(response => response.data).catch(error => error);
}

export function getEmailHistoryItem(emailHistory_ID) {
    return Axios.get(`/api/GetEmailHistoryItem`, { params: { emailHistory_ID } }).then(response => response.data).catch(error => error);
}

export function getTransactionalEmailHistoryByEmail(messageId) {
    return Axios.get(`/api/GetTransactionalEmailHistoryByEmail`, { params: { messageId } }).then(response => response.data).catch(error => error);
}

export function getEmailReport() {
    return Axios.get(`/api/GetEmailReport`).then(response => response.data).catch(error => error);
}