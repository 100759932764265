import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Input } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useForm, Controller } from 'react-hook-form';
import Globals from '../../../config/globals';
import { activeUserCandidatesState, canSelectedCandidateBeUpdated, editCandidate, userCandidates } from '../../../services/CandidateService';
import { useMediaQuery } from 'react-responsive';
import { getChangesToTrack } from '../../../helpers/DetectChanges';

export function SocialMediaCard(props) {

    const { register, getValues, setValue, control, watch, formState } = useForm();
    const location = useLocation();
    const navigate = useNavigate();

    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        formFields: props.selectedCandidate.candidates,
        persistentInformation: props.selectedCandidate.candidates
    });

    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const facebookPrefix = "https://facebook.com/";
    const linkedInPrefix = "https://www.linkedin.com/in/";
    const twitterPrefix = "https://www.twitter.com/";
    const urlPrefix = "https://";

    const [isActive, setIsActive] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (edit) {
            let formFields = { ...state.persistentInformation };
            formFields.facebook = formFields.facebook?.split(facebookPrefix)[1] || formFields.facebook;
            formFields.linkedIn = formFields.linkedIn?.split(linkedInPrefix)[1] || formFields.linkedIn;
            formFields.twitter = formFields.twitter?.split(twitterPrefix)[1] || formFields.twitter;
            formFields.url = formFields.url?.split(urlPrefix)[1] || formFields.url;

            setValue('formFields', formFields);
        }
    }, [edit]);

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setEdit(false);
                        isDirty.current = false;

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function handleSubmit() {
        let formFields = { ...getValues().formFields };
        formFields.facebook = facebookPrefix + formFields.facebook;
        formFields.linkedIn = linkedInPrefix + formFields.linkedIn;
        formFields.twitter = twitterPrefix + formFields.twitter;
        formFields.url = urlPrefix + formFields.url;

        let postdata = {
            candidatesModel: formFields,
            auditChangesList: [getChangesToTrack(state.persistentInformation, formFields, "Candidates", "Candidate Details")]
        };

        toast.loading("Updating candidate...", { key: 'loading' });
        editCandidate(postdata).then(data => {
            toast.destroy('loading');
            toast.success("Candidate has been updated");

            setState({
                ...state,
                persistentInformation: formFields
            });

            setEdit(false);

            isDirty.current = false;
            Globals.isDirtyPage = false;

            var currentCandidate = data.userCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentInformation?.candidates_ID);
            if (!currentCandidate) {
                currentCandidate = data.companyCandidates?.find(uc => uc.candidates?.candidates_ID === state.persistentInformation?.candidates_ID);
            }

            if (currentCandidate) {
                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: currentCandidate, from: location.state.from }
                });
            }

            userCandidates.next(data);
            setActiveUserCandidates(data);
        }).catch(error => {
            toast.error("Something went wrong");

            isDirty = false;
            Globals.isDirtyPage = false;
        });
    }

    return (
        <>
            {isActive &&
                <>
                    {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    <Descriptions
                        title="Social Media Information"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size='small'
                        labelStyle={{ width: 300 }}
                        layout={isDesktop ? 'horizontal' : 'vertical'}
                    >
                        <Descriptions.Item label={<div><i className="fa-brands fa-square-facebook"></i> Facebook</div>}>{state.persistentInformation?.facebook || ''}</Descriptions.Item>
                        <Descriptions.Item label={<div><i className="fa-brands fa-linkedin"></i> LinkedIn</div>}>{state.persistentInformation?.linkedIn || ''}</Descriptions.Item>
                        <Descriptions.Item label={<div><i className="fa-brands fa-square-twitter"></i> Twitter</div>}>{state.persistentInformation?.twitter || ''}</Descriptions.Item>
                        <Descriptions.Item label={<div><i className="fa-solid fa-globe"></i> URL</div>}>{state.persistentInformation?.url || ''}</Descriptions.Item>
                    </Descriptions>

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Social Media'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='small'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Facebook</label>
                                <Controller
                                    control={control}
                                    name="formFields.facebook"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="29" addonBefore={facebookPrefix} onChange={onChange} value={value} />
                                    }
                                />

                            </div>
                            <div className="form-group col-12">
                                <label>LinkedIn</label>
                                <Controller
                                    name="formFields.linkedIn"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="22" addonBefore={linkedInPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>Twitter</label>
                                <Controller
                                    name="formFields.twitter"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="26" addonBefore={twitterPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>URL</label>
                                <Controller
                                    name="formFields.url"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="40" addonBefore={urlPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}