import React, { useState, useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Alert, Divider, message, Upload } from 'antd';
import { ResumeCard } from './CandidateCards/ResumeCard';
import { convertToPDF } from '../../services/CandidateAttachmentsService';
import { useRecoilState } from 'recoil';
import { activeOrgInfoState } from '../../services/OrganizationsService';

const types = ["application/msword", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

const { Dragger } = Upload;

function ImportCandidateResume(props) {

    const [orgInfo, setOrgInfo] = useRecoilState(activeOrgInfoState);

    const [modalInfo, setModalInfo] = useState({
        show: false,
        file: {}
    });

    const [isPdf, setIsPdf] = useState(true);
    const [fileText, setFileText] = useState(null);

    const [resumeImported, setResumeImported] = useState(false);

    useEffect(() => {
        if (props.resume) {
            var fileUrl = props.resume;

            if(props.resume?.type === 'application/pdf') { 
                fileUrl = URL.createObjectURL(new Blob([props.resume], {type: "application/pdf"}));
            }
            setModalInfo({
                show: true,
                file: fileUrl
            });

            setResumeImported(true);
        } else {
            setModalInfo({
                show: false,
                file: {}
            });

            setResumeImported(false);
        }
    }, [props.resume]);

    async function openAddModal(file) {
        const reader = new FileReader();
        const reader2 = new FileReader();
        reader.onload = async (e) => {
            const text = (e.target.result);
            if (file.type === 'application/msword') setFileText(text);

            if(file.type !== 'application/pdf') {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('rtfContent', text);
                convertToPDF(formData).then((data) => {
                    file = new File([data], `${file.name.split('.')[0]}.pdf`, {type: 'application/pdf'});

                    reader2.readAsDataURL(new Blob([data])); 
                    reader2.onloadend = function() {
                        var base64data = reader2.result;
                        base64data = base64data.split(',')[1];

                        setModalInfo({
                            show: true,
                            //file1: window.URL.createObjectURL(new Blob([data])),
                            file: `data:application/pdf;base64,${base64data}`
                        });
                    }
                    props.setResume(file, text);
                });
            } else {

                // reader2.readAsDataURL(new Blob([file])); 
                // reader2.onloadend = function() {
                //     var base64data = reader2.result;
                //     base64data = base64data.split(',')[1];
                //     console.log(base64data);
                //     setModalInfo({
                //         show: true,
                //         //file1: window.URL.createObjectURL(new Blob([data])),
                //         file: `data:application/pdf;base64,${base64data}`
                //     });
                // }


                const blobContent = new Blob([file], {type: "application/pdf"});

                setModalInfo({
                    show: true,
                    file: URL.createObjectURL(blobContent)
                });
                
                props.setResume(file, text);
            }
    
        };
        reader.readAsText(file);        
    }

    return (
        <>
            {!resumeImported && 
            <>
            {orgInfo?.limitResumeParses &&
                <>
                    {orgInfo?.availableResumeParses <= 0 ?
                        <Alert style={{maxWidth: '80%', margin: '10px auto'}} message="Parsing Unavailable" description={
                            <p>Your organization has run out of parses. Please contact support to get more credits. You can still import a resume to save to the candidate.
                                <Divider />
                                <u><a href="mailto:support@recruiterbalm.com">Email RecruiterBalm Support</a></u>
                                <br />
                                <u><a href="tel:3049910238">Call John Frey - (860) 991-0238</a></u>
                            </p>
                        } type="warning" showIcon />
                        :
                        <Alert style={{maxWidth: '80%', margin: '10px auto'}} message="Parse a Resume" description={<p>
                            This will extract data and auto populate the applicable fields. Please check for accuracy. 
                            <br /> 
                            ** Importing a PDF will be the most accurate. **
                            <br />
                            <strong>Your org has {orgInfo?.availableResumeParses} parse credits remaining.</strong></p>} type="info" showIcon />
                    }
                </>
            }
            
            <Dragger
                style={{maxWidth: '80%', margin: '0 auto'}}
                accept=".pdf, .docx, .rtf"
                fileList={null}
                beforeUpload={(file) => {
                    if (!types.includes(file.type)) {
                        message.error(`${file.name} is not of proper type`);
                        return false;
                    } else {
                        if (file.type !== 'application/pdf') {
                            setIsPdf(false);
                        } else {
                            setIsPdf(true);
                        }
                        return true
                    }
                }}
                customRequest={(info) => {
                    openAddModal(info.file);
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    {orgInfo?.limitResumeParses && orgInfo?.availableResumeParses === 0 ? 'Click here to import a resume' : 'Click here to import a resume to parse' }
                    <p>
                        <strong>For best results, a PDF is recommended. Otherwise we will try to convert it.</strong>
                    </p>
                </p>
            </Dragger>
    </>
}
            {modalInfo?.show &&
                <ResumeCard from="parse" setResumeImported={setResumeImported} resume={modalInfo.show ? modalInfo.file : null} />
            }
        </>
    );
}

export default React.memo(ImportCandidateResume);