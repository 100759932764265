import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { updateUserPassword, userInfo } from '../../../services/UsersService';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip, Typography } from 'antd';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';


export function ManageSecuritySettings(props) {
    const { register, getValues, setValue, control, watch, formState } = useForm();
    const [uInfo, setUInfo] = useState({});

    const [validated, setValidated] = useState(true);
    const [mismatch, setMismatch] = useState(false);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data]) => {
            if (data?.users_ID) {
                setUInfo(data);
            }
        });

        return () => { alive = false; }
    }, []);

    function handleSubmit() {

        let formFields = { ...getValues().formFields };

        if (formFields.newPassword !== formFields.confirmNewPassword) {
            setMismatch(true);
        } else {
            setMismatch(false);

            formFields.users_ID = uInfo.users_ID;
            updateUserPassword(formFields).then(data => {
                if (data === 'success') {
                    toast.success('Password has been updated');
                    setValidated(true);

                    setValue('formFields', {
                        currentPassword: '',
                        newPassword: '',
                        confirmNewPassword: ''
                    });

                } else if (data === 'invalid password') {
                    setValidated(false);
                } else {
                    toast.error("Something went wrong");
                }
            });
        }
    }

    return (
        <>
            <div className="row">
                <div className="form-group col-lg-7 col-12">
                    <label>Current Password</label>
                    <Controller
                        name="formFields.currentPassword"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => <Form.Item
                            htmlFor='currentPass'
                            validateStatus={!validated ? 'error' : ''}
                            help={!validated ? 'Incorrect Password' : ''}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} value={value} onChange={onChange} />
                        </Form.Item>
                        }
                    />
                </div>
                <div className="form-group col-lg-7 col-12">
                    <label>New Password</label>
                    <Controller
                        name="formFields.newPassword"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => <Form.Item htmlFor='newPass'>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} value={value} onChange={onChange} />
                        </Form.Item>
                        }
                    />
                </div>
                <div className="form-group col-lg-7 col-12">
                    <label>Confirm New Password</label>
                    <Controller
                        name="formFields.confirmNewPassword"
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => <Form.Item
                            htmlFor='confirmNewPass'
                            validateStatus={mismatch ? 'error' : ''}
                            help={mismatch ? 'Passwords do not match' : ''}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} value={value} onChange={onChange} />
                        </Form.Item>
                        }
                    />
                </div>
            </div>

            <div className="d-flex justify-content-between" key="1">
                <div className="text-center">
                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </>
    );
}
