import React, { useEffect, useState } from "react";
import { Modal, Button } from 'antd';

import Globals from '../config/globals';
import { useNavigate, useLocation } from "react-router-dom";
import { logoutAudit } from "../services/AuditLoginService";
import { userInfo, userPermissions } from "../services/UsersService";

const { confirm, info } = Modal;

function DetectActivity({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    var countDownSeconds = 5 * 60 //countdown for 5 minutes;
    var timeInMS = 1000 * 60 * 30; //set timeout for 15 minutes

    //var countDownSeconds = 5 //countdown for 5 seconds;
    //var timeInMS = 1000 * 6; //set timeout for 6 seconds

    var modalOpen = false;
    var time;
    let mounted = true;

    var timer;

    useEffect(() => {
        mounted = true;
        window.onload = resetTimer;

        // DOM Events
        document.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onmousedown = resetTimer; // touchscreen presses
        document.ontouchstart = resetTimer;
        document.onclick = resetTimer;

        document.addEventListener('visibilitychange', function () {
            if (!document.hidden) {
                checkIfExpired();
            }
        });

        return () => {
            mounted = false;
            clearTimeout(time);
            Modal.destroyAll();
        }
    }, []);

    function checkIfExpired() {
        var currentTime = new Date();

        let timeToExpire = window.sessionStorage.getItem("timeToExpire");
        let timeToExpireDate = new Date(timeToExpire);

        if (timeToExpire && currentTime.getTime() > timeToExpireDate.getTime()) {
            handleLogout();
        }
    }

    function logout() {
        if (mounted && !modalOpen) {
            countDown();
        }
    }

    function resetTimer() {
        clearTimeout(time);

        let timeToExpire = new Date();
        timeToExpire.setSeconds(timeToExpire.getSeconds() + countDownSeconds + (timeInMS / 1000));

        if (!modalOpen) {
            window.sessionStorage.setItem("timeToExpire", timeToExpire);
        }

        if (mounted && !modalOpen) {
            time = setTimeout(logout, timeInMS)
        }
    }

    function countDown() {
        let secondsToGo = countDownSeconds;
        modalOpen = true;
        const modal = confirm({
            title: 'Are you still there?',
            content: `You will be logged out in ${secondsToTime(secondsToGo)}.`,
            cancelText: 'I am here',
            cancelType: 'success',
            okText: 'Log me out',
            okType: 'danger',
            className: 'timeout-modal',
            onOk() {
                handleLogout();
                Modal.destroyAll();
                clearInterval(timer);
                clearTimeout(time);
                modal.visible = false;
                modalOpen = false;
            },
            onCancel() {
                clearInterval(timer);
                modalOpen = false;
                resetTimer();
            },
        });

        timer = setInterval(() => {
            checkIfExpired();

            secondsToGo -= 1;
            if (location.pathname === '/') {
                //Modal.destroyAll();
                clearInterval(timer);
                //clearTimeout(timeout);
            } else if (secondsToGo === 0) {
                Modal.destroyAll();
                clearInterval(timer);
                //clearTimeout(timeout);

                handleLogout();
            } else {
                modal.update({
                    content: `You will be logged out in ${secondsToTime(secondsToGo)}.`,
                });
            }
        }, 1000);

        //const timeout = setTimeout(() => {
        //    //Modal.destroyAll();
        //    modal.visible = false;

        //    clearInterval(timer);

        //    if (modalOpen) {
        //        modalOpen = false;
        //        Modal.destroyAll();
        //        modal.visible = false;
        //        info({
        //            title: 'You have been logged out.',
        //            content: 'Please log back in to continue.',
        //            className: 'timeout-modal',
        //            onOk() { Modal.destroyAll() }
        //        });

        //        handleLogout(self);
        //    }
        //}, secondsToGo * 1000);
    }

    function secondsToTime(e) {
        var m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return m + ':' + s;
    }

    function handleLogout() {
        modalOpen = false;
        Modal.destroyAll();
        clearInterval(timer);

        logoutAudit();
        
        navigate("/", { replace: true });
    }

    return children;
}

export default DetectActivity;