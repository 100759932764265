import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Empty } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { modifyCertifications } from '../../../services/CertificationsService';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../../config/globals';
import { getChanges } from '../../../helpers/DetectChanges';
import { activeUserInfo } from '../../../services/UsersService';
import { canSelectedCandidateBeUpdated } from '../../../services/CandidateService';

export function CertificationsCard(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        formFields: props.selectedCandidate.candidates,
        persistentInformation: props.selectedCandidate.candidates
    });

    const [certifications, setCertifications] = useState([...props.dashboardInformation.certifications]);

    const [stickyCertifications, setStickyCertifications] = useState([...props.dashboardInformation.certifications]);

    const [noCertifications, setNoCertifications] = useState(false);

    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const [isActive, setIsActive] = useState(false);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        manipulateListData();
    }, []);

    function manipulateListData(data = null) {
        if (!data) {
            data = [...certifications];
        }

        if (data.length === 0) {
            data.push({
                name: "",
                description: ""
            });

            setNoCertifications(true);
        } else {
            setNoCertifications(false);
        }

        setCertifications([...data]);

        setStickyCertifications([...data]);
    }

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setEdit(false);
                        isDirty.current = false;

                        setCertifications([...stickyCertifications]);

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function addOrRemoveHistory(e, i) {
        e.preventDefault();

        let certs = [...certifications];

        isDirty.current = true;
        switch (e.target.id) {
            case 'add':

                certs.push({
                    name: "",
                    description: ""
                });
                setCertifications(certs);
                break;
            case 'remove':
                var history = { ...certs[i] };

                history.delete = true;
                history.isDirty = true;

                certs[i] = { ...history };

                setCertifications(certs);
                break;
            default:
                break;
        }
    }

    function handleChange(event, i) {
        //event.preventDefault();
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var history = { ...certifications[i] };
        history[name] = value;
        history["isDirty"] = true;
        isDirty.current = true;
        let certs = [...certifications];
        certs[i] = history;

        setCertifications(certs);
    }

    function handleSubmit() {
        let certs = [...certifications];
        let certificationsList = [];

        for (var i = 0; i < certs.length; i++) {
            if (certs[i].isDirty) {

                certs[i].candidates_ID = state.selectedCandidate.candidates.candidates_ID;

                certificationsList.push(certs[i]);

                certs[i].isDirty = false;
            }
        }

        let postdata = {
            id: state.selectedCandidate.candidates.candidates_ID,
            id2: uInfo.users_ID,
            certificationsList,
            auditChangesList: []
        };

        let informationChanges = {
            oldValues: {},
            changeValues: {}
        };

        postdata.certificationsList.forEach((item, idx) => {
            let oldObj = { ...stickyCertifications[idx] };
            let change = {};

            if (oldObj?.candidates_ID) {
                change = getChanges(oldObj, item, `_certifications_ID_${oldObj.certifications_ID}`);
            } else if (item?.candidates_ID) {
                change = {
                    oldValues: {},
                    changeValues: {
                        certificationItemAdded: item.name
                    }
                }
            }

            if (change?.changeValues?.delete) {
                change = {
                    oldValues: {},
                    changeValues: {
                        educationItemRemoved: item.name
                    }
                }
            }

            for (var values in change) {
                for (var changedItems in change[values]) {
                    informationChanges[values][changedItems] = change[values][changedItems];
                }
            }
        });

        if (informationChanges) {
            informationChanges.oldValues = JSON.stringify(informationChanges.oldValues);
            informationChanges.changeValues = JSON.stringify(informationChanges.changeValues);
            informationChanges.changeType = "Certifications";
            informationChanges.changeLocation = "License/Certifications";

            postdata.auditChangesList.push(informationChanges);
        }

        modifyCertifications(postdata).then(data => {
            if (data) {
                manipulateListData(data);

                toast.success("Certifications have been updated");

                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: location.state.selectedCandidate, from: location.state.from, activeTab: 'license' } //set activeTab to whatever tab is currently on
                });

                setEdit(false);
                isDirty.current = false;
            } else {
                toast.error("Something went wrong")
            }
        }).catch(error => toast.error("Something went wrong"));

    }

    return (
        <>
            {isActive &&
                <>
                    {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    {!noCertifications ?
                        <Descriptions
                            title='License/Certifications'
                            bordered
                            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                            size='small'
                        labelStyle={{ width: 300 }}
                        layout={isDesktop ? 'horizontal' : 'vertical'}
                        >
                            <Descriptions.Item label='Certifications'>
                                <div>
                                    {stickyCertifications.map((cert, i) =>
                                        <p key={i}>
                                            {cert.name}
                                        </p>
                                    )}
                                </div>
                            </Descriptions.Item>
                        </Descriptions>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Certifications Listed' />
                    }


                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Certifications'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="">
                            <span className="float-end"><i className="hover far fa-plus-square icon-md candidate-text" id="add" onClick={addOrRemoveHistory} /></span>
                            {certifications.map((cert, i) =>
                                <div className="row" key={i}>
                                    {!cert.delete &&
                                        <>
                                            <div className="col-12">
                                                <div className="float-end">
                                                    <i className="hover far fa-trash-alt icon-md" id="remove" onClick={(e) => addOrRemoveHistory(e, i)} />
                                                </div>
                                            </div>
                                            <div className="form-group col-12">
                                                <label>Name</label>
                                                <input maxLength="500" type="text" name="name" value={cert.name || ''} onChange={(e) => handleChange(e, i)} className="ant-input" />
                                            </div>
                                            <div className="form-group col-12">
                                                <label>Description</label>
                                                <textarea maxLength="2000" type="text" name="description" value={cert.description || ''} onChange={(e) => handleChange(e, i)} className="ant-input" />
                                            </div>

                                            {(certifications.filter(item => !item.delete).length > 1 && i !== certifications.filter(item => !item.delete).length - 1) && <div className="horizontal-candidates-divider" />}
                                        </>
                                    }

                                </div>
                            )}
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}