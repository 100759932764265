export function cityStateFormatter(entity) {
    let city = '';
    let state = ''

    if(entity?.city || entity?.state) {
        city = entity?.city;
        state = entity?.state;
    } else if (entity?.jobCity || entity?.jobState) {
        city = entity?.jobCity;
        state = entity?.jobState;
    } else if(entity?.candidateCity || entity?.candidateState) {
        city = entity?.candidateCity;
        state = entity?.candidateState
    }

    if(city && state) return <span>{city}, {state}</span>
    else if(city) return <span>{city}</span>
    else if(state) return <span>{state}</span>
    return <span>Not Provided</span>;
}