import React, { useState, useEffect, useRef } from 'react';

import { Descriptions, Drawer, Alert, Select, Switch } from 'antd';
import statesList from '../../../helpers/StatesList';
import countryStatesList from '../../../helpers/CountriesStates';
import Globals from '../../../config/globals';

import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat } from 'react-number-format';
import { updateOrganization } from '../../../services/OrganizationsService';

export function ContactInformationCard(props) {

    const { register, getValues, setValue, control, watch, formState } = useForm();

    const isDirty = useRef(false);
    const [state, setState] = useState({
        selectedOrganization: props.selectedOrganization,
        states: statesList.filter(obj => { return obj.country_code === 'US' }),
        persistentContactInformation: props.selectedOrganization
    });

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (edit) {
            let ff = { ...state.persistentContactInformation };
            setValue('formFields', ff);
        }
    }, [edit]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
                Globals.isDirtyPage = true;
            }

            if (name === 'formFields.country') {
                var states = statesList.filter(obj => {
                    return obj.country_code === value.formFields.country
                });

                setState({
                    ...state,
                    states
                });

                setValue('formFields.state', '');
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);



    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setValue('formFields', state.persistentContactInformation);

        var states = statesList.filter(obj => {
            return obj.country_code === state.persistentContactInformation.country
        });

        setState({
            ...state,
            states,
            formFields: { ...state.persistentContactInformation },
        });

        setEdit(false);
        isDirty.current = false;
    }

    function handleSubmit() {
        let postdata = { ...getValues().formFields };
        updateOrganization(postdata).then(data => {
            if (data) {
                toast.success("Organization information has been updated");
                setState({
                    ...state,
                    persistentContactInformation: postdata
                });
                isDirty.current = false;
                setEdit(false);

                props.updateOrganizationInformation(postdata);
            } else {
                toast.error("Something went wrong");
            }
        });

    }

    return (
        <>
            <i className="float-end fa-sharp fa-solid fa-pen-to-square organization-text icon-md hover" onClick={openEdit}></i>

            <Descriptions
                title="General Information"
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size='small'
                labelStyle={{ width: 300 }}
            >
                <Descriptions.Item label="Phone">
                    {state.persistentContactInformation?.phone &&
                        <a href={"tel:" + state.persistentContactInformation.phone}>{state.persistentContactInformation.phone}</a>
                    }
                </Descriptions.Item>
                {/*<Descriptions.Item label="SMS number">*/}
                {/*    {state.persistentContactInformation?.smsNumber &&*/}
                {/*        <a href={"tel:" + state.persistentContactInformation.smsNumber}>{state.persistentContactInformation.smsNumber}</a>*/}
                {/*    }*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label="Address">
                    <p className="no-bottom-margin line-height-1-5 xs-margin-top">{state.persistentContactInformation.address1}</p>
                    <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.address2}</p>
                    {state.persistentContactInformation.city && <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.city}, {state.persistentContactInformation.state} {state.persistentContactInformation.zip}</p>}
                    {(!state.persistentContactInformation.city && state.persistentContactInformation.state) && <p className="no-bottom-margin line-height-1-5">{state.persistentContactInformation.state} {state.persistentContactInformation.zip}</p>}
                </Descriptions.Item>
                <Descriptions.Item label={<div><i className="fa-solid fa-globe"></i> URL</div>}>{state.persistentContactInformation?.url ? <a target="_blank" rel="noopener noreferrer" href={`https://${state.persistentContactInformation.url}`}>{state.persistentContactInformation.url}</a> : 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Comments">{state.persistentContactInformation?.comments}</Descriptions.Item>
                <Descriptions.Item label="Limit Resume Parses">{state.persistentContactInformation.limitResumeParses ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Resume Parse Credits">{state.persistentContactInformation.availableResumeParses}</Descriptions.Item>
            </Descriptions>

            <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title='Edit Contact Information'
                placement={'right'}
                onClose={handleClose}
                open={edit}
                key='slider'
                size='large'
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                        </div>
                    </div>
                }
            >
                <div className="row">
                    <div className="form-group col-12">
                        <label>Organization Name</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.name')} />
                    </div>
                    <div className="form-group col-lg-6 col-12">
                        <label className="form-label">Contact Number</label>
                        <Controller
                            control={control}
                            name="formFields.phone"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />
                            }
                        />
                    </div>
                    {/*<div className="form-group col-lg-6 col-12">*/}
                    {/*    <label className="form-label">SMS Number</label>*/}
                    {/*    <Controller*/}
                    {/*        control={control}*/}
                    {/*        name="formFields.smsNumber"*/}
                    {/*        render={({ field: { onChange, onBlur, value, ref } }) =>*/}
                    {/*            <PatternFormat className="ant-input" onChange={onChange} value={value} format="###-###-####" />*/}
                    {/*        }*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="form-group col-12">
                        <label>Country</label>
                        <select className="ant-input" {...register('formFields.country')} >
                            {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Address</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.address1')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Address 2</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.address2')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>City</label>
                        <input maxLength="100" className="ant-input" {...register('formFields.city')} />
                    </div>

                    <div className="form-group col-lg-3 col-12">
                        <label>State</label>
                        <select className="ant-input" {...register('formFields.state')}>
                            <option></option>
                            {state.states.map((state, idx) => <option key={idx} value={state.state_code}>{state.name}</option>)}
                        </select>
                    </div>

                    <div className="form-group col-lg-3 col-12">
                        <label>Zip</label>
                        <input maxLength="10" className="ant-input" {...register('formFields.zip')} />
                    </div>   

                    <div className="form-group col-lg-6 col-12">
                        <label>URL</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.url')} />
                    </div>

                    <div className="form-group col-12">
                        <label>Comments</label>
                        <textarea maxLength="2000" className="ant-input" {...register('formFields.comments')} />
                    </div>

                    
                <div className="form-group col-6">
                    <label>Limit Resume Parses</label>
                    <br />
                    <Controller
                        control={control}
                        name="formFields.limitResumeParses"
                        render={({ field: { onChange, onBlur, value, ref } }) =>
                            <Switch checked={value} onChange={onChange} />
                        }
                    />
                </div>

                <div className="form-group col-lg-6 col-12">
                    <label>Resume Parse Credits</label>
                    <input type="number" className="ant-input" {...register('formFields.availableResumeParses')} />
                </div>
                </div>
            </Drawer>
        </>
    );
}