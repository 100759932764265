export const isItemStillMatch = (candidate, job) => {
    let jobTypeList = candidate.jobTypes?.split('|');
    let preferredStatesList = candidate.preferredStatesToWork?.split('|');
    let visaTypesList = job.jobVisaTypes?.split('|');

    if (
        job.jobSpecialties_ID === candidate.candidateSpecialties_ID &&
        job.jobSubSpecialties_ID === candidate.candidateSubSpecialties_ID &&
        (!job.jobType || !jobTypeList || (jobTypeList.length > 1 &&
            jobTypeList.find(j => parseInt(j) === job.jobType))) &&
        (!job.jobState || !preferredStatesList || (preferredStatesList.length > 1 &&
            preferredStatesList.find(p => p === job.jobState))) &&
        (!candidate.visaTypes_ID || !visaTypesList || (visaTypesList.length > 1 &&
            visaTypesList.find(v => parseInt(v) === candidate.visaTypes_ID)))
    )
        return true;
    else
        return false;
}