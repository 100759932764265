// import { createRoot } from "react-dom/client";
// import { convertHtmlToText, isHTML } from "./ManipulateHTML";
// import { flushSync } from "react-dom";

// export function filterTable(arr, searchValue, ignoreList = null, tableColumns = null) {
//     console.log(tableColumns);
//     console.log(arr);
//     //this handles two levels of nesting to look upon all fields in a nested object array
//     return arr.filter(o =>
//     {
//         if (tableColumns?.length > 0) {
//             tableColumns.forEach(col => {
//                 let fieldName = col.dataIndex;
//                 if (col.dataIndex instanceof Array) {
//                     fieldName = col.dataIndex.join('.');
//                     if (fieldName === 'candidates.lastName') {
//                         console.log(getPropByString(o, fieldName));
//                     }
//                 }
//             });
//         }

//         return (Object.keys(o).some(k => { //#1 this loops through an object to find property matches
//             if (typeof o[k] === 'string') {
//                 if (!ignoreList?.includes(k) && String(o[k])
//                     .toLowerCase()
//                     .includes(searchValue.toLowerCase())) {
//                     o.matchedOn = k;
//                     return true;
//                 } else {
//                     return false;
//                 }
//             } else {
//                 var matched = false;

//                 //#2 this loops through an object array to find property matches
//                 if (o[k] instanceof Array) {
//                     o[k].forEach(obj => {
//                         Object.keys(obj).some(j => {
//                             var stringToCompare = obj[j];
//                             if (isHTML(obj[j])) {
//                                 stringToCompare = convertHtmlToText(obj[j]);

//                             }
//                             if (!ignoreList?.includes(j) && String(stringToCompare)
//                                 .toLowerCase()
//                                 .includes(searchValue.toLowerCase())) {
//                                 o.matchedOn = j;
//                                 matched = true;
//                             }
//                         });
//                     });
//                 } else { //#3 this loops through an object to find property matches. If we got here, it is because this is an object within the initial object found in #1
//                     let obj = { ...o[k] };
//                     if (obj) {
//                         Object.keys(obj).some(j => {
//                             var stringToCompare = obj[j];
//                             if (isHTML(obj[j])) {
//                                 stringToCompare = convertHtmlToText(obj[j]);
//                             }
//                             if (!ignoreList?.includes(j) && String(stringToCompare)
//                                 .toLowerCase()
//                                 .includes(searchValue.toLowerCase())) {
//                                 o.matchedOn = j;
//                                 matched = true;
//                             }
//                         });
//                     }
//                 }

//                 if (matched) {
//                     return o;
//                 }
//             }
//         }))
//     }
//     );
// }

// export function filterTableFromColumns(arr, searchValue, tableColumns) {
//     //this handles two levels of nesting to look upon all fields in a nested object array
//     return arr.filter(o1 => {
//         let o = { ...o1 };

//         var matched = false;
//         tableColumns.every(col => {
//             let fieldName = col.dataIndex;

//             if (col.dataIndex instanceof Array) {
//                 fieldName = col.dataIndex.join('.');
//             }

//             let propertyValue = getPropByString(o, fieldName, col);

//             let stringToCompare = isHTML(propertyValue) ? convertHtmlToText(propertyValue) : propertyValue;
            
//             if (String(stringToCompare).toLowerCase().includes(searchValue.toLowerCase())) {
//                 o.matchedOn = col.hide ? col.title : null;
//                 matched = true;
//                 return false;
//             }

//             return true;
//         });
//         if (matched) {
//             return o;
//         }
//     });
// }

// function getPropByString(obj, propString, column) {
//     console.log(obj);
//     console.log(propString);
    
//     if (!propString)
//         return obj;

    
//     if(column.render) {
//         var test = column.render('', obj);
//         return nodeToString(test);
//     }
    
//     var prop, props = propString.split('.');

//     for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
//         prop = props[i];
//         console.log(obj[prop]);
//         var candidate = obj[prop];
//         if (candidate !== undefined) {
//             obj = candidate;
//         } else {
//             break;
//         }
//     }
//     console.log(obj[props[i]]);
//     return obj[props[i]];
// }

// const nodeToString = (node) => {
//     const div = document.createElement("div");
//     const root = createRoot(div);
//     flushSync(() => root.render(node));
//     return div.innerText; // or innerHTML or textContent
//   };

import { convertHtmlToText, isHTML } from "./ManipulateHTML";

export function filterTable(arr, searchValue, ignoreList = null, tableColumns = null) {
    //this handles two levels of nesting to look upon all fields in a nested object array
    return arr.filter(o2 =>
    {
        let o = { ...o2 };

        if (tableColumns?.length > 0) {
            tableColumns.forEach(col => {
                let fieldName = col.dataIndex;
                if (col.dataIndex instanceof Array) {
                    fieldName = col.dataIndex.join('.');
                    // if (fieldName === 'candidates.lastName') {
                    //     console.log(getPropByString(o, fieldName));
                    // }
                }
            });
        }

        return (Object.keys(o).some(k => { //#1 this loops through an object to find property matches
            let o = {...o2};

            if (typeof o[k] === 'string') {
                if (!ignoreList?.includes(k) && String(o[k])
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) {
                    o.matchedOn = k;
                    return true;
                } else {
                    return false;
                }
            } else {
                var matched = false;

                //#2 this loops through an object array to find property matches
                if (o[k] instanceof Array) {
                    o[k].forEach(obj => {
                        Object.keys(obj).some(j => {
                            var stringToCompare = obj[j];
                            if (isHTML(obj[j])) {
                                stringToCompare = convertHtmlToText(obj[j]);

                            }
                            if (!ignoreList?.includes(j) && String(stringToCompare)
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())) {
                                o.matchedOn = j;
                                matched = true;
                            }
                        });
                    });
                } else { //#3 this loops through an object to find property matches. If we got here, it is because this is an object within the initial object found in #1
                    let obj = { ...o[k] };
                    if (obj) {
                        Object.keys(obj).some(j => {
                            var stringToCompare = obj[j];
                            if (isHTML(obj[j])) {
                                stringToCompare = convertHtmlToText(obj[j]);
                            }
                            if (!ignoreList?.includes(j) && String(stringToCompare)
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())) {
                                o.matchedOn = j;
                                matched = true;
                            }
                        });
                    }
                }

                if (matched) {
                    return o;
                }
            }
        }))
    }
    );
}

export function filterTableFromColumns(arr, searchValue, tableColumns) {
    //this handles two levels of nesting to look upon all fields in a nested object array
    return arr.filter(o2 => {
        let o = { ...o2 };
        
        var matched = false;
        tableColumns.every(col => {
            let fieldName = col.dataIndex;

            if (col.dataIndex instanceof Array) {
                fieldName = col.dataIndex.join('.');
            }

            let propertyValue = getPropByString(o, fieldName);

            let stringToCompare = isHTML(propertyValue) ? convertHtmlToText(propertyValue) : propertyValue;
            
            if (String(stringToCompare).toLowerCase().includes(searchValue.toLowerCase())) {
                o.matchedOn = col.hide ? col.title : null;
                matched = true;
                return false;
            }

            return true;
        });
        if (matched) {
            return o;
        }
    });
}

function getPropByString(obj, propString) {
    if (!propString)
        return obj;

    var prop, props = propString.split('.');

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        var candidate = obj[prop];
        if (candidate !== undefined) {
            obj = candidate;
        } else {
            break;
        }
    }
    return obj[props[i]];
}