import React, { useEffect } from 'react';
import { EmailHistory } from '../../Email/EmailHistory';
import { Empty } from 'antd';

export function EmailHistoryCard({ specificEmail, isActive }) {

    return (
        <>
            {isActive &&
                <>
                <div className="horizontal-candidates-divider" />
                    {specificEmail ? 
                        <EmailHistory searchSpecificEmail={true} specificEmail={specificEmail || ''} />
                        :
                        <Empty description="No email history" />
                    }
                </>
            }
        </>
    );
}