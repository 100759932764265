import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { Table, Drawer, Empty } from 'antd';

import PhoneInput from 'react-phone-number-input'
import { deleteClientContact, upsertClientContact } from "../../../services/ClientContactsService";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "../../../swr/fetcher";
import { useMediaQuery } from "react-responsive";
import Globals from "../../../config/globals";

export function ClientContactsCard({ clients_ID }) {
    const { mutate } = useSWRConfig();
    const isDesktop = useMediaQuery({ minWidth: Globals.lgScreen });
    
    const { register, getValues, setValue, control, watch, reset } = useForm({
        defaultValues: {
            formFields: {
                firstName: '',
                lastName: '',
                title: '',
                phone: '',
                emailAddress: '',
                details: ''
            }
        }
    });

    const [showAddClientContact, setShowAddClientContact] = useState(false);
    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const key = `/api/GetClientContactsByClient?clients_ID=${clients_ID}`;
    const { data, isLoading } = useSWR(key, fetcher);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function openEdit(row, index) {
        var item = data[index];
        setValue('formFields', item);
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        reset();

        setEdit(false);
        isDirty.current = false;
    }

    function handleSubmit() {
        let postdata = { ...getValues().formFields };
        postdata.clients_ID = clients_ID;


        upsertClientContact(postdata).then(data => {
            mutate(key);
            if(data) {
                toast.success("Contact information saved successfully.");
                performClose();
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function handleDeleteClientContact() { 
        confirm({
            title: "You are about to delete this contact.",
            content: "This action cannot be undone. Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    deleteClientContact(getValues().formFields).then(data => { 
                        mutate(key);
                        if(data) {
                            toast.success("Contact deleted successfully.");
                            performClose();
                        } else {
                            toast.error("Something went wrong");
                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );

        
    }

    var clientColumns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName?.localeCompare(b.firstName)
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName?.localeCompare(b.lastName)
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title?.localeCompare(b.title),
            responsive: ['xl'],
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone?.localeCompare(b.phone),
            responsive: ['lg'],
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            sorter: (a, b) => a.emailAddress?.localeCompare(b.emailAddress),
            responsive: ['lg'],
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            sorter: (a, b) => a.details?.localeCompare(b.details),
            responsive: ['xl'],
        }
    ];

    const dropdownRender = (record) => {
        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>Title:</label>
                        &nbsp;
                        {record.title}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Phone:</label>
                        &nbsp;
                        {record.phone}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Email Address:</label>
                        &nbsp;
                        {record.emailAddress}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Details:</label>
                        &nbsp;
                        {record.details}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex justify-content-end">
                <button className="ant-btn ant-btn-primary float-end" onClick={() => setEdit(true)}>
                    <i className="far fa-plus-square"></i>
                    &nbsp;
                    Add Client Contact
                </button>
            </div>

            <div className="horizontal-clients-divider" />

            {data?.length > 0 ?
                <>

                    <Table
                        rowKey={(record) => record.clientContacts_ID}
                        dataSource={data}
                        columns={clientColumns}
                        size='small'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { openEdit(record, rowIndex) }, // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                        expandable={!isDesktop && {
                            expandedRowRender: (record) => dropdownRender(record),
                            rowExpandable: (record) => record.clients_ID !== 'Not Expandable',
                        }}
                    />
                </>
                :
                <Empty description={<p>You currently have no clients - to begin adding a client, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Client'.</p>} />
            }

            <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title='Edit Contact Information'
                placement={'right'}
                onClose={handleClose}
                open={edit}
                key='slider'
                size='large'
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                        </div>
                        {getValues().formFields.clientContacts_ID &&
                            <div className="float-end">
                                <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteClientContact}><i className='far fa-trash-alt'></i> &nbsp; Delete this Client Contact</button>
                            </div>
                        }
                    </div>
                }
            >
                <div className="row">
                    <div className="form-group col-12">
                        <label>First Name</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.firstName')} />
                    </div>
                    <div className="form-group col-12">
                        <label>Last Name</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.lastName')} />
                    </div>
                    
                    <div className="form-group col-12">
                        <label>Title</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.title')} />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label className="form-label">Contact Number</label>
                        <Controller
                            control={control}
                            name="formFields.phone"
                            render={({ field: { onChange, onBlur, value, ref } }) =>
                                <PhoneInput
                                    numberInputProps={{
                                        className: 'ant-input'
                                    }}
                                    defaultCountry={'US'}
                                    value={value}
                                    onChange={onChange}
                                />
                            }
                        />
                    </div>

                    <div className="form-group col-lg-6 col-12">
                        <label>Email Address</label>
                        <input maxLength="200" className="ant-input" {...register('formFields.emailAddress')} />
                    </div>

                    <div className="form-group col-12">
                        <label>Details</label>
                        <textarea maxLength="500" className="ant-input" {...register('formFields.details')} />
                    </div>
                </div>
            </Drawer>
        </div>
    );
}