import React from 'react';
import Globals from '../../config/globals';

export function ReleaseNotes() {
    return (
        <div>
            <h4>Release Notes</h4>

            <h5>Introduction</h5>
            <div>
                These are the latest release notes for {Globals.appName}.
            </div>
            <br />
            <h5>Impacted Areas</h5>
            <div>
                <ul>
                    <li>Candidates</li>
                    <li>Campaigns</li>
                    <li>Text</li>
                    <li>Email</li>
                    <li>User Settings</li>
                </ul>
            </div>

            <h5>What Changed?</h5>
            <div>
                <ul>
                    <li>
                        Candidates
                        <ul>
                            <li>View Candidates tied to a Recruiter</li>
                            <li>
                                View and Update Specific Candidate Details
                                <ul>
                                    <li>Generic Details</li>
                                    <li>Education</li>
                                    <li>License/Certifications</li>
                                    <li>Work History</li>
                                    <li>Tags</li>
                                    <li>Compensation</li>
                                    <li>Message History</li>
                                    <li>Attachments</li>
                                </ul>
                            </li>
                            <li>Add New Candidate</li>
                            <li>Archive Existing Candidate</li>
                            <li>Delete Existing Candidate</li>
                        </ul>
                    </li>

                    <li>
                        Campaigns
                        <ul>
                            <li>View Campaigns tied to a Recruiter</li>
                            <li>
                                View and Update Specific Campaign Details
                                <ul>
                                    <li>Update Campaign Name</li>
                                    <li>Add or Remove Candidates via keyword search, or location</li>
                                    <li>Message Available (those who have valid cell phone number on record, and has consented to receive texts) Candidates from a Campaign</li>
                                </ul>
                            </li>
                            <li>Add New Campaign</li>
                        </ul>
                    </li>
                    <li>
                        Text
                        <ul>
                            <li>Organizations are assigned a number at time of creation</li>
                            <li>Recruiters have the ability to message candidates both individually and from a campaign group</li>
                        </ul>
                    </li>
                    <li>
                        User Profile Management
                        <ul>
                            <li>Add/Update Profile Picture, or change avatar color</li>
                            <li>Update basic user information</li>
                            <li>Elevated privledges based system role</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>    
    );
}