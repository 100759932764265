
import nchcrLogo from '../img/nchcr_brand_banner.png';

export default function NchcrLogo({shortHeight, removeText}) {
    return (
        <a href="https://www.nchcr.com" target="_blank" rel="noopener noreferrer">
            {!removeText && <div className="text-center" style={{fontFamily: 'ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace', fontStyle: 'italic'}}>Powered By</div>}
            <img
                alt="logo"
                src={nchcrLogo}
                style={shortHeight ? {"height": "42px"} : {"height":"4rem"}}
            />
        </a>
    );
}