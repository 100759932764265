import React, { useState, useEffect, Fragment, useRef } from 'react';
import Moment from 'moment';
import '../../../css/chatbox.css';
import { Tooltip, Empty } from 'antd';

export function MessageHistoryCard(props) {

    const [smsHistory, setSmsHistory] = useState([]);

    const [isActive, setIsActive] = useState(false);

    var messageList = useRef();

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        let data = props.dashboardInformation.smsHistory;
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    data[i].showTime = true;
                } else {
                    var currentMessageDate = new Date(new Date(data[i].messageDate).getFullYear(), new Date(data[i].messageDate).getMonth(), new Date(data[i].messageDate).getDate());
                    var prevMessageDate = new Date(new Date(data[i - 1].messageDate).getFullYear(), new Date(data[i - 1].messageDate).getMonth(), new Date(data[i - 1].messageDate).getDate());

                    if (currentMessageDate.getTime() !== prevMessageDate.getTime()) {
                        data[i].showTime = true;
                    }
                }
            }

            setSmsHistory(data);
        }

    }, []);

    const content = (date) => (
        <div>
            <p>{date}</p>
        </div>
    );

    return (
        <>
            {isActive &&
                <>
                    {smsHistory.length > 0 ?
                        <>
                                <div className="messenger-container">
                                    <div className="message-conversation-container" id='message-conversation-container' ref={(div) => messageList = div}>

                                {smsHistory.map((item, idx) => (
                                        <Fragment key={idx}>
                                            <div className="row text-center"><small>{item.showTime && Moment(item.messageDate).format("ddd, MMMM Do YYYY, h:mm a")}</small></div>
                                            <li className="clearfix">
                                                <Tooltip title={() => content(Moment(item.messageDate).format("ddd, MMMM Do YYYY, h:mm a"))} placement={item.messageType === 'Out' ? "topRight" : "topLeft"}>
                                                    <div className={`message ${item.messageType === 'Out' ? 'my-message float-end' : 'other-message float-start'}`}>
                                                        {item.messageContent}
                                                    </div>
                                                </Tooltip>
                                            </li>
                                    </Fragment>
                                ))}
                                    </div>
                                </div>
                        </>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="This candidate has no chat history" />
                    }
                </>
            }
        </>
    );
}