import { Divider, Typography } from 'antd';
import React from 'react';
const { Title, Paragraph, Text, Link } = Typography;

export default function FAQ() {
    return (
        <Typography>
          <Title>Introduction</Title>
          <Paragraph>
            In the process of internal desktop applications development, many different design specs and
            implementations would be involved, which might cause designers and developers difficulties and
            duplication and reduce the efficiency of development.
          </Paragraph>
          <Paragraph>
            After massive project practice and summaries, Ant Design, a design language for background
            applications, is refined by Ant UED Team, which aims to{' '}
            <Text strong>
              uniform the user interface specs for internal background projects, lower the unnecessary
              cost of design differences and implementation and liberate the resources of design and
              front-end development
            </Text>
            .
          </Paragraph>
          
          <Divider />

          <Title level={2}>What is the criteria for a candidate matching to a job?</Title>
          <Paragraph>
            The primary criteria for a candidate to match to a job is the specialty and sub specialty. Both must match in order for the system to consider them a match.
          </Paragraph>
      
          <Paragraph>
            There is potentially more to it than that though...
          </Paragraph>

          <Paragraph>
            <ul>
              <li>
                If the candidate has preferred job types, then it will only match on jobs of the preferred type(s).
              </li>
              <li>
                If the candidate has a visa type, then it will only match on jobs that accept visa, with the specified type.
              </li>
              <li>
                If the candidate has a preferred location, then it will only match on jobs in that location.
              </li>
              <li>
                A recruiter can see a match on candidates and jobs across their organization if they have the appropriate permissions to view organizational candidates and jobs, which is enabled by default.
              </li>
            </ul>
          </Paragraph>
      
          
      
        </Typography>
      );
}