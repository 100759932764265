import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Table, Tabs, Input, Empty, Divider, Modal, Popover } from 'antd';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { clientTableParams } from '../../services/ClientsService';
import { useClients } from '../../swr/clients';
import { useForm } from "react-hook-form";
import { fetcher } from '../../swr/fetcher';
import useSWR from 'swr';

export function OverviewDashboard(props) {

    const navigate = useNavigate();
    const { register, watch } = useForm();

    const isDesktop = useMediaQuery({ minWidth: Globals.lgScreen });

    const [loading, setLoading] = useState(false);

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [activeTab, setActiveTab] = useState('logs');

    const [tableParams, setTableParams] = useState({
            pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        }
    });

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });
    const [searchValue, setSearchValue] = useState('');
    const [showArchived, setShowArchived] = useState(false);

    const key = `/api/GetInfoLogs?&pageSize=${tableParams.pagination.pageSize}&offset=${tableParams.pagination.current - 1}`;

    const { data, isLoading } = useSWR(key, fetcher);

    useEffect(() => {
        if (uInfo?.users_ID) {
            if (uInfo.userType !== 'SuperAdmin') {
                navigate('/dashboard', { replace: true });
            }
        }
    }, [uInfo]);

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: data?.length > 0 ? data[0].totalCount : 0
            }
        });
    }, [data, loading]);

    // useEffect(() => {
    //     setLoading(true);

    //     getClientsByOrganization(uInfo.organizations_ID, uInfo.users_ID).then(data => {
    //         let clientsToSet = data.userClients ? [...data.userClients] : [];

    //         setClientLists({
    //             allUserClients: data.userClients ? [...data.userClients] : [],
    //             filterUserClients: clientsToSet,
    //             allClients: data.companyClients ? [...data.companyClients] : [],
    //             loaded: true
    //         });

    //         setClientsToFilterAgainst(clientsToSet);

    //         setTableParams({
    //             ...tableParams,
    //             pagination: {
    //                 ...tableParams.pagination,
    //                 total: clientsToSet.length
    //             }
    //         })

    //         setLoading(false);
    //     });

    // }, []);

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if(sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    var columns = [
        {
            title: 'Date/Time',
            dataIndex: 'created',
            key: 'created'
        },
        {
            title: 'Log Level',
            dataIndex: 'logLevel',
            key: 'logLevel'
        },
        {
            title: 'API Path',
            dataIndex: 'path',
            key: 'path'
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user'
        }
    ];

    function handleSearch(value) {
        setSearchValue(value);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    function displayModal(record) {
        Modal.info({
            title: 'This is a notification message',
            content: (
              <pre>
                {JSON.stringify(JSON.parse(record.params), undefined, 4)}
              </pre>
            ),
            style: {
                maxWidth: '50vw'
            },
            width: 'auto',
            onOk() {},
          });
    }

    var tabs = [
        {
            label: <div style={{color: 'rgb(91 33 182)'}}>Logs</div>,
            key: 'logs',
            children: (
                <>
                    {loading ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            {/* <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider> */}
                            {data?.length > 0 ?
                                <>

                                    <Table
                                        rowKey={(record) => record.id}
                                        dataSource={data}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { displayModal(record) }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination}
                                    />
                                </>
                                :
                                <Empty />
                            }
                        </>
                    }
                </>
            ),
        }
    ];

    return (
        <>
            <div className="card-container">
                <Tabs activeKey={activeTab} type="card" items={tabs} onChange={handleTabChange} />
            </div>
        </>
    );
}