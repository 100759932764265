import Moment from 'moment';

export function dateWithNoTimezone(isostring) {
    if (isostring) {
        var date = new Date(Moment.parseZone(isostring).utc().format())
        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    } else {
        return new Date();
    }
    
}

export function changeDateWithNoTimezone(date) {
    new Date(date.toDateString());
    return new Date(new Date().toDateString());
}

export function isValidDate(isostring) {
    if (isostring != null && isostring === '0001-01-01T00:00:00') {
        return false;
    } else {
        return true;
    }
}
