import React, { useState, useEffect } from 'react'
import { Avatar } from 'antd';
import { NavBar, TabBar } from 'antd-mobile'
import {
    useLocation,
    useNavigate,
} from 'react-router-dom'
import { NotificationOutlined, MobileOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import './navigation.css';
import { getColorShade } from '../../helpers/ColorShades';
import { takeWhile } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { userInfo } from '../../services/UsersService';

export function BottomNav(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;

    const [uInfo, setUInfo] = useState({})
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo]) => {
            if (userInfo.users_ID) {
                setUInfo(userInfo);
            }
        });

        return () => alive = false;
    }, []);
    

    function setRouteActive(string) {
        switch (string) {
            case 'menu':
                props.handleSider(!collapsed);
                setCollapsed(!collapsed);
                break;
            case 'sms':
                navigate("/sms-dashboard", { replace: true });
                break;
            case 'candidates':
                navigate("/candidates", { replace: true });
                break;
            case 'campaigns':
                navigate("/campaigns", { replace: true });
                break;
            case 'settings':
                navigate("/account-settings", { replace: true });
                break;
            default:
                break;
        }
        console.log(string);
        //history.push(value);
    }

    const tabs = [
        {
            key: 'candidates',
            title: <span className="candidate-text">Candidates</span>,
            icon: <i className="fa-regular fa-user candidate-text" id="candidates"></i>,
        },
        {
            key: 'campaigns',
            title: <span className="campaign-text">Campaigns</span>,
            icon: <NotificationOutlined id="campaigns" className="campaign-text" />,
        },
        {
            key: 'sms',
            title: <span className="campaign-text">Text</span>,
            icon: <MobileOutlined id="sms" className="campaign-text" />,
        },
        {
            key: 'settings',
            title: 'Profile',
            icon: <Avatar style={{ color: uInfo.color, backgroundColor: getColorShade(uInfo.color) }} src={uInfo.profilePicture ? `data:image/jpeg;base64,${uInfo.profilePicture}` : null} icon={<UserOutlined />}></Avatar>,
        },
        {
            key: 'menu',
            title: 'Menu',
            icon: <MenuOutlined />,
        },
    ]

    return (
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}