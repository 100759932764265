import Axios from '../config/axios';

import {
    atom,
} from 'recoil';

export const textState = atom({
    key: 'textState', // unique ID (with respect to other atoms/selectors)
    default: 'hello there', // default value (aka initial value)
});

export function validateUser(username, password) {
    let postdata = {
        username,
        password
    }

    return Axios.post('/api/auth/ValidateUser', postdata).then(response => response.data);
}

export function unauth() {
    return Axios.get('/api/auth/unauth').then(response => response.data);
}

export function validateUserByUid(auditLogin_ID) {
    let postdata = {
        auditLogin_ID
    }

    return Axios.post('/api/auth/ValidateUserByUid', postdata).then(response => response.data);
}

export async function refreshToken(postdata) {
    const response = await Axios.post('/api/auth/refreshToken', postdata);

    return response.data;
}

export function validateResetToken(token) {
    return Axios.get('/api/auth/ValidateResetToken', {
        params: {
            token
        }
    }).then(response => response.data);
}

export function resetPassword(postdata) {
    return Axios.post('/api/auth/ResetPassword', postdata).then(response => response.data);
}