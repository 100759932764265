import axios from 'axios';
import Axios from '../config/axios';
import Globals from '../config/globals';

export function importCandidates(file, users_ID) {
    //let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('users_ID', users_ID);
    //formdata.append('userID', userInfo.userId);

    return axios.post(`${window.api}api/ImportCandidates`, formdata, {
        headers: {
          Authorization: 'Bearer ' + Globals.bearer,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          'Access-Control-Allow-Origin': '*'
        },
      }).then(response => response.data);
}

export function downloadCandidateTemplate() {
    return Axios.get(`/api/DownloadCandidateTemplate`, {
        responseType: 'blob'
    }).then(response => response.data).catch(error => {
        console.log(error);
    });
}