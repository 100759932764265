const countryStatesList = [
    {
        "name": "Afghanistan",
        "iso3": "AFG",
        "iso2": "AF",
        "phone_code": "93",
        "capital": "Kabul",
        "currency": "AFN",
        "states": [
            "Badakhshan",
            "Badghis",
            "Baghlan",
            "Balkh",
            "Bamyan",
            "Daykundi",
            "Farah",
            "Faryab",
            "Ghazni",
            "Gh?r",
            "Helmand",
            "Herat",
            "Jowzjan",
            "Kabul",
            "Kandahar",
            "Kapisa",
            "Khost",
            "Kunar",
            "Kunduz Province",
            "Laghman",
            "Logar",
            "Nangarhar",
            "Nimruz",
            "Nuristan",
            "Paktia",
            "Paktika",
            "Panjshir",
            "Parwan",
            "Samangan",
            "Sar-e Pol",
            "Takhar",
            "Urozgan",
            "Zabul"
        ]
    },
    {
        "name": "Aland Islands",
        "iso3": "ALA",
        "iso2": "AX",
        "phone_code": "+358-18",
        "capital": "Mariehamn",
        "currency": "EUR",
        "states": [
            "Badakhshan",
            "Badghis",
            "Baghlan",
            "Balkh",
            "Bamyan",
            "Daykundi",
            "Farah",
            "Faryab",
            "Ghazni",
            "Gh?r",
            "Helmand",
            "Herat",
            "Jowzjan",
            "Kabul",
            "Kandahar",
            "Kapisa",
            "Khost",
            "Kunar",
            "Kunduz Province",
            "Laghman",
            "Logar",
            "Nangarhar",
            "Nimruz",
            "Nuristan",
            "Paktia",
            "Paktika",
            "Panjshir",
            "Parwan",
            "Samangan",
            "Sar-e Pol",
            "Takhar",
            "Urozgan",
            "Zabul"
        ]
    },
    {
        "name": "Albania",
        "iso3": "ALB",
        "iso2": "AL",
        "phone_code": "355",
        "capital": "Tirana",
        "currency": "ALL",
        "states": [
            "Berat County",
            "Berat District",
            "Bulqiz� District",
            "Delvin� District",
            "Devoll District",
            "Dib�r County",
            "Dib�r District",
            "Durr�s County",
            "Durr�s District",
            "Elbasan County",
            "Fier County",
            "Fier District",
            "Gjirokast�r County",
            "Gjirokast�r District",
            "Gramsh District",
            "Has District",
            "Kavaj� District",
            "Kolonj� District",
            "Kor�� County",
            "Kor�� District",
            "Kruj� District",
            "Ku�ov� District",
            "Kuk�s County",
            "Kuk�s District",
            "Kurbin District",
            "Lezh� County",
            "Lezh� District",
            "Librazhd District",
            "Lushnj� District",
            "Mal�si e Madhe District",
            "Mallakast�r District",
            "Mat District",
            "Mirdit� District",
            "P�rmet District",
            "Peqin District",
            "Pogradec District",
            "Puk� District",
            "Sarand� District",
            "Shkod�r County",
            "Shkod�r District",
            "Skrapar District",
            "Tepelen� District",
            "Tirana County",
            "Tirana District",
            "Tropoj� District",
            "Vlor� County",
            "Vlor� District"
        ]
    },
    {
        "name": "Algeria",
        "iso3": "DZA",
        "iso2": "DZ",
        "phone_code": "213",
        "capital": "Algiers",
        "currency": "DZD",
        "states": [
            "A�n Defla Province",
            "A�n T�mouchent Province",
            "Adrar Province",
            "Algiers Province",
            "Annaba Province",
            "Batna Province",
            "B�char Province",
            "B�ja�a Province",
            "Biskra",
            "Blida Province",
            "Bordj Bou Arr�ridj Province",
            "Bou�ra Province",
            "Boumerd�s Province",
            "Chlef Province",
            "Constantine Province",
            "Djelfa Province",
            "El Bayadh Province",
            "El Oued Province",
            "El Tarf Province",
            "Gharda�a Province",
            "Guelma Province",
            "Illizi Province",
            "Jijel Province",
            "Khenchela Province",
            "Laghouat Province",
            "M'Sila Province",
            "Mascara Province",
            "M�d�a Province",
            "Mila Province",
            "Mostaganem Province",
            "Naama Province",
            "Oran Province",
            "Ouargla Province",
            "Oum El Bouaghi Province",
            "Relizane Province",
            "Sa�da Province",
            "S�tif Province",
            "Skikda Province",
            "Souk Ahras Province",
            "Tamanghasset Province",
            "T�bessa Province",
            "Tiaret Province",
            "Tindouf Province",
            "Tipasa Province",
            "Tissemsilt Province",
            "Tizi Ouzou Province",
            "Tlemcen Province"
        ]
    },
    {
        "name": "American Samoa",
        "iso3": "ASM",
        "iso2": "AS",
        "phone_code": "+1-684",
        "capital": "Pago Pago",
        "currency": "USD",
        "states": [
            "A�n Defla Province",
            "A�n T�mouchent Province",
            "Adrar Province",
            "Algiers Province",
            "Annaba Province",
            "Batna Province",
            "B�char Province",
            "B�ja�a Province",
            "Biskra",
            "Blida Province",
            "Bordj Bou Arr�ridj Province",
            "Bou�ra Province",
            "Boumerd�s Province",
            "Chlef Province",
            "Constantine Province",
            "Djelfa Province",
            "El Bayadh Province",
            "El Oued Province",
            "El Tarf Province",
            "Gharda�a Province",
            "Guelma Province",
            "Illizi Province",
            "Jijel Province",
            "Khenchela Province",
            "Laghouat Province",
            "M'Sila Province",
            "Mascara Province",
            "M�d�a Province",
            "Mila Province",
            "Mostaganem Province",
            "Naama Province",
            "Oran Province",
            "Ouargla Province",
            "Oum El Bouaghi Province",
            "Relizane Province",
            "Sa�da Province",
            "S�tif Province",
            "Skikda Province",
            "Souk Ahras Province",
            "Tamanghasset Province",
            "T�bessa Province",
            "Tiaret Province",
            "Tindouf Province",
            "Tipasa Province",
            "Tissemsilt Province",
            "Tizi Ouzou Province",
            "Tlemcen Province"
        ]
    },
    {
        "name": "Andorra",
        "iso3": "AND",
        "iso2": "AD",
        "phone_code": "376",
        "capital": "Andorra la Vella",
        "currency": "EUR",
        "states": [
            "Andorra la Vella",
            "Canillo",
            "Encamp",
            "Escaldes-Engordany",
            "La Massana",
            "Ordino",
            "Sant Juli� de L�ria"
        ]
    },
    {
        "name": "Angola",
        "iso3": "AGO",
        "iso2": "AO",
        "phone_code": "244",
        "capital": "Luanda",
        "currency": "AOA",
        "states": [
            "Bengo Province",
            "Benguela Province",
            "Bi� Province",
            "Cabinda Province",
            "Cuando Cubango Province",
            "Cuanza Norte Province",
            "Cuanza Sul",
            "Cunene Province",
            "Huambo Province",
            "Hu�la Province",
            "Luanda Province",
            "Lunda Norte Province",
            "Lunda Sul Province",
            "Malanje Province",
            "Moxico Province",
            "U�ge Province",
            "Zaire Province"
        ]
    },
    {
        "name": "Anguilla",
        "iso3": "AIA",
        "iso2": "AI",
        "phone_code": "+1-264",
        "capital": "The Valley",
        "currency": "XCD",
        "states": [
            "Bengo Province",
            "Benguela Province",
            "Bi� Province",
            "Cabinda Province",
            "Cuando Cubango Province",
            "Cuanza Norte Province",
            "Cuanza Sul",
            "Cunene Province",
            "Huambo Province",
            "Hu�la Province",
            "Luanda Province",
            "Lunda Norte Province",
            "Lunda Sul Province",
            "Malanje Province",
            "Moxico Province",
            "U�ge Province",
            "Zaire Province"
        ]
    },
    {
        "name": "Antarctica",
        "iso3": "ATA",
        "iso2": "AQ",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "states": [
            "Bengo Province",
            "Benguela Province",
            "Bi� Province",
            "Cabinda Province",
            "Cuando Cubango Province",
            "Cuanza Norte Province",
            "Cuanza Sul",
            "Cunene Province",
            "Huambo Province",
            "Hu�la Province",
            "Luanda Province",
            "Lunda Norte Province",
            "Lunda Sul Province",
            "Malanje Province",
            "Moxico Province",
            "U�ge Province",
            "Zaire Province"
        ]
    },
    {
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "iso2": "AG",
        "phone_code": "+1-268",
        "capital": "St. John's",
        "currency": "XCD",
        "states": [
            "Barbuda",
            "Redonda",
            "Saint George Parish",
            "Saint John Parish",
            "Saint Mary Parish",
            "Saint Paul Parish",
            "Saint Peter Parish",
            "Saint Philip Parish"
        ]
    },
    {
        "name": "Argentina",
        "iso3": "ARG",
        "iso2": "AR",
        "phone_code": "54",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "states": [
            "Buenos Aires",
            "Catamarca Province",
            "C�rdoba Province",
            "Chaco Province",
            "Chubut Province",
            "Corrientes",
            "Entre R�os Province",
            "Formosa Province",
            "Jujuy Province",
            "La Pampa",
            "La Rioja Province",
            "Mendoza",
            "Misiones Province",
            "Neuqu�n Province",
            "R�o Negro Province",
            "Salta Province",
            "San Juan Province",
            "San Luis Province",
            "Santa Cruz Province",
            "Santa Fe Province",
            "Santiago del Estero Province",
            "Tierra del Fuego Province",
            "Tucum�n Province"
        ]
    },
    {
        "name": "Armenia",
        "iso3": "ARM",
        "iso2": "AM",
        "phone_code": "374",
        "capital": "Yerevan",
        "currency": "AMD",
        "states": [
            "Aragatsotn Region",
            "Ararat Province",
            "Armavir Region",
            "Gegharkunik Province",
            "Kotayk Region",
            "Lori Region",
            "Shirak Region",
            "Syunik Province",
            "Tavush Region",
            "Vayots Dzor Region",
            "Yerevan"
        ]
    },
    {
        "name": "Aruba",
        "iso3": "ABW",
        "iso2": "AW",
        "phone_code": "297",
        "capital": "Oranjestad",
        "currency": "AWG",
        "states": [
            "Aragatsotn Region",
            "Ararat Province",
            "Armavir Region",
            "Gegharkunik Province",
            "Kotayk Region",
            "Lori Region",
            "Shirak Region",
            "Syunik Province",
            "Tavush Region",
            "Vayots Dzor Region",
            "Yerevan"
        ]
    },
    {
        "name": "Australia",
        "iso3": "AUS",
        "iso2": "AU",
        "phone_code": "61",
        "capital": "Canberra",
        "currency": "AUD",
        "states": [
            "Australian Capital Territory",
            "New South Wales",
            "Northern Territory",
            "Queensland",
            "South Australia",
            "Tasmania",
            "Victoria",
            "Western Australia"
        ]
    },
    {
        "name": "Austria",
        "iso3": "AUT",
        "iso2": "AT",
        "phone_code": "43",
        "capital": "Vienna",
        "currency": "EUR",
        "states": [
            "Burgenland",
            "Carinthia",
            "Lower Austria",
            "Salzburg",
            "Styria",
            "Tyrol",
            "Upper Austria",
            "Vienna",
            "Vorarlberg"
        ]
    },
    {
        "name": "Azerbaijan",
        "iso3": "AZE",
        "iso2": "AZ",
        "phone_code": "994",
        "capital": "Baku",
        "currency": "AZN",
        "states": [
            "Absheron District",
            "Agdam District",
            "Agdash District",
            "Aghjabadi District",
            "Agstafa District",
            "Agsu District",
            "Astara District",
            "Babek District",
            "Baku",
            "Balakan District",
            "Barda District",
            "Beylagan District",
            "Bilasuvar District",
            "Dashkasan District",
            "Fizuli District",
            "Ganja",
            "G?d?b?y",
            "Gobustan District",
            "Goranboy District",
            "Goychay",
            "Goygol District",
            "Hajigabul District",
            "Imishli District",
            "Ismailli District",
            "Jabrayil District",
            "Jalilabad District",
            "Julfa District",
            "Kalbajar District",
            "Kangarli District",
            "Khachmaz District",
            "Khizi District",
            "Khojali District",
            "Kurdamir District",
            "Lachin District",
            "Lankaran",
            "Lankaran District",
            "Lerik District",
            "Martuni",
            "Masally District",
            "Mingachevir",
            "Nakhchivan Autonomous Republic",
            "Neftchala District",
            "Oghuz District",
            "Ordubad District",
            "Qabala District",
            "Qakh District",
            "Qazakh District",
            "Quba District",
            "Qubadli District",
            "Qusar District",
            "Saatly District",
            "Sabirabad District",
            "Sadarak District",
            "Salyan District",
            "Samukh District",
            "Shabran District",
            "Shahbuz District",
            "Shaki",
            "Shaki District",
            "Shamakhi District",
            "Shamkir District",
            "Sharur District",
            "Shirvan",
            "Shusha District",
            "Siazan District",
            "Sumqayit",
            "Tartar District",
            "Tovuz District",
            "Ujar District",
            "Yardymli District",
            "Yevlakh",
            "Yevlakh District",
            "Zangilan District",
            "Zaqatala District",
            "Zardab District"
        ]
    },
    {
        "name": "Bahamas The",
        "iso3": "BHS",
        "iso2": "BS",
        "phone_code": "+1-242",
        "capital": "Nassau",
        "currency": "BSD",
        "states": [
            "Acklins",
            "Acklins and Crooked Islands",
            "Berry Islands",
            "Bimini",
            "Black Point",
            "Cat Island",
            "Central Abaco",
            "Central Andros",
            "Central Eleuthera",
            "Crooked Island",
            "East Grand Bahama",
            "Exuma",
            "Freeport",
            "Fresh Creek",
            "Governor's Harbour",
            "Grand Cay",
            "Green Turtle Cay",
            "Harbour Island",
            "High Rock",
            "Hope Town",
            "Inagua",
            "Kemps Bay",
            "Long Island",
            "Mangrove Cay",
            "Marsh Harbour",
            "Mayaguana District",
            "Nichollstown and Berry Islands",
            "North Abaco",
            "North Andros",
            "North Eleuthera",
            "Ragged Island",
            "Rock Sound",
            "Rum Cay District",
            "San Salvador and Rum Cay",
            "San Salvador Island",
            "Sandy Point",
            "South Abaco",
            "South Andros",
            "South Eleuthera",
            "Spanish Wells",
            "West Grand Bahama"
        ]
    },
    {
        "name": "Bahrain",
        "iso3": "BHR",
        "iso2": "BH",
        "phone_code": "973",
        "capital": "Manama",
        "currency": "BHD",
        "states": [
            "Capital Governorate",
            "Central Governorate",
            "Muharraq Governorate",
            "Northern Governorate",
            "Southern Governorate"
        ]
    },
    {
        "name": "Bangladesh",
        "iso3": "BGD",
        "iso2": "BD",
        "phone_code": "880",
        "capital": "Dhaka",
        "currency": "BDT",
        "states": [
            "Bagerhat District",
            "Bahadia",
            "Bandarban District",
            "Barguna District",
            "Barisal District",
            "Barisal Division",
            "Bhola District",
            "Bogra District",
            "Brahmanbaria District",
            "Chandpur District",
            "Chapai Nawabganj District",
            "Chittagong District",
            "Chittagong Division",
            "Chuadanga District",
            "Comilla District",
            "Cox's Bazar District",
            "Dhaka District",
            "Dhaka Division",
            "Dinajpur District",
            "Faridpur District",
            "Feni District",
            "Gaibandha District",
            "Gazipur District",
            "Gopalganj District",
            "Habiganj District",
            "Jamalpur District",
            "Jessore District",
            "Jhalokati District",
            "Jhenaidah District",
            "Joypurhat District",
            "Khagrachari District",
            "Khulna District",
            "Khulna Division",
            "Kishoreganj District",
            "Kurigram District",
            "Kushtia District",
            "Lakshmipur District",
            "Lalmonirhat District",
            "Madaripur District",
            "Meherpur District",
            "Moulvibazar District",
            "Munshiganj District",
            "Mymensingh District",
            "Mymensingh Division",
            "Naogaon District",
            "Narail District",
            "Narayanganj District",
            "Natore District",
            "Netrokona District",
            "Nilphamari District",
            "Noakhali District",
            "Pabna District",
            "Panchagarh District",
            "Patuakhali District",
            "Pirojpur District",
            "Rajbari District",
            "Rajshahi District",
            "Rajshahi Division",
            "Rangamati Hill District",
            "Rangpur District",
            "Rangpur Division",
            "Satkhira District",
            "Shariatpur District",
            "Sherpur District",
            "Sirajganj District",
            "Sunamganj District",
            "Sylhet District",
            "Sylhet Division",
            "Tangail District",
            "Thakurgaon District"
        ]
    },
    {
        "name": "Barbados",
        "iso3": "BRB",
        "iso2": "BB",
        "phone_code": "+1-246",
        "capital": "Bridgetown",
        "currency": "BBD",
        "states": [
            "Christ Church",
            "Saint Andrew",
            "Saint George",
            "Saint James",
            "Saint John",
            "Saint Joseph",
            "Saint Lucy",
            "Saint Michael",
            "Saint Peter",
            "Saint Philip",
            "Saint Thomas"
        ]
    },
    {
        "name": "Belarus",
        "iso3": "BLR",
        "iso2": "BY",
        "phone_code": "375",
        "capital": "Minsk",
        "currency": "BYR",
        "states": [
            "Brest Region",
            "Gomel Region",
            "Grodno Region",
            "Minsk",
            "Minsk Region",
            "Mogilev Region",
            "Vitebsk Region"
        ]
    },
    {
        "name": "Belgium",
        "iso3": "BEL",
        "iso2": "BE",
        "phone_code": "32",
        "capital": "Brussels",
        "currency": "EUR",
        "states": [
            "Antwerp",
            "Brussels-Capital Region",
            "East Flanders",
            "Flanders",
            "Flemish Brabant",
            "Hainaut",
            "Li�ge",
            "Limburg",
            "Luxembourg",
            "Namur",
            "Wallonia",
            "Walloon Brabant",
            "West Flanders"
        ]
    },
    {
        "name": "Belize",
        "iso3": "BLZ",
        "iso2": "BZ",
        "phone_code": "501",
        "capital": "Belmopan",
        "currency": "BZD",
        "states": [
            "Belize District",
            "Cayo District",
            "Corozal District",
            "Orange Walk District",
            "Stann Creek District",
            "Toledo District"
        ]
    },
    {
        "name": "Benin",
        "iso3": "BEN",
        "iso2": "BJ",
        "phone_code": "229",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "states": [
            "Alibori Department",
            "Atakora Department",
            "Atlantique Department",
            "Borgou Department",
            "Collines Department",
            "Donga Department",
            "Kouffo Department",
            "Littoral Department",
            "Mono Department",
            "Ou�m� Department",
            "Plateau Department",
            "Zou Department"
        ]
    },
    {
        "name": "Bermuda",
        "iso3": "BMU",
        "iso2": "BM",
        "phone_code": "+1-441",
        "capital": "Hamilton",
        "currency": "BMD",
        "states": [
            "Alibori Department",
            "Atakora Department",
            "Atlantique Department",
            "Borgou Department",
            "Collines Department",
            "Donga Department",
            "Kouffo Department",
            "Littoral Department",
            "Mono Department",
            "Ou�m� Department",
            "Plateau Department",
            "Zou Department"
        ]
    },
    {
        "name": "Bhutan",
        "iso3": "BTN",
        "iso2": "BT",
        "phone_code": "975",
        "capital": "Thimphu",
        "currency": "BTN",
        "states": [
            "Bumthang District",
            "Chukha District",
            "Dagana District",
            "Gasa District",
            "Haa District",
            "Lhuntse District",
            "Mongar District",
            "Paro District",
            "Pemagatshel District",
            "Punakha District",
            "Samdrup Jongkhar District",
            "Samtse District",
            "Sarpang District",
            "Thimphu District",
            "Trashigang District",
            "Trongsa District",
            "Tsirang District",
            "Wangdue Phodrang District",
            "Zhemgang District"
        ]
    },
    {
        "name": "Bolivia",
        "iso3": "BOL",
        "iso2": "BO",
        "phone_code": "591",
        "capital": "Sucre",
        "currency": "BOB",
        "states": [
            "Beni Department",
            "Chuquisaca Department",
            "Cochabamba Department",
            "La Paz Department",
            "Oruro Department",
            "Pando Department",
            "Potos� Department",
            "Santa Cruz Department",
            "Tarija Department"
        ]
    },
    {
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "iso2": "BA",
        "phone_code": "387",
        "capital": "Sarajevo",
        "currency": "BAM",
        "states": [
            "Bosnian Podrinje Canton",
            "Br?ko District",
            "Canton 10",
            "Central Bosnia Canton",
            "Federation of Bosnia and Herzegovina",
            "Herzegovina-Neretva Canton",
            "Posavina Canton",
            "Republika Srpska",
            "Sarajevo Canton",
            "Tuzla Canton",
            "Una-Sana Canton",
            "West Herzegovina Canton",
            "Zenica-Doboj Canton"
        ]
    },
    {
        "name": "Botswana",
        "iso3": "BWA",
        "iso2": "BW",
        "phone_code": "267",
        "capital": "Gaborone",
        "currency": "BWP",
        "states": [
            "Central District",
            "Ghanzi District",
            "Kgalagadi District",
            "Kgatleng District",
            "Kweneng District",
            "Ngamiland",
            "North-East District",
            "North-West District",
            "South-East District",
            "Southern District"
        ]
    },
    {
        "name": "Bouvet Island",
        "iso3": "BVT",
        "iso2": "BV",
        "phone_code": "",
        "capital": "",
        "currency": "NOK",
        "states": [
            "Central District",
            "Ghanzi District",
            "Kgalagadi District",
            "Kgatleng District",
            "Kweneng District",
            "Ngamiland",
            "North-East District",
            "North-West District",
            "South-East District",
            "Southern District"
        ]
    },
    {
        "name": "Brazil",
        "iso3": "BRA",
        "iso2": "BR",
        "phone_code": "55",
        "capital": "Brasilia",
        "currency": "BRL",
        "states": [
            "Acre",
            "Alagoas",
            "Amap�",
            "Amazonas",
            "Bahia",
            "Cear�",
            "Esp�rito Santo",
            "Federal District",
            "Goi�s",
            "Maranh�o",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Para�ba",
            "Paran�",
            "Par�",
            "Pernambuco",
            "Piau�",
            "Rio de Janeiro",
            "Rio Grande do Norte",
            "Rio Grande do Sul",
            "Rond�nia",
            "Santa Catarina",
            "S�o Paulo",
            "Sergipe",
            "Tocantins"
        ]
    },
    {
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "iso2": "IO",
        "phone_code": "246",
        "capital": "Diego Garcia",
        "currency": "USD",
        "states": [
            "Acre",
            "Alagoas",
            "Amap�",
            "Amazonas",
            "Bahia",
            "Cear�",
            "Esp�rito Santo",
            "Federal District",
            "Goi�s",
            "Maranh�o",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Para�ba",
            "Paran�",
            "Par�",
            "Pernambuco",
            "Piau�",
            "Rio de Janeiro",
            "Rio Grande do Norte",
            "Rio Grande do Sul",
            "Rond�nia",
            "Santa Catarina",
            "S�o Paulo",
            "Sergipe",
            "Tocantins"
        ]
    },
    {
        "name": "Brunei",
        "iso3": "BRN",
        "iso2": "BN",
        "phone_code": "673",
        "capital": "Bandar Seri Begawan",
        "currency": "BND",
        "states": [
            "Belait District",
            "Brunei-Muara District",
            "Temburong District",
            "Tutong District"
        ]
    },
    {
        "name": "Bulgaria",
        "iso3": "BGR",
        "iso2": "BG",
        "phone_code": "359",
        "capital": "Sofia",
        "currency": "BGN",
        "states": [
            "Blagoevgrad Province",
            "Burgas Province",
            "Dobrich Province",
            "Gabrovo Province",
            "Haskovo Province",
            "Kardzhali Province",
            "Kyustendil Province",
            "Lovech Province",
            "Montana Province",
            "Pazardzhik Province",
            "Pernik Province",
            "Pleven Province",
            "Plovdiv Province",
            "Razgrad Province",
            "Ruse Province",
            "Silistra Province",
            "Sliven Province",
            "Smolyan Province",
            "Sofia City Province",
            "Sofia Province",
            "Stara Zagora Province",
            "Targovishte Province",
            "Varna Province",
            "Veliko Tarnovo Province",
            "Vidin Province",
            "Vratsa Province",
            "Yambol Province"
        ]
    },
    {
        "name": "Burkina Faso",
        "iso3": "BFA",
        "iso2": "BF",
        "phone_code": "226",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "states": [
            "Bal� Province",
            "Bam Province",
            "Banwa Province",
            "Baz�ga Province",
            "Boucle du Mouhoun Region",
            "Bougouriba Province",
            "Boulgou",
            "Cascades Region",
            "Centre",
            "Centre-Est Region",
            "Centre-Nord Region",
            "Centre-Ouest Region",
            "Centre-Sud Region",
            "Como� Province",
            "Est Region",
            "Ganzourgou Province",
            "Gnagna Province",
            "Gourma Province",
            "Hauts-Bassins Region",
            "Houet Province",
            "Ioba Province",
            "Kadiogo Province",
            "K�n�dougou Province",
            "Komondjari Province",
            "Kompienga Province",
            "Kossi Province",
            "Koulp�logo Province",
            "Kouritenga Province",
            "Kourw�ogo Province",
            "L�raba Province",
            "Loroum Province",
            "Mouhoun",
            "Nahouri Province",
            "Namentenga Province",
            "Nayala Province",
            "Nord Region, Burkina Faso",
            "Noumbiel Province",
            "Oubritenga Province",
            "Oudalan Province",
            "Passor� Province",
            "Plateau-Central Region",
            "Poni Province",
            "Sahel Region",
            "Sangui� Province",
            "Sanmatenga Province",
            "S�no Province",
            "Sissili Province",
            "Soum Province",
            "Sourou Province",
            "Sud-Ouest Region",
            "Tapoa Province",
            "Tuy Province",
            "Yagha Province",
            "Yatenga Province",
            "Ziro Province",
            "Zondoma Province",
            "Zoundw�ogo Province"
        ]
    },
    {
        "name": "Burundi",
        "iso3": "BDI",
        "iso2": "BI",
        "phone_code": "257",
        "capital": "Bujumbura",
        "currency": "BIF",
        "states": [
            "Bubanza Province",
            "Bujumbura Mairie Province",
            "Bujumbura Rural Province",
            "Bururi Province",
            "Cankuzo Province",
            "Cibitoke Province",
            "Gitega Province",
            "Karuzi Province",
            "Kayanza Province",
            "Kirundo Province",
            "Makamba Province",
            "Muramvya Province",
            "Muyinga Province",
            "Mwaro Province",
            "Ngozi Province",
            "Rumonge Province",
            "Rutana Province",
            "Ruyigi Province"
        ]
    },
    {
        "name": "Cambodia",
        "iso3": "KHM",
        "iso2": "KH",
        "phone_code": "855",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "states": [
            "Banteay Meanchey Province",
            "Battambang Province",
            "Kampong Cham Province",
            "Kampong Chhnang Province",
            "Kampong Speu Province",
            "Kampot Province",
            "Kandal Province",
            "Kep Province",
            "Koh Kong Province",
            "Krati� Province",
            "Mondulkiri Province",
            "Oddar Meanchey Province",
            "Pailin Province",
            "Phnom Penh",
            "Preah Vihear Province",
            "Prey Veng Province",
            "Pursat Province",
            "Ratanakiri Province",
            "Siem Reap Province",
            "Sihanoukville Province",
            "Stung Treng Province",
            "Svay Rieng Province",
            "Tak�o Province"
        ]
    },
    {
        "name": "Cameroon",
        "iso3": "CMR",
        "iso2": "CM",
        "phone_code": "237",
        "capital": "Yaounde",
        "currency": "XAF",
        "states": [
            "Adamawa",
            "Centre",
            "East",
            "Far North",
            "Littoral",
            "North",
            "Northwest",
            "South",
            "Southwest",
            "West"
        ]
    },
    {
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "phone_code": "1",
        "capital": "Ottawa",
        "currency": "CAD",
        "states": [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Northwest Territories",
            "Nova Scotia",
            "Nunavut",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon"
        ]
    },
    {
        "name": "Cape Verde",
        "iso3": "CPV",
        "iso2": "CV",
        "phone_code": "238",
        "capital": "Praia",
        "currency": "CVE",
        "states": [
            "Barlavento Islands",
            "Boa Vista",
            "Brava",
            "Maio Municipality",
            "Mosteiros",
            "Paul",
            "Porto Novo",
            "Praia",
            "Ribeira Brava Municipality",
            "Ribeira Grande",
            "Ribeira Grande de Santiago",
            "Sal",
            "Santa Catarina",
            "Santa Catarina do Fogo",
            "Santa Cruz",
            "S�o Domingos",
            "S�o Filipe",
            "S�o Louren�o dos �rg�os",
            "S�o Miguel",
            "S�o Vicente",
            "Sotavento Islands",
            "Tarrafal",
            "Tarrafal de S�o Nicolau"
        ]
    },
    {
        "name": "Cayman Islands",
        "iso3": "CYM",
        "iso2": "KY",
        "phone_code": "+1-345",
        "capital": "George Town",
        "currency": "KYD",
        "states": [
            "Barlavento Islands",
            "Boa Vista",
            "Brava",
            "Maio Municipality",
            "Mosteiros",
            "Paul",
            "Porto Novo",
            "Praia",
            "Ribeira Brava Municipality",
            "Ribeira Grande",
            "Ribeira Grande de Santiago",
            "Sal",
            "Santa Catarina",
            "Santa Catarina do Fogo",
            "Santa Cruz",
            "S�o Domingos",
            "S�o Filipe",
            "S�o Louren�o dos �rg�os",
            "S�o Miguel",
            "S�o Vicente",
            "Sotavento Islands",
            "Tarrafal",
            "Tarrafal de S�o Nicolau"
        ]
    },
    {
        "name": "Central African Republic",
        "iso3": "CAF",
        "iso2": "CF",
        "phone_code": "236",
        "capital": "Bangui",
        "currency": "XAF",
        "states": [
            "Bamingui-Bangoran Prefecture",
            "Bangui",
            "Basse-Kotto Prefecture",
            "Haut-Mbomou Prefecture",
            "Haute-Kotto Prefecture",
            "K�mo Prefecture",
            "Lobaye Prefecture",
            "Mamb�r�-Kad��",
            "Mbomou Prefecture",
            "Nana-Gr�bizi Economic Prefecture",
            "Nana-Mamb�r� Prefecture",
            "Ombella-M'Poko Prefecture",
            "Ouaka Prefecture",
            "Ouham Prefecture",
            "Ouham-Pend� Prefecture",
            "Sangha-Mba�r�",
            "Vakaga Prefecture"
        ]
    },
    {
        "name": "Chad",
        "iso3": "TCD",
        "iso2": "TD",
        "phone_code": "235",
        "capital": "N'Djamena",
        "currency": "XAF",
        "states": [
            "Bahr el Gazel",
            "Batha Region",
            "Borkou",
            "Ennedi Region",
            "Ennedi-Est",
            "Ennedi-Ouest",
            "Gu�ra Region",
            "Hadjer-Lamis",
            "Kanem Region",
            "Lac Region",
            "Logone Occidental Region",
            "Logone Oriental Region",
            "Mandoul Region",
            "Mayo-Kebbi Est Region",
            "Mayo-Kebbi Ouest Region",
            "Moyen-Chari Region",
            "N'Djamena",
            "Ouadda� Region",
            "Salamat Region",
            "Sila Region",
            "Tandjil� Region",
            "Tibesti Region",
            "Wadi Fira Region"
        ]
    },
    {
        "name": "Chile",
        "iso3": "CHL",
        "iso2": "CL",
        "phone_code": "56",
        "capital": "Santiago",
        "currency": "CLP",
        "states": [
            "Antofagasta Region",
            "Araucan�a Region",
            "Arica y Parinacota Region",
            "Atacama Region",
            "Ays�n Region",
            "�uble Region",
            "B�o B�o Region",
            "Coquimbo Region",
            "Los Lagos Region",
            "Los R�os Region",
            "Magellan and the Chilean Antarctic Region",
            "Maule Region",
            "O'Higgins",
            "Santiago Metropolitan Region",
            "Tarapac� Region",
            "Valpara�so"
        ]
    },
    {
        "name": "China",
        "iso3": "CHN",
        "iso2": "CN",
        "phone_code": "86",
        "capital": "Beijing",
        "currency": "CNY",
        "states": [
            "Anhui",
            "Beijing",
            "Chongqing",
            "Fujian",
            "Gansu",
            "Guangdong",
            "Guangxi Zhuang Autonomous Region",
            "Guizhou",
            "Hainan",
            "Hebei",
            "Heilongjiang",
            "Henan",
            "Hong Kong",
            "Hubei",
            "Hunan",
            "Inner Mongolia",
            "Jiangsu",
            "Jiangxi",
            "Jilin",
            "Keelung",
            "Liaoning",
            "Macau",
            "Ningxia Hui Autonomous Region",
            "Qinghai",
            "Shaanxi",
            "Shandong",
            "Shanghai",
            "Shanxi",
            "Sichuan",
            "Taiwan Province, People's Republic of China",
            "Tibet Autonomous Region",
            "Xinjiang",
            "Yunnan",
            "Zhejiang"
        ]
    },
    {
        "name": "Christmas Island",
        "iso3": "CXR",
        "iso2": "CX",
        "phone_code": "61",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "states": [
            "Anhui",
            "Beijing",
            "Chongqing",
            "Fujian",
            "Gansu",
            "Guangdong",
            "Guangxi Zhuang Autonomous Region",
            "Guizhou",
            "Hainan",
            "Hebei",
            "Heilongjiang",
            "Henan",
            "Hong Kong",
            "Hubei",
            "Hunan",
            "Inner Mongolia",
            "Jiangsu",
            "Jiangxi",
            "Jilin",
            "Keelung",
            "Liaoning",
            "Macau",
            "Ningxia Hui Autonomous Region",
            "Qinghai",
            "Shaanxi",
            "Shandong",
            "Shanghai",
            "Shanxi",
            "Sichuan",
            "Taiwan Province, People's Republic of China",
            "Tibet Autonomous Region",
            "Xinjiang",
            "Yunnan",
            "Zhejiang"
        ]
    },
    {
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "iso2": "CC",
        "phone_code": "61",
        "capital": "West Island",
        "currency": "AUD",
        "states": [
            "Anhui",
            "Beijing",
            "Chongqing",
            "Fujian",
            "Gansu",
            "Guangdong",
            "Guangxi Zhuang Autonomous Region",
            "Guizhou",
            "Hainan",
            "Hebei",
            "Heilongjiang",
            "Henan",
            "Hong Kong",
            "Hubei",
            "Hunan",
            "Inner Mongolia",
            "Jiangsu",
            "Jiangxi",
            "Jilin",
            "Keelung",
            "Liaoning",
            "Macau",
            "Ningxia Hui Autonomous Region",
            "Qinghai",
            "Shaanxi",
            "Shandong",
            "Shanghai",
            "Shanxi",
            "Sichuan",
            "Taiwan Province, People's Republic of China",
            "Tibet Autonomous Region",
            "Xinjiang",
            "Yunnan",
            "Zhejiang"
        ]
    },
    {
        "name": "Colombia",
        "iso3": "COL",
        "iso2": "CO",
        "phone_code": "57",
        "capital": "Bogota",
        "currency": "COP",
        "states": [
            "Amazonas Department",
            "Antioquia Department",
            "Arauca Department",
            "Archipelago of Saint Andr�ws, Providence and Saint Catalina",
            "Atl�ntico Department",
            "Bol�var Department",
            "Boyac� Department",
            "Caldas Department",
            "Caquet� Department",
            "Casanare Department",
            "Cauca Department",
            "C�rdoba Department",
            "Cesar Department",
            "Choc� Department",
            "Cundinamarca Department",
            "Guain�a Department",
            "Guaviare Department",
            "La Guajira Department",
            "Magdalena Department",
            "Meta",
            "Nari�o Department",
            "Norte de Santander Department",
            "Putumayo Department",
            "Quind�o Department",
            "Risaralda Department",
            "Santander Department",
            "Sucre Department",
            "Tolima Department",
            "Valle del Cauca Department",
            "Vaup�s Department",
            "Vichada Department"
        ]
    },
    {
        "name": "Comoros",
        "iso3": "COM",
        "iso2": "KM",
        "phone_code": "269",
        "capital": "Moroni",
        "currency": "KMF",
        "states": [
            "Anjouan",
            "Grande Comore",
            "Moh�li"
        ]
    },
    {
        "name": "Congo",
        "iso3": "COG",
        "iso2": "CG",
        "phone_code": "242",
        "capital": "Brazzaville",
        "currency": "XAF",
        "states": [
            "Bouenza Department",
            "Brazzaville",
            "Cuvette Department",
            "Cuvette-Ouest Department",
            "Kouilou Department",
            "L�koumou Department",
            "Likouala Department",
            "Niari Department",
            "Plateaux Department",
            "Pointe-Noire",
            "Pool Department",
            "Sangha Department"
        ]
    },
    {
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "iso2": "CD",
        "phone_code": "243",
        "capital": "Kinshasa",
        "currency": "CDF",
        "states": [
            "�quateur",
            "Bandundu Province",
            "Bas-Congo province",
            "Bas-Uele",
            "Haut-Katanga Province",
            "Haut-Lomami District",
            "Haut-Uele",
            "Ituri Interim Administration",
            "Kasa� District",
            "Kasa�-Occidental",
            "Kasa�-Oriental",
            "Katanga Province",
            "Kinshasa",
            "Kwango District",
            "Kwilu District",
            "Lomami Province",
            "Mai-Ndombe Province",
            "Maniema",
            "Mongala District",
            "Nord-Ubangi District",
            "North Kivu",
            "Orientale Province",
            "Sankuru District",
            "South Kivu",
            "Sud-Ubangi",
            "Tanganyika Province",
            "Tshopo District",
            "Tshuapa District"
        ]
    },
    {
        "name": "Cook Islands",
        "iso3": "COK",
        "iso2": "CK",
        "phone_code": "682",
        "capital": "Avarua",
        "currency": "NZD",
        "states": [
            "�quateur",
            "Bandundu Province",
            "Bas-Congo province",
            "Bas-Uele",
            "Haut-Katanga Province",
            "Haut-Lomami District",
            "Haut-Uele",
            "Ituri Interim Administration",
            "Kasa� District",
            "Kasa�-Occidental",
            "Kasa�-Oriental",
            "Katanga Province",
            "Kinshasa",
            "Kwango District",
            "Kwilu District",
            "Lomami Province",
            "Mai-Ndombe Province",
            "Maniema",
            "Mongala District",
            "Nord-Ubangi District",
            "North Kivu",
            "Orientale Province",
            "Sankuru District",
            "South Kivu",
            "Sud-Ubangi",
            "Tanganyika Province",
            "Tshopo District",
            "Tshuapa District"
        ]
    },
    {
        "name": "Costa Rica",
        "iso3": "CRI",
        "iso2": "CR",
        "phone_code": "506",
        "capital": "San Jose",
        "currency": "CRC",
        "states": [
            "Alajuela Province",
            "Guanacaste Province",
            "Heredia Province",
            "Lim�n Province",
            "Provincia de Cartago",
            "Puntarenas Province",
            "San Jos� Province"
        ]
    },
    {
        "name": "Cote D'Ivoire (Ivory Coast)",
        "iso3": "CIV",
        "iso2": "CI",
        "phone_code": "225",
        "capital": "Yamoussoukro",
        "currency": "XOF",
        "states": [
            "Abidjan",
            "Agn�by",
            "Bafing Region",
            "Bas-Sassandra District",
            "Bas-Sassandra Region",
            "Como� District",
            "Dengu�l� District",
            "Dengu�l� Region",
            "Dix-Huit Montagnes",
            "Fromager",
            "G�h-Djiboua District",
            "Haut-Sassandra",
            "Lacs District",
            "Lacs Region",
            "Lagunes District",
            "Lagunes region",
            "Marahou� Region",
            "Montagnes District",
            "Moyen-Cavally",
            "Moyen-Como�",
            "N'zi-Como�",
            "Sassandra-Marahou� District",
            "Savanes Region",
            "Sud-Bandama",
            "Sud-Como�",
            "Vall�e du Bandama District",
            "Vall�e du Bandama Region",
            "Woroba District",
            "Worodougou",
            "Yamoussoukro",
            "Zanzan Region"
        ]
    },
    {
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "iso2": "HR",
        "phone_code": "385",
        "capital": "Zagreb",
        "currency": "HRK",
        "states": [
            "�ibenik-Knin County",
            "Bjelovar-Bilogora County",
            "Brod-Posavina County",
            "Dubrovnik-Neretva County",
            "Istria County",
            "Koprivnica-Kri�evci County",
            "Krapina-Zagorje County",
            "Lika-Senj County",
            "Me?imurje County",
            "Osijek-Baranja County",
            "Po�ega-Slavonia County",
            "Primorje-Gorski Kotar County",
            "Sisak-Moslavina County",
            "Split-Dalmatia County",
            "Vara�din County",
            "Virovitica-Podravina County",
            "Vukovar-Syrmia County",
            "Zadar County",
            "Zagreb",
            "Zagreb County"
        ]
    },
    {
        "name": "Cuba",
        "iso3": "CUB",
        "iso2": "CU",
        "phone_code": "53",
        "capital": "Havana",
        "currency": "CUP",
        "states": [
            "Artemisa Province",
            "Camag�ey Province",
            "Ciego de �vila Province",
            "Cienfuegos Province",
            "Granma Province",
            "Guant�namo Province",
            "Havana Province",
            "Holgu�n Province",
            "Isla de la Juventud",
            "Las Tunas Province",
            "Matanzas Province",
            "Mayabeque Province",
            "Pinar del R�o Province",
            "Sancti Sp�ritus Province",
            "Santiago de Cuba Province",
            "Villa Clara Province"
        ]
    },
    {
        "name": "Cyprus",
        "iso3": "CYP",
        "iso2": "CY",
        "phone_code": "357",
        "capital": "Nicosia",
        "currency": "EUR",
        "states": [
            "Famagusta District",
            "Kyrenia District",
            "Larnaca District",
            "Limassol District",
            "Nicosia District",
            "Paphos District"
        ]
    },
    {
        "name": "Czech Republic",
        "iso3": "CZE",
        "iso2": "CZ",
        "phone_code": "420",
        "capital": "Prague",
        "currency": "CZK",
        "states": [
            "�st� nad Labem District",
            "�st� nad Labem Region",
            "�st� nad Orlic� District",
            "�umperk District",
            "�?�r nad S�zavou District",
            "?esk� L�pa District",
            "?esk� Bud?jovice District",
            "?esk� Krumlov District",
            "B?eclav District",
            "Bene�ov District",
            "Beroun District",
            "Blansko District",
            "Brno-City District",
            "Brno-Country District",
            "Brunt�l District",
            "Central Bohemian Region",
            "Cheb District",
            "Chomutov District",
            "Chrudim District",
            "D??�n District",
            "Doma�lice District",
            "Fr�dek-M�stek District",
            "Havl�?k?v Brod District",
            "Hodon�n District",
            "Horn� Po?ernice",
            "Hradec Kr�lov� District",
            "Hradec Kr�lov� Region",
            "Jablonec nad Nisou District",
            "Jesen�k District",
            "Ji?�n District",
            "Jihlava District",
            "Jind?ich?v Hradec District",
            "Karlovy Vary District",
            "Karlovy Vary Region",
            "Karvin� District",
            "Kladno District",
            "Klatovy District",
            "Kol�n District",
            "Krom??� District",
            "Liberec District",
            "Liberec Region",
            "Litom??ice District",
            "Louny District",
            "M?ln�k District",
            "Mlad� Boleslav District",
            "Moravian-Silesian Region",
            "Most District",
            "N�chod District",
            "Nov� Ji?�n District",
            "Nymburk District",
            "Olomouc District",
            "Olomouc Region",
            "Opava District",
            "Ostrava-City District",
            "Pardubice District",
            "Pardubice Region",
            "P�sek District",
            "P?�bram District",
            "P?erov District",
            "Pelh?imov District",
            "Plze? Region",
            "Plze?-City District",
            "Plze?-North District",
            "Plze?-South District",
            "Prachatice District",
            "Prague",
            "Prague 1",
            "Prague 10",
            "Prague 11",
            "Prague 12",
            "Prague 13",
            "Prague 14",
            "Prague 15",
            "Prague 16",
            "Prague 2",
            "Prague 21",
            "Prague 3",
            "Prague 4",
            "Prague 5",
            "Prague 6",
            "Prague 7",
            "Prague 8",
            "Prague 9",
            "Prague-East District",
            "Prague-West District",
            "Prost?jov District",
            "Rakovn�k District",
            "Rokycany District",
            "Rychnov nad Kn?�nou District",
            "Semily District",
            "Sokolov District",
            "South Bohemian Region",
            "South Moravian Region",
            "Strakonice District",
            "Svitavy District",
            "Tachov District",
            "T�bor District",
            "T?eb�? District",
            "Teplice District",
            "Trutnov District",
            "Uhersk� Hradi�t? District",
            "Vset�n District",
            "Vy�kov District",
            "Vyso?ina Region",
            "Zl�n District",
            "Zl�n Region",
            "Znojmo District"
        ]
    },
    {
        "name": "Denmark",
        "iso3": "DNK",
        "iso2": "DK",
        "phone_code": "45",
        "capital": "Copenhagen",
        "currency": "DKK",
        "states": [
            "Capital Region of Denmark",
            "Central Denmark Region",
            "North Denmark Region",
            "Region of Southern Denmark",
            "Region Zealand"
        ]
    },
    {
        "name": "Djibouti",
        "iso3": "DJI",
        "iso2": "DJ",
        "phone_code": "253",
        "capital": "Djibouti",
        "currency": "DJF",
        "states": [
            "Ali Sabieh Region",
            "Arta Region",
            "Dikhil Region",
            "Djibouti",
            "Obock Region",
            "Tadjourah Region"
        ]
    },
    {
        "name": "Dominica",
        "iso3": "DMA",
        "iso2": "DM",
        "phone_code": "+1-767",
        "capital": "Roseau",
        "currency": "XCD",
        "states": [
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint John Parish",
            "Saint Joseph Parish",
            "Saint Luke Parish",
            "Saint Mark Parish",
            "Saint Patrick Parish",
            "Saint Paul Parish",
            "Saint Peter Parish"
        ]
    },
    {
        "name": "Dominican Republic",
        "iso3": "DOM",
        "iso2": "DO",
        "phone_code": "+1-809 and 1-829",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "states": [
            "Azua Province",
            "Baoruco Province",
            "Barahona Province",
            "Dajab�n Province",
            "Distrito Nacional",
            "Duarte Province",
            "El Seibo Province",
            "Espaillat Province",
            "Hato Mayor Province",
            "Hermanas Mirabal Province",
            "Independencia",
            "La Altagracia Province",
            "La Romana Province",
            "La Vega Province",
            "Mar�a Trinidad S�nchez Province",
            "Monse�or Nouel Province",
            "Monte Cristi Province",
            "Monte Plata Province",
            "Pedernales Province",
            "Peravia Province",
            "Puerto Plata Province",
            "Saman� Province",
            "San Crist�bal Province",
            "San Jos� de Ocoa Province",
            "San Juan Province",
            "San Pedro de Macor�s",
            "Santiago Province",
            "Santiago Rodr�guez Province",
            "Santo Domingo Province",
            "S�nchez Ram�rez Province",
            "Valverde Province"
        ]
    },
    {
        "name": "East Timor",
        "iso3": "TLS",
        "iso2": "TL",
        "phone_code": "670",
        "capital": "Dili",
        "currency": "USD",
        "states": [
            "Aileu municipality",
            "Ainaro Municipality",
            "Baucau Municipality",
            "Bobonaro Municipality",
            "Cova Lima Municipality",
            "Dili municipality",
            "Ermera District",
            "Laut�m Municipality",
            "Liqui�� Municipality",
            "Manatuto District",
            "Manufahi Municipality",
            "Viqueque Municipality"
        ]
    },
    {
        "name": "Ecuador",
        "iso3": "ECU",
        "iso2": "EC",
        "phone_code": "593",
        "capital": "Quito",
        "currency": "USD",
        "states": [
            "Azuay Province",
            "Bol�var Province",
            "Ca�ar Province",
            "Carchi Province",
            "Chimborazo Province",
            "Cotopaxi Province",
            "El Oro Province",
            "Esmeraldas",
            "Gal�pagos Province",
            "Guayas Province",
            "Imbabura Province",
            "Los R�os Province",
            "Manab� Province",
            "Morona-Santiago Province",
            "Napo Province",
            "Orellana Province",
            "Pastaza Province",
            "Pichincha Province",
            "Santa Elena Province",
            "Santo Domingo de los Ts�chilas Province",
            "Sucumb�os Province",
            "Tungurahua Province",
            "Zamora-Chinchipe Province"
        ]
    },
    {
        "name": "Egypt",
        "iso3": "EGY",
        "iso2": "EG",
        "phone_code": "20",
        "capital": "Cairo",
        "currency": "EGP",
        "states": [
            "Alexandria Governorate",
            "Aswan Governorate",
            "Asyut Governorate",
            "Beheira Governorate",
            "Beni Suef Governorate",
            "Cairo Governorate",
            "Dakahlia Governorate",
            "Damietta Governorate",
            "Faiyum Governorate",
            "Gharbia Governorate",
            "Giza Governorate",
            "Ismailia Governorate",
            "Kafr el-Sheikh Governorate",
            "Luxor Governorate",
            "Matrouh Governorate",
            "Minya Governorate",
            "Monufia Governorate",
            "New Valley Governorate",
            "North Sinai Governorate",
            "Port Said Governorate",
            "Qalyubia Governorate",
            "Qena Governorate",
            "Red Sea Governorate",
            "Sohag Governorate",
            "South Sinai Governorate",
            "Suez Governorate"
        ]
    },
    {
        "name": "El Salvador",
        "iso3": "SLV",
        "iso2": "SV",
        "phone_code": "503",
        "capital": "San Salvador",
        "currency": "USD",
        "states": [
            "Ahuachap�n Department",
            "Caba�as Department",
            "Chalatenango Department",
            "Cuscatl�n Department",
            "La Libertad Department",
            "La Paz Department",
            "La Uni�n Department",
            "Moraz�n Department",
            "San Miguel Department",
            "San Salvador Department",
            "San Vicente Department",
            "Santa Ana Department",
            "Sonsonate Department",
            "Usulut�n Department"
        ]
    },
    {
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "iso2": "GQ",
        "phone_code": "240",
        "capital": "Malabo",
        "currency": "XAF",
        "states": [
            "Annob�n Province",
            "Bioko Norte Province",
            "Bioko Sur Province",
            "Centro Sur Province",
            "Insular Region",
            "Ki�-Ntem Province",
            "Litoral Province",
            "R�o Muni",
            "Wele-Nzas Province"
        ]
    },
    {
        "name": "Eritrea",
        "iso3": "ERI",
        "iso2": "ER",
        "phone_code": "291",
        "capital": "Asmara",
        "currency": "ERN",
        "states": [
            "Anseba Region",
            "Debub Region",
            "Gash-Barka Region",
            "Maekel Region",
            "Northern Red Sea Region",
            "Southern Red Sea Region"
        ]
    },
    {
        "name": "Estonia",
        "iso3": "EST",
        "iso2": "EE",
        "phone_code": "372",
        "capital": "Tallinn",
        "currency": "EUR",
        "states": [
            "Harju County",
            "Hiiu County",
            "Ida-Viru County",
            "J�rva County",
            "J�geva County",
            "L��ne County",
            "L��ne-Viru County",
            "P�rnu County",
            "P�lva County",
            "Rapla County",
            "Saare County",
            "Tartu County",
            "Valga County",
            "V�ru County",
            "Viljandi County"
        ]
    },
    {
        "name": "Ethiopia",
        "iso3": "ETH",
        "iso2": "ET",
        "phone_code": "251",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "states": [
            "Addis Ababa",
            "Afar Region",
            "Amhara Region",
            "Benishangul-Gumuz Region",
            "Dire Dawa",
            "Gambela Region",
            "Harari Region",
            "Oromia Region",
            "Somali Region",
            "Southern Nations, Nationalities, and Peoples' Region",
            "Tigray Region"
        ]
    },
    {
        "name": "Falkland Islands",
        "iso3": "FLK",
        "iso2": "FK",
        "phone_code": "500",
        "capital": "Stanley",
        "currency": "FKP",
        "states": [
            "Addis Ababa",
            "Afar Region",
            "Amhara Region",
            "Benishangul-Gumuz Region",
            "Dire Dawa",
            "Gambela Region",
            "Harari Region",
            "Oromia Region",
            "Somali Region",
            "Southern Nations, Nationalities, and Peoples' Region",
            "Tigray Region"
        ]
    },
    {
        "name": "Faroe Islands",
        "iso3": "FRO",
        "iso2": "FO",
        "phone_code": "298",
        "capital": "Torshavn",
        "currency": "DKK",
        "states": [
            "Addis Ababa",
            "Afar Region",
            "Amhara Region",
            "Benishangul-Gumuz Region",
            "Dire Dawa",
            "Gambela Region",
            "Harari Region",
            "Oromia Region",
            "Somali Region",
            "Southern Nations, Nationalities, and Peoples' Region",
            "Tigray Region"
        ]
    },
    {
        "name": "Fiji Islands",
        "iso3": "FJI",
        "iso2": "FJ",
        "phone_code": "679",
        "capital": "Suva",
        "currency": "FJD",
        "states": [
            "Ba",
            "Bua",
            "Cakaudrove",
            "Central Division",
            "Eastern Division",
            "Kadavu",
            "Lau",
            "Lomaiviti",
            "Macuata",
            "Nadroga-Navosa",
            "Naitasiri",
            "Namosi",
            "Northern Division",
            "Ra",
            "Rewa",
            "Rotuma",
            "Serua",
            "Tailevu",
            "Western Division"
        ]
    },
    {
        "name": "Finland",
        "iso3": "FIN",
        "iso2": "FI",
        "phone_code": "358",
        "capital": "Helsinki",
        "currency": "EUR",
        "states": [
            "�land Islands",
            "Central Finland",
            "Central Ostrobothnia",
            "Eastern Finland Province",
            "Finland Proper",
            "Kainuu",
            "Kymenlaakso",
            "Lapland",
            "North Karelia",
            "Northern Ostrobothnia",
            "Northern Savonia",
            "Ostrobothnia",
            "Oulu Province",
            "P�ij�nne Tavastia",
            "Pirkanmaa",
            "Satakunta",
            "South Karelia",
            "Southern Ostrobothnia",
            "Southern Savonia",
            "Tavastia Proper",
            "Uusimaa"
        ]
    },
    {
        "name": "France",
        "iso3": "FRA",
        "iso2": "FR",
        "phone_code": "33",
        "capital": "Paris",
        "currency": "EUR",
        "states": [
            "Alo",
            "Alsace",
            "Aquitaine",
            "Auvergne",
            "Auvergne-Rh�ne-Alpes",
            "�le-de-France",
            "Bourgogne-Franche-Comt�",
            "Brittany",
            "Burgundy",
            "Centre-Val de Loire",
            "Champagne-Ardenne",
            "Corsica",
            "Franche-Comt�",
            "French Guiana",
            "French Polynesia",
            "Grand Est",
            "Guadeloupe",
            "Hauts-de-France",
            "Languedoc-Roussillon",
            "Limousin",
            "Lorraine",
            "Lower Normandy",
            "Martinique",
            "Mayotte",
            "Nord-Pas-de-Calais",
            "Normandy",
            "Nouvelle-Aquitaine",
            "Occitania",
            "Paris",
            "Pays de la Loire",
            "Picardy",
            "Poitou-Charentes",
            "Provence-Alpes-C�te d'Azur",
            "R�union",
            "Rh�ne-Alpes",
            "Saint Barth�lemy",
            "Saint Martin",
            "Saint Pierre and Miquelon",
            "Sigave",
            "Upper Normandy",
            "Uvea",
            "Wallis and Futuna"
        ]
    },
    {
        "name": "French Guiana",
        "iso3": "GUF",
        "iso2": "GF",
        "phone_code": "594",
        "capital": "Cayenne",
        "currency": "EUR",
        "states": [
            "Alo",
            "Alsace",
            "Aquitaine",
            "Auvergne",
            "Auvergne-Rh�ne-Alpes",
            "�le-de-France",
            "Bourgogne-Franche-Comt�",
            "Brittany",
            "Burgundy",
            "Centre-Val de Loire",
            "Champagne-Ardenne",
            "Corsica",
            "Franche-Comt�",
            "French Guiana",
            "French Polynesia",
            "Grand Est",
            "Guadeloupe",
            "Hauts-de-France",
            "Languedoc-Roussillon",
            "Limousin",
            "Lorraine",
            "Lower Normandy",
            "Martinique",
            "Mayotte",
            "Nord-Pas-de-Calais",
            "Normandy",
            "Nouvelle-Aquitaine",
            "Occitania",
            "Paris",
            "Pays de la Loire",
            "Picardy",
            "Poitou-Charentes",
            "Provence-Alpes-C�te d'Azur",
            "R�union",
            "Rh�ne-Alpes",
            "Saint Barth�lemy",
            "Saint Martin",
            "Saint Pierre and Miquelon",
            "Sigave",
            "Upper Normandy",
            "Uvea",
            "Wallis and Futuna"
        ]
    },
    {
        "name": "French Polynesia",
        "iso3": "PYF",
        "iso2": "PF",
        "phone_code": "689",
        "capital": "Papeete",
        "currency": "XPF",
        "states": [
            "Alo",
            "Alsace",
            "Aquitaine",
            "Auvergne",
            "Auvergne-Rh�ne-Alpes",
            "�le-de-France",
            "Bourgogne-Franche-Comt�",
            "Brittany",
            "Burgundy",
            "Centre-Val de Loire",
            "Champagne-Ardenne",
            "Corsica",
            "Franche-Comt�",
            "French Guiana",
            "French Polynesia",
            "Grand Est",
            "Guadeloupe",
            "Hauts-de-France",
            "Languedoc-Roussillon",
            "Limousin",
            "Lorraine",
            "Lower Normandy",
            "Martinique",
            "Mayotte",
            "Nord-Pas-de-Calais",
            "Normandy",
            "Nouvelle-Aquitaine",
            "Occitania",
            "Paris",
            "Pays de la Loire",
            "Picardy",
            "Poitou-Charentes",
            "Provence-Alpes-C�te d'Azur",
            "R�union",
            "Rh�ne-Alpes",
            "Saint Barth�lemy",
            "Saint Martin",
            "Saint Pierre and Miquelon",
            "Sigave",
            "Upper Normandy",
            "Uvea",
            "Wallis and Futuna"
        ]
    },
    {
        "name": "French Southern Territories",
        "iso3": "ATF",
        "iso2": "TF",
        "phone_code": "",
        "capital": "Port-aux-Francais",
        "currency": "EUR",
        "states": [
            "Alo",
            "Alsace",
            "Aquitaine",
            "Auvergne",
            "Auvergne-Rh�ne-Alpes",
            "�le-de-France",
            "Bourgogne-Franche-Comt�",
            "Brittany",
            "Burgundy",
            "Centre-Val de Loire",
            "Champagne-Ardenne",
            "Corsica",
            "Franche-Comt�",
            "French Guiana",
            "French Polynesia",
            "Grand Est",
            "Guadeloupe",
            "Hauts-de-France",
            "Languedoc-Roussillon",
            "Limousin",
            "Lorraine",
            "Lower Normandy",
            "Martinique",
            "Mayotte",
            "Nord-Pas-de-Calais",
            "Normandy",
            "Nouvelle-Aquitaine",
            "Occitania",
            "Paris",
            "Pays de la Loire",
            "Picardy",
            "Poitou-Charentes",
            "Provence-Alpes-C�te d'Azur",
            "R�union",
            "Rh�ne-Alpes",
            "Saint Barth�lemy",
            "Saint Martin",
            "Saint Pierre and Miquelon",
            "Sigave",
            "Upper Normandy",
            "Uvea",
            "Wallis and Futuna"
        ]
    },
    {
        "name": "Gabon",
        "iso3": "GAB",
        "iso2": "GA",
        "phone_code": "241",
        "capital": "Libreville",
        "currency": "XAF",
        "states": [
            "Estuaire Province",
            "Haut-Ogoou� Province",
            "Moyen-Ogoou� Province",
            "Ngouni� Province",
            "Nyanga Province",
            "Ogoou�-Ivindo Province",
            "Ogoou�-Lolo Province",
            "Ogoou�-Maritime Province",
            "Woleu-Ntem Province"
        ]
    },
    {
        "name": "Gambia The",
        "iso3": "GMB",
        "iso2": "GM",
        "phone_code": "220",
        "capital": "Banjul",
        "currency": "GMD",
        "states": [
            "Banjul",
            "Central River Division",
            "Lower River Division",
            "North Bank Division",
            "Upper River Division",
            "West Coast Division"
        ]
    },
    {
        "name": "Georgia",
        "iso3": "GEO",
        "iso2": "GE",
        "phone_code": "995",
        "capital": "Tbilisi",
        "currency": "GEL",
        "states": [
            "Adjara",
            "Autonomous Republic of Abkhazia",
            "Guria",
            "Imereti",
            "Kakheti",
            "Khelvachauri Municipality",
            "Kvemo Kartli",
            "Mtskheta-Mtianeti",
            "Racha-Lechkhumi and Kvemo Svaneti",
            "Samegrelo-Zemo Svaneti",
            "Samtskhe-Javakheti",
            "Senaki Municipality",
            "Shida Kartli",
            "Tbilisi"
        ]
    },
    {
        "name": "Germany",
        "iso3": "DEU",
        "iso2": "DE",
        "phone_code": "49",
        "capital": "Berlin",
        "currency": "EUR",
        "states": [
            "Baden-W�rttemberg",
            "Bavaria",
            "Berlin",
            "Brandenburg",
            "Bremen",
            "Hamburg",
            "Hesse",
            "Lower Saxony",
            "Mecklenburg-Vorpommern",
            "Neufahrn in Niederbayern",
            "North Rhine-Westphalia",
            "Rhineland-Palatinate",
            "Saarland",
            "Saxony",
            "Saxony-Anhalt",
            "Schleswig-Holstein",
            "Thuringia"
        ]
    },
    {
        "name": "Ghana",
        "iso3": "GHA",
        "iso2": "GH",
        "phone_code": "233",
        "capital": "Accra",
        "currency": "GHS",
        "states": [
            "Ashanti Region",
            "Brong-Ahafo Region",
            "Central Region",
            "Eastern Region",
            "Greater Accra Region",
            "Northern Region",
            "Upper East Region",
            "Upper West Region",
            "Volta Region",
            "Western Region"
        ]
    },
    {
        "name": "Gibraltar",
        "iso3": "GIB",
        "iso2": "GI",
        "phone_code": "350",
        "capital": "Gibraltar",
        "currency": "GIP",
        "states": [
            "Ashanti Region",
            "Brong-Ahafo Region",
            "Central Region",
            "Eastern Region",
            "Greater Accra Region",
            "Northern Region",
            "Upper East Region",
            "Upper West Region",
            "Volta Region",
            "Western Region"
        ]
    },
    {
        "name": "Greece",
        "iso3": "GRC",
        "iso2": "GR",
        "phone_code": "30",
        "capital": "Athens",
        "currency": "EUR",
        "states": [
            "Achaea Regional Unit",
            "Aetolia-Acarnania Regional Unit",
            "Arcadia Prefecture",
            "Argolis Regional Unit",
            "Attica Region",
            "Boeotia Regional Unit",
            "Central Greece Region",
            "Central Macedonia",
            "Chania Regional Unit",
            "Corfu Prefecture",
            "Corinthia Regional Unit",
            "Crete Region",
            "Drama Regional Unit",
            "East Attica Regional Unit",
            "East Macedonia and Thrace",
            "Epirus Region",
            "Euboea",
            "Grevena Prefecture",
            "Imathia Regional Unit",
            "Ioannina Regional Unit",
            "Ionian Islands Region",
            "Karditsa Regional Unit",
            "Kastoria Regional Unit",
            "Kefalonia Prefecture",
            "Kilkis Regional Unit",
            "Kozani Prefecture",
            "Laconia",
            "Larissa Prefecture",
            "Lefkada Regional Unit",
            "Pella Regional Unit",
            "Peloponnese Region",
            "Phthiotis Prefecture",
            "Preveza Prefecture",
            "Serres Prefecture",
            "South Aegean",
            "Thessaloniki Regional Unit",
            "West Greece Region",
            "West Macedonia Region"
        ]
    },
    {
        "name": "Greenland",
        "iso3": "GRL",
        "iso2": "GL",
        "phone_code": "299",
        "capital": "Nuuk",
        "currency": "DKK",
        "states": [
            "Achaea Regional Unit",
            "Aetolia-Acarnania Regional Unit",
            "Arcadia Prefecture",
            "Argolis Regional Unit",
            "Attica Region",
            "Boeotia Regional Unit",
            "Central Greece Region",
            "Central Macedonia",
            "Chania Regional Unit",
            "Corfu Prefecture",
            "Corinthia Regional Unit",
            "Crete Region",
            "Drama Regional Unit",
            "East Attica Regional Unit",
            "East Macedonia and Thrace",
            "Epirus Region",
            "Euboea",
            "Grevena Prefecture",
            "Imathia Regional Unit",
            "Ioannina Regional Unit",
            "Ionian Islands Region",
            "Karditsa Regional Unit",
            "Kastoria Regional Unit",
            "Kefalonia Prefecture",
            "Kilkis Regional Unit",
            "Kozani Prefecture",
            "Laconia",
            "Larissa Prefecture",
            "Lefkada Regional Unit",
            "Pella Regional Unit",
            "Peloponnese Region",
            "Phthiotis Prefecture",
            "Preveza Prefecture",
            "Serres Prefecture",
            "South Aegean",
            "Thessaloniki Regional Unit",
            "West Greece Region",
            "West Macedonia Region"
        ]
    },
    {
        "name": "Grenada",
        "iso3": "GRD",
        "iso2": "GD",
        "phone_code": "+1-473",
        "capital": "St. George's",
        "currency": "XCD",
        "states": [
            "Carriacou and Petite Martinique",
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint John Parish",
            "Saint Mark Parish",
            "Saint Patrick Parish"
        ]
    },
    {
        "name": "Guadeloupe",
        "iso3": "GLP",
        "iso2": "GP",
        "phone_code": "590",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "states": [
            "Carriacou and Petite Martinique",
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint John Parish",
            "Saint Mark Parish",
            "Saint Patrick Parish"
        ]
    },
    {
        "name": "Guam",
        "iso3": "GUM",
        "iso2": "GU",
        "phone_code": "+1-671",
        "capital": "Hagatna",
        "currency": "USD",
        "states": [
            "Carriacou and Petite Martinique",
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint John Parish",
            "Saint Mark Parish",
            "Saint Patrick Parish"
        ]
    },
    {
        "name": "Guatemala",
        "iso3": "GTM",
        "iso2": "GT",
        "phone_code": "502",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "states": [
            "Alta Verapaz Department",
            "Baja Verapaz Department",
            "Chimaltenango Department",
            "Chiquimula Department",
            "El Progreso Department",
            "Escuintla Department",
            "Guatemala Department",
            "Huehuetenango Department",
            "Izabal Department",
            "Jalapa Department",
            "Jutiapa Department",
            "Pet�n Department",
            "Quetzaltenango Department",
            "Quich� Department",
            "Retalhuleu Department",
            "Sacatep�quez Department",
            "San Marcos Department",
            "Santa Rosa Department",
            "Solol� Department",
            "Suchitep�quez Department",
            "Totonicap�n Department"
        ]
    },
    {
        "name": "Guernsey and Alderney",
        "iso3": "GGY",
        "iso2": "GG",
        "phone_code": "+44-1481",
        "capital": "St Peter Port",
        "currency": "GBP",
        "states": [
            "Alta Verapaz Department",
            "Baja Verapaz Department",
            "Chimaltenango Department",
            "Chiquimula Department",
            "El Progreso Department",
            "Escuintla Department",
            "Guatemala Department",
            "Huehuetenango Department",
            "Izabal Department",
            "Jalapa Department",
            "Jutiapa Department",
            "Pet�n Department",
            "Quetzaltenango Department",
            "Quich� Department",
            "Retalhuleu Department",
            "Sacatep�quez Department",
            "San Marcos Department",
            "Santa Rosa Department",
            "Solol� Department",
            "Suchitep�quez Department",
            "Totonicap�n Department"
        ]
    },
    {
        "name": "Guinea",
        "iso3": "GIN",
        "iso2": "GN",
        "phone_code": "224",
        "capital": "Conakry",
        "currency": "GNF",
        "states": [
            "Beyla Prefecture",
            "Boffa Prefecture",
            "Bok� Prefecture",
            "Bok� Region",
            "Conakry",
            "Coyah Prefecture",
            "Dabola Prefecture",
            "Dalaba Prefecture",
            "Dinguiraye Prefecture",
            "Dubr�ka Prefecture",
            "Faranah Prefecture",
            "For�cariah Prefecture",
            "Fria Prefecture",
            "Gaoual Prefecture",
            "Gu�ck�dou Prefecture",
            "Kankan Prefecture",
            "Kankan Region",
            "K�rouan� Prefecture",
            "Kindia Prefecture",
            "Kindia Region",
            "Kissidougou Prefecture",
            "Koubia Prefecture",
            "Koundara Prefecture",
            "Kouroussa Prefecture",
            "Lab� Prefecture",
            "Lab� Region",
            "L�louma Prefecture",
            "Lola Prefecture",
            "Macenta Prefecture",
            "Mali Prefecture",
            "Mamou Prefecture",
            "Mamou Region",
            "Mandiana Prefecture",
            "Nz�r�kor� Prefecture",
            "Nz�r�kor� Region",
            "Pita Prefecture",
            "Siguiri Prefecture",
            "T�lim�l� Prefecture",
            "Tougu� Prefecture",
            "Yomou Prefecture"
        ]
    },
    {
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "iso2": "GW",
        "phone_code": "245",
        "capital": "Bissau",
        "currency": "XOF",
        "states": [
            "Bafat�",
            "Biombo Region",
            "Bolama Region",
            "Cacheu Region",
            "Gab� Region",
            "Leste Province",
            "Norte Province",
            "Oio Region",
            "Quinara Region",
            "Sul Province",
            "Tombali Region"
        ]
    },
    {
        "name": "Guyana",
        "iso3": "GUY",
        "iso2": "GY",
        "phone_code": "592",
        "capital": "Georgetown",
        "currency": "GYD",
        "states": [
            "Barima-Waini",
            "Cuyuni-Mazaruni",
            "Demerara-Mahaica",
            "East Berbice-Corentyne",
            "Essequibo Islands-West Demerara",
            "Mahaica-Berbice",
            "Pomeroon-Supenaam",
            "Potaro-Siparuni",
            "Upper Demerara-Berbice",
            "Upper Takutu-Upper Essequibo"
        ]
    },
    {
        "name": "Haiti",
        "iso3": "HTI",
        "iso2": "HT",
        "phone_code": "509",
        "capital": "Port-au-Prince",
        "currency": "HTG",
        "states": [
            "Artibonite",
            "Centre",
            "Grand'Anse",
            "Nippes",
            "Nord",
            "Nord-Est",
            "Nord-Ouest",
            "Ouest",
            "Sud",
            "Sud-Est"
        ]
    },
    {
        "name": "Heard and McDonald Islands",
        "iso3": "HMD",
        "iso2": "HM",
        "phone_code": " ",
        "capital": "",
        "currency": "AUD",
        "states": [
            "Artibonite",
            "Centre",
            "Grand'Anse",
            "Nippes",
            "Nord",
            "Nord-Est",
            "Nord-Ouest",
            "Ouest",
            "Sud",
            "Sud-Est"
        ]
    },
    {
        "name": "Honduras",
        "iso3": "HND",
        "iso2": "HN",
        "phone_code": "504",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "states": [
            "Atl�ntida Department",
            "Bay Islands Department",
            "Choluteca Department",
            "Col�n Department",
            "Comayagua Department",
            "Cop�n Department",
            "Cort�s Department",
            "El Para�so Department",
            "Francisco Moraz�n Department",
            "Gracias a Dios Department",
            "Intibuc� Department",
            "La Paz Department",
            "Lempira Department",
            "Ocotepeque Department",
            "Olancho Department",
            "Santa B�rbara Department",
            "Valle Department",
            "Yoro Department"
        ]
    },
    {
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "iso2": "HK",
        "phone_code": "852",
        "capital": "Hong Kong",
        "currency": "HKD",
        "states": [
            "Atl�ntida Department",
            "Bay Islands Department",
            "Choluteca Department",
            "Col�n Department",
            "Comayagua Department",
            "Cop�n Department",
            "Cort�s Department",
            "El Para�so Department",
            "Francisco Moraz�n Department",
            "Gracias a Dios Department",
            "Intibuc� Department",
            "La Paz Department",
            "Lempira Department",
            "Ocotepeque Department",
            "Olancho Department",
            "Santa B�rbara Department",
            "Valle Department",
            "Yoro Department"
        ]
    },
    {
        "name": "Hungary",
        "iso3": "HUN",
        "iso2": "HU",
        "phone_code": "36",
        "capital": "Budapest",
        "currency": "HUF",
        "states": [
            "�rd",
            "Baranya County",
            "B�cs-Kiskun County",
            "B�k�s County",
            "B�k�scsaba",
            "Borsod-Aba�j-Zempl�n County",
            "Budapest",
            "Csongr�d County",
            "Debrecen",
            "Duna�jv�ros",
            "Eger",
            "Fej�r County",
            "Gy?r",
            "Gy?r-Moson-Sopron County",
            "Hajd�-Bihar County",
            "H�dmez?v�s�rhely",
            "Heves County",
            "J�sz-Nagykun-Szolnok County",
            "Kaposv�r",
            "Kecskem�t",
            "Miskolc",
            "Nagykanizsa",
            "N�gr�d County",
            "Ny�regyh�za",
            "P�cs",
            "Pest County",
            "Salg�tarj�n",
            "Somogy County",
            "Sopron",
            "Szabolcs-Szatm�r-Bereg County",
            "Sz�kesfeh�rv�r",
            "Szeged",
            "Szeksz�rd",
            "Szolnok",
            "Szombathely",
            "Tatab�nya",
            "Tolna County",
            "Vas County",
            "Veszpr�m",
            "Veszpr�m County",
            "Zala County",
            "Zalaegerszeg"
        ]
    },
    {
        "name": "Iceland",
        "iso3": "ISL",
        "iso2": "IS",
        "phone_code": "354",
        "capital": "Reykjavik",
        "currency": "ISK",
        "states": [
            "Capital Region",
            "Eastern Region",
            "Northeastern Region",
            "Northwestern Region",
            "Southern Peninsula Region",
            "Southern Region",
            "Western Region",
            "Westfjords"
        ]
    },
    {
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "phone_code": "91",
        "capital": "New Delhi",
        "currency": "INR",
        "states": [
            "Andaman and Nicobar Islands",
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chandigarh",
            "Chhattisgarh",
            "Dadra and Nagar Haveli",
            "Daman and Diu",
            "Delhi",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jammu and Kashmir",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Lakshadweep",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Puducherry",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttar Pradesh",
            "Uttarakhand"
        ]
    },
    {
        "name": "Indonesia",
        "iso3": "IDN",
        "iso2": "ID",
        "phone_code": "62",
        "capital": "Jakarta",
        "currency": "IDR",
        "states": [
            "Aceh",
            "Bali",
            "Bangka Belitung Islands",
            "Banten",
            "Bengkulu",
            "Central Java",
            "Central Kalimantan",
            "Central Sulawesi",
            "East Java",
            "East Kalimantan",
            "East Nusa Tenggara",
            "Gorontalo",
            "Jakarta",
            "Jambi",
            "Kalimantan",
            "Lampung",
            "Lesser Sunda Islands",
            "Maluku",
            "Maluku Islands",
            "North Kalimantan",
            "North Maluku",
            "North Sulawesi",
            "North Sumatra",
            "Papua",
            "Riau",
            "Riau Islands",
            "South Kalimantan",
            "South Sulawesi",
            "South Sumatra",
            "Southeast Sulawesi",
            "Special Region of Yogyakarta",
            "Sulawesi",
            "Sumatra",
            "West Java",
            "West Nusa Tenggara",
            "West Papua",
            "West Sulawesi",
            "West Sumatra"
        ]
    },
    {
        "name": "Iran",
        "iso3": "IRN",
        "iso2": "IR",
        "phone_code": "98",
        "capital": "Tehran",
        "currency": "IRR",
        "states": [
            "Alborz Province",
            "Ardabil Province",
            "Bushehr Province",
            "Chaharmahal and Bakhtiari Province",
            "East Azerbaijan Province",
            "Fars Province",
            "Gilan Province",
            "Golestan Province",
            "Hormozgan Province",
            "Ilam Province",
            "Isfahan Province",
            "Kerman Province",
            "Kermanshah Province",
            "Khuzestan Province",
            "Kohgiluyeh and Boyer-Ahmad Province",
            "Kurdistan Province",
            "Lorestan Province",
            "Markazi Province",
            "Mazandaran Province",
            "North Khorasan Province",
            "Qazvin Province",
            "Qom Province",
            "Razavi Khorasan Province",
            "Semnan Province",
            "Sistan and Baluchestan",
            "South Khorasan Province",
            "Tehran Province",
            "West Azarbaijan Province",
            "Yazd Province",
            "Zanjan Province"
        ]
    },
    {
        "name": "Iraq",
        "iso3": "IRQ",
        "iso2": "IQ",
        "phone_code": "964",
        "capital": "Baghdad",
        "currency": "IQD",
        "states": [
            "Al Anbar Governorate",
            "Al Muthanna Governorate",
            "Al-Q?disiyyah Governorate",
            "Babylon Governorate",
            "Baghdad Governorate",
            "Basra Governorate",
            "Dhi Qar Governorate",
            "Diyala Governorate",
            "Dohuk Governorate",
            "Erbil Governorate",
            "Karbala Governorate",
            "Kirkuk Governorate",
            "Maysan Governorate",
            "Najaf Governorate",
            "Nineveh Governorate",
            "Saladin Governorate",
            "Sulaymaniyah Governorate",
            "Wasit Governorate"
        ]
    },
    {
        "name": "Ireland",
        "iso3": "IRL",
        "iso2": "IE",
        "phone_code": "353",
        "capital": "Dublin",
        "currency": "EUR",
        "states": [
            "Connacht",
            "County Carlow",
            "County Cavan",
            "County Clare",
            "County Cork",
            "County Donegal",
            "County Dublin",
            "County Galway",
            "County Kerry",
            "County Kildare",
            "County Kilkenny",
            "County Laois",
            "County Limerick",
            "County Longford",
            "County Louth",
            "County Mayo",
            "County Meath",
            "County Monaghan",
            "County Offaly",
            "County Roscommon",
            "County Sligo",
            "County Tipperary",
            "County Waterford",
            "County Westmeath",
            "County Wexford",
            "County Wicklow",
            "Leinster",
            "Munster",
            "Ulster"
        ]
    },
    {
        "name": "Israel",
        "iso3": "ISR",
        "iso2": "IL",
        "phone_code": "972",
        "capital": "Jerusalem",
        "currency": "ILS",
        "states": [
            "Central District",
            "Haifa District",
            "Jerusalem District",
            "Northern District",
            "Southern District",
            "Tel Aviv District"
        ]
    },
    {
        "name": "Italy",
        "iso3": "ITA",
        "iso2": "IT",
        "phone_code": "39",
        "capital": "Rome",
        "currency": "EUR",
        "states": [
            "Abruzzo",
            "Aosta Valley",
            "Apulia",
            "Basilicata",
            "Benevento Province",
            "Calabria",
            "Campania",
            "Emilia-Romagna",
            "Friuli�Venezia Giulia",
            "Lazio",
            "Libero consorzio comunale di Agrigento",
            "Libero consorzio comunale di Caltanissetta",
            "Libero consorzio comunale di Enna",
            "Libero consorzio comunale di Ragusa",
            "Libero consorzio comunale di Siracusa",
            "Libero consorzio comunale di Trapani",
            "Liguria",
            "Lombardy",
            "Marche",
            "Metropolitan City of Bari",
            "Metropolitan City of Bologna",
            "Metropolitan City of Cagliari",
            "Metropolitan City of Catania",
            "Metropolitan City of Florence",
            "Metropolitan City of Genoa",
            "Metropolitan City of Messina",
            "Metropolitan City of Milan",
            "Metropolitan City of Naples",
            "Metropolitan City of Palermo",
            "Metropolitan City of Reggio Calabria",
            "Metropolitan City of Rome",
            "Metropolitan City of Turin",
            "Metropolitan City of Venice",
            "Molise",
            "Pesaro and Urbino Province",
            "Piedmont",
            "Province of Alessandria",
            "Province of Ancona",
            "Province of Ascoli Piceno",
            "Province of Asti",
            "Province of Avellino",
            "Province of Barletta-Andria-Trani",
            "Province of Belluno",
            "Province of Bergamo",
            "Province of Biella",
            "Province of Brescia",
            "Province of Brindisi",
            "Province of Campobasso",
            "Province of Carbonia-Iglesias",
            "Province of Caserta",
            "Province of Catanzaro",
            "Province of Chieti",
            "Province of Como",
            "Province of Cosenza",
            "Province of Cremona",
            "Province of Crotone",
            "Province of Cuneo",
            "Province of Fermo",
            "Province of Ferrara",
            "Province of Foggia",
            "Province of Forl�-Cesena",
            "Province of Frosinone",
            "Province of Gorizia",
            "Province of Grosseto",
            "Province of Imperia",
            "Province of Isernia",
            "Province of L'Aquila",
            "Province of La Spezia",
            "Province of Latina",
            "Province of Lecce",
            "Province of Lecco",
            "Province of Livorno",
            "Province of Lodi",
            "Province of Lucca",
            "Province of Macerata",
            "Province of Mantua",
            "Province of Massa and Carrara",
            "Province of Matera",
            "Province of Medio Campidano",
            "Province of Modena",
            "Province of Monza and Brianza",
            "Province of Novara",
            "Province of Nuoro",
            "Province of Ogliastra",
            "Province of Olbia-Tempio",
            "Province of Oristano",
            "Province of Padua",
            "Province of Parma",
            "Province of Pavia",
            "Province of Perugia",
            "Province of Pescara",
            "Province of Piacenza",
            "Province of Pisa",
            "Province of Pistoia",
            "Province of Pordenone",
            "Province of Potenza",
            "Province of Prato",
            "Province of Ravenna",
            "Province of Reggio Emilia",
            "Province of Rieti",
            "Province of Rimini",
            "Province of Rovigo",
            "Province of Salerno",
            "Province of Sassari",
            "Province of Savona",
            "Province of Siena",
            "Province of Sondrio",
            "Province of Taranto",
            "Province of Teramo",
            "Province of Terni",
            "Province of Treviso",
            "Province of Trieste",
            "Province of Udine",
            "Province of Varese",
            "Province of Verbano-Cusio-Ossola",
            "Province of Vercelli",
            "Province of Verona",
            "Province of Vibo Valentia",
            "Province of Vicenza",
            "Province of Viterbo",
            "Sardinia",
            "Sicily",
            "South Tyrol",
            "Trentino",
            "Trentino-South Tyrol",
            "Tuscany",
            "Umbria",
            "Veneto"
        ]
    },
    {
        "name": "Jamaica",
        "iso3": "JAM",
        "iso2": "JM",
        "phone_code": "+1-876",
        "capital": "Kingston",
        "currency": "JMD",
        "states": [
            "Clarendon Parish",
            "Hanover Parish",
            "Kingston Parish",
            "Manchester Parish",
            "Portland Parish",
            "Saint Andrew",
            "Saint Ann Parish",
            "Saint Catherine Parish",
            "Saint Elizabeth Parish",
            "Saint James Parish",
            "Saint Mary Parish",
            "Saint Thomas Parish",
            "Trelawny Parish",
            "Westmoreland Parish"
        ]
    },
    {
        "name": "Japan",
        "iso3": "JPN",
        "iso2": "JP",
        "phone_code": "81",
        "capital": "Tokyo",
        "currency": "JPY",
        "states": [
            "Aichi Prefecture",
            "Akita Prefecture",
            "Aomori Prefecture",
            "?ita Prefecture",
            "?saka Prefecture",
            "Chiba Prefecture",
            "Ehime Prefecture",
            "Fukui Prefecture",
            "Fukuoka Prefecture",
            "Fukushima Prefecture",
            "Gifu Prefecture",
            "Gunma Prefecture",
            "Hiroshima Prefecture",
            "Hokkaid? Prefecture",
            "Hy?go Prefecture",
            "Ibaraki Prefecture",
            "Ishikawa Prefecture",
            "Iwate Prefecture",
            "Kagawa Prefecture",
            "Kagoshima Prefecture",
            "Kanagawa Prefecture",
            "Kumamoto Prefecture",
            "Ky?to Prefecture",
            "Mie Prefecture",
            "Miyagi Prefecture",
            "Miyazaki Prefecture",
            "Nagano Prefecture",
            "Nagasaki Prefecture",
            "Nara Prefecture",
            "Niigata Prefecture",
            "Okayama Prefecture",
            "Okinawa Prefecture",
            "Saga Prefecture",
            "Saitama Prefecture",
            "Shiga Prefecture",
            "Shimane Prefecture",
            "Shizuoka Prefecture",
            "Tochigi Prefecture",
            "Tokushima Prefecture",
            "Tokyo",
            "Tottori Prefecture",
            "Toyama Prefecture",
            "Wakayama Prefecture",
            "Yamagata Prefecture",
            "Yamaguchi Prefecture",
            "Yamanashi Prefecture"
        ]
    },
    {
        "name": "Jersey",
        "iso3": "JEY",
        "iso2": "JE",
        "phone_code": "+44-1534",
        "capital": "Saint Helier",
        "currency": "GBP",
        "states": [
            "Aichi Prefecture",
            "Akita Prefecture",
            "Aomori Prefecture",
            "?ita Prefecture",
            "?saka Prefecture",
            "Chiba Prefecture",
            "Ehime Prefecture",
            "Fukui Prefecture",
            "Fukuoka Prefecture",
            "Fukushima Prefecture",
            "Gifu Prefecture",
            "Gunma Prefecture",
            "Hiroshima Prefecture",
            "Hokkaid? Prefecture",
            "Hy?go Prefecture",
            "Ibaraki Prefecture",
            "Ishikawa Prefecture",
            "Iwate Prefecture",
            "Kagawa Prefecture",
            "Kagoshima Prefecture",
            "Kanagawa Prefecture",
            "Kumamoto Prefecture",
            "Ky?to Prefecture",
            "Mie Prefecture",
            "Miyagi Prefecture",
            "Miyazaki Prefecture",
            "Nagano Prefecture",
            "Nagasaki Prefecture",
            "Nara Prefecture",
            "Niigata Prefecture",
            "Okayama Prefecture",
            "Okinawa Prefecture",
            "Saga Prefecture",
            "Saitama Prefecture",
            "Shiga Prefecture",
            "Shimane Prefecture",
            "Shizuoka Prefecture",
            "Tochigi Prefecture",
            "Tokushima Prefecture",
            "Tokyo",
            "Tottori Prefecture",
            "Toyama Prefecture",
            "Wakayama Prefecture",
            "Yamagata Prefecture",
            "Yamaguchi Prefecture",
            "Yamanashi Prefecture"
        ]
    },
    {
        "name": "Jordan",
        "iso3": "JOR",
        "iso2": "JO",
        "phone_code": "962",
        "capital": "Amman",
        "currency": "JOD",
        "states": [
            "Ajloun Governorate",
            "Amman Governorate",
            "Aqaba Governorate",
            "Balqa Governorate",
            "Irbid Governorate",
            "Jerash Governorate",
            "Karak Governorate",
            "Ma'an Governorate",
            "Madaba Governorate",
            "Mafraq Governorate",
            "Tafilah Governorate",
            "Zarqa Governorate"
        ]
    },
    {
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "iso2": "KZ",
        "phone_code": "7",
        "capital": "Astana",
        "currency": "KZT",
        "states": [
            "Akmola Region",
            "Aktobe Region",
            "Almaty",
            "Almaty Region",
            "Atyrau Region",
            "Baikonur",
            "East Kazakhstan Region",
            "Jambyl Region",
            "Karaganda Region",
            "Kostanay Region",
            "Kyzylorda Region",
            "Mangystau Region",
            "North Kazakhstan Region",
            "Nur-Sultan",
            "Pavlodar Region",
            "Turkestan Region",
            "West Kazakhstan Province"
        ]
    },
    {
        "name": "Kenya",
        "iso3": "KEN",
        "iso2": "KE",
        "phone_code": "254",
        "capital": "Nairobi",
        "currency": "KES",
        "states": [
            "Baringo County",
            "Bomet County",
            "Bungoma County",
            "Busia County",
            "Central Province",
            "Coast Province",
            "Eastern Province",
            "Elgeyo-Marakwet County",
            "Embu County",
            "Garissa County",
            "Homa Bay County",
            "Isiolo County",
            "Kajiado County",
            "Kakamega County",
            "Kericho County",
            "Kiambu County",
            "Kilifi County",
            "Kirinyaga County",
            "Kisii County",
            "Kisumu County",
            "Kitui County",
            "Kwale County",
            "Laikipia County",
            "Lamu County",
            "Machakos County",
            "Makueni County",
            "Mandera County",
            "Marsabit County",
            "Meru County",
            "Migori County",
            "Mombasa County",
            "Muranga County",
            "Nairobi",
            "Nakuru District",
            "Nandi District",
            "Narok County",
            "North Eastern Province",
            "Nyamira District",
            "Nyandarua County",
            "Nyanza Province",
            "Nyeri County",
            "Rift Valley Province",
            "Samburu County",
            "Siaya County",
            "Taita�Taveta County",
            "Tana River County",
            "Tharaka Nithi County",
            "Trans-Nzoia District",
            "Turkana County",
            "Uasin Gishu District",
            "Vihiga District",
            "Wajir County",
            "West Pokot County",
            "Western Province"
        ]
    },
    {
        "name": "Kiribati",
        "iso3": "KIR",
        "iso2": "KI",
        "phone_code": "686",
        "capital": "Tarawa",
        "currency": "AUD",
        "states": [
            "Gilbert Islands",
            "Line Islands",
            "Phoenix Islands"
        ]
    },
    {
        "name": "Korea North\n",
        "iso3": "PRK",
        "iso2": "KP",
        "phone_code": "850",
        "capital": "Pyongyang",
        "currency": "KPW",
        "states": [
            "Chagang Province",
            "Kangwon Province",
            "North Hamgyong Province",
            "North Hwanghae Province",
            "North Pyongan Province",
            "Pyongyang",
            "Rason",
            "Ryanggang Province",
            "South Hamgyong Province",
            "South Hwanghae Province",
            "South Pyongan Province"
        ]
    },
    {
        "name": "Korea South",
        "iso3": "KOR",
        "iso2": "KR",
        "phone_code": "82",
        "capital": "Seoul",
        "currency": "KRW",
        "states": [
            "Busan",
            "Daegu",
            "Daejeon",
            "Gangwon Province",
            "Gwangju",
            "Gyeonggi Province",
            "Incheon",
            "Jeju",
            "North Chungcheong Province",
            "North Gyeongsang Province",
            "North Jeolla Province",
            "Sejong City",
            "Seoul",
            "South Chungcheong Province",
            "South Gyeongsang Province",
            "South Jeolla Province",
            "Ulsan"
        ]
    },
    {
        "name": "Kuwait",
        "iso3": "KWT",
        "iso2": "KW",
        "phone_code": "965",
        "capital": "Kuwait City",
        "currency": "KWD",
        "states": [
            "Al Ahmadi Governorate",
            "Al Farwaniyah Governorate",
            "Al Jahra Governorate",
            "Capital Governorate",
            "Hawalli Governorate",
            "Mubarak Al-Kabeer Governorate"
        ]
    },
    {
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "iso2": "KG",
        "phone_code": "996",
        "capital": "Bishkek",
        "currency": "KGS",
        "states": [
            "Batken Region",
            "Bishkek",
            "Chuy Region",
            "Issyk-Kul Region",
            "Jalal-Abad Region",
            "Naryn Region",
            "Osh",
            "Osh Region",
            "Talas Region"
        ]
    },
    {
        "name": "Laos",
        "iso3": "LAO",
        "iso2": "LA",
        "phone_code": "856",
        "capital": "Vientiane",
        "currency": "LAK",
        "states": [
            "Attapeu Province",
            "Bokeo Province",
            "Bolikhamsai Province",
            "Champasak Province",
            "Houaphanh Province",
            "Khammouane Province",
            "Luang Namtha Province",
            "Luang Prabang Province",
            "Oudomxay Province",
            "Phongsaly Province",
            "Sainyabuli Province",
            "Salavan Province",
            "Savannakhet Province",
            "Sekong Province",
            "Vientiane Prefecture",
            "Vientiane Province",
            "Xaisomboun",
            "Xaisomboun Province",
            "Xiangkhouang Province"
        ]
    },
    {
        "name": "Latvia",
        "iso3": "LVA",
        "iso2": "LV",
        "phone_code": "371",
        "capital": "Riga",
        "currency": "EUR",
        "states": [
            "Aglona Municipality",
            "Aizkraukle Municipality",
            "Aizpute Municipality",
            "Akn?ste Municipality",
            "Al?ksne Municipality",
            "Aloja Municipality",
            "Alsunga Municipality",
            "Amata Municipality",
            "Ape Municipality",
            "Auce Municipality",
            "?egums Municipality",
            "?ekava Municipality",
            "?rg?i Municipality",
            "Bab?te Municipality",
            "Baldone Municipality",
            "Baltinava Municipality",
            "Balvi Municipality",
            "Bauska Municipality",
            "Bever?na Municipality",
            "Broc?ni Municipality",
            "Burtnieki Municipality",
            "Carnikava Municipality",
            "C?sis Municipality",
            "Cesvaine Municipality",
            "Cibla Municipality",
            "Dagda Municipality",
            "Daugavpils",
            "Daugavpils Municipality",
            "Dobele Municipality",
            "Dundaga Municipality",
            "Durbe Municipality",
            "Engure Municipality",
            "Garkalne Municipality",
            "Grobi?a Municipality",
            "Gulbene Municipality",
            "Iecava Municipality",
            "Ik�?ile Municipality",
            "Il?kste Municipality",
            "In?ukalns Municipality",
            "Jaunjelgava Municipality",
            "Jaunpiebalga Municipality",
            "Jaunpils Municipality",
            "J?rmala",
            "J?kabpils",
            "J?kabpils Municipality",
            "Jelgava",
            "Jelgava Municipality",
            "Kandava Municipality",
            "K?rsava Municipality",
            "Koc?ni Municipality",
            "Koknese Municipality",
            "Kr?slava Municipality",
            "Krimulda Municipality",
            "Krustpils Municipality",
            "Kuld?ga Municipality",
            "L?gatne Municipality",
            "L?v?ni Municipality",
            "Lielv?rde Municipality",
            "Liep?ja",
            "Limba�i Municipality",
            "Lub?na Municipality",
            "Ludza Municipality",
            "Madona Municipality",
            "Mazsalaca Municipality",
            "M?lpils Municipality",
            "M?rupe Municipality",
            "M?rsrags Municipality",
            "Nauk�?ni Municipality",
            "N?ca Municipality",
            "Nereta Municipality",
            "Ogre Municipality",
            "Olaine Municipality",
            "Ozolnieki Municipality",
            "P?rgauja Municipality",
            "P?vilosta Municipality",
            "P?avi?as Municipality",
            "Prei?i Municipality",
            "Prieku?i Municipality",
            "Priekule Municipality",
            "Rauna Municipality",
            "R?jiena Municipality",
            "R?zekne",
            "R?zekne Municipality",
            "Riebi?i Municipality",
            "Riga",
            "Roja Municipality",
            "Ropa�i Municipality",
            "Rucava Municipality",
            "Rug?ji Municipality",
            "Rund?le Municipality",
            "Sala Municipality",
            "Salacgr?va Municipality",
            "Salaspils Municipality",
            "Saldus Municipality",
            "Saulkrasti Municipality",
            "S?ja Municipality",
            "Sigulda Municipality",
            "Skr?veri Municipality",
            "Skrunda Municipality",
            "Smiltene Municipality",
            "Stopi?i Municipality",
            "Stren?i Municipality",
            "Talsi Municipality",
            "T?rvete Municipality",
            "Tukums Municipality",
            "Vai?ode Municipality",
            "Valka Municipality",
            "Valmiera",
            "Varak??ni Municipality",
            "V?rkava Municipality",
            "Vecpiebalga Municipality",
            "Vecumnieki Municipality",
            "Ventspils",
            "Ventspils Municipality",
            "Vi?aka Municipality",
            "Vi??ni Municipality",
            "Vies?te Municipality",
            "Zilupe Municipality"
        ]
    },
    {
        "name": "Lebanon",
        "iso3": "LBN",
        "iso2": "LB",
        "phone_code": "961",
        "capital": "Beirut",
        "currency": "LBP",
        "states": [
            "Akkar Governorate",
            "Baalbek-Hermel Governorate",
            "Beirut Governorate",
            "Beqaa Governorate",
            "Mount Lebanon Governorate",
            "Nabatieh Governorate",
            "North Governorate",
            "South Governorate"
        ]
    },
    {
        "name": "Lesotho",
        "iso3": "LSO",
        "iso2": "LS",
        "phone_code": "266",
        "capital": "Maseru",
        "currency": "LSL",
        "states": [
            "Berea District",
            "Butha-Buthe District",
            "Leribe District",
            "Mafeteng District",
            "Maseru District",
            "Mohale's Hoek District",
            "Mokhotlong District",
            "Qacha's Nek District",
            "Quthing District",
            "Thaba-Tseka District"
        ]
    },
    {
        "name": "Liberia",
        "iso3": "LBR",
        "iso2": "LR",
        "phone_code": "231",
        "capital": "Monrovia",
        "currency": "LRD",
        "states": [
            "Bomi County",
            "Bong County",
            "Gbarpolu County",
            "Grand Bassa County",
            "Grand Cape Mount County",
            "Grand Gedeh County",
            "Grand Kru County",
            "Lofa County",
            "Margibi County",
            "Maryland County",
            "Montserrado County",
            "Nimba",
            "River Cess County",
            "River Gee County",
            "Sinoe County"
        ]
    },
    {
        "name": "Libya",
        "iso3": "LBY",
        "iso2": "LY",
        "phone_code": "218",
        "capital": "Tripolis",
        "currency": "LYD",
        "states": [
            "Al Wahat District",
            "Benghazi",
            "Derna District",
            "Ghat District",
            "Jabal al Akhdar",
            "Jabal al Gharbi District",
            "Jafara",
            "Jufra",
            "Kufra District",
            "Marj District",
            "Misrata District",
            "Murqub",
            "Murzuq District",
            "Nalut District",
            "Nuqat al Khams",
            "Sabha District",
            "Sirte District",
            "Tripoli District",
            "Wadi al Hayaa District",
            "Wadi al Shatii District",
            "Zawiya District"
        ]
    },
    {
        "name": "Liechtenstein",
        "iso3": "LIE",
        "iso2": "LI",
        "phone_code": "423",
        "capital": "Vaduz",
        "currency": "CHF",
        "states": [
            "Balzers",
            "Eschen",
            "Gamprin",
            "Mauren",
            "Planken",
            "Ruggell",
            "Schaan",
            "Schellenberg",
            "Triesen",
            "Triesenberg",
            "Vaduz"
        ]
    },
    {
        "name": "Lithuania",
        "iso3": "LTU",
        "iso2": "LT",
        "phone_code": "370",
        "capital": "Vilnius",
        "currency": "LTL",
        "states": [
            "Akmen? District Municipality",
            "Alytus City Municipality",
            "Alytus County",
            "Alytus District Municipality",
            "�akiai District Municipality",
            "�al?ininkai District Municipality",
            "�iauliai City Municipality",
            "�iauliai County",
            "�iauliai District Municipality",
            "�ilal? District Municipality",
            "�ilut? District Municipality",
            "�irvintos District Municipality",
            "�ven?ionys District Municipality",
            "Bir�tonas Municipality",
            "Bir�ai District Municipality",
            "Druskininkai municipality",
            "Elektr?nai municipality",
            "Ignalina District Municipality",
            "Jonava District Municipality",
            "Joni�kis District Municipality",
            "Jurbarkas District Municipality",
            "Kai�iadorys District Municipality",
            "Kalvarija municipality",
            "Kaunas City Municipality",
            "Kaunas County",
            "Kaunas District Municipality",
            "Kazl? R?da municipality",
            "K?dainiai District Municipality",
            "Kelm? District Municipality",
            "Klaip?da County",
            "Klaip?da District Municipality",
            "Klaipeda City Municipality",
            "Kretinga District Municipality",
            "Kupi�kis District Municipality",
            "Lazdijai District Municipality",
            "Ma�eikiai District Municipality",
            "Marijampol? County",
            "Marijampol? Municipality",
            "Mol?tai District Municipality",
            "Neringa Municipality",
            "Pag?giai municipality",
            "Pakruojis District Municipality",
            "Palanga City Municipality",
            "Panev?�ys City Municipality",
            "Panev?�ys County",
            "Panev?�ys District Municipality",
            "Pasvalys District Municipality",
            "Plung? District Municipality",
            "Prienai District Municipality",
            "Radvili�kis District Municipality",
            "Raseiniai District Municipality",
            "Rietavas municipality",
            "Roki�kis District Municipality",
            "Skuodas District Municipality",
            "Taurag? County",
            "Taurag? District Municipality",
            "Tel�iai County",
            "Tel�iai District Municipality",
            "Trakai District Municipality",
            "Ukmerg? District Municipality",
            "Utena County",
            "Utena District Municipality",
            "Var?na District Municipality",
            "Vilkavi�kis District Municipality",
            "Vilnius City Municipality",
            "Vilnius County",
            "Vilnius District Municipality",
            "Visaginas Municipality",
            "Zarasai District Municipality"
        ]
    },
    {
        "name": "Luxembourg",
        "iso3": "LUX",
        "iso2": "LU",
        "phone_code": "352",
        "capital": "Luxembourg",
        "currency": "EUR",
        "states": [
            "Canton of Capellen",
            "Canton of Clervaux",
            "Canton of Diekirch",
            "Canton of Echternach",
            "Canton of Esch-sur-Alzette",
            "Canton of Grevenmacher",
            "Canton of Luxembourg",
            "Canton of Mersch",
            "Canton of Redange",
            "Canton of Remich",
            "Canton of Vianden",
            "Canton of Wiltz",
            "Diekirch District",
            "Grevenmacher District",
            "Luxembourg District"
        ]
    },
    {
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "iso2": "MO",
        "phone_code": "853",
        "capital": "Macao",
        "currency": "MOP",
        "states": [
            "Canton of Capellen",
            "Canton of Clervaux",
            "Canton of Diekirch",
            "Canton of Echternach",
            "Canton of Esch-sur-Alzette",
            "Canton of Grevenmacher",
            "Canton of Luxembourg",
            "Canton of Mersch",
            "Canton of Redange",
            "Canton of Remich",
            "Canton of Vianden",
            "Canton of Wiltz",
            "Diekirch District",
            "Grevenmacher District",
            "Luxembourg District"
        ]
    },
    {
        "name": "Macedonia",
        "iso3": "MKD",
        "iso2": "MK",
        "phone_code": "389",
        "capital": "Skopje",
        "currency": "MKD",
        "states": [
            "Aerodrom Municipality",
            "Ara?inovo Municipality",
            "�tip Municipality",
            "�uto Orizari Municipality",
            "�elino Municipality",
            "?a�ka Municipality",
            "?air Municipality",
            "?e�inovo-Oble�evo Municipality",
            "?u?er-Sandevo Municipality",
            "Berovo Municipality",
            "Bitola Municipality",
            "Bogdanci Municipality",
            "Bogovinje Municipality",
            "Bosilovo Municipality",
            "Brvenica Municipality",
            "Butel Municipality",
            "Centar �upa Municipality",
            "Centar Municipality",
            "Debarca Municipality",
            "Del?evo Municipality",
            "Demir Hisar Municipality",
            "Demir Kapija Municipality",
            "Dojran Municipality",
            "Dolneni Municipality",
            "Drugovo Municipality",
            "Gazi Baba Municipality",
            "Gevgelija Municipality",
            "Gjor?e Petrov Municipality",
            "Gostivar Municipality",
            "Gradsko Municipality",
            "Greater Skopje",
            "Ilinden Municipality",
            "Jegunovce Municipality",
            "Karbinci",
            "Karpo� Municipality",
            "Kavadarci Municipality",
            "Ki?evo Municipality",
            "Kisela Voda Municipality",
            "Ko?ani Municipality",
            "Kon?e Municipality",
            "Kratovo Municipality",
            "Kriva Palanka Municipality",
            "Krivoga�tani Municipality",
            "Kru�evo Municipality",
            "Kumanovo Municipality",
            "Lipkovo Municipality",
            "Lozovo Municipality",
            "Makedonska Kamenica Municipality",
            "Makedonski Brod Municipality",
            "Mavrovo and Rostu�a Municipality",
            "Mogila Municipality",
            "Negotino Municipality",
            "Novaci Municipality",
            "Novo Selo Municipality",
            "Ohrid Municipality",
            "Oslomej Municipality",
            "Peh?evo Municipality",
            "Petrovec Municipality",
            "Plasnica Municipality",
            "Prilep Municipality",
            "Probi�tip Municipality",
            "Radovi� Municipality",
            "Rankovce Municipality",
            "Resen Municipality",
            "Rosoman Municipality",
            "Saraj Municipality",
            "Sopi�te Municipality",
            "Staro Nagori?ane Municipality",
            "Struga Municipality",
            "Strumica Municipality",
            "Studeni?ani Municipality",
            "Sveti Nikole Municipality",
            "Tearce Municipality",
            "Tetovo Municipality",
            "Valandovo Municipality",
            "Vasilevo Municipality",
            "Veles Municipality",
            "Vev?ani Municipality",
            "Vinica Municipality",
            "Vrane�tica Municipality",
            "Vrap?i�te Municipality",
            "Zajas Municipality",
            "Zelenikovo Municipality",
            "Zrnovci Municipality"
        ]
    },
    {
        "name": "Madagascar",
        "iso3": "MDG",
        "iso2": "MG",
        "phone_code": "261",
        "capital": "Antananarivo",
        "currency": "MGA",
        "states": [
            "Antananarivo Province",
            "Antsiranana Province",
            "Fianarantsoa Province",
            "Mahajanga Province",
            "Toamasina Province",
            "Toliara Province"
        ]
    },
    {
        "name": "Malawi",
        "iso3": "MWI",
        "iso2": "MW",
        "phone_code": "265",
        "capital": "Lilongwe",
        "currency": "MWK",
        "states": [
            "Balaka District",
            "Blantyre District",
            "Central Region",
            "Chikwawa District",
            "Chiradzulu District",
            "Chitipa district",
            "Dedza District",
            "Dowa District",
            "Karonga District",
            "Kasungu District",
            "Likoma District",
            "Lilongwe District",
            "Machinga District",
            "Mangochi District",
            "Mchinji District",
            "Mulanje District",
            "Mwanza District",
            "Mzimba District",
            "Nkhata Bay District",
            "Nkhotakota District",
            "Northern Region",
            "Nsanje District",
            "Ntcheu District",
            "Ntchisi District",
            "Phalombe District",
            "Rumphi District",
            "Salima District",
            "Southern Region",
            "Thyolo District",
            "Zomba District"
        ]
    },
    {
        "name": "Malaysia",
        "iso3": "MYS",
        "iso2": "MY",
        "phone_code": "60",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "states": [
            "Johor",
            "Kedah",
            "Kelantan",
            "Kuala Lumpur",
            "Labuan",
            "Melaka",
            "Negeri Sembilan",
            "Pahang",
            "Penang",
            "Perak",
            "Perlis",
            "Putrajaya",
            "Sabah",
            "Sarawak",
            "Selangor",
            "Terengganu"
        ]
    },
    {
        "name": "Maldives",
        "iso3": "MDV",
        "iso2": "MV",
        "phone_code": "960",
        "capital": "Male",
        "currency": "MVR",
        "states": [
            "Addu Atoll",
            "Alif Alif Atoll",
            "Alif Dhaal Atoll",
            "Central Province",
            "Dhaalu Atoll",
            "Faafu Atoll",
            "Gaafu Alif Atoll",
            "Gaafu Dhaalu Atoll",
            "Gnaviyani Atoll",
            "Haa Alif Atoll",
            "Haa Dhaalu Atoll",
            "Kaafu Atoll",
            "Laamu Atoll",
            "Lhaviyani Atoll",
            "Mal�",
            "Meemu Atoll",
            "Noonu Atoll",
            "North Central Province",
            "North Province",
            "Raa Atoll",
            "Shaviyani Atoll",
            "South Central Province",
            "South Province",
            "Thaa Atoll",
            "Upper South Province",
            "Vaavu Atoll"
        ]
    },
    {
        "name": "Mali",
        "iso3": "MLI",
        "iso2": "ML",
        "phone_code": "223",
        "capital": "Bamako",
        "currency": "XOF",
        "states": [
            "Bamako",
            "Gao Region",
            "Kayes Region",
            "Kidal Region",
            "Koulikoro Region",
            "M�naka Region",
            "Mopti Region",
            "S�gou Region",
            "Sikasso Region",
            "Taoud�nit Region",
            "Tombouctou Region"
        ]
    },
    {
        "name": "Malta",
        "iso3": "MLT",
        "iso2": "MT",
        "phone_code": "356",
        "capital": "Valletta",
        "currency": "EUR",
        "states": [
            "Attard",
            "?amrun",
            "?abbar",
            "?ebbu? Gozo",
            "?ebbu? Malta",
            "?ejtun",
            "?urrieq",
            "Balzan",
            "Bir?ebbu?a",
            "Birgu",
            "Birkirkara",
            "Cospicua",
            "Dingli",
            "Fgura",
            "Floriana",
            "Fontana",
            "G?ajnsielem",
            "G?arb",
            "G?arg?ur",
            "G?asri",
            "G?axaq",
            "G?ira",
            "Gudja",
            "Iklin",
            "Kalkara",
            "Ker?em",
            "Kirkop",
            "Lija",
            "Luqa",
            "Marsa",
            "Marsaskala",
            "Marsaxlokk",
            "M?arr",
            "Mdina",
            "Mellie?a",
            "Mosta",
            "Mqabba",
            "Msida",
            "Mtarfa",
            "Munxar",
            "Nadur",
            "Naxxar",
            "Paola",
            "Pembroke",
            "Piet�",
            "Qala",
            "Qormi",
            "Qrendi",
            "Rabat",
            "Saint Lawrence",
            "San ?wann",
            "Sannat",
            "Santa Lu?ija",
            "Santa Venera",
            "Senglea",
            "Si??iewi",
            "Sliema",
            "St. Julian's",
            "St. Paul's Bay",
            "Swieqi",
            "Ta' Xbiex",
            "Tarxien",
            "Valletta",
            "Victoria",
            "Xag?ra",
            "Xewkija",
            "Xg?ajra"
        ]
    },
    {
        "name": "Man (Isle of)",
        "iso3": "IMN",
        "iso2": "IM",
        "phone_code": "+44-1624",
        "capital": "Douglas, Isle of Man",
        "currency": "GBP",
        "states": [
            "Attard",
            "?amrun",
            "?abbar",
            "?ebbu? Gozo",
            "?ebbu? Malta",
            "?ejtun",
            "?urrieq",
            "Balzan",
            "Bir?ebbu?a",
            "Birgu",
            "Birkirkara",
            "Cospicua",
            "Dingli",
            "Fgura",
            "Floriana",
            "Fontana",
            "G?ajnsielem",
            "G?arb",
            "G?arg?ur",
            "G?asri",
            "G?axaq",
            "G?ira",
            "Gudja",
            "Iklin",
            "Kalkara",
            "Ker?em",
            "Kirkop",
            "Lija",
            "Luqa",
            "Marsa",
            "Marsaskala",
            "Marsaxlokk",
            "M?arr",
            "Mdina",
            "Mellie?a",
            "Mosta",
            "Mqabba",
            "Msida",
            "Mtarfa",
            "Munxar",
            "Nadur",
            "Naxxar",
            "Paola",
            "Pembroke",
            "Piet�",
            "Qala",
            "Qormi",
            "Qrendi",
            "Rabat",
            "Saint Lawrence",
            "San ?wann",
            "Sannat",
            "Santa Lu?ija",
            "Santa Venera",
            "Senglea",
            "Si??iewi",
            "Sliema",
            "St. Julian's",
            "St. Paul's Bay",
            "Swieqi",
            "Ta' Xbiex",
            "Tarxien",
            "Valletta",
            "Victoria",
            "Xag?ra",
            "Xewkija",
            "Xg?ajra"
        ]
    },
    {
        "name": "Marshall Islands",
        "iso3": "MHL",
        "iso2": "MH",
        "phone_code": "692",
        "capital": "Majuro",
        "currency": "USD",
        "states": [
            "Ralik Chain",
            "Ratak Chain"
        ]
    },
    {
        "name": "Martinique",
        "iso3": "MTQ",
        "iso2": "MQ",
        "phone_code": "596",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "states": [
            "Ralik Chain",
            "Ratak Chain"
        ]
    },
    {
        "name": "Mauritania",
        "iso3": "MRT",
        "iso2": "MR",
        "phone_code": "222",
        "capital": "Nouakchott",
        "currency": "MRO",
        "states": [
            "Adrar Region",
            "Assaba Region",
            "Brakna Region",
            "Dakhlet Nouadhibou",
            "Gorgol Region",
            "Guidimaka Region",
            "Hodh Ech Chargui Region",
            "Hodh El Gharbi Region",
            "Inchiri Region",
            "Nouakchott-Nord Region",
            "Nouakchott-Ouest Region",
            "Nouakchott-Sud Region",
            "Tagant Region",
            "Tiris Zemmour Region",
            "Trarza Region"
        ]
    },
    {
        "name": "Mauritius",
        "iso3": "MUS",
        "iso2": "MU",
        "phone_code": "230",
        "capital": "Port Louis",
        "currency": "MUR",
        "states": [
            "Agal�ga",
            "Beau Bassin-Rose Hill",
            "Cargados Carajos",
            "Curepipe",
            "Flacq District",
            "Grand Port District",
            "Moka District",
            "Pamplemousses District",
            "Plaines Wilhems District",
            "Port Louis",
            "Port Louis District",
            "Quatre Bornes",
            "Rivi�re du Rempart District",
            "Rivi�re Noire District",
            "Rodrigues",
            "Savanne District",
            "Vacoas-Phoenix"
        ]
    },
    {
        "name": "Mayotte",
        "iso3": "MYT",
        "iso2": "YT",
        "phone_code": "262",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "states": [
            "Agal�ga",
            "Beau Bassin-Rose Hill",
            "Cargados Carajos",
            "Curepipe",
            "Flacq District",
            "Grand Port District",
            "Moka District",
            "Pamplemousses District",
            "Plaines Wilhems District",
            "Port Louis",
            "Port Louis District",
            "Quatre Bornes",
            "Rivi�re du Rempart District",
            "Rivi�re Noire District",
            "Rodrigues",
            "Savanne District",
            "Vacoas-Phoenix"
        ]
    },
    {
        "name": "Mexico",
        "iso3": "MEX",
        "iso2": "MX",
        "phone_code": "52",
        "capital": "Mexico City",
        "currency": "MXN",
        "states": [
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Chiapas",
            "Chihuahua",
            "Coahuila",
            "Colima",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "M�xico",
            "Mexico City",
            "Michoac�n",
            "Morelos",
            "Nayarit",
            "Nuevo Le�n",
            "Oaxaca",
            "Puebla",
            "Quer�taro",
            "Quintana Roo",
            "San Luis Potos�",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz",
            "Yucat�n",
            "Zacatecas"
        ]
    },
    {
        "name": "Micronesia",
        "iso3": "FSM",
        "iso2": "FM",
        "phone_code": "691",
        "capital": "Palikir",
        "currency": "USD",
        "states": [
            "Chuuk State",
            "Kosrae State",
            "Pohnpei State",
            "Yap State"
        ]
    },
    {
        "name": "Moldova",
        "iso3": "MDA",
        "iso2": "MD",
        "phone_code": "373",
        "capital": "Chisinau",
        "currency": "MDL",
        "states": [
            "Anenii Noi District",
            "Basarabeasca District",
            "B?l?i Municipality",
            "Bender Municipality",
            "Briceni District",
            "Cahul District",
            "Cantemir District",
            "C?l?ra?i District",
            "C?u?eni District",
            "Chi?in?u Municipality",
            "Cimi?lia District",
            "Criuleni District",
            "Dondu?eni District",
            "Drochia District",
            "Dub?sari District",
            "Edine? District",
            "?old?ne?ti District",
            "?tefan Vod? District",
            "F?le?ti District",
            "Flore?ti District",
            "Gagauzia",
            "Glodeni District",
            "H�nce?ti District",
            "Ialoveni District",
            "Nisporeni District",
            "Ocni?a District",
            "Orhei District",
            "R�?cani District",
            "Rezina District",
            "S�ngerei District",
            "Soroca District",
            "Str??eni District",
            "Taraclia District",
            "Telene?ti District",
            "Transnistria autonomous territorial unit",
            "Ungheni District"
        ]
    },
    {
        "name": "Monaco",
        "iso3": "MCO",
        "iso2": "MC",
        "phone_code": "377",
        "capital": "Monaco",
        "currency": "EUR",
        "states": [
            "Anenii Noi District",
            "Basarabeasca District",
            "B?l?i Municipality",
            "Bender Municipality",
            "Briceni District",
            "Cahul District",
            "Cantemir District",
            "C?l?ra?i District",
            "C?u?eni District",
            "Chi?in?u Municipality",
            "Cimi?lia District",
            "Criuleni District",
            "Dondu?eni District",
            "Drochia District",
            "Dub?sari District",
            "Edine? District",
            "?old?ne?ti District",
            "?tefan Vod? District",
            "F?le?ti District",
            "Flore?ti District",
            "Gagauzia",
            "Glodeni District",
            "H�nce?ti District",
            "Ialoveni District",
            "Nisporeni District",
            "Ocni?a District",
            "Orhei District",
            "R�?cani District",
            "Rezina District",
            "S�ngerei District",
            "Soroca District",
            "Str??eni District",
            "Taraclia District",
            "Telene?ti District",
            "Transnistria autonomous territorial unit",
            "Ungheni District"
        ]
    },
    {
        "name": "Mongolia",
        "iso3": "MNG",
        "iso2": "MN",
        "phone_code": "976",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "states": [
            "Arkhangai Province",
            "�mn�govi Province",
            "�v�rkhangai Province",
            "Bayan-�lgii Province",
            "Bayankhongor Province",
            "Bulgan Province",
            "Darkhan-Uul Province",
            "Dornod Province",
            "Dornogovi Province",
            "Dundgovi Province",
            "Govi-Altai Province",
            "Govis�mber Province",
            "Kh�vsg�l Province",
            "Khentii Province",
            "Khovd Province",
            "Orkhon Province",
            "S�khbaatar Province",
            "Selenge Province",
            "T�v Province",
            "Uvs Province",
            "Zavkhan Province"
        ]
    },
    {
        "name": "Montenegro",
        "iso3": "MNE",
        "iso2": "ME",
        "phone_code": "382",
        "capital": "Podgorica",
        "currency": "EUR",
        "states": [
            "Andrijevica Municipality",
            "�avnik Municipality",
            "�abljak Municipality",
            "Bar Municipality",
            "Berane Municipality",
            "Bijelo Polje Municipality",
            "Budva Municipality",
            "Danilovgrad Municipality",
            "Gusinje Municipality",
            "Kola�in Municipality",
            "Kotor Municipality",
            "Mojkovac Municipality",
            "Nik�i? Municipality",
            "Old Royal Capital Cetinje",
            "Petnjica Municipality",
            "Plav Municipality",
            "Pljevlja Municipality",
            "Plu�ine Municipality",
            "Podgorica Municipality",
            "Ro�aje Municipality",
            "Tivat Municipality",
            "Ulcinj Municipality"
        ]
    },
    {
        "name": "Montserrat",
        "iso3": "MSR",
        "iso2": "MS",
        "phone_code": "+1-664",
        "capital": "Plymouth",
        "currency": "XCD",
        "states": [
            "Andrijevica Municipality",
            "�avnik Municipality",
            "�abljak Municipality",
            "Bar Municipality",
            "Berane Municipality",
            "Bijelo Polje Municipality",
            "Budva Municipality",
            "Danilovgrad Municipality",
            "Gusinje Municipality",
            "Kola�in Municipality",
            "Kotor Municipality",
            "Mojkovac Municipality",
            "Nik�i? Municipality",
            "Old Royal Capital Cetinje",
            "Petnjica Municipality",
            "Plav Municipality",
            "Pljevlja Municipality",
            "Plu�ine Municipality",
            "Podgorica Municipality",
            "Ro�aje Municipality",
            "Tivat Municipality",
            "Ulcinj Municipality"
        ]
    },
    {
        "name": "Morocco",
        "iso3": "MAR",
        "iso2": "MA",
        "phone_code": "212",
        "capital": "Rabat",
        "currency": "MAD",
        "states": [
            "Al Haouz Province",
            "Al Hoce�ma Province",
            "Aousserd Province",
            "Assa-Zag Province",
            "Azilal Province",
            "B�ni Mellal-Kh�nifra",
            "B�ni-Mellal Province",
            "Ben Slimane Province",
            "Berkane Province",
            "Boujdour Province",
            "Boulemane Province",
            "Casablanca-Settat",
            "Chefchaouen Province",
            "Chichaoua Province",
            "Dakhla-Oued Ed-Dahab",
            "Dr�a-Tafilalet",
            "El Hajeb Province",
            "El Jadida Province",
            "Errachidia Province",
            "Es Semara Province",
            "Essaouira Province",
            "Fahs Anjra Province",
            "F�s-Mekn�s",
            "Figuig Province",
            "Guelmim Province",
            "Guelmim-Oued Noun",
            "Ifrane Province",
            "Inezgane-A�t Melloul Prefecture",
            "Jerada Province",
            "K�nitra Province",
            "Kelaat Sraghna Province",
            "Kh�misset Province",
            "Kh�nifra Province",
            "Khouribga Province",
            "La�youne Province",
            "La�youne-Sakia El Hamra",
            "Larache Province",
            "Marrakesh-Safi",
            "Mediouna Province",
            "Moulay Yacoub Province",
            "Nador Province",
            "Nouaceur Province",
            "Oriental",
            "Ouarzazate Province",
            "Oued Ed-Dahab Province",
            "Safi Province",
            "Sefrou Province",
            "Settat Province",
            "Shtouka Ait Baha Province",
            "Sidi Kacem Province",
            "Sidi Youssef Ben Ali",
            "Souss-Massa",
            "Tan-Tan Province",
            "Tanger-T�touan-Al Hoce�ma",
            "Taounate Province",
            "Taourirt Province",
            "Taroudant Province",
            "Tata Province",
            "Taza Province",
            "T�touan Province",
            "Tiznit Province",
            "Zagora Province"
        ]
    },
    {
        "name": "Mozambique",
        "iso3": "MOZ",
        "iso2": "MZ",
        "phone_code": "258",
        "capital": "Maputo",
        "currency": "MZN",
        "states": [
            "Cabo Delgado Province",
            "Gaza Province",
            "Inhambane Province",
            "Manica Province",
            "Maputo",
            "Maputo Province",
            "Nampula Province",
            "Niassa Province",
            "Sofala Province",
            "Tete Province",
            "Zambezia Province"
        ]
    },
    {
        "name": "Myanmar",
        "iso3": "MMR",
        "iso2": "MM",
        "phone_code": "95",
        "capital": "Nay Pyi Taw",
        "currency": "MMK",
        "states": [
            "Ayeyarwady Region",
            "Bago",
            "Chin State",
            "Kachin State",
            "Kayah State",
            "Kayin State",
            "Magway Region",
            "Mandalay Region",
            "Mon State",
            "Naypyidaw Union Territory",
            "Rakhine State",
            "Sagaing Region",
            "Shan State",
            "Tanintharyi Region",
            "Yangon Region"
        ]
    },
    {
        "name": "Namibia",
        "iso3": "NAM",
        "iso2": "NA",
        "phone_code": "264",
        "capital": "Windhoek",
        "currency": "NAD",
        "states": [
            "Erongo Region",
            "Hardap Region",
            "Karas Region",
            "Kavango East Region",
            "Kavango West Region",
            "Khomas Region",
            "Kunene Region",
            "Ohangwena Region",
            "Omaheke Region",
            "Omusati Region",
            "Oshana Region",
            "Oshikoto Region",
            "Otjozondjupa Region",
            "Zambezi Region"
        ]
    },
    {
        "name": "Nauru",
        "iso3": "NRU",
        "iso2": "NR",
        "phone_code": "674",
        "capital": "Yaren",
        "currency": "AUD",
        "states": [
            "Aiwo District",
            "Anabar District",
            "Anetan District",
            "Anibare District",
            "Baiti District",
            "Boe District",
            "Buada District",
            "Denigomodu District",
            "Ewa District",
            "Ijuw District",
            "Meneng District",
            "Nibok District",
            "Uaboe District",
            "Yaren District"
        ]
    },
    {
        "name": "Nepal",
        "iso3": "NPL",
        "iso2": "NP",
        "phone_code": "977",
        "capital": "Kathmandu",
        "currency": "NPR",
        "states": [
            "Bagmati Zone",
            "Bheri Zone",
            "Central Region",
            "Dhaulagiri Zone",
            "Eastern Development Region",
            "Far-Western Development Region",
            "Gandaki Zone",
            "Janakpur Zone",
            "Karnali Zone",
            "Kosi Zone",
            "Lumbini Zone",
            "Mahakali Zone",
            "Mechi Zone",
            "Mid-Western Region",
            "Narayani Zone",
            "Rapti Zone",
            "Sagarmatha Zone",
            "Seti Zone",
            "Western Region"
        ]
    },
    {
        "name": "Netherlands Antilles",
        "iso3": "ANT",
        "iso2": "AN",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "states": [
            "Bagmati Zone",
            "Bheri Zone",
            "Central Region",
            "Dhaulagiri Zone",
            "Eastern Development Region",
            "Far-Western Development Region",
            "Gandaki Zone",
            "Janakpur Zone",
            "Karnali Zone",
            "Kosi Zone",
            "Lumbini Zone",
            "Mahakali Zone",
            "Mechi Zone",
            "Mid-Western Region",
            "Narayani Zone",
            "Rapti Zone",
            "Sagarmatha Zone",
            "Seti Zone",
            "Western Region"
        ]
    },
    {
        "name": "Netherlands The",
        "iso3": "NLD",
        "iso2": "NL",
        "phone_code": "31",
        "capital": "Amsterdam",
        "currency": "EUR",
        "states": [
            "Bonaire",
            "Drenthe",
            "Flevoland",
            "Friesland",
            "Gelderland",
            "Groningen",
            "Limburg",
            "North Brabant",
            "North Holland",
            "Overijssel",
            "Saba",
            "Sint Eustatius",
            "South Holland",
            "Utrecht",
            "Zeeland"
        ]
    },
    {
        "name": "New Caledonia",
        "iso3": "NCL",
        "iso2": "NC",
        "phone_code": "687",
        "capital": "Noumea",
        "currency": "XPF",
        "states": [
            "Bonaire",
            "Drenthe",
            "Flevoland",
            "Friesland",
            "Gelderland",
            "Groningen",
            "Limburg",
            "North Brabant",
            "North Holland",
            "Overijssel",
            "Saba",
            "Sint Eustatius",
            "South Holland",
            "Utrecht",
            "Zeeland"
        ]
    },
    {
        "name": "New Zealand",
        "iso3": "NZL",
        "iso2": "NZ",
        "phone_code": "64",
        "capital": "Wellington",
        "currency": "NZD",
        "states": [
            "Auckland Region",
            "Bay of Plenty Region",
            "Canterbury Region",
            "Chatham Islands",
            "Gisborne District",
            "Hawke's Bay Region",
            "Manawatu-Wanganui Region",
            "Marlborough Region",
            "Nelson Region",
            "Northland Region",
            "Otago Region",
            "Southland Region",
            "Taranaki Region",
            "Tasman District",
            "Waikato Region",
            "Wellington Region",
            "West Coast Region"
        ]
    },
    {
        "name": "Nicaragua",
        "iso3": "NIC",
        "iso2": "NI",
        "phone_code": "505",
        "capital": "Managua",
        "currency": "NIO",
        "states": [
            "Boaco Department",
            "Carazo Department",
            "Chinandega Department",
            "Chontales Department",
            "Estel� Department",
            "Granada Department",
            "Jinotega Department",
            "Le�n Department",
            "Madriz Department",
            "Managua Department",
            "Masaya Department",
            "Matagalpa Department",
            "North Caribbean Coast Autonomous Region",
            "R�o San Juan Department",
            "Rivas Department",
            "South Caribbean Coast Autonomous Region"
        ]
    },
    {
        "name": "Niger",
        "iso3": "NER",
        "iso2": "NE",
        "phone_code": "227",
        "capital": "Niamey",
        "currency": "XOF",
        "states": [
            "Agadez Region",
            "Diffa Region",
            "Dosso Region",
            "Maradi Region",
            "Tahoua Region",
            "Tillab�ri Region",
            "Zinder Region"
        ]
    },
    {
        "name": "Nigeria",
        "iso3": "NGA",
        "iso2": "NG",
        "phone_code": "234",
        "capital": "Abuja",
        "currency": "NGN",
        "states": [
            "Abia State",
            "Adamawa State",
            "Akwa Ibom State",
            "Anambra State",
            "Bauchi State",
            "Bayelsa State",
            "Benue State",
            "Borno State",
            "Cross River State",
            "Delta State",
            "Ebonyi State",
            "Edo State",
            "Ekiti State",
            "Enugu State",
            "Federal Capital Territory",
            "Gombe State",
            "Imo State",
            "Jigawa State",
            "Kaduna State",
            "Kano State",
            "Katsina State",
            "Kebbi State",
            "Kogi State",
            "Kwara State",
            "Lagos",
            "Nasarawa State",
            "Niger State",
            "Ogun State",
            "Ondo State",
            "Osun State",
            "Oyo State",
            "Plateau State",
            "Sokoto State",
            "Taraba State",
            "Yobe State",
            "Zamfara State"
        ]
    },
    {
        "name": "Niue",
        "iso3": "NIU",
        "iso2": "NU",
        "phone_code": "683",
        "capital": "Alofi",
        "currency": "NZD",
        "states": [
            "Abia State",
            "Adamawa State",
            "Akwa Ibom State",
            "Anambra State",
            "Bauchi State",
            "Bayelsa State",
            "Benue State",
            "Borno State",
            "Cross River State",
            "Delta State",
            "Ebonyi State",
            "Edo State",
            "Ekiti State",
            "Enugu State",
            "Federal Capital Territory",
            "Gombe State",
            "Imo State",
            "Jigawa State",
            "Kaduna State",
            "Kano State",
            "Katsina State",
            "Kebbi State",
            "Kogi State",
            "Kwara State",
            "Lagos",
            "Nasarawa State",
            "Niger State",
            "Ogun State",
            "Ondo State",
            "Osun State",
            "Oyo State",
            "Plateau State",
            "Sokoto State",
            "Taraba State",
            "Yobe State",
            "Zamfara State"
        ]
    },
    {
        "name": "Norfolk Island",
        "iso3": "NFK",
        "iso2": "NF",
        "phone_code": "672",
        "capital": "Kingston",
        "currency": "AUD",
        "states": [
            "Abia State",
            "Adamawa State",
            "Akwa Ibom State",
            "Anambra State",
            "Bauchi State",
            "Bayelsa State",
            "Benue State",
            "Borno State",
            "Cross River State",
            "Delta State",
            "Ebonyi State",
            "Edo State",
            "Ekiti State",
            "Enugu State",
            "Federal Capital Territory",
            "Gombe State",
            "Imo State",
            "Jigawa State",
            "Kaduna State",
            "Kano State",
            "Katsina State",
            "Kebbi State",
            "Kogi State",
            "Kwara State",
            "Lagos",
            "Nasarawa State",
            "Niger State",
            "Ogun State",
            "Ondo State",
            "Osun State",
            "Oyo State",
            "Plateau State",
            "Sokoto State",
            "Taraba State",
            "Yobe State",
            "Zamfara State"
        ]
    },
    {
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "iso2": "MP",
        "phone_code": "+1-670",
        "capital": "Saipan",
        "currency": "USD",
        "states": [
            "Abia State",
            "Adamawa State",
            "Akwa Ibom State",
            "Anambra State",
            "Bauchi State",
            "Bayelsa State",
            "Benue State",
            "Borno State",
            "Cross River State",
            "Delta State",
            "Ebonyi State",
            "Edo State",
            "Ekiti State",
            "Enugu State",
            "Federal Capital Territory",
            "Gombe State",
            "Imo State",
            "Jigawa State",
            "Kaduna State",
            "Kano State",
            "Katsina State",
            "Kebbi State",
            "Kogi State",
            "Kwara State",
            "Lagos",
            "Nasarawa State",
            "Niger State",
            "Ogun State",
            "Ondo State",
            "Osun State",
            "Oyo State",
            "Plateau State",
            "Sokoto State",
            "Taraba State",
            "Yobe State",
            "Zamfara State"
        ]
    },
    {
        "name": "Norway",
        "iso3": "NOR",
        "iso2": "NO",
        "phone_code": "47",
        "capital": "Oslo",
        "currency": "NOK",
        "states": [
            "Akershus",
            "�stfold",
            "Buskerud",
            "Finnmark",
            "Hedmark",
            "Hordaland",
            "Jan Mayen",
            "M�re og Romsdal",
            "Nord-Tr�ndelag",
            "Nordland",
            "Oppland",
            "Oslo",
            "Rogaland",
            "S�r-Tr�ndelag",
            "Sogn og Fjordane",
            "Svalbard",
            "Telemark",
            "Tr�ndelag",
            "Troms",
            "Vest-Agder",
            "Vestfold"
        ]
    },
    {
        "name": "Oman",
        "iso3": "OMN",
        "iso2": "OM",
        "phone_code": "968",
        "capital": "Muscat",
        "currency": "OMR",
        "states": [
            "Ad Dakhiliyah Governorate",
            "Ad Dhahirah Governorate",
            "Al Batinah North Governorate",
            "Al Batinah Region",
            "Al Batinah South Governorate",
            "Al Buraimi Governorate",
            "Al Wusta Governorate",
            "Ash Sharqiyah North Governorate",
            "Ash Sharqiyah Region",
            "Ash Sharqiyah South Governorate",
            "Dhofar Governorate",
            "Musandam Governorate",
            "Muscat Governorate"
        ]
    },
    {
        "name": "Pakistan",
        "iso3": "PAK",
        "iso2": "PK",
        "phone_code": "92",
        "capital": "Islamabad",
        "currency": "PKR",
        "states": [
            "Azad Kashmir",
            "Balochistan",
            "Federally Administered Tribal Areas",
            "Gilgit-Baltistan",
            "Islamabad Capital Territory",
            "Khyber Pakhtunkhwa",
            "Punjab",
            "Sindh"
        ]
    },
    {
        "name": "Palau",
        "iso3": "PLW",
        "iso2": "PW",
        "phone_code": "680",
        "capital": "Melekeok",
        "currency": "USD",
        "states": [
            "Aimeliik",
            "Airai",
            "Angaur",
            "Hatohobei",
            "Kayangel",
            "Koror",
            "Melekeok",
            "Ngaraard",
            "Ngarchelong",
            "Ngardmau",
            "Ngatpang",
            "Ngchesar",
            "Ngeremlengui",
            "Ngiwal",
            "Peleliu",
            "Sonsorol"
        ]
    },
    {
        "name": "Palestinian Territory Occupied",
        "iso3": "PSE",
        "iso2": "PS",
        "phone_code": "970",
        "capital": "East Jerusalem",
        "currency": "ILS",
        "states": [
            "Aimeliik",
            "Airai",
            "Angaur",
            "Hatohobei",
            "Kayangel",
            "Koror",
            "Melekeok",
            "Ngaraard",
            "Ngarchelong",
            "Ngardmau",
            "Ngatpang",
            "Ngchesar",
            "Ngeremlengui",
            "Ngiwal",
            "Peleliu",
            "Sonsorol"
        ]
    },
    {
        "name": "Panama",
        "iso3": "PAN",
        "iso2": "PA",
        "phone_code": "507",
        "capital": "Panama City",
        "currency": "PAB",
        "states": [
            "Bocas del Toro Province",
            "Chiriqu� Province",
            "Cocl� Province",
            "Col�n Province",
            "Dari�n Province",
            "Ember�-Wounaan Comarca",
            "Guna Yala",
            "Herrera Province",
            "Los Santos Province",
            "Ng�be-Bugl� Comarca",
            "Panam� Oeste Province",
            "Panam� Province",
            "Veraguas Province"
        ]
    },
    {
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "iso2": "PG",
        "phone_code": "675",
        "capital": "Port Moresby",
        "currency": "PGK",
        "states": [
            "Bougainville",
            "Central Province",
            "Chimbu Province",
            "East New Britain",
            "Eastern Highlands Province",
            "Enga Province",
            "Gulf",
            "Hela",
            "Jiwaka Province",
            "Madang Province",
            "Manus Province",
            "Milne Bay Province",
            "Morobe Province",
            "New Ireland Province",
            "Oro Province",
            "Port Moresby",
            "Sandaun Province",
            "Southern Highlands Province",
            "West New Britain Province",
            "Western Highlands Province",
            "Western Province"
        ]
    },
    {
        "name": "Paraguay",
        "iso3": "PRY",
        "iso2": "PY",
        "phone_code": "595",
        "capital": "Asuncion",
        "currency": "PYG",
        "states": [
            "Alto Paraguay Department",
            "Alto Paran� Department",
            "Amambay Department",
            "�eembuc� Department",
            "Boquer�n Department",
            "Caaguaz�",
            "Caazap�",
            "Canindey�",
            "Central Department",
            "Concepci�n Department",
            "Cordillera Department",
            "Guair� Department",
            "Itap�a",
            "Misiones Department",
            "Paraguar� Department",
            "Presidente Hayes Department",
            "San Pedro Department"
        ]
    },
    {
        "name": "Peru",
        "iso3": "PER",
        "iso2": "PE",
        "phone_code": "51",
        "capital": "Lima",
        "currency": "PEN",
        "states": [
            "Amazonas",
            "Apur�mac",
            "Arequipa",
            "Ayacucho",
            "�ncash",
            "Cajamarca",
            "Callao",
            "Cusco",
            "Huancavelica",
            "Huanuco",
            "Ica",
            "Jun�n",
            "La Libertad",
            "Lambayeque",
            "Lima",
            "Lima Province",
            "Madre de Dios",
            "Moquegua",
            "Pasco",
            "Piura",
            "Puno",
            "San Mart�n",
            "Tacna",
            "Tumbes",
            "Ucayali"
        ]
    },
    {
        "name": "Philippines",
        "iso3": "PHL",
        "iso2": "PH",
        "phone_code": "63",
        "capital": "Manila",
        "currency": "PHP",
        "states": [
            "Abra",
            "Agusan del Norte",
            "Agusan del Sur",
            "Aklan",
            "Albay",
            "Antique",
            "Apayao",
            "Aurora",
            "Autonomous Region in Muslim Mindanao",
            "Basilan",
            "Bataan",
            "Batanes",
            "Batangas",
            "Benguet",
            "Bicol Region",
            "Biliran",
            "Bohol",
            "Bukidnon",
            "Bulacan",
            "Cagayan",
            "Cagayan Valley",
            "Calabarzon",
            "Camarines Norte",
            "Camarines Sur",
            "Camiguin",
            "Capiz",
            "Caraga",
            "Catanduanes",
            "Cavite",
            "Cebu",
            "Central Luzon",
            "Central Visayas",
            "Compostela Valley",
            "Cordillera Administrative Region",
            "Cotabato",
            "Davao del Norte",
            "Davao del Sur",
            "Davao Occidental",
            "Davao Oriental",
            "Davao Region",
            "Dinagat Islands",
            "Eastern Samar",
            "Eastern Visayas",
            "Guimaras",
            "Ifugao",
            "Ilocos Norte",
            "Ilocos Region",
            "Ilocos Sur",
            "Iloilo",
            "Isabela",
            "Kalinga",
            "La Union",
            "Laguna",
            "Lanao del Norte",
            "Lanao del Sur",
            "Leyte",
            "Maguindanao",
            "Marinduque",
            "Masbate",
            "Metro Manila",
            "Mimaropa",
            "Misamis Occidental",
            "Misamis Oriental",
            "Mountain Province",
            "Negros Occidental",
            "Negros Oriental",
            "Northern Mindanao",
            "Northern Samar",
            "Nueva Ecija",
            "Nueva Vizcaya",
            "Occidental Mindoro",
            "Oriental Mindoro",
            "Palawan",
            "Pampanga",
            "Pangasinan",
            "Quezon",
            "Quirino",
            "Rizal",
            "Romblon",
            "Sarangani",
            "Siquijor",
            "Soccsksargen",
            "Sorsogon",
            "South Cotabato",
            "Southern Leyte",
            "Sultan Kudarat",
            "Sulu",
            "Surigao del Norte",
            "Surigao del Sur",
            "Tarlac",
            "Tawi-Tawi",
            "Western Visayas",
            "Zambales",
            "Zamboanga del Norte",
            "Zamboanga del Sur",
            "Zamboanga Peninsula",
            "Zamboanga Sibugay"
        ]
    },
    {
        "name": "Pitcairn Island",
        "iso3": "PCN",
        "iso2": "PN",
        "phone_code": "870",
        "capital": "Adamstown",
        "currency": "NZD",
        "states": [
            "Abra",
            "Agusan del Norte",
            "Agusan del Sur",
            "Aklan",
            "Albay",
            "Antique",
            "Apayao",
            "Aurora",
            "Autonomous Region in Muslim Mindanao",
            "Basilan",
            "Bataan",
            "Batanes",
            "Batangas",
            "Benguet",
            "Bicol Region",
            "Biliran",
            "Bohol",
            "Bukidnon",
            "Bulacan",
            "Cagayan",
            "Cagayan Valley",
            "Calabarzon",
            "Camarines Norte",
            "Camarines Sur",
            "Camiguin",
            "Capiz",
            "Caraga",
            "Catanduanes",
            "Cavite",
            "Cebu",
            "Central Luzon",
            "Central Visayas",
            "Compostela Valley",
            "Cordillera Administrative Region",
            "Cotabato",
            "Davao del Norte",
            "Davao del Sur",
            "Davao Occidental",
            "Davao Oriental",
            "Davao Region",
            "Dinagat Islands",
            "Eastern Samar",
            "Eastern Visayas",
            "Guimaras",
            "Ifugao",
            "Ilocos Norte",
            "Ilocos Region",
            "Ilocos Sur",
            "Iloilo",
            "Isabela",
            "Kalinga",
            "La Union",
            "Laguna",
            "Lanao del Norte",
            "Lanao del Sur",
            "Leyte",
            "Maguindanao",
            "Marinduque",
            "Masbate",
            "Metro Manila",
            "Mimaropa",
            "Misamis Occidental",
            "Misamis Oriental",
            "Mountain Province",
            "Negros Occidental",
            "Negros Oriental",
            "Northern Mindanao",
            "Northern Samar",
            "Nueva Ecija",
            "Nueva Vizcaya",
            "Occidental Mindoro",
            "Oriental Mindoro",
            "Palawan",
            "Pampanga",
            "Pangasinan",
            "Quezon",
            "Quirino",
            "Rizal",
            "Romblon",
            "Sarangani",
            "Siquijor",
            "Soccsksargen",
            "Sorsogon",
            "South Cotabato",
            "Southern Leyte",
            "Sultan Kudarat",
            "Sulu",
            "Surigao del Norte",
            "Surigao del Sur",
            "Tarlac",
            "Tawi-Tawi",
            "Western Visayas",
            "Zambales",
            "Zamboanga del Norte",
            "Zamboanga del Sur",
            "Zamboanga Peninsula",
            "Zamboanga Sibugay"
        ]
    },
    {
        "name": "Poland",
        "iso3": "POL",
        "iso2": "PL",
        "phone_code": "48",
        "capital": "Warsaw",
        "currency": "PLN",
        "states": [
            "?wi?tokrzyskie Voivodeship",
            "?�d? Voivodeship",
            "Greater Poland Voivodeship",
            "Kielce",
            "Kuyavian-Pomeranian Voivodeship",
            "Lesser Poland Voivodeship",
            "Lower Silesian Voivodeship",
            "Lublin Voivodeship",
            "Lubusz Voivodeship",
            "Masovian Voivodeship",
            "Opole Voivodeship",
            "Podkarpackie Voivodeship",
            "Podlaskie Voivodeship",
            "Pomeranian Voivodeship",
            "Silesian Voivodeship",
            "Warmian-Masurian Voivodeship",
            "West Pomeranian Voivodeship"
        ]
    },
    {
        "name": "Portugal",
        "iso3": "PRT",
        "iso2": "PT",
        "phone_code": "351",
        "capital": "Lisbon",
        "currency": "EUR",
        "states": [
            "Aveiro District",
            "Azores",
            "�vora District",
            "Beja District",
            "Braga District",
            "Bragan�a District",
            "Castelo Branco District",
            "Coimbra District",
            "Faro District",
            "Leiria District",
            "Lisbon District",
            "Madeira",
            "Portalegre District",
            "Porto District",
            "Santar�m District",
            "Set�bal District",
            "Viana do Castelo District",
            "Vila Real District",
            "Viseu District"
        ]
    },
    {
        "name": "Puerto Rico",
        "iso3": "PRI",
        "iso2": "PR",
        "phone_code": "+1-787 and 1-939",
        "capital": "San Juan",
        "currency": "USD",
        "states": [
            "Aveiro District",
            "Azores",
            "�vora District",
            "Beja District",
            "Braga District",
            "Bragan�a District",
            "Castelo Branco District",
            "Coimbra District",
            "Faro District",
            "Leiria District",
            "Lisbon District",
            "Madeira",
            "Portalegre District",
            "Porto District",
            "Santar�m District",
            "Set�bal District",
            "Viana do Castelo District",
            "Vila Real District",
            "Viseu District"
        ]
    },
    {
        "name": "Qatar",
        "iso3": "QAT",
        "iso2": "QA",
        "phone_code": "974",
        "capital": "Doha",
        "currency": "QAR",
        "states": [
            "Al Daayen",
            "Al Khor",
            "Al Rayyan Municipality",
            "Al Wakrah",
            "Al-Shahaniya",
            "Doha",
            "Madinat ash Shamal",
            "Umm Salal Municipality"
        ]
    },
    {
        "name": "Reunion",
        "iso3": "REU",
        "iso2": "RE",
        "phone_code": "262",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "states": [
            "Al Daayen",
            "Al Khor",
            "Al Rayyan Municipality",
            "Al Wakrah",
            "Al-Shahaniya",
            "Doha",
            "Madinat ash Shamal",
            "Umm Salal Municipality"
        ]
    },
    {
        "name": "Romania",
        "iso3": "ROU",
        "iso2": "RO",
        "phone_code": "40",
        "capital": "Bucharest",
        "currency": "RON",
        "states": [
            "Alba",
            "Arad County",
            "Arges",
            "Bac?u County",
            "Bihor County",
            "Bistri?a-N?s?ud County",
            "Boto?ani County",
            "Bra?ov County",
            "Braila",
            "Bucharest",
            "Buz?u County",
            "Cara?-Severin County",
            "C?l?ra?i County",
            "Cluj County",
            "Constan?a County",
            "Covasna County",
            "D�mbovi?a County",
            "Dolj County",
            "Gala?i County",
            "Giurgiu County",
            "Gorj County",
            "Harghita County",
            "Hunedoara County",
            "Ia?i County",
            "Ialomi?a County",
            "Ilfov County",
            "Maramure? County",
            "Mehedin?i County",
            "Neam? County",
            "Olt County",
            "Prahova County",
            "Satu Mare County",
            "S?laj County",
            "Sibiu County",
            "Suceava County",
            "Teleorman County",
            "Timi? County",
            "Tulcea County",
            "Vaslui County",
            "V�lcea County",
            "Vrancea County"
        ]
    },
    {
        "name": "Russia",
        "iso3": "RUS",
        "iso2": "RU",
        "phone_code": "7",
        "capital": "Moscow",
        "currency": "RUB",
        "states": [
            "Altai Krai",
            "Altai Republic",
            "Amur Oblast",
            "Arkhangelsk",
            "Astrakhan Oblast",
            "Belgorod Oblast",
            "Bryansk Oblast",
            "Chechen Republic",
            "Chelyabinsk Oblast",
            "Chukotka Autonomous Okrug",
            "Chuvash Republic",
            "Irkutsk",
            "Ivanovo Oblast",
            "Jewish Autonomous Oblast",
            "Kabardino-Balkar Republic",
            "Kaliningrad",
            "Kaluga Oblast",
            "Kamchatka Krai",
            "Karachay-Cherkess Republic",
            "Kemerovo Oblast",
            "Khabarovsk Krai",
            "Khanty-Mansi Autonomous Okrug",
            "Kirov Oblast",
            "Komi Republic",
            "Kostroma Oblast",
            "Krasnodar Krai",
            "Krasnoyarsk Krai",
            "Kurgan Oblast",
            "Kursk Oblast",
            "Leningrad Oblast",
            "Lipetsk Oblast",
            "Magadan Oblast",
            "Mari El Republic",
            "Moscow",
            "Moscow Oblast",
            "Murmansk Oblast",
            "Nenets Autonomous Okrug",
            "Nizhny Novgorod Oblast",
            "Novgorod Oblast",
            "Novosibirsk",
            "Omsk Oblast",
            "Orenburg Oblast",
            "Oryol Oblast",
            "Penza Oblast",
            "Perm Krai",
            "Primorsky Krai",
            "Pskov Oblast",
            "Republic of Adygea",
            "Republic of Bashkortostan",
            "Republic of Buryatia",
            "Republic of Dagestan",
            "Republic of Ingushetia",
            "Republic of Kalmykia",
            "Republic of Karelia",
            "Republic of Khakassia",
            "Republic of Mordovia",
            "Republic of North Ossetia-Alania",
            "Republic of Tatarstan",
            "Rostov Oblast",
            "Ryazan Oblast",
            "Saint Petersburg",
            "Sakha Republic",
            "Sakhalin",
            "Samara Oblast",
            "Saratov Oblast",
            "Sevastopol",
            "Smolensk Oblast",
            "Stavropol Krai",
            "Sverdlovsk",
            "Tambov Oblast",
            "Tomsk Oblast",
            "Tula Oblast",
            "Tuva Republic",
            "Tver Oblast",
            "Tyumen Oblast",
            "Udmurt Republic",
            "Ulyanovsk Oblast",
            "Vladimir Oblast",
            "Vologda Oblast",
            "Voronezh Oblast",
            "Yamalo-Nenets Autonomous Okrug",
            "Yaroslavl Oblast",
            "Zabaykalsky Krai"
        ]
    },
    {
        "name": "Rwanda",
        "iso3": "RWA",
        "iso2": "RW",
        "phone_code": "250",
        "capital": "Kigali",
        "currency": "RWF",
        "states": [
            "Eastern Province",
            "Kigali district",
            "Northern Province",
            "Southern Province",
            "Western Province"
        ]
    },
    {
        "name": "Saint Helena",
        "iso3": "SHN",
        "iso2": "SH",
        "phone_code": "290",
        "capital": "Jamestown",
        "currency": "SHP",
        "states": [
            "Eastern Province",
            "Kigali district",
            "Northern Province",
            "Southern Province",
            "Western Province"
        ]
    },
    {
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "iso2": "KN",
        "phone_code": "+1-869",
        "capital": "Basseterre",
        "currency": "XCD",
        "states": [
            "Christ Church Nichola Town Parish",
            "Nevis",
            "Saint Anne Sandy Point Parish",
            "Saint George Gingerland Parish",
            "Saint James Windward Parish",
            "Saint John Capisterre Parish",
            "Saint John Figtree Parish",
            "Saint Kitts",
            "Saint Mary Cayon Parish",
            "Saint Paul Capisterre Parish",
            "Saint Paul Charlestown Parish",
            "Saint Peter Basseterre Parish",
            "Saint Thomas Lowland Parish",
            "Saint Thomas Middle Island Parish",
            "Trinity Palmetto Point Parish"
        ]
    },
    {
        "name": "Saint Lucia",
        "iso3": "LCA",
        "iso2": "LC",
        "phone_code": "+1-758",
        "capital": "Castries",
        "currency": "XCD",
        "states": [
            "Anse la Raye Quarter",
            "Canaries",
            "Castries Quarter",
            "Choiseul Quarter",
            "Dauphin Quarter",
            "Dennery Quarter",
            "Gros Islet Quarter",
            "Laborie Quarter",
            "Micoud Quarter",
            "Praslin Quarter",
            "Soufri�re Quarter",
            "Vieux Fort Quarter"
        ]
    },
    {
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "iso2": "PM",
        "phone_code": "508",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "states": [
            "Anse la Raye Quarter",
            "Canaries",
            "Castries Quarter",
            "Choiseul Quarter",
            "Dauphin Quarter",
            "Dennery Quarter",
            "Gros Islet Quarter",
            "Laborie Quarter",
            "Micoud Quarter",
            "Praslin Quarter",
            "Soufri�re Quarter",
            "Vieux Fort Quarter"
        ]
    },
    {
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "iso2": "VC",
        "phone_code": "+1-784",
        "capital": "Kingstown",
        "currency": "XCD",
        "states": [
            "Charlotte Parish",
            "Grenadines Parish",
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint Patrick Parish"
        ]
    },
    {
        "name": "Saint-Barthelemy",
        "iso3": "BLM",
        "iso2": "BL",
        "phone_code": "590",
        "capital": "Gustavia",
        "currency": "EUR",
        "states": [
            "Charlotte Parish",
            "Grenadines Parish",
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint Patrick Parish"
        ]
    },
    {
        "name": "Saint-Martin (French part)",
        "iso3": "MAF",
        "iso2": "MF",
        "phone_code": "590",
        "capital": "Marigot",
        "currency": "EUR",
        "states": [
            "Charlotte Parish",
            "Grenadines Parish",
            "Saint Andrew Parish",
            "Saint David Parish",
            "Saint George Parish",
            "Saint Patrick Parish"
        ]
    },
    {
        "name": "Samoa",
        "iso3": "WSM",
        "iso2": "WS",
        "phone_code": "685",
        "capital": "Apia",
        "currency": "WST",
        "states": [
            "A'ana",
            "Aiga-i-le-Tai",
            "Atua",
            "Fa'asaleleaga",
            "Gaga'emauga",
            "Gaga'ifomauga",
            "Palauli",
            "Satupa'itea",
            "Tuamasaga",
            "Va'a-o-Fonoti",
            "Vaisigano"
        ]
    },
    {
        "name": "San Marino",
        "iso3": "SMR",
        "iso2": "SM",
        "phone_code": "378",
        "capital": "San Marino",
        "currency": "EUR",
        "states": [
            "Acquaviva",
            "Borgo Maggiore",
            "Chiesanuova",
            "Domagnano",
            "Faetano",
            "Fiorentino",
            "Montegiardino",
            "San Marino",
            "Serravalle"
        ]
    },
    {
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "iso2": "ST",
        "phone_code": "239",
        "capital": "Sao Tome",
        "currency": "STD",
        "states": [
            "Pr�ncipe Province",
            "S�o Tom� Province"
        ]
    },
    {
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "iso2": "SA",
        "phone_code": "966",
        "capital": "Riyadh",
        "currency": "SAR",
        "states": [
            "'Asir Region",
            "Al Bahah Region",
            "Al Jawf Region",
            "Al Madinah Region",
            "Al-Qassim Region",
            "Eastern Province",
            "Ha'il Region",
            "Jizan Region",
            "Makkah Region",
            "Najran Region",
            "Northern Borders Region",
            "Riyadh Region",
            "Tabuk Region"
        ]
    },
    {
        "name": "Senegal",
        "iso3": "SEN",
        "iso2": "SN",
        "phone_code": "221",
        "capital": "Dakar",
        "currency": "XOF",
        "states": [
            "Dakar",
            "Diourbel Region",
            "Fatick",
            "Kaffrine",
            "Kaolack",
            "K�dougou",
            "Kolda",
            "Louga",
            "Matam",
            "Saint-Louis",
            "S�dhiou",
            "Tambacounda Region",
            "Thi�s Region",
            "Ziguinchor"
        ]
    },
    {
        "name": "Serbia",
        "iso3": "SRB",
        "iso2": "RS",
        "phone_code": "381",
        "capital": "Belgrade",
        "currency": "RSD",
        "states": [
            "Autonomous Province of Kosovo and Metohija",
            "�umadija District",
            "Belgrade",
            "Bor District",
            "Brani?evo District",
            "Central Banat District",
            "Jablanica District",
            "Kolubara District",
            "Kosovo-Pomoravlje District",
            "Kosovska Mitrovica District",
            "Ma?va District",
            "Moravica District",
            "Ni�ava District",
            "North Ba?ka District",
            "North Banat District",
            "P?inja District",
            "Pe? District",
            "Pirot District",
            "Podunavlje District",
            "Pomoravlje District",
            "Prizren District",
            "Ra�ka District",
            "Rasina District",
            "South Ba?ka District",
            "South Banat District",
            "Srem District",
            "Toplica District",
            "Vojvodina",
            "West Ba?ka District",
            "Zaje?ar District",
            "Zlatibor District"
        ]
    },
    {
        "name": "Seychelles",
        "iso3": "SYC",
        "iso2": "SC",
        "phone_code": "248",
        "capital": "Victoria",
        "currency": "SCR",
        "states": [
            "Anse Boileau",
            "Anse Royale",
            "Anse-aux-Pins",
            "Au Cap",
            "Baie Lazare",
            "Baie Sainte Anne",
            "Beau Vallon",
            "Bel Air",
            "Bel Ombre",
            "Cascade",
            "Glacis",
            "Grand'Anse Mah�",
            "Grand'Anse Praslin",
            "La Digue",
            "La Rivi�re Anglaise",
            "Les Mamelles",
            "Mont Buxton",
            "Mont Fleuri",
            "Plaisance",
            "Pointe La Rue",
            "Port Glaud",
            "Roche Caiman",
            "Saint Louis",
            "Takamaka"
        ]
    },
    {
        "name": "Sierra Leone",
        "iso3": "SLE",
        "iso2": "SL",
        "phone_code": "232",
        "capital": "Freetown",
        "currency": "SLL",
        "states": [
            "Eastern Province",
            "Northern Province",
            "Southern Province",
            "Western Area"
        ]
    },
    {
        "name": "Singapore",
        "iso3": "SGP",
        "iso2": "SG",
        "phone_code": "65",
        "capital": "Singapur",
        "currency": "SGD",
        "states": [
            "Central Singapore Community Development Council",
            "North East Community Development Council",
            "North West Community Development Council",
            "South East Community Development Council",
            "South West Community Development Council"
        ]
    },
    {
        "name": "Slovakia",
        "iso3": "SVK",
        "iso2": "SK",
        "phone_code": "421",
        "capital": "Bratislava",
        "currency": "EUR",
        "states": [
            "�ilina Region",
            "Bansk� Bystrica Region",
            "Bratislava Region",
            "Ko�ice Region",
            "Nitra Region",
            "Pre�ov Region",
            "Tren?�n Region",
            "Trnava Region"
        ]
    },
    {
        "name": "Slovenia",
        "iso3": "SVN",
        "iso2": "SI",
        "phone_code": "386",
        "capital": "Ljubljana",
        "currency": "EUR",
        "states": [
            "Ajdov�?ina Municipality",
            "Ankaran Municipality",
            "�alovci Municipality",
            "�empeter�Vrtojba Municipality",
            "�en?ur Municipality",
            "�entilj Municipality",
            "�entjernej Municipality",
            "�entjur Municipality",
            "�entrupert Municipality",
            "�kocjan Municipality",
            "�kofja Loka Municipality",
            "�marje pri Jel�ah Municipality",
            "�marje�ke Toplice Municipality",
            "�martno ob Paki Municipality",
            "�martno pri Litiji Municipality",
            "�o�tanj Municipality",
            "�tore Municipality",
            "�alec Municipality",
            "�elezniki Municipality",
            "�etale Municipality",
            "�iri Municipality",
            "�irovnica Municipality",
            "�u�emberk Municipality",
            "?ren�ovci Municipality",
            "?rna na Koro�kem Municipality",
            "?rnomelj Municipality",
            "Beltinci Municipality",
            "Benedikt Municipality",
            "Bistrica ob Sotli Municipality",
            "Bled Municipality",
            "Bloke Municipality",
            "Bohinj Municipality",
            "Borovnica Municipality",
            "Bovec Municipality",
            "Braslov?e Municipality",
            "Brda Municipality",
            "Bre�ice Municipality",
            "Brezovica Municipality",
            "Cankova Municipality",
            "Cerklje na Gorenjskem Municipality",
            "Cerknica Municipality",
            "Cerkno Municipality",
            "Cerkvenjak Municipality",
            "City Municipality of Celje",
            "City Municipality of Novo Mesto",
            "Destrnik Municipality",
            "Diva?a Municipality",
            "Dobje Municipality",
            "Dobrepolje Municipality",
            "Dobrna Municipality",
            "Dobrova�Polhov Gradec Municipality",
            "Dobrovnik Municipality",
            "Dol pri Ljubljani Municipality",
            "Dolenjske Toplice Municipality",
            "Dom�ale Municipality",
            "Dornava Municipality",
            "Dravograd Municipality",
            "Duplek Municipality",
            "Gorenja Vas�Poljane Municipality",
            "Gori�nica Municipality",
            "Gorje Municipality",
            "Gornja Radgona Municipality",
            "Gornji Grad Municipality",
            "Gornji Petrovci Municipality",
            "Grad Municipality",
            "Grosuplje Municipality",
            "Hajdina Municipality",
            "Ho?e�Slivnica Municipality",
            "Hodo� Municipality",
            "Horjul Municipality",
            "Hrastnik Municipality",
            "Hrpelje�Kozina Municipality",
            "Idrija Municipality",
            "Ig Municipality",
            "Ivan?na Gorica Municipality",
            "Izola Municipality",
            "Jesenice Municipality",
            "Jezersko Municipality",
            "Jur�inci Municipality",
            "Kamnik Municipality",
            "Kanal ob So?i Municipality",
            "Kidri?evo Municipality",
            "Ko?evje Municipality",
            "Kobarid Municipality",
            "Kobilje Municipality",
            "Komen Municipality",
            "Komenda Municipality",
            "Koper City Municipality",
            "Kostanjevica na Krki Municipality",
            "Kostel Municipality",
            "Kozje Municipality",
            "Kranj City Municipality",
            "Kranjska Gora Municipality",
            "Kri�evci Municipality",
            "Kungota",
            "Kuzma Municipality",
            "La�ko Municipality",
            "Lenart Municipality",
            "Lendava Municipality",
            "Litija Municipality",
            "Ljubljana City Municipality",
            "Ljubno Municipality",
            "Ljutomer Municipality",
            "Lo�ka Dolina Municipality",
            "Lo�ki Potok Municipality",
            "Logatec Municipality",
            "Log�Dragomer Municipality",
            "Lovrenc na Pohorju Municipality",
            "Lu?e Municipality",
            "Lukovica Municipality",
            "Maj�perk Municipality",
            "Makole Municipality",
            "Maribor City Municipality",
            "Markovci Municipality",
            "Me�ica Municipality",
            "Medvode Municipality",
            "Menge� Municipality",
            "Metlika Municipality",
            "Miklav� na Dravskem Polju Municipality",
            "Miren�Kostanjevica Municipality",
            "Mirna Municipality",
            "Mirna Pe? Municipality",
            "Mislinja Municipality",
            "Mokronog�Trebelno Municipality",
            "Morav?e Municipality",
            "Moravske Toplice Municipality",
            "Mozirje Municipality",
            "Municipality of Apa?e",
            "Municipality of �kofljica",
            "Municipality of Cirkulane",
            "Municipality of Ilirska Bistrica",
            "Municipality of Kr�ko",
            "Murska Sobota City Municipality",
            "Muta Municipality",
            "Naklo Municipality",
            "Nazarje Municipality",
            "Nova Gorica City Municipality",
            "Odranci Municipality",
            "Oplotnica",
            "Ormo� Municipality",
            "Osilnica Municipality",
            "Pesnica Municipality",
            "Piran Municipality",
            "Pivka Municipality",
            "Pod?etrtek Municipality",
            "Podlehnik Municipality",
            "Podvelka Municipality",
            "Polj?ane Municipality",
            "Polzela Municipality",
            "Postojna Municipality",
            "Prebold Municipality",
            "Preddvor Municipality",
            "Prevalje Municipality",
            "Ptuj City Municipality",
            "Puconci Municipality",
            "Ra?e�Fram Municipality",
            "Rade?e Municipality",
            "Radenci Municipality",
            "Radlje ob Dravi Municipality",
            "Radovljica Municipality",
            "Ravne na Koro�kem Municipality",
            "Razkri�je Municipality",
            "Re?ica ob Savinji Municipality",
            "Ren?e�Vogrsko Municipality",
            "Ribnica Municipality",
            "Ribnica na Pohorju Municipality",
            "Roga�ka Slatina Municipality",
            "Roga�ovci Municipality",
            "Rogatec Municipality",
            "Ru�e Municipality",
            "Se�ana Municipality",
            "Selnica ob Dravi Municipality",
            "Semi? Municipality",
            "Sevnica Municipality",
            "Slovenj Gradec City Municipality",
            "Slovenska Bistrica Municipality",
            "Slovenske Konjice Municipality",
            "Sodra�ica Municipality",
            "Sol?ava Municipality",
            "Sredi�?e ob Dravi",
            "Star�e Municipality",
            "Stra�a Municipality",
            "Sveta Ana Municipality",
            "Sveta Trojica v Slovenskih Goricah Municipality",
            "Sveti Andra� v Slovenskih Goricah Municipality",
            "Sveti Jurij ob �?avnici Municipality",
            "Sveti Jurij v Slovenskih Goricah Municipality",
            "Sveti Toma� Municipality",
            "Tabor Municipality",
            "Ti�ina Municipality",
            "Tolmin Municipality",
            "Tr�i? Municipality",
            "Trbovlje Municipality",
            "Trebnje Municipality",
            "Trnovska Vas Municipality",
            "Trzin Municipality",
            "Turni�?e Municipality",
            "Velika Polana Municipality",
            "Velike La�?e Municipality",
            "Ver�ej Municipality",
            "Videm Municipality",
            "Vipava Municipality",
            "Vitanje Municipality",
            "Vodice Municipality",
            "Vojnik Municipality",
            "Vransko Municipality",
            "Vrhnika Municipality",
            "Vuzenica Municipality",
            "Zagorje ob Savi Municipality",
            "Zavr? Municipality",
            "Zre?e Municipality"
        ]
    },
    {
        "name": "Solomon Islands",
        "iso3": "SLB",
        "iso2": "SB",
        "phone_code": "677",
        "capital": "Honiara",
        "currency": "SBD",
        "states": [
            "Central Province",
            "Choiseul Province",
            "Guadalcanal Province",
            "Honiara",
            "Isabel Province",
            "Makira-Ulawa Province",
            "Malaita Province",
            "Rennell and Bellona Province",
            "Temotu Province",
            "Western Province"
        ]
    },
    {
        "name": "Somalia",
        "iso3": "SOM",
        "iso2": "SO",
        "phone_code": "252",
        "capital": "Mogadishu",
        "currency": "SOS",
        "states": [
            "Awdal Region",
            "Bakool",
            "Banaadir",
            "Bari",
            "Bay",
            "Galguduud",
            "Gedo",
            "Hiran",
            "Lower Juba",
            "Lower Shebelle",
            "Middle Juba",
            "Middle Shebelle",
            "Mudug",
            "Nugal",
            "Sanaag Region",
            "Togdheer Region"
        ]
    },
    {
        "name": "South Africa",
        "iso3": "ZAF",
        "iso2": "ZA",
        "phone_code": "27",
        "capital": "Pretoria",
        "currency": "ZAR",
        "states": [
            "Eastern Cape",
            "Free State",
            "Gauteng",
            "KwaZulu-Natal",
            "Limpopo",
            "Mpumalanga",
            "North West",
            "Northern Cape",
            "Western Cape"
        ]
    },
    {
        "name": "South Georgia",
        "iso3": "SGS",
        "iso2": "GS",
        "phone_code": "",
        "capital": "Grytviken",
        "currency": "GBP",
        "states": [
            "Eastern Cape",
            "Free State",
            "Gauteng",
            "KwaZulu-Natal",
            "Limpopo",
            "Mpumalanga",
            "North West",
            "Northern Cape",
            "Western Cape"
        ]
    },
    {
        "name": "South Sudan",
        "iso3": "SSD",
        "iso2": "SS",
        "phone_code": "211",
        "capital": "Juba",
        "currency": "SSP",
        "states": [
            "Central Equatoria",
            "Eastern Equatoria",
            "Jonglei State",
            "Lakes",
            "Northern Bahr el Ghazal",
            "Unity",
            "Upper Nile",
            "Warrap",
            "Western Bahr el Ghazal",
            "Western Equatoria"
        ]
    },
    {
        "name": "Spain",
        "iso3": "ESP",
        "iso2": "ES",
        "phone_code": "34",
        "capital": "Madrid",
        "currency": "EUR",
        "states": [
            "A Coru�a Province",
            "Albacete Province",
            "Alicante Province",
            "Almer�a Province",
            "Andalusia",
            "Araba \/ �lava",
            "Aragon",
            "Badajoz Province",
            "Balearic Islands",
            "Barcelona Province",
            "Basque Country",
            "Biscay",
            "Burgos Province",
            "Canary Islands",
            "Cantabria",
            "Castell�n Province",
            "Castile and Le�n",
            "Castile-La Mancha",
            "Catalonia",
            "C�ceres Province",
            "C�diz Province",
            "C�rdoba Province",
            "Ceuta",
            "Ciudad Real Province",
            "Community of Madrid",
            "Cuenca Province",
            "Extremadura",
            "Galicia",
            "Gipuzkoa",
            "Girona Province",
            "Granada Province",
            "Guadalajara Province",
            "Huelva Province",
            "Huesca Province",
            "Ja�n Province",
            "La Rioja",
            "Las Palmas Province",
            "Le�n Province",
            "Lleida Province",
            "Lugo Province",
            "Madrid Province",
            "M�laga Province",
            "Melilla",
            "Murcia Province",
            "Navarre",
            "Ourense Province",
            "Palencia Province",
            "Pontevedra Province",
            "Province of Asturias",
            "Province of �vila",
            "Region of Murcia",
            "Salamanca Province",
            "Santa Cruz de Tenerife Province",
            "Segovia Province",
            "Seville Province",
            "Soria Province",
            "Tarragona Province",
            "Teruel Province",
            "Toledo Province",
            "Valencia Province",
            "Valencian Community",
            "Valladolid Province",
            "Zamora Province",
            "Zaragoza Province"
        ]
    },
    {
        "name": "Sri Lanka",
        "iso3": "LKA",
        "iso2": "LK",
        "phone_code": "94",
        "capital": "Colombo",
        "currency": "LKR",
        "states": [
            "Ampara District",
            "Anuradhapura District",
            "Badulla District",
            "Batticaloa District",
            "Central Province",
            "Colombo District",
            "Eastern Province",
            "Galle District",
            "Gampaha District",
            "Hambantota District",
            "Jaffna District",
            "Kalutara District",
            "Kandy District",
            "Kegalle District",
            "Kilinochchi District",
            "Mannar District",
            "Matale District",
            "Matara District",
            "Monaragala District",
            "Mullaitivu District",
            "North Central Province",
            "North Western Province",
            "Northern Province",
            "Nuwara Eliya District",
            "Polonnaruwa District",
            "Puttalam District",
            "Ratnapura district",
            "Sabaragamuwa Province",
            "Southern Province",
            "Trincomalee District",
            "Uva Province",
            "Vavuniya District",
            "Western Province"
        ]
    },
    {
        "name": "Sudan",
        "iso3": "SDN",
        "iso2": "SD",
        "phone_code": "249",
        "capital": "Khartoum",
        "currency": "SDG",
        "states": [
            "Al Jazirah",
            "Al Qadarif",
            "Blue Nile",
            "Central Darfur",
            "East Darfur",
            "Kassala",
            "Khartoum",
            "North Darfur",
            "North Kordofan",
            "Northern",
            "Red Sea",
            "River Nile",
            "Sennar",
            "South Darfur",
            "South Kordofan",
            "West Darfur",
            "West Kordofan",
            "White Nile"
        ]
    },
    {
        "name": "Suriname",
        "iso3": "SUR",
        "iso2": "SR",
        "phone_code": "597",
        "capital": "Paramaribo",
        "currency": "SRD",
        "states": [
            "Brokopondo District",
            "Commewijne District",
            "Coronie District",
            "Marowijne District",
            "Nickerie District",
            "Para District",
            "Paramaribo District",
            "Saramacca District",
            "Sipaliwini District",
            "Wanica District"
        ]
    },
    {
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "iso2": "SJ",
        "phone_code": "47",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "states": [
            "Brokopondo District",
            "Commewijne District",
            "Coronie District",
            "Marowijne District",
            "Nickerie District",
            "Para District",
            "Paramaribo District",
            "Saramacca District",
            "Sipaliwini District",
            "Wanica District"
        ]
    },
    {
        "name": "Swaziland",
        "iso3": "SWZ",
        "iso2": "SZ",
        "phone_code": "268",
        "capital": "Mbabane",
        "currency": "SZL",
        "states": [
            "Hhohho District",
            "Lubombo District",
            "Manzini District",
            "Shiselweni District"
        ]
    },
    {
        "name": "Sweden",
        "iso3": "SWE",
        "iso2": "SE",
        "phone_code": "46",
        "capital": "Stockholm",
        "currency": "SEK",
        "states": [
            "�rebro County",
            "�sterg�tland County",
            "Blekinge",
            "Dalarna County",
            "G�vleborg County",
            "Gotland County",
            "Halland County",
            "J�nk�ping County",
            "Kalmar County",
            "Kronoberg County",
            "Norrbotten County",
            "S�dermanland County",
            "Sk�ne County",
            "Stockholm County",
            "Uppsala County",
            "V�rmland County",
            "V�sterbotten County",
            "V�sternorrland County",
            "V�stmanland County",
            "V�stra G�taland County"
        ]
    },
    {
        "name": "Switzerland",
        "iso3": "CHE",
        "iso2": "CH",
        "phone_code": "41",
        "capital": "Berne",
        "currency": "CHF",
        "states": [
            "Aargau",
            "Appenzell Ausserrhoden",
            "Appenzell Innerrhoden",
            "Basel-Landschaft",
            "canton of Bern",
            "Canton of Fribourg",
            "Canton of Geneva",
            "Canton of Jura",
            "Canton of Lucerne",
            "Canton of Neuch�tel",
            "Canton of Schaffhausen",
            "Canton of Solothurn",
            "Canton of St. Gallen",
            "Canton of Valais",
            "Canton of Vaud",
            "canton of Z�rich",
            "Canton of Zug",
            "Glarus",
            "Graub�nden",
            "Nidwalden",
            "Obwalden",
            "Schwyz",
            "Thurgau",
            "Ticino",
            "Uri"
        ]
    },
    {
        "name": "Syria",
        "iso3": "SYR",
        "iso2": "SY",
        "phone_code": "963",
        "capital": "Damascus",
        "currency": "SYP",
        "states": [
            "Al-Hasakah Governorate",
            "Al-Raqqah Governorate",
            "Aleppo Governorate",
            "As-Suwayda Governorate",
            "Damascus Governorate",
            "Daraa Governorate",
            "Deir ez-Zor Governorate",
            "Hama Governorate",
            "Homs Governorate",
            "Idlib Governorate",
            "Latakia Governorate",
            "Quneitra Governorate",
            "Rif Dimashq Governorate",
            "Tartus Governorate"
        ]
    },
    {
        "name": "Taiwan",
        "iso3": "TWN",
        "iso2": "TW",
        "phone_code": "886",
        "capital": "Taipei",
        "currency": "TWD",
        "states": [
            "Changhua County",
            "Chiayi City",
            "Chiayi County",
            "Hsinchu",
            "Hsinchu County",
            "Hualien County",
            "Kaohsiung",
            "Kaohsiung County",
            "Kinmen",
            "Lienchiang County",
            "Miaoli County",
            "Nantou County",
            "Penghu County",
            "Pingtung County",
            "Taichung",
            "Taichung County",
            "Tainan",
            "Tainan County",
            "Taipei",
            "Taitung County",
            "Taoyuan City",
            "Yilan County",
            "Yunlin County"
        ]
    },
    {
        "name": "Tajikistan",
        "iso3": "TJK",
        "iso2": "TJ",
        "phone_code": "992",
        "capital": "Dushanbe",
        "currency": "TJS",
        "states": [
            "districts of Republican Subordination",
            "Gorno-Badakhshan Autonomous Province",
            "Khatlon Province",
            "Sughd Province"
        ]
    },
    {
        "name": "Tanzania",
        "iso3": "TZA",
        "iso2": "TZ",
        "phone_code": "255",
        "capital": "Dodoma",
        "currency": "TZS",
        "states": [
            "Arusha Region",
            "Dar es Salaam Region",
            "Dodoma Region",
            "Geita Region",
            "Iringa Region",
            "Kagera Region",
            "Katavi Region",
            "Kigoma Region",
            "Kilimanjaro Region",
            "Lindi Region",
            "Manyara Region",
            "Mara Region",
            "Morogoro Region",
            "Mtwara Region",
            "Mwanza Region",
            "Njombe Region",
            "North Pemba Region",
            "Pwani Region",
            "Rukwa Region",
            "Ruvuma Region",
            "Shinyanga Region",
            "Simiyu Region",
            "Singida Region",
            "South Pemba Region",
            "Tabora Region",
            "Tanga Region",
            "Zanzibar Central\/South Region",
            "Zanzibar North Region",
            "Zanzibar Urban\/West Region"
        ]
    },
    {
        "name": "Thailand",
        "iso3": "THA",
        "iso2": "TH",
        "phone_code": "66",
        "capital": "Bangkok",
        "currency": "THB",
        "states": [
            "Amnat Charoen",
            "Ang Thong",
            "Bangkok",
            "Bueng Kan",
            "Buri Ram",
            "Chachoengsao",
            "Chai Nat",
            "Chanthaburi",
            "Chiang Mai",
            "Chiang Rai",
            "Chon Buri",
            "Chumphon",
            "Kalasin",
            "Kamphaeng Phet",
            "Kanchanaburi",
            "Khon Kaen",
            "Krabi",
            "Lampang",
            "Lamphun",
            "Loei",
            "Lopburi",
            "Mae Hong Son",
            "Maha Sarakham",
            "Mukdahan",
            "Nakhon Nayok",
            "Nakhon Pathom",
            "Nakhon Phanom",
            "Nakhon Ratchasima",
            "Nakhon Sawan",
            "Nakhon Si Thammarat",
            "Nan",
            "Narathiwat",
            "Nong Bua Lam Phu",
            "Nong Khai",
            "Nonthaburi",
            "Pathum Thani",
            "Pattani",
            "Pattaya",
            "Phang Nga",
            "Phatthalung",
            "Phayao",
            "Phetchabun",
            "Phetchaburi",
            "Phichit",
            "Phitsanulok",
            "Phra Nakhon Si Ayutthaya",
            "Phrae",
            "Phuket",
            "Prachin Buri",
            "Prachuap Khiri Khan",
            "Ranong",
            "Ratchaburi",
            "Rayong",
            "Roi Et",
            "Sa Kaeo",
            "Sakon Nakhon",
            "Samut Prakan",
            "Samut Sakhon",
            "Samut Songkhram",
            "Saraburi",
            "Satun",
            "Si Sa Ket",
            "Sing Buri",
            "Songkhla",
            "Sukhothai",
            "Suphanburi",
            "Surat Thani",
            "Surin",
            "Tak",
            "Trang",
            "Trat",
            "Ubon Ratchathani",
            "Udon Thani",
            "Uthai Thani",
            "Uttaradit",
            "Yala",
            "Yasothon"
        ]
    },
    {
        "name": "Togo",
        "iso3": "TGO",
        "iso2": "TG",
        "phone_code": "228",
        "capital": "Lome",
        "currency": "XOF",
        "states": [
            "Centrale Region",
            "Kara Region",
            "Maritime",
            "Plateaux Region",
            "Savanes Region"
        ]
    },
    {
        "name": "Tokelau",
        "iso3": "TKL",
        "iso2": "TK",
        "phone_code": "690",
        "capital": "",
        "currency": "NZD",
        "states": [
            "Centrale Region",
            "Kara Region",
            "Maritime",
            "Plateaux Region",
            "Savanes Region"
        ]
    },
    {
        "name": "Tonga",
        "iso3": "TON",
        "iso2": "TO",
        "phone_code": "676",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "states": [
            "?Eua",
            "Ha?apai",
            "Niuas",
            "Tongatapu",
            "Vava?u"
        ]
    },
    {
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "iso2": "TT",
        "phone_code": "+1-868",
        "capital": "Port of Spain",
        "currency": "TTD",
        "states": [
            "Arima",
            "Chaguanas",
            "Couva-Tabaquite-Talparo Regional Corporation",
            "Diego Martin Regional Corporation",
            "Eastern Tobago",
            "Penal-Debe Regional Corporation",
            "Point Fortin",
            "Port of Spain",
            "Princes Town Regional Corporation",
            "Rio Claro-Mayaro Regional Corporation",
            "San Fernando",
            "San Juan-Laventille Regional Corporation",
            "Sangre Grande Regional Corporation",
            "Siparia Regional Corporation",
            "Tunapuna-Piarco Regional Corporation",
            "Western Tobago"
        ]
    },
    {
        "name": "Tunisia",
        "iso3": "TUN",
        "iso2": "TN",
        "phone_code": "216",
        "capital": "Tunis",
        "currency": "TND",
        "states": [
            "Ariana Governorate",
            "Ben Arous Governorate",
            "Bizerte Governorate",
            "Gab�s Governorate",
            "Gafsa Governorate",
            "Jendouba Governorate",
            "Kairouan Governorate",
            "Kasserine Governorate",
            "Kassrine",
            "Kebili Governorate",
            "Kef Governorate",
            "Mahdia Governorate",
            "Manouba Governorate",
            "Medenine Governorate",
            "Monastir Governorate",
            "Sfax Governorate",
            "Sidi Bouzid Governorate",
            "Siliana Governorate",
            "Sousse Governorate",
            "Tataouine Governorate",
            "Tozeur Governorate",
            "Tunis Governorate",
            "Zaghouan Governorate"
        ]
    },
    {
        "name": "Turkey",
        "iso3": "TUR",
        "iso2": "TR",
        "phone_code": "90",
        "capital": "Ankara",
        "currency": "TRY",
        "states": [
            "A?r? Province",
            "Adana Province",
            "Ad?yaman Province",
            "Afyonkarahisar Province",
            "Aksaray Province",
            "Amasya Province",
            "Ankara Province",
            "Antalya Province",
            "Ardahan Province",
            "Artvin Province",
            "Ayd?n Province",
            "?anl?urfa Province",
            "??rnak Province",
            "?zmir Province",
            "�anakkale Province",
            "�ank?r? Province",
            "�orum Province",
            "Bal?kesir Province",
            "Bart?n Province",
            "Batman Province",
            "Bayburt Province",
            "Bilecik Province",
            "Bing�l Province",
            "Bitlis Province",
            "Bolu Province",
            "Burdur Province",
            "Bursa Province",
            "D�zce Province",
            "Denizli Province",
            "Diyarbak?r Province",
            "Edirne Province",
            "Elaz?? Province",
            "Erzincan Province",
            "Erzurum Province",
            "Eski?ehir Province",
            "Gaziantep Province",
            "G�m�?hane Province",
            "Giresun Province",
            "Hakk�ri Province",
            "Hatay Province",
            "I?d?r Province",
            "Isparta Province",
            "Istanbul Province",
            "Kahramanmara? Province",
            "Karab�k Province",
            "Karaman Province",
            "Kars Province",
            "Kastamonu Province",
            "Kayseri Province",
            "K?r?ehir Province",
            "K?r?kkale Province",
            "K?rklareli Province",
            "K�tahya Province",
            "Kilis Province",
            "Kocaeli Province",
            "Konya Province",
            "Malatya Province",
            "Manisa Province",
            "Mardin Province",
            "Mersin Province",
            "Mu? Province",
            "Mu?la Province",
            "Nev?ehir Province",
            "Ni?de Province",
            "Ordu Province",
            "Osmaniye Province",
            "Rize Province",
            "Sakarya Province",
            "Samsun Province",
            "Siirt Province",
            "Sivas Province",
            "Tekirda? Province",
            "Tokat Province",
            "Trabzon Province",
            "Tunceli Province",
            "U?ak Province",
            "Van Province",
            "Yalova Province",
            "Yozgat Province",
            "Zonguldak Province"
        ]
    },
    {
        "name": "Turkmenistan",
        "iso3": "TKM",
        "iso2": "TM",
        "phone_code": "993",
        "capital": "Ashgabat",
        "currency": "TMT",
        "states": [
            "Ahal Region",
            "Ashgabat",
            "Balkan Region",
            "Da?oguz Region",
            "Lebap Region",
            "Mary Region"
        ]
    },
    {
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "iso2": "TC",
        "phone_code": "+1-649",
        "capital": "Cockburn Town",
        "currency": "USD",
        "states": [
            "Ahal Region",
            "Ashgabat",
            "Balkan Region",
            "Da?oguz Region",
            "Lebap Region",
            "Mary Region"
        ]
    },
    {
        "name": "Tuvalu",
        "iso3": "TUV",
        "iso2": "TV",
        "phone_code": "688",
        "capital": "Funafuti",
        "currency": "AUD",
        "states": [
            "Funafuti",
            "Nanumanga",
            "Nanumea",
            "Niutao Island Council",
            "Nui",
            "Nukufetau",
            "Nukulaelae",
            "Vaitupu"
        ]
    },
    {
        "name": "Uganda",
        "iso3": "UGA",
        "iso2": "UG",
        "phone_code": "256",
        "capital": "Kampala",
        "currency": "UGX",
        "states": [
            "Abim District",
            "Adjumani District",
            "Agago District",
            "Alebtong District",
            "Amolatar District",
            "Amudat District",
            "Amuria District",
            "Amuru District",
            "Apac District",
            "Arua District",
            "Budaka District",
            "Bududa District",
            "Bugiri District",
            "Buhweju District",
            "Buikwe District",
            "Bukedea District",
            "Bukomansimbi District",
            "Bukwo District",
            "Bulambuli District",
            "Buliisa District",
            "Bundibugyo District",
            "Bunyangabu District",
            "Bushenyi District",
            "Busia District",
            "Butaleja District",
            "Butambala District",
            "Butebo District",
            "Buvuma District",
            "Buyende District",
            "Central Region",
            "Dokolo District",
            "Eastern Region",
            "Gomba District",
            "Gulu District",
            "Ibanda District",
            "Iganga District",
            "Isingiro District",
            "Jinja District",
            "Kaabong District",
            "Kabale District",
            "Kabarole District",
            "Kaberamaido District",
            "Kagadi District",
            "Kakumiro District",
            "Kalangala District",
            "Kaliro District",
            "Kalungu District",
            "Kampala District",
            "Kamuli District",
            "Kamwenge District",
            "Kanungu District",
            "Kapchorwa District",
            "Kasese District",
            "Katakwi District",
            "Kayunga District",
            "Kibaale District",
            "Kiboga District",
            "Kibuku District",
            "Kiruhura District",
            "Kiryandongo District",
            "Kisoro District",
            "Kitgum District",
            "Koboko District",
            "Kole District",
            "Kotido District",
            "Kumi District",
            "Kween District",
            "Kyankwanzi District",
            "Kyegegwa District",
            "Kyenjojo District",
            "Kyotera District",
            "Lamwo District",
            "Lira District",
            "Luuka District",
            "Luwero District",
            "Lwengo District",
            "Lyantonde District",
            "Manafwa District",
            "Maracha District",
            "Masaka District",
            "Masindi District",
            "Mayuge District",
            "Mbale District",
            "Mbarara District",
            "Mitooma District",
            "Mityana District",
            "Moroto District",
            "Moyo District",
            "Mpigi District",
            "Mubende District",
            "Mukono District",
            "Nakapiripirit District",
            "Nakaseke District",
            "Nakasongola District",
            "Namayingo District",
            "Namisindwa District",
            "Namutumba District",
            "Napak District",
            "Nebbi District",
            "Ngora District",
            "Northern Region",
            "Ntoroko District",
            "Ntungamo District",
            "Nwoya District",
            "Omoro District",
            "Otuke District",
            "Oyam District",
            "Pader District",
            "Pakwach District",
            "Pallisa District",
            "Rakai District",
            "Rubanda District",
            "Rubirizi District",
            "Rukiga District",
            "Rukungiri District",
            "Sembabule District",
            "Serere District",
            "Sheema District",
            "Sironko District",
            "Soroti District",
            "Tororo District",
            "Wakiso District",
            "Western Region",
            "Yumbe District",
            "Zombo District"
        ]
    },
    {
        "name": "Ukraine",
        "iso3": "UKR",
        "iso2": "UA",
        "phone_code": "380",
        "capital": "Kiev",
        "currency": "UAH",
        "states": [
            "Autonomous Republic of Crimea",
            "Cherkasy Oblast",
            "Chernihiv Oblast",
            "Chernivtsi Oblast",
            "Dnipropetrovsk Oblast",
            "Donetsk Oblast",
            "Ivano-Frankivsk Oblast",
            "Kharkiv Oblast",
            "Kherson Oblast",
            "Khmelnytsky Oblast",
            "Kiev",
            "Kirovohrad Oblast",
            "Kyiv Oblast",
            "Luhansk Oblast",
            "Lviv Oblast",
            "Mykolaiv Oblast",
            "Odessa Oblast",
            "Rivne Oblast",
            "Sumy Oblast",
            "Ternopil Oblast",
            "Vinnytsia Oblast",
            "Volyn Oblast",
            "Zakarpattia Oblast",
            "Zaporizhzhya Oblast",
            "Zhytomyr Oblast"
        ]
    },
    {
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "iso2": "AE",
        "phone_code": "971",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "states": [
            "Abu Dhabi Emirate",
            "Ajman Emirate",
            "Dubai",
            "Fujairah",
            "Ras al-Khaimah",
            "Sharjah Emirate",
            "Umm al-Quwain"
        ]
    },
    {
        "name": "United Kingdom",
        "iso3": "GBR",
        "iso2": "GB",
        "phone_code": "44",
        "capital": "London",
        "currency": "GBP",
        "states": [
            "Aberdeen",
            "Aberdeenshire",
            "Angus",
            "Antrim",
            "Antrim and Newtownabbey",
            "Ards",
            "Ards and North Down",
            "Argyll and Bute",
            "Armagh City and District Council",
            "Armagh, Banbridge and Craigavon",
            "Ascension Island",
            "Ballymena Borough",
            "Ballymoney",
            "Banbridge",
            "Barnsley",
            "Bath and North East Somerset",
            "Bedford",
            "Belfast district",
            "Birmingham",
            "Blackburn with Darwen",
            "Blackpool",
            "Blaenau Gwent County Borough",
            "Bolton",
            "Bournemouth",
            "Bracknell Forest",
            "Bradford",
            "Bridgend County Borough",
            "Brighton and Hove",
            "Buckinghamshire",
            "Bury",
            "Caerphilly County Borough",
            "Calderdale",
            "Cambridgeshire",
            "Carmarthenshire",
            "Carrickfergus Borough Council",
            "Castlereagh",
            "Causeway Coast and Glens",
            "Central Bedfordshire",
            "Ceredigion",
            "Cheshire East",
            "Cheshire West and Chester",
            "City and County of Cardiff",
            "City and County of Swansea",
            "City of Bristol",
            "City of Derby",
            "City of Kingston upon Hull",
            "City of Leicester",
            "City of London",
            "City of Nottingham",
            "City of Peterborough",
            "City of Plymouth",
            "City of Portsmouth",
            "City of Southampton",
            "City of Stoke-on-Trent",
            "City of Sunderland",
            "City of Westminster",
            "City of Wolverhampton",
            "City of York",
            "Clackmannanshire",
            "Coleraine Borough Council",
            "Conwy County Borough",
            "Cookstown District Council",
            "Cornwall",
            "County Durham",
            "Coventry",
            "Craigavon Borough Council",
            "Cumbria",
            "Darlington",
            "Denbighshire",
            "Derbyshire",
            "Derry City and Strabane",
            "Derry City Council",
            "Devon",
            "Doncaster",
            "Dorset",
            "Down District Council",
            "Dudley",
            "Dumfries and Galloway",
            "Dundee",
            "Dungannon and South Tyrone Borough Council",
            "East Ayrshire",
            "East Dunbartonshire",
            "East Lothian",
            "East Renfrewshire",
            "East Riding of Yorkshire",
            "East Sussex",
            "Edinburgh",
            "England",
            "Essex",
            "Falkirk",
            "Fermanagh and Omagh",
            "Fermanagh District Council",
            "Fife",
            "Flintshire",
            "Gateshead",
            "Glasgow",
            "Gloucestershire",
            "Gwynedd",
            "Halton",
            "Hampshire",
            "Hartlepool",
            "Herefordshire",
            "Hertfordshire",
            "Highland",
            "Inverclyde",
            "Isle of Wight",
            "Isles of Scilly",
            "Kent",
            "Kirklees",
            "Knowsley",
            "Lancashire",
            "Larne Borough Council",
            "Leeds",
            "Leicestershire",
            "Limavady Borough Council",
            "Lincolnshire",
            "Lisburn and Castlereagh",
            "Lisburn City Council",
            "Liverpool",
            "London Borough of Barking and Dagenham",
            "London Borough of Barnet",
            "London Borough of Bexley",
            "London Borough of Brent",
            "London Borough of Bromley",
            "London Borough of Camden",
            "London Borough of Croydon",
            "London Borough of Ealing",
            "London Borough of Enfield",
            "London Borough of Hackney",
            "London Borough of Hammersmith and Fulham",
            "London Borough of Haringey",
            "London Borough of Harrow",
            "London Borough of Havering",
            "London Borough of Hillingdon",
            "London Borough of Hounslow",
            "London Borough of Islington",
            "London Borough of Lambeth",
            "London Borough of Lewisham",
            "London Borough of Merton",
            "London Borough of Newham",
            "London Borough of Redbridge",
            "London Borough of Richmond upon Thames",
            "London Borough of Southwark",
            "London Borough of Sutton",
            "London Borough of Tower Hamlets",
            "London Borough of Waltham Forest",
            "London Borough of Wandsworth",
            "Magherafelt District Council",
            "Manchester",
            "Medway",
            "Merthyr Tydfil County Borough",
            "Metropolitan Borough of Wigan",
            "Mid and East Antrim",
            "Mid Ulster",
            "Middlesbrough",
            "Midlothian",
            "Milton Keynes",
            "Monmouthshire",
            "Moray",
            "Moyle District Council",
            "Neath Port Talbot County Borough",
            "Newcastle upon Tyne",
            "Newport",
            "Newry and Mourne District Council",
            "Newry, Mourne and Down",
            "Newtownabbey Borough Council",
            "Norfolk",
            "North Ayrshire",
            "North Down Borough Council",
            "North East Lincolnshire",
            "North Lanarkshire",
            "North Lincolnshire",
            "North Somerset",
            "North Tyneside",
            "North Yorkshire",
            "Northamptonshire",
            "Northern Ireland",
            "Northumberland",
            "Nottinghamshire",
            "Oldham",
            "Omagh District Council",
            "Orkney Islands",
            "Outer Hebrides",
            "Oxfordshire",
            "Pembrokeshire",
            "Perth and Kinross",
            "Poole",
            "Powys",
            "Reading",
            "Redcar and Cleveland",
            "Renfrewshire",
            "Rhondda Cynon Taf",
            "Rochdale",
            "Rotherham",
            "Royal Borough of Greenwich",
            "Royal Borough of Kensington and Chelsea",
            "Royal Borough of Kingston upon Thames",
            "Rutland",
            "Saint Helena",
            "Salford",
            "Sandwell",
            "Scotland",
            "Scottish Borders",
            "Sefton",
            "Sheffield",
            "Shetland Islands",
            "Shropshire",
            "Slough",
            "Solihull",
            "Somerset",
            "South Ayrshire",
            "South Gloucestershire",
            "South Lanarkshire",
            "South Tyneside",
            "Southend-on-Sea",
            "St Helens",
            "Staffordshire",
            "Stirling",
            "Stockport",
            "Stockton-on-Tees",
            "Strabane District Council",
            "Suffolk",
            "Surrey",
            "Swindon",
            "Tameside",
            "Telford and Wrekin",
            "Thurrock",
            "Torbay",
            "Torfaen",
            "Trafford",
            "United Kingdom",
            "Vale of Glamorgan",
            "Wakefield",
            "Wales",
            "Walsall",
            "Warrington",
            "Warwickshire",
            "West Berkshire",
            "West Dunbartonshire",
            "West Lothian",
            "West Sussex",
            "Wiltshire",
            "Windsor and Maidenhead",
            "Wirral",
            "Wokingham",
            "Worcestershire",
            "Wrexham County Borough"
        ]
    },
    {
        "name": "United States",
        "iso3": "USA",
        "iso2": "US",
        "phone_code": "1",
        "capital": "Washington",
        "currency": "USD",
        "states": [
            "Alabama",
            "Alaska",
            "American Samoa",
            "Arizona",
            "Arkansas",
            "Baker Island",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "District of Columbia",
            "Florida",
            "Georgia",
            "Guam",
            "Hawaii",
            "Howland Island",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Jarvis Island",
            "Johnston Atoll",
            "Kansas",
            "Kentucky",
            "Kingman Reef",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Midway Atoll",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Navassa Island",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Northern Mariana Islands",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Palmyra Atoll",
            "Pennsylvania",
            "Puerto Rico",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "United States Minor Outlying Islands",
            "United States Virgin Islands",
            "Utah",
            "Vermont",
            "Virginia",
            "Wake Island",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ]
    },
    {
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "iso2": "UM",
        "phone_code": "1",
        "capital": "",
        "currency": "USD",
        "states": [
            "Alabama",
            "Alaska",
            "American Samoa",
            "Arizona",
            "Arkansas",
            "Baker Island",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "District of Columbia",
            "Florida",
            "Georgia",
            "Guam",
            "Hawaii",
            "Howland Island",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Jarvis Island",
            "Johnston Atoll",
            "Kansas",
            "Kentucky",
            "Kingman Reef",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Midway Atoll",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Navassa Island",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Northern Mariana Islands",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Palmyra Atoll",
            "Pennsylvania",
            "Puerto Rico",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "United States Minor Outlying Islands",
            "United States Virgin Islands",
            "Utah",
            "Vermont",
            "Virginia",
            "Wake Island",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ]
    },
    {
        "name": "Uruguay",
        "iso3": "URY",
        "iso2": "UY",
        "phone_code": "598",
        "capital": "Montevideo",
        "currency": "UYU",
        "states": [
            "Artigas Department",
            "Canelones Department",
            "Cerro Largo Department",
            "Colonia Department",
            "Durazno Department",
            "Flores Department",
            "Florida Department",
            "Lavalleja Department",
            "Maldonado Department",
            "Montevideo Department",
            "Paysand� Department",
            "R�o Negro Department",
            "Rivera Department",
            "Rocha Department",
            "Salto Department",
            "San Jos� Department",
            "Soriano Department",
            "Tacuaremb� Department",
            "Treinta y Tres Department"
        ]
    },
    {
        "name": "Uzbekistan",
        "iso3": "UZB",
        "iso2": "UZ",
        "phone_code": "998",
        "capital": "Tashkent",
        "currency": "UZS",
        "states": [
            "Andijan Region",
            "Bukhara Region",
            "Fergana Region",
            "Jizzakh Region",
            "Karakalpakstan",
            "Namangan Region",
            "Navoiy Region",
            "Qashqadaryo Region",
            "Samarqand Region",
            "Sirdaryo Region",
            "Surxondaryo Region",
            "Tashkent",
            "Tashkent Region",
            "Xorazm Region"
        ]
    },
    {
        "name": "Vanuatu",
        "iso3": "VUT",
        "iso2": "VU",
        "phone_code": "678",
        "capital": "Port Vila",
        "currency": "VUV",
        "states": [
            "Malampa",
            "Penama",
            "Sanma",
            "Shefa",
            "Tafea",
            "Torba"
        ]
    },
    {
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "iso2": "VA",
        "phone_code": "379",
        "capital": "Vatican City",
        "currency": "EUR",
        "states": [
            "Malampa",
            "Penama",
            "Sanma",
            "Shefa",
            "Tafea",
            "Torba"
        ]
    },
    {
        "name": "Venezuela",
        "iso3": "VEN",
        "iso2": "VE",
        "phone_code": "58",
        "capital": "Caracas",
        "currency": "VEF",
        "states": [
            "Amazonas",
            "Anzo�tegui",
            "Aragua",
            "Barinas",
            "Bol�var",
            "Carabobo",
            "Cojedes",
            "Delta Amacuro",
            "Falc�n",
            "Federal Dependencies of Venezuela",
            "Gu�rico",
            "Lara",
            "M�rida",
            "Miranda",
            "Monagas",
            "Nueva Esparta",
            "Portuguesa",
            "Sucre",
            "T�chira",
            "Trujillo",
            "Vargas",
            "Yaracuy",
            "Zulia"
        ]
    },
    {
        "name": "Vietnam",
        "iso3": "VNM",
        "iso2": "VN",
        "phone_code": "84",
        "capital": "Hanoi",
        "currency": "VND",
        "states": [
            "An Giang",
            "??k L?k",
            "??k N�ng",
            "??ng Nai",
            "??ng Th�p",
            "?i?n Bi�n",
            "B� R?a-V?ng T�u",
            "B�nh ??nh",
            "B�nh D??ng",
            "B�nh Ph??c",
            "B�nh Thu?n",
            "B?c Li�u",
            "B?c Giang",
            "B?c K?n",
            "B?c Ninh",
            "B?n Tre",
            "Cao B?ng",
            "C� Mau",
            "Da Nang",
            "Gia Lai",
            "Haiphong",
            "Hanoi",
            "H� Giang",
            "H� Nam",
            "H� T�y",
            "H� T?nh",
            "H�a B�nh",
            "H?i D??ng",
            "H?u Giang",
            "Ho Chi Minh City",
            "H?ng Y�n",
            "Kh�nh H�a",
            "Ki�n Giang",
            "Kon Tum",
            "Lai Ch�u",
            "L�o Cai",
            "L�m ??ng",
            "L?ng S?n",
            "Long An",
            "Nam ??nh",
            "Ngh? An",
            "Ninh B�nh",
            "Ninh Thu?n",
            "Ph� Th?",
            "Ph� Y�n",
            "Qu?ng B�nh",
            "Qu?ng Nam",
            "Qu?ng Ng�i",
            "Qu?ng Ninh",
            "Qu?ng Tr?",
            "S�c Tr?ng",
            "S?n La",
            "T�y Ninh",
            "Thanh H�a",
            "Th�i B�nh",
            "Th�i Nguy�n",
            "Th?a Thi�n-Hu?",
            "Ti?n Giang",
            "Tr� Vinh",
            "Tuy�n Quang",
            "V?nh Long",
            "V?nh Ph�c",
            "Y�n B�i"
        ]
    },
    {
        "name": "Virgin Islands (British)",
        "iso3": "VGB",
        "iso2": "VG",
        "phone_code": "+1-284",
        "capital": "Road Town",
        "currency": "USD",
        "states": [
            "An Giang",
            "??k L?k",
            "??k N�ng",
            "??ng Nai",
            "??ng Th�p",
            "?i?n Bi�n",
            "B� R?a-V?ng T�u",
            "B�nh ??nh",
            "B�nh D??ng",
            "B�nh Ph??c",
            "B�nh Thu?n",
            "B?c Li�u",
            "B?c Giang",
            "B?c K?n",
            "B?c Ninh",
            "B?n Tre",
            "Cao B?ng",
            "C� Mau",
            "Da Nang",
            "Gia Lai",
            "Haiphong",
            "Hanoi",
            "H� Giang",
            "H� Nam",
            "H� T�y",
            "H� T?nh",
            "H�a B�nh",
            "H?i D??ng",
            "H?u Giang",
            "Ho Chi Minh City",
            "H?ng Y�n",
            "Kh�nh H�a",
            "Ki�n Giang",
            "Kon Tum",
            "Lai Ch�u",
            "L�o Cai",
            "L�m ??ng",
            "L?ng S?n",
            "Long An",
            "Nam ??nh",
            "Ngh? An",
            "Ninh B�nh",
            "Ninh Thu?n",
            "Ph� Th?",
            "Ph� Y�n",
            "Qu?ng B�nh",
            "Qu?ng Nam",
            "Qu?ng Ng�i",
            "Qu?ng Ninh",
            "Qu?ng Tr?",
            "S�c Tr?ng",
            "S?n La",
            "T�y Ninh",
            "Thanh H�a",
            "Th�i B�nh",
            "Th�i Nguy�n",
            "Th?a Thi�n-Hu?",
            "Ti?n Giang",
            "Tr� Vinh",
            "Tuy�n Quang",
            "V?nh Long",
            "V?nh Ph�c",
            "Y�n B�i"
        ]
    },
    {
        "name": "Virgin Islands (US)",
        "iso3": "VIR",
        "iso2": "VI",
        "phone_code": "+1-340",
        "capital": "Charlotte Amalie",
        "currency": "USD",
        "states": [
            "An Giang",
            "??k L?k",
            "??k N�ng",
            "??ng Nai",
            "??ng Th�p",
            "?i?n Bi�n",
            "B� R?a-V?ng T�u",
            "B�nh ??nh",
            "B�nh D??ng",
            "B�nh Ph??c",
            "B�nh Thu?n",
            "B?c Li�u",
            "B?c Giang",
            "B?c K?n",
            "B?c Ninh",
            "B?n Tre",
            "Cao B?ng",
            "C� Mau",
            "Da Nang",
            "Gia Lai",
            "Haiphong",
            "Hanoi",
            "H� Giang",
            "H� Nam",
            "H� T�y",
            "H� T?nh",
            "H�a B�nh",
            "H?i D??ng",
            "H?u Giang",
            "Ho Chi Minh City",
            "H?ng Y�n",
            "Kh�nh H�a",
            "Ki�n Giang",
            "Kon Tum",
            "Lai Ch�u",
            "L�o Cai",
            "L�m ??ng",
            "L?ng S?n",
            "Long An",
            "Nam ??nh",
            "Ngh? An",
            "Ninh B�nh",
            "Ninh Thu?n",
            "Ph� Th?",
            "Ph� Y�n",
            "Qu?ng B�nh",
            "Qu?ng Nam",
            "Qu?ng Ng�i",
            "Qu?ng Ninh",
            "Qu?ng Tr?",
            "S�c Tr?ng",
            "S?n La",
            "T�y Ninh",
            "Thanh H�a",
            "Th�i B�nh",
            "Th�i Nguy�n",
            "Th?a Thi�n-Hu?",
            "Ti?n Giang",
            "Tr� Vinh",
            "Tuy�n Quang",
            "V?nh Long",
            "V?nh Ph�c",
            "Y�n B�i"
        ]
    },
    {
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "iso2": "WF",
        "phone_code": "681",
        "capital": "Mata Utu",
        "currency": "XPF",
        "states": [
            "An Giang",
            "??k L?k",
            "??k N�ng",
            "??ng Nai",
            "??ng Th�p",
            "?i?n Bi�n",
            "B� R?a-V?ng T�u",
            "B�nh ??nh",
            "B�nh D??ng",
            "B�nh Ph??c",
            "B�nh Thu?n",
            "B?c Li�u",
            "B?c Giang",
            "B?c K?n",
            "B?c Ninh",
            "B?n Tre",
            "Cao B?ng",
            "C� Mau",
            "Da Nang",
            "Gia Lai",
            "Haiphong",
            "Hanoi",
            "H� Giang",
            "H� Nam",
            "H� T�y",
            "H� T?nh",
            "H�a B�nh",
            "H?i D??ng",
            "H?u Giang",
            "Ho Chi Minh City",
            "H?ng Y�n",
            "Kh�nh H�a",
            "Ki�n Giang",
            "Kon Tum",
            "Lai Ch�u",
            "L�o Cai",
            "L�m ??ng",
            "L?ng S?n",
            "Long An",
            "Nam ??nh",
            "Ngh? An",
            "Ninh B�nh",
            "Ninh Thu?n",
            "Ph� Th?",
            "Ph� Y�n",
            "Qu?ng B�nh",
            "Qu?ng Nam",
            "Qu?ng Ng�i",
            "Qu?ng Ninh",
            "Qu?ng Tr?",
            "S�c Tr?ng",
            "S?n La",
            "T�y Ninh",
            "Thanh H�a",
            "Th�i B�nh",
            "Th�i Nguy�n",
            "Th?a Thi�n-Hu?",
            "Ti?n Giang",
            "Tr� Vinh",
            "Tuy�n Quang",
            "V?nh Long",
            "V?nh Ph�c",
            "Y�n B�i"
        ]
    },
    {
        "name": "Western Sahara",
        "iso3": "ESH",
        "iso2": "EH",
        "phone_code": "212",
        "capital": "El-Aaiun",
        "currency": "MAD",
        "states": [
            "An Giang",
            "??k L?k",
            "??k N�ng",
            "??ng Nai",
            "??ng Th�p",
            "?i?n Bi�n",
            "B� R?a-V?ng T�u",
            "B�nh ??nh",
            "B�nh D??ng",
            "B�nh Ph??c",
            "B�nh Thu?n",
            "B?c Li�u",
            "B?c Giang",
            "B?c K?n",
            "B?c Ninh",
            "B?n Tre",
            "Cao B?ng",
            "C� Mau",
            "Da Nang",
            "Gia Lai",
            "Haiphong",
            "Hanoi",
            "H� Giang",
            "H� Nam",
            "H� T�y",
            "H� T?nh",
            "H�a B�nh",
            "H?i D??ng",
            "H?u Giang",
            "Ho Chi Minh City",
            "H?ng Y�n",
            "Kh�nh H�a",
            "Ki�n Giang",
            "Kon Tum",
            "Lai Ch�u",
            "L�o Cai",
            "L�m ??ng",
            "L?ng S?n",
            "Long An",
            "Nam ??nh",
            "Ngh? An",
            "Ninh B�nh",
            "Ninh Thu?n",
            "Ph� Th?",
            "Ph� Y�n",
            "Qu?ng B�nh",
            "Qu?ng Nam",
            "Qu?ng Ng�i",
            "Qu?ng Ninh",
            "Qu?ng Tr?",
            "S�c Tr?ng",
            "S?n La",
            "T�y Ninh",
            "Thanh H�a",
            "Th�i B�nh",
            "Th�i Nguy�n",
            "Th?a Thi�n-Hu?",
            "Ti?n Giang",
            "Tr� Vinh",
            "Tuy�n Quang",
            "V?nh Long",
            "V?nh Ph�c",
            "Y�n B�i"
        ]
    },
    {
        "name": "Yemen",
        "iso3": "YEM",
        "iso2": "YE",
        "phone_code": "967",
        "capital": "Sanaa",
        "currency": "YER",
        "states": [
            "'Adan Governorate",
            "'Amran Governorate",
            "Abyan Governorate",
            "Al Bayda' Governorate",
            "Al Hudaydah Governorate",
            "Al Jawf Governorate",
            "Al Mahrah Governorate",
            "Al Mahwit Governorate",
            "Dhamar Governorate",
            "Hadhramaut Governorate",
            "Hajjah Governorate",
            "Ibb Governorate",
            "Lahij Governorate",
            "Ma'rib Governorate",
            "Raymah Governorate",
            "Saada Governorate",
            "Sana'a",
            "Sana'a Governorate",
            "Shabwah Governorate",
            "Socotra Governorate",
            "Ta'izz Governorate"
        ]
    },
    {
        "name": "Zambia",
        "iso3": "ZMB",
        "iso2": "ZM",
        "phone_code": "260",
        "capital": "Lusaka",
        "currency": "ZMK",
        "states": [
            "Central Province",
            "Copperbelt Province",
            "Eastern Province",
            "Luapula Province",
            "Lusaka Province",
            "Muchinga Province",
            "Northern Province",
            "Northwestern Province",
            "Southern Province",
            "Western Province"
        ]
    },
    {
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "iso2": "ZW",
        "phone_code": "263",
        "capital": "Harare",
        "currency": "ZWL",
        "states": [
            "Bulawayo Province",
            "Harare Province",
            "Manicaland",
            "Mashonaland Central Province",
            "Mashonaland East Province",
            "Mashonaland West Province",
            "Masvingo Province",
            "Matabeleland North Province",
            "Matabeleland South Province",
            "Midlands Province"
        ]
    }
]

export default countryStatesList;