import useSWR from "swr"
import { fetcher } from "./fetcher"

export function useOrgs(pageSize, offset, searchValue, sortBy, sortDirection, showArchived) {
    const { data, error, isLoading, isMutating } = useSWR(() => `/api/GetOrgs?pageSize=${pageSize}&offset=${offset}&searchValue=${searchValue}&sortBy=${sortBy}&sortDirection=${sortDirection}&showArchived=${showArchived}`, fetcher)
   
    return {
      data,
      isLoading,
      isError: error,
      isMutating
    }
  }