import Axios from '../config/axios';

export function validateDomainConfiguration(domainName, org_ID) {
    return Axios.get('/api/ValidateDomainConfiguration', {
        params: {
            domainName,
            org_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function createDomain(postdata) {
    return Axios.post('/api/CreateDomain', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function authenticateDomain(postdata) {
    return Axios.post('/api/AuthenticateDomain', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function createSender(postdata) {
    return Axios.post('/api/CreateSender', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function validateSender(postdata) {
    return Axios.post('/api/ValidateSender', postdata)
        .then(response => response.data)
        .catch(error => error);
}

export function sendSupportEmail(postdata) {
    return Axios.post('/api/SendSupportEmail', postdata)
        .then(response => response.data)
        .catch(error => error);
}