import React, { useEffect } from 'react';
import { Descriptions, Switch } from 'antd';
import { toast } from '@rickylandino/react-messages';
import { activeUserCandidatesState, editCandidate } from '../../../services/CandidateService';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { getChangesToTrack } from '../../../helpers/DetectChanges';

export function SettingsCard({ selectedCandidate, isActive}) {

    const { register, getValues, setValue, control, watch, formState } = useForm();
    const setActiveUserCandidates = useSetRecoilState(activeUserCandidatesState);
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        if(selectedCandidate) {
            setValue('formFields', selectedCandidate.candidates);
        }
    }, [selectedCandidate]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                handleUpdate(value.formFields);
            }            
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    function handleUpdate(candidatesModel) {
        let postdata = {
            candidatesModel,
            auditChangesList: [getChangesToTrack(selectedCandidate.candidates, candidatesModel, "Candidates", "Candidate Settings")]
        }
            
        editCandidate(postdata).then(data => {
            if (data?.code === 'ERR_BAD_REQUEST') {
                return toast.error(data.response.data);
            } else {
                toast.success("Candidate has been updated");

                var currentCandidate = data.userCandidates?.find(uc => uc.candidates.candidates_ID === selectedCandidate.candidates.candidates_ID);
                
                if (currentCandidate) {
                    navigate(".", {
                        replace: true,
                        state: { selectedCandidate: currentCandidate, from: location.state.from }
                    });
                }

                setActiveUserCandidates(data);
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }
    
    return (
        <>
            {isActive &&
                <>
                    <Descriptions
                        title="Settings"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size='small'
                        labelStyle={{ width: 500 }}
                    >
                        <Descriptions.Item label="Block Candidate From Campaigns, Emails & Texts">
                            <Controller
                                control={control}
                                name="formFields.blockAppCommunications"
                                render={({ field: { onChange, onBlur, value, ref } }) =>
                                    <Switch checked={value} onChange={onChange} />
                                }
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Candidate Has Opted Out of Email Communications">
                            {getValues('formFields.emailOptOut') ? "Yes" : "No"}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            }
        </>
    );
}