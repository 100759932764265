import $ from 'jquery';
import React, { Component } from 'react';
import { Tag } from 'antd';


export class MultiSelectInput extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.toUpdate !== prevState.toUpdate) {
            return {
                toUpdate: nextProps.toUpdate,
                data: nextProps.data,
                selectedItems: nextProps.selectedItems
            };

        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            toUpdate: false,
            data: props.data,
            filterField: props.filterField,
            nestedSelectedItem: props.nestedSelectedItem,
            idField: props.idField,
            filteredDataList: [],
            showFilteredDataList: false,
            selectedItems: props.selectedItems,
            selectedIdx: -1,
            formFields: {
                item: ''
            }
        }

        this.input = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;

        $(document).ready();
        $(document.body).on('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        this._isMounted = false;
        $(document.body).off('keydown', this.handleKeyDown);

    }

    handleKeyDown = (event) => {
        if (this.state.showFilteredDataList && this.state.filteredDataList.length > 0) {
            if (event.keyCode === 13 /*enter*/) {
                event.preventDefault();
                if (this.state.selectedIdx >= 0) {


                    let item = this.state.filteredDataList[this.state.selectedIdx];
                    let filteredDataList = this.state.filteredDataList;

                    let selectedItems = this.state.selectedItems;



                    if (this.props.nestedSelectedItem) {

                        var pushableItem = {};
                        pushableItem[this.props.nestedSelectedItem] = item;

                        selectedItems.push(pushableItem);
                    } else {
                        selectedItems.push(item);
                    }


                    item.hover = false;
                    filteredDataList[this.state.selectedIdx] = item;

                    this.setState({
                        selectedItems,
                        //formFields: { ...this.state.formFields, item: '' },
                        //showFilteredDataList: false,
                        //selectedIdx: -1,
                        filteredDataList
                    }, () => {
                        this.props.updateSelectedItems(selectedItems);
                    });
                }
            }
            if (event.keyCode === 40 /*down*/) {
                $('.optiondropdown').scrollTop(34 * this.state.selectedIdx);

                event.preventDefault();
                let list = this.state.filteredDataList;
                if (this.state.selectedIdx < list.length - 1) {
                    list[this.state.selectedIdx + 1].hover = true;
                    if (this.state.selectedIdx >= 0) {
                        list[this.state.selectedIdx].hover = false;
                    }

                    this.setState({
                        selectedIdx: this.state.selectedIdx + 1,
                        filteredDataList: list
                    });
                }
            }

            if (event.keyCode === 38 /*up*/) {
                event.preventDefault();
                let list = this.state.filteredDataList;
                if (this.state.selectedIdx > 0) {
                    $('.optiondropdown').scrollTop((34 * this.state.selectedIdx) - 34);

                    list[this.state.selectedIdx - 1].hover = true;
                    if (this.state.selectedIdx < list.length) {
                        list[this.state.selectedIdx].hover = false;
                    }

                    this.setState({
                        selectedIdx: this.state.selectedIdx - 1
                    });
                }


            }

            if (event.keyCode === 27 /*esc*/) {
                event.preventDefault();
                this.setState({
                    selectedIdx: -1,
                    filteredDataList: [],
                    showFilteredDataList: false,
                    formFields: { ...this.state.formFields, item: '' }
                })
            }
        } else {
            if (event.keyCode === 13 /*enter*/) {
                event.preventDefault();
                if (this.state.formFields.item && !this.props.disableCreate) {
                    this.props.createItem(this.state.formFields.item);

                    this.setState({
                        formFields: { ...this.state.formFields, item: '' },
                        showFilteredDataList: false,
                        selectedIdx: -1,
                        filteredDataList: []
                    });
                }
            }
        }

    }

    searchableInput = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const filteredDataList = this.state.data.filter(item => (item[this.props.filterField] && item[this.props.filterField].toLowerCase().includes(value.toLowerCase())));

        this.setState({
            formFields: { ...this.state.formFields, [name]: value },
            filteredDataList,
            showFilteredDataList: value ? true : false,
            selectedIdx: -1
        });
    }

    toggleItem(event, action, id) {
        const target = event.target;
        const value = target.value;

        let selectedItems = this.state.selectedItems;

        switch (action) {
            case 'add':

                let item = JSON.parse(value);


                for (var i = 0; i < selectedItems.length; i++) {
                    if (JSON.stringify(selectedItems[i]) === JSON.stringify(item)) {
                        return;
                    }

                    if (this.props.nestedSelectedItem && selectedItems[i][this.props.nestedSelectedItem][this.props.idField] === item[this.props.idField]) {

                        return;
                    } else if (selectedItems[i][this.props.idField] === item[this.props.idField]) {

                        return;
                    }
                }

                if (this.props.nestedSelectedItem) {

                    var pushableItem = {};
                    pushableItem[this.props.nestedSelectedItem] = item;
                    selectedItems.push(pushableItem);
                } else {
                    selectedItems.push(item);
                }

                this.setState({
                    selectedItems
                }, () => {
                    this.props.updateSelectedItems(selectedItems);
                });


                break;
            case 'delete':

                for (var i = 0; i < selectedItems.length; i++) {
                    const myItem = this.props.nestedSelectedItem ? selectedItems[i][this.props.nestedSelectedItem] : selectedItems[i];
                    if (myItem[this.props.idField] === id) {
                        selectedItems.splice(i, 1);
                        this.setState({
                            selectedItems
                        }, () => {
                            this.props.updateSelectedItems(selectedItems);
                        });

                    }
                }
                break;
            default:
                break;
        }
    }

    hideList = (event) => {
        if (this.state.showFilteredDataList) {
            if (/*!event.relatedTarget || (event.relatedTarget?.parentElement?.id && event.relatedTarget?.parentElement?.id !== event.target.parentElement?.id)*/!event.relatedTarget?.tabIndex) {
                this.setState({
                    formFields: { ...this.state.formFields, item: '' },
                    showFilteredDataList: false,
                    selectedIdx: -1
                });
            } else {
                this.input.current.focus();
            }
        }

    }

    render() {
        const myItem = (item) => this.props.nestedSelectedItem ? item[this.props.nestedSelectedItem] : item;
        
        return (
            <div tabIndex="1">
                {this.state.data.length > 0 &&
                    <div className="col-12">
                        <form onSubmit={this.createTag} id={this.props.title}>
                            <label>{this.props.title}</label>
                            <input className="ant-input" onChange={this.searchableInput} value={this.state.formFields.item || ''} name="item" autoComplete="off" onBlur={this.hideList} ref={this.input} />
                        </form>
                    </div>
                }

                <div className="col-12">
                    <div className="selectable-list">
                        {this.state.selectedItems.map((item, idx) => <Tag key={idx} value={myItem(item)[this.props.idField]} onClick={(e, action) => this.toggleItem(e, 'delete', myItem(item)[this.props.idField])}>{myItem(item)[this.props.filterField]} &times;</Tag>)}
                    </div>
                </div>

                {this.state.showFilteredDataList &&
                    <div className="col-12">
                        <div className="selectable-list optiondropdown">
                            {this.state.filteredDataList.length > 0 ?
                                this.state.filteredDataList.map((item, idx) => <option key={idx} className={item.hover ? 'hover' : ''} value={JSON.stringify(item)} onMouseDown={(e, action) => this.toggleItem(e, 'add')}>{item[this.props.filterField]}</option>)
                                :
                                <span>{!this.props.disableCreate ? <em>Press enter to create new item</em> : <em>No Records Found</em>}</span>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}