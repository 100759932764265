import Axios from '../config/axios';

export function syncWithNchcr(postdata) {
    return Axios.post(`/api/SyncWithNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}

export function syncFromNchcr(postdata) {
    return Axios.post(`/api/SyncFromNchcr`, postdata
    ).then(response => response.data).catch(error => error);
}