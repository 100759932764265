import React, { useState, useEffect, useRef, createRef } from "react";

import { Circle, FeatureGroup, MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import Globals from '../../config/globals';

import { getCandidatesByRadius } from '../../services/CandidateService';
import LeafletControlGeocoder from "./Map/LeafletControlGeocoder";

export function MapCampaign(props) {
    const fgRef = createRef();
    const [placeSearched, setPlaceSearched] = useState(null);
    const [poly, setPoly] = useState(null);

    const defaultProps = {
        center: {
            lat: 41.850033,
            lng: -87.6500523
        },
        zoom: 4,
    };

    const [center, setCenter] = useState({
        lat: 37.6872,
        lng: -97.3301
    });

    const [mapRadius, setMapRadius] = useState(0);

    useEffect(() => {
        handleSearch(placeSearched);
    }, [props.filterOptions.radius]);

    function handleSearch(place, localCenter, localMapRadius) {

        const fg = fgRef.current?.leafletElement;
        fg?.clearLayers();

        setPlaceSearched(place);

        if (localCenter) {
            setCenter(localCenter);
        }

        if(localMapRadius) {
            setMapRadius(localMapRadius);
        }

        let postdata = {
            city: place?.address?.town || "",
            state: place?.address?.state || "",
            county: "",
            zip: place?.address?.postcode || "",
            radius: props.filterOptions.radius,
            users_ID: Globals.userInfo.users_ID,
        }


        getCandidatesByRadius(postdata).then(data => {
            props.updateCandidates(data);
        });
    }

    const fillBlueOptions = { fillColor: 'blue' }

    return (
        <div style={{ height: '50vh', width: '100%' }} className="map-container">
            <MapContainer
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                scrollWheelZoom={true}
                key={props.filterOptions.radius}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <FeatureGroup ref={fgRef}>
                    <Circle center={center} pathOptions={fillBlueOptions} radius={mapRadius} />
                </FeatureGroup> */}
                
                
                <LeafletControlGeocoder  handleSearch={handleSearch} radius={props.filterOptions.radius} existingPoly={poly} updatePoly={(p) => setPoly(p)} />
            </MapContainer> 
        </div>
    );
}
