import React from 'react';

class Globals extends React.Component {

    static userInfo = {
        id: "",
        userName: "",
        isLoggedIn: false,
        userType: "",
        title: "",
        fullName: "",
        valSignature: "",
        email: ""
    };

    static bearer = '';
    static environmentToDisplay = '';

    static brand = 'recruiterbalm'; //options: 'nchcr', 'recruiterbalm'
    static appName = "The Best ATS";
    
    static mobileWidth = 992;
    static condenseNav = 1714;
    static superCondenseNav = 770;
    static xlScreen = 1600;
    static lgScreen = 1280;
    static mdScreen = 900;

    static colors = [];
}

export default Globals;

export class Constants extends React.Component {

    static companyCandidateText = "Orgnizational Candidate";
    static COMPANY_TEXT = 'Organizational';

}

