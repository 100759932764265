import Axios from '../config/axios';

export function getArchivedJobTypesByOrganization(org_ID) {
    return Axios.get(`/api/GetArchivedJobTypesByOrganization`, { params: { org_ID } }
    ).then(response => response.data).catch(error => error);
}

export function updateJobTypes(postdata) {
    return Axios.post(`/api/UpdateJobTypes`, postdata
    ).then(response => response.data).catch(error => error);
}

export function insertJobType(postdata) {
    return Axios.post(`/api/AddJobType`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateJobType(postdata) {
    return Axios.post(`/api/UpdateJobType`, postdata
    ).then(response => response.data).catch(error => error);
}

export function archiveJobType(postdata) {
    return Axios.post(`/api/ArchiveJobType`, postdata
    ).then(response => response.data).catch(error => error);
}

