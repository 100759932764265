import Axios from '../config/axios';
import Globals from '../config/globals';
import { BehaviorSubject } from 'rxjs';
import { atom } from 'recoil';


export const smsHistory = new BehaviorSubject([]);
export const textableCandidates = new BehaviorSubject([]);
export const textableUsers = new BehaviorSubject([]);
export const unreadMessages = new BehaviorSubject([]);
export const doesUserHaveNumber = new BehaviorSubject(null);

export const bandwidthNumbers = atom({
    key: 'bwNumbers',
    default: [],
});

export function sentTestText() {
    return Axios.get('/api/SentTestText').then(response => response.data);
}

export function clearSMSItems() {
    smsHistory.next([]);
    textableCandidates.next([]);
    textableUsers.next([]);
    doesUserHaveNumber.next(null);
}

export function getSMSHistoryByOrganization() {
    return Axios.get('/api/GetMessagesByOrganization', {
        params: {
            organizations_ID: Globals.userInfo.organizations_ID
        }
    }).then(response => {
        smsHistory.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function getSMSHistoryByUser(users_ID, withCampaign = false) {
    return Axios.get('/api/GetMessagesByUser', {
        params: {
            users_ID
        }
    }).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getSMSOnLoadInfo() {
    return Axios.get('/api/GetSMSOnLoadInfo', {
        params: {
            organizations_ID: Globals.userInfo.organizations_ID,
            users_ID: Globals.userInfo.users_ID
        }
    }).then(response => {
        smsHistory.next(response.data.allMessages);
        textableCandidates.next(response.data.textableCandidates);
        textableUsers.next(response.data.textableUsers);
        doesUserHaveNumber.next(response.data.doesUserHaveNumber);
    }).catch(error => {
        console.log(error);
    });
}
export function getSMSOnLoadInfoForCampaign(campaigns_ID) {
    return Axios.get('/api/GetSMSOnLoadInfoForCampaign', {
        params: {
            organizations_ID: Globals.userInfo.organizations_ID,
            users_ID: Globals.userInfo.users_ID,
            campaigns_ID
        }
    }).then(response => {
        smsHistory.next(response.data.allMessages);
        textableCandidates.next(response.data.textableCandidates);
        textableUsers.next(response.data.textableUsers);
        doesUserHaveNumber.next(response.data.doesUserHaveNumber);
    }).catch(error => {
        console.log(error);
    });
}

export function sendSMS(postdata) {
    return Axios.post('/api/SendSMS', postdata).then(response => {
        smsHistory.next(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export function simulateReceiveMessage() {

    return Axios.post('/api/SimulateReceiveMessage').then(response => {
    }).catch(error => {
        console.log(error);
    });
}

export function updateReadMessagesByNumber(postdata) {
    return Axios.put('/api/UpdateReadMessagesByNumber', postdata).then(response => response.data).catch(error => error);
}

export function updateReadMessagesByUser(postdata) {
    return Axios.put('/api/UpdateReadMessagesByUser', postdata).then(response => response.data).catch(error => error);
}

export function getSMSHistoryByCandidate(candidates_ID) {
    return Axios.get('/api/GetSMSHistoryByCandidate', {
        params: {
            candidates_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getAvailableNumbers(areaCode) {
    return Axios.get('/api/GetAvailableNumbers', { params: {areaCode}}).then(response => response.data).catch(error => error);
}

export function getRecruiterbalmNumbers() {
    return Axios.get('/api/GetRecruiterbalmNumbers').then(response => response.data).catch(error => error);
}

export function orderNumber(postdata) {
    return Axios.post('/api/OrderNumber', postdata).then(response => response.data).catch(error => error);
}