import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tabs, Empty, Pagination, Skeleton, notification, Progress, Modal } from 'antd';
import * as CandidateCards from './CandidateCards/index';
import { activeUserCandidatesState, archiveCandidate, canSelectedCandidateBeDeleted, canSelectedCandidateBeUpdated, deleteCandidate, getCandidateDashboardInformation, restoreCandidate, userCandidates } from '../../services/CandidateService';
import { downloadCandidateResume } from '../../services/CandidateAttachmentsService';
import { confirm, toast } from '@rickylandino/react-messages';
import { Document, Page, pdfjs } from "react-pdf";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ChangeLog } from '../Audit/ChangeLog';
import EventLog from '../Audit/EventLog';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { activeEventTypes } from '../../services/OrganizationsService';
import { EventSlider } from '../Dashboard/EventSlider';
import Moment from 'moment';
import { emptyEvent } from '../../services/EventsService';
import { useSWRConfig } from "swr";
import { MatchPane } from '../Placements/MatchPane';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { syncWithNchcr } from '../../services/NchcrService';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function CandidateDashboard(props) {

    const { mutate } = useSWRConfig()
    const { candidates_ID } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const setCanUpdateCandidate = useSetRecoilState(canSelectedCandidateBeUpdated);
    const uPermissions = useRecoilValue(activeUserPermissions);
    const uInfo = useRecoilValue(activeUserInfo);
    const [canDelete, setCanDelete] = useRecoilState(canSelectedCandidateBeDeleted);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);

    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [specialty, setSpecialty] = useState({
        parent: '',
        sub: ''
    });

    const [matchPaneInfo, setMatchPaneInfo] = useState(null);

    const [dashboardInformation, setDashboardInformation] = useState({});

    const [loaded, setLoaded] = useState(false);

    const [resume, setResume] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [reloadResume, setReloadResume] = useState(false);

    const [activeTab, setActiveTab] = useState(location?.state?.activeTab ? location.state.activeTab : 'candidateDetails');

    const [showEventsSlider, setShowEventsSlider] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({ ...emptyEvent });
    const eventTypes = useRecoilValue(activeEventTypes);

    const [showMatchPane, setShowMatchPane] = useState(false);

    const [goBackText, setGoBackText] = useState("Return to my candidates");

    useEffect(() => {
        if (selectedCandidate?.candidates?.candidates_ID) {
            setReloadResume(true);
        }

    }, [selectedCandidate?.candidates?.candidates_ID]);

    useEffect(() => {
        if (selectedCandidate?.candidates?.candidates_ID >= 0 && uPermissions?.users_ID) {
            const canUpdate = (uPermissions.updateCandidates === 'company' || (uPermissions.updateCandidates === 'personal' && uInfo.users_ID === selectedCandidate.candidates.users_ID));
            const canBeDeleted = (uPermissions.deleteCandidates === 'company' || (uPermissions.deleteCandidates === 'personal' && uInfo.users_ID === selectedCandidate.candidates.users_ID));
            setCanUpdateCandidate(canUpdate);
            setCanDelete(canBeDeleted);
        } else {
            setCanUpdateCandidate(false);
        }
    }, [selectedCandidate, uPermissions]);

    useEffect(() => {
        if (reloadResume) {
            let postdata = {
                id: selectedCandidate.candidates.candidates_ID
            };
            downloadCandidateResume(postdata).then(data => {
                if (data?.size > 0) {
                    const url = window.URL.createObjectURL(new Blob([data]));

                    const reader2 = new FileReader();
                    reader2.readAsDataURL(new Blob([data])); 
                    reader2.onloadend = function() {
                        var base64data = reader2.result;
                        base64data = base64data.split(',')[1];

                        setResume(`data:application/pdf;base64,${base64data}`);
                        // setModalInfo({
                        //     show: true,
                        //     //file1: window.URL.createObjectURL(new Blob([data])),
                        //     file: `data:application/pdf;base64,${base64data}`
                        // });
                    }
                    
                } else {
                    setResume(null);
                }
            }).catch(error => {
                setResume(null);
            });

            setReloadResume(false);
        }
    }, [reloadResume]);

    // useEffect(() => {
    //     setLoaded(false);

    //     setSelectedCandidate({ ...location?.state?.selectedCandidate });
    //     setSpecialty({
    //         parent: location?.state?.selectedCandidate?.parentSpecialty,
    //         sub: location?.state?.selectedCandidate?.parentSpecialty
    //     });

    //     if (location?.state?.dashboardInformation) {
    //         setDashboardInformation({ ...location.state.dashboardInformation, loaded: true });

    //         setLoaded(true);
    //     } else {
    //         getCandidateDashboardInformation(location?.state?.selectedCandidate.candidates.candidates_ID).then(data => {
    //             setDashboardInformation({ ...data, loaded: true });

    //             setLoaded(true);
    //         });
    //     }

    // }, [location]);

    useEffect(() => {
        setLoaded(false);
        getCandidateDashboardInformation(candidates_ID).then(data => {
            setSelectedCandidate({ ...data.candidateDetails });

            setMatchPaneInfo({
                candidateFirstName: data.candidateDetails?.candidates?.firstName,
                candidateLastName: data.candidateDetails?.candidates?.lastName,
                candidates_ID: data.candidateDetails?.candidates?.candidates_ID,
                users_ID: data.candidateDetails?.candidates?.users_ID,
                parentSpecialty: data.candidateDetails?.parentSpecialty,
                subSpecialty: data.candidateDetails?.subSpecialty
            });

            setSpecialty({
                parent: data.candidateDetails?.parentSpecialty,
                sub: data.candidateDetails?.subSpecialty
            });

            setDashboardInformation({ ...data, loaded: true });

            setLoaded(true);
        });
    }, [candidates_ID]);


    // useEffect(() => {
    //     if (location?.state?.from) {
    //         switch (location.state.from) {
    //             case 'placementWorkflow':
    //                 setGoBackText('Return to Candidate Workflow Summary');
    //                 break;
    //             case 'jobPlacementWorkflow':
    //                 setGoBackText('Return to Job Workflow Summary');
    //                 break;
    //             case 'myJobs':
    //                 setGoBackText('Return to my jobs');
    //                 break;
    //             case 'jobWorkflowCard':
    //                 setGoBackText('Return to job');
    //                 break;
    //             case 'clientPlacementWorkflow':
    //                 setGoBackText('Return to client');
    //                 break;
    //             case 'sms-dashboard':
    //                 setGoBackText('Return SMS Dashboard');
    //                 break;
    //             default:
    //                 setGoBackText('Return to my candidates');
    //                 break;
    //         }
    //     }
    // }, []);

    const file = useMemo(() => (resume), [resume]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function onChangePage(page) {
        setPageNumber(page);
    };

    function addEvent() {

        let eventToAdd = {
            events: {
                ...emptyEvent,
                eventDateTime: Moment(new Date),
                eventTypes_ID: eventTypes.find(type => type.eventType === 'To-Do')?.eventTypes_ID
            },
            eventTypes: eventTypes.find(type => type.eventType === 'To-Do')
        };

        setSelectedEvent(eventToAdd);
        setShowEventsSlider(true);
    }

    const resumeComponent = (
        <>
            {resume ?
                <>
                    <CandidateCards.ResumeCard resume={resume} />
                    {/* <Document file={file} loading="" onLoadSuccess={onDocumentLoadSuccess} noData={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="This candidate has no resume. Add a resume in the attachments section." />}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    {numPages > 1 &&
                        <Pagination className="text-center" simple defaultCurrent={1} pageSize={1} total={numPages} onChange={onChangePage} />
                    } */}
                </>
                :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="This candidate has no resume. Add a resume in the attachments section." />
            }

        </>
    );

    var tabs = [
        {
            label: <div><i className="fa-regular fa-user me-2"></i>Candidate Details</div>,
            key: 'candidateDetails'
        },
        {
            label: `Workflow`,
            key: 'workflow'
        },
        {
            label: `Education`,
            key: 'education'
        },
        {
            label: `License/Certifications`,
            key: 'license'
        },
        {
            label: `Work History`,
            key: 'workHistory'
        },
        {
            label: `Tags`,
            key: 'tags'
        },
        {
            label: `Compensation`,
            key: 'compensation'
        },
        {
            label: `Chat History`,
            key: 'messageHistory'
        },
        {
            label: `Email History`,
            key: 'emailHistory'
        },
        {
            label: `Comments`,
            key: 'comments'
        },
        {
            label: `Attachments`,
            key: 'attachments'
        },
        {
            label: `Change Log`,
            key: 'changeLog'
        },
        {
            label: `Events Log`,
            key: 'eventLog'
        },
        {
            label: `Candidate Settings`,
            key: 'settings'
        },
    ];

    if(uInfo?.disableTexting) {
        tabs.splice(tabs.findIndex(tab => tab.key === 'messageHistory'), 1);
    }

    function goBack() {
        navigate("/candidates", { replace: true, state: { from: location.state?.from } });
        // if ((!location.state.from) || (location.state.from && (location.state.from === 'placementWorkflow' || location.state.from === 'myCandidates' || location.state.from === 'candidateWorkflowCard' || location.state.from === 'addCandidate'))) {
        //     navigate("/candidates", { replace: true, state: { from: location.state.from } });
        // } else if (location.state.from && (location.state.from === 'jobWorkflowCard')) {
        //     navigate("/job-dashboard", { replace: true, state: { selectedObject: location.state.currentJob, from: 'jobPlacementWorkflow' } });
        // }
        // else if (location.state.from && (location.state.from === 'clientPlacementWorkflow')) {

        //     navigate("/client-dashboard", {
        //         replace: true, state: {
        //             selectedObject: location.state.currentClient, from: 'clientPlacementWorkflow'
        //         }
        //     });
        // } else if (location?.state?.from === 'sms-dashboard') {
        //     navigate("/sms-dashboard", { replace: true });
        // }
        // else {
        //     navigate("/jobs", { replace: true, state: { from: location.state.from } });
        // }
    }

    function handleArchiveCandidate() {
        confirm({
            title: <span><i className='fas fa-archive text-warning icon-md pr-1'></i> &nbsp; You are about to archive this candidate.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    toast.loading("Archiving candidate...", { key: 'loading' });
                    archiveCandidate(selectedCandidate.candidates).then(data => {
                        if (data) {
                            toast.destroy('loading');
                            toast.success("Candidate has been archived");

                            navigate("/candidates", { replace: true });
                        }
                    }).catch(error => {
                        toast.error('Something went wrong');
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleRestoreCandidate() {
        confirm({
            title: <span><i className='far fa-window-restore text-info icon-md pr-1'></i> &nbsp; You are about to restore this candidate.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    toast.loading("Restoring candidate...", { key: 'loading' });
                    restoreCandidate(selectedCandidate.candidates).then(data => {
                        if (data) {
                            toast.destroy('loading');
                            toast.success("Candidate has been restored");

                            navigate("/candidates", { replace: true });
                        }
                    }).catch(error => {
                        toast.error('Something went wrong');
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleDeleteCandidate() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this candidate and all associated data.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {

                    Modal.confirm({
                        icon: <ExclamationCircleOutlined />,
                        content: "Deleting this candidate will also delete all associated data in the system including workflow, and events. Are you sure you want to proceed?",
                        okText: "Yes",
                        onOk() {
                            toast.loading("Deleting candidate...", { key: 'loading' });

                            let postdata = {
                                Id: selectedCandidate.candidates.candidates_ID
                            }

                            deleteCandidate(postdata).then(data => {
                                if (data) {
                                    toast.destroy('loading');
                                    userCandidates.next(data);
                                    setActiveUserCandidates(data);
                                    toast.success("Candidate data has been deleted");

                                    navigate("/candidates", { replace: true });
                                }
                            }).catch(error => {
                                toast.error('Something went wrong');
                            });
                        }
                    });
                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }

    function triggerRefresh() {
        mutate(`/api/getCandidatesWithPlacementsByUser?org_ID=${uInfo.organizations_ID}&users_ID=${uInfo.users_ID}&placementStatus=Active`);

        notification.info({
            message: `Refreshing data...`,
            description: <div>
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#326085',
                    }}
                    percent={100}
                    showInfo={false}
                    status="active"
                />
            </div>,
            placement: 'bottomRight',
            duration: 2
        });
    }

    function handleSyncWithNchcr() {
        let postdata = {
            candidates_ID: selectedCandidate.candidates.candidates_ID,
            users_ID: uInfo.users_ID
        }

        syncWithNchcr(postdata).then(data => {          
            if (data) {
                toast.success("Candidate has been synced with NCHCR");
            }
        }).catch(error => {
            toast.error('Something went wrong');   
        });
    }

    return (
        <div>
            <div className="horizontal-candidates-divider-thick" />

            <div className="row mt-3">

                <div className="col col-lg-4 col-12">
                    <div className="margin-0-10">
                        {selectedCandidate ?
                            <>
                                {(selectedCandidate?.candidates?.anonymous && !selectedCandidate?.candidates?.firstName && !selectedCandidate?.candidates?.lastName) ?
                                    <h1>{selectedCandidate?.candidateNumber || '[No Name]'}</h1>
                                    :
                                    <h1>{selectedCandidate?.candidates?.firstName || '[No Name]'} {selectedCandidate?.candidates?.lastName}</h1>
                                }
                                <h5 className="">{specialty?.parent}{specialty?.sub && <span> - {specialty?.sub}</span>}</h5>

                                <span className="a" onClick={goBack}><i className="fas fa-arrow-left"></i> {goBackText}</span>
                            </>
                            :
                            <Skeleton active />
                        }
                    </div>
                </div>

                <div className="col col-lg-8 col-12 mb-4">
                    {selectedCandidate?.candidates?.archive !== 1 &&
                        <>
                            <div className="float-start">
                                <button id="matchButton" className="ant-btn ant-btn-match m-1" onClick={() => setShowMatchPane(true)}> Match Jobs</ button>
                            </div>
                            <div className="float-start">
                                <button className="ant-btn ant-btn-primary float-left m-1" onClick={addEvent}><i className="fa-solid fa-plus hover" onClick={addEvent}></i> Add Event</button>
                            </div>
                            {uInfo.nchcrMember &&
                                <div className="float-start">
                                    <button className="ant-btn ant-btn-success float-end m-1" onClick={handleSyncWithNchcr}>
                                        Sync/Push this Candidate to NCHCR
                                    </button>
                                </div>
                            }
                        </>
                    }
                    {canDelete &&
                        <>
                            <div className="float-end">
                                <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteCandidate}><i className='far fa-trash-alt'></i> &nbsp; Delete this Candidate</button>
                            </div>
                            {selectedCandidate?.candidates?.archive === 1 ?
                                <div className="float-end">
                                    <button className="ant-btn ant-btn-info float-right m-1" onClick={handleRestoreCandidate}><i className='far fa-window-restore'></i> &nbsp; Restore this Candidate</button>
                                </div>
                                :
                                <div className="float-end">
                                    <button className="ant-btn ant-btn-warning float-right m-1" onClick={handleArchiveCandidate}><i className='fas fa-archive'></i> &nbsp; Archive this Candidate</button>
                                </div>
                            }
                        </>
                    }

                </div>

            </div>

            <div className="horizontal-candidates-divider" />

            {loaded ?
                <>
                    <Tabs
                        type="card"
                        items={tabs}
                        className="candidate-tabs"
                        onChange={handleTabChange}
                        activeKey={activeTab}
                    />
                    <div className="row">

                        <div className={(activeTab === 'changeLog' || activeTab === 'eventLog' || activeTab === 'workflow' || activeTab === 'emailHistory') ? "col-12" : "col-xl-6 col-12"}>
                            <>
                                <CandidateCards.ContactInformationCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'candidateDetails'} />
                                {activeTab === 'candidateDetails' && <div className="horizontal-candidates-divider" />}
                                <CandidateCards.RelocationCard {...props} selectedCandidate={selectedCandidate} isActive={activeTab === 'candidateDetails'} />
                                {activeTab === 'candidateDetails' && <div className="horizontal-candidates-divider" />}
                                <CandidateCards.SocialMediaCard {...props} selectedCandidate={selectedCandidate} isActive={activeTab === 'candidateDetails'} />
                            </>

                            <>
                                <CandidateCards.EducationCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'education'} />
                            </>

                            <>
                                <CandidateCards.CertificationsCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'license'} />
                            </>

                            <>
                                <CandidateCards.WorkHistoryCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'workHistory'} />
                            </>

                            <>
                                <CandidateCards.TagsCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'tags'} />
                            </>

                            <>
                                <CandidateCards.CompensationCard {...props} selectedCandidate={selectedCandidate} isActive={activeTab === 'compensation'} />
                            </>

                            <>
                                <CandidateCards.MessageHistoryCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'messageHistory'} />
                            </>

                            <>
                                <CandidateCards.EmailHistoryCard specificEmail={selectedCandidate?.candidates?.email1} isActive={activeTab === 'emailHistory'} />
                            </>

                            <>
                                <CandidateCards.CommentsCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'comments'} />
                            </>

                            <>
                                <CandidateCards.AttachmentsCard {...props} selectedCandidate={selectedCandidate} dashboardInformation={dashboardInformation} isActive={activeTab === 'attachments'} clearResume={() => setResume(null)} reloadResume={() => setReloadResume(true)} />
                            </>

                            <>
                                <ChangeLog {...props} selectedCandidate={selectedCandidate} candidates_ID={selectedCandidate?.candidates?.candidates_ID} isActive={activeTab === 'changeLog'} />
                            </>

                            <>
                                <EventLog {...props} selectedCandidate={selectedCandidate} listData={dashboardInformation.changeLog} isActive={activeTab === 'eventLog'} />
                            </>

                            <>
                                <CandidateCards.WorkflowCard {...props} selectedCandidate={selectedCandidate} isActive={activeTab === 'workflow'} />
                            </>
                            
                            <>
                                <CandidateCards.SettingsCard selectedCandidate={selectedCandidate} isActive={activeTab === 'settings'} />
                            </>
                        </div>
                        <div className="col-xl-6 col-12">
                            {(activeTab !== 'changeLog' && activeTab !== 'eventLog' && activeTab !== 'workflow' && activeTab !== 'emailHistory') && resumeComponent}
                        </div>
                    </div>
                </>
                :
                <div className="col-xl-6 col-12">
                    <Skeleton active paragraph={{ rows: 10 }} />
                </div>
            }

            <EventSlider candidates_ID={selectedCandidate?.candidates?.candidates_ID || null} showModal={showEventsSlider} hideModal={() => setShowEventsSlider(false)} selectedEvent={selectedEvent} selectedCandidate={selectedCandidate} eventTypes={eventTypes} selectedDate={Moment(new Date)} users_ID={uInfo.users_ID} />
            <MatchPane show={showMatchPane} hidePane={() => setShowMatchPane(false)} entity={matchPaneInfo} from='candidates' placementStatus="Active" triggerRefresh={triggerRefresh} />
        </div>
    );
}