import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast, confirm } from '@rickylandino/react-messages';
import { Table, Empty, Modal, Space, Card, Spin } from 'antd';
import { DragOutlined, LoadingOutlined } from '@ant-design/icons';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { activeJobTypesState, jobTypes } from '../../../services/OrganizationsService';
import SkeletonTable from '../../../helpers/SkeletonTable';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { archiveJobType, getArchivedJobTypesByOrganization, insertJobType, updateJobType, updateJobTypes } from '../../../services/JobTypesService';
import { userInfo } from '../../../services/UsersService';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../../config/globals';
import { useRecoilState } from 'recoil';
const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{
                cursor: 'move',
                ...style,
            }}
            {...restProps}
        />
    );
};

export function ManageJobTypes(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [uInfo, setUInfo] = useState({});

    const [types, setTypes] = useState({
        allJobTypes: [],
        loaded: false
    });

    const [data, setData] = useState([]);

    const [selectedJobType, setSelectedJobType] = useState(null);

    const [archivedJobTypes, setArchivedJobTypes] = useState([]);
    const [selectedArchivedJobType, setSelectedArchivedJobType] = useState(null);

    const [activeJobTypes, setActiveJobTypes] = useRecoilState(activeJobTypesState);

    var isDirty = useRef(false);

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        var alive = true;

        combineLatest(
            jobTypes,
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([data, userInfo]) => {
            setData([...data]);

            setTypes({
                ...types,
                allJobTypes: [...data],
                loaded: true
            });

            if (userInfo.users_ID) {
                setUInfo(userInfo);
                getArchivedJobTypesByOrganization(userInfo.organizations_ID).then(data => {
                    setArchivedJobTypes(data);
                });
            }
        });

        return () => { alive = false; }
    }, []);

    function handleOrderUpdate(jobTypesList) {
        let postdata = {
            jobTypesList,
            id: uInfo.organizations_ID
        }

        updateJobTypes(postdata).then(data => {
            if (data) {
                jobTypes.next(data);
                setActiveJobTypes(data);
                toast.success("Order has been updated");
            }
        });
    }

    function pushJobType(jt) {
        let dt = [...data];

        setValue('formFields', jt);
        dt.push(jt);
        jobTypes.next(dt);
        setActiveJobTypes(dt);
    }

    function handleSubmit() {
        let jt = getValues().formFields;
        if (jt.jobTypes_ID) {
            updateJobType(jt).then(response => {
                if (response > 0) {
                    let dt = [...data];
                    let jtIdx = dt.findIndex(t => t.jobTypes_ID === jt.jobTypes_ID);
                    if (jtIdx >= 0) {
                        data[jtIdx] = jt;
                        jobTypes.next(data);
                        setActiveJobTypes(data);
                    }

                    setSelectedJobType(null)
                    
                    toast.success("Job Type has been updated");
                } else {
                    toast.error("Something went wrong");
                }
            });
        } else {
            insertJobType(jt).then(response => {
                if (response === 'duplicate') {
                    toast.error("This Job Type already exists");
                } else {
                    if (response > 0) {
                        jt.jobTypes_ID = response;
                        pushJobType(jt);

                        setSelectedJobType(jt);
                        toast.success("Job Type has been added");
                        setSelectedJobType(null)
                    } else {
                        toast.error("Something went wrong");
                    }

                }
            })
        }
    }

    function addJobType() {
        let jobType = {
            jobType: '',
            organizations_ID: uInfo.organizations_ID,
            active: true,
            order: data.length + 1
        }

        setValue('formFields', jobType);

        setSelectedJobType(jobType);
    }

    function restoreJobType(index) {
        let ajt = [...archivedJobTypes];

        let restoredObj = {
            ...archivedJobTypes[index],
            active: true,
            order: data.length + 1
        };

        updateJobType(restoredObj).then(response => {
            if (response >= 0) {
                pushJobType(restoredObj);

                ajt.splice(index, 1);
                setArchivedJobTypes(ajt);

                toast.success("Job Type has been restored");
            } else {
                toast.error("Something went wrong");
            }
        });

    }

    function removeJobType(index) {
        let dt = [...data];
        let ajt = [...archivedJobTypes];
        confirm({
            title: "You are about to archive this job type.",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    setSelectedJobType(null);

                    let jtToRemove = dt[index];

                    archiveJobType(jtToRemove).then(response => {
                        if (response >= 0) {
                            ajt.push(jtToRemove);

                            dt.splice(index, 1);

                            dt.forEach((item, index) => {
                                item.order = index + 1;
                            });

                            setArchivedJobTypes(ajt);
                            setData(dt);

                            jobTypes.next(dt);
                            setActiveJobTypes(dt);
                            toast.success("Job Type has been archived");
                        } else {
                            toast.error("Something went wrong");
                        }
                    });

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
        

        //confirm({
        //    title: "You are about to delete this tag.",
        //    content: "Are you sure you would like to proceed?",
        //    buttons: ["Yes", "No"],
        //    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        //},
        //    (ButtonPressed) => {
        //        if (ButtonPressed === "Yes") {
        //            deleteTag(tagToRemove).then(data => {
        //                if (data > 0) {
        //                    toast.success("Tag has been deleted");
        //                    let allTags = [...tags.allTags];
        //                    let allTagsIdx = allTags.findIndex(t => t.tags_ID === tagToRemove.tags_ID);
        //                    allTags.splice(allTagsIdx, 1);
        //                    orgTags.next(allTags);
        //                    setSelectedTag(null);
        //                    setValue('formFields', null);

        //                }
        //            });

        //            return 0;
        //        }
        //        if (ButtonPressed === "No") {
        //            return 0;
        //        }
        //    }
        //);


    }

    const jtColumns = [
        {
            title: 'Reorder',
            key: 'reorder',
            render: (_, record) => (
                <DragOutlined />
            ),
            width: 50,
            className: 'text-center move-cursor',
            hide: !isDesktop
        },
        {
            title: 'Job Type',
            dataIndex: 'jobType',
            key: 'jobType',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="edit" className="a hover">Edit</span>
                    <span id="delete" className="a hover">Archive</span>
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    const archiveColumns = [
        {
            title: 'Archived Job Type',
            dataIndex: 'jobType',
            key: 'jobType',
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <span id="restore" className="a hover">Restore</span>
                </Space>
            ),
            width: 200,
            className: 'text-center',
        }
    ];

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex];

            let tempList = update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });

            tempList.forEach((item, idx) => {
                item.order = idx + 1;
            });

            setData(tempList);
            handleOrderUpdate(tempList);

        },
        [data],
    );

    const columns = jtColumns.filter(c => !c.hide);

    return (
        <>
            <div className="d-flex justify-content-end mb-3">
                <button className="ant-btn ant-btn-primary float-end" onClick={addJobType}>
                    <i className="far fa-plus-square"></i>
                    &nbsp;
                    Add Job Type
                </button>
            </div>

            <div className="row">
                <div className="col-lg-6 col-12 pb-4">
                    {types.loaded ?
                        <>
                            {data.length > 0 ?
                                <DndProvider backend={HTML5Backend}>
                                    <Table
                                        width={'50vw'}
                                        rowKey='jobTypes_ID'
                                        dataSource={data}
                                        columns={columns}
                                        size='small'
                                        pagination={false}
                                        rowClassName={(record, index) => record.jobTypes_ID === selectedJobType?.jobTypes_ID ? 'row-selected' : ''}
                                        components={components}
                                        onRow={(record, index) => {
                                            const attr = {
                                                index,
                                                moveRow,
                                            };

                                            return {
                                                onClick: event => {
                                                    if (event.target.id === 'delete') {
                                                        removeJobType(index);
                                                    } else {
                                                        setSelectedJobType(record); setValue('formFields', record);
                                                    }

                                                },
                                                index,
                                                moveRow
                                            };
                                        }}
                                    />
                                </DndProvider>
                                :
                                <Empty description={<p>Your organization currently has no job types - to begin adding a job type, click the "<i className="far fa-plus-square" /> Add Job Type" button above.</p>} />
                            }
                        </>
                        :
                        <SkeletonTable rowCount={5} columns={columns} />
                    }
                </div>

                <div className="col-lg-6 col-12">
                    {archivedJobTypes?.length > 0 &&
                        <Table
                            width={'50vw'}
                            rowKey='jobTypes_ID'
                            dataSource={archivedJobTypes}
                            columns={archiveColumns}
                            size='small'
                            pagination={false}
                            rowClassName={(record, index) => record.jobTypes_ID === selectedArchivedJobType?.jobTypes_ID ? 'row-selected' : ''}
                            onRow={(record, index) => {
                                return {
                                    onClick: event => {
                                        if (event.target.id === 'restore') {
                                            restoreJobType(index);
                                        }
                                    }
                                };
                            }}
                        />
                    }
                </div>

                <Modal
                    open={selectedJobType}
                    title={selectedJobType?.jobTypes_ID ? "Edit Job Type" : "Add Job Type"}
                    footer={[
                        <div className="text-start" key="1">
                            <button className="ant-btn ant-btn-primary text-start" onClick={handleSubmit}>Save</button>
                            <button className="ant-btn ant-btn-outline m-1" onClick={() => setSelectedJobType(null)}>Cancel</button>
                        </div>
                    ]}
                >
                    <div className="form-group col-lg-6 col-12">
                        <label>Job Type</label>
                        <input maxLength="25" className="ant-input" {...register('formFields.jobType')} />
                    </div>
                </Modal>

                {/*selectedJobType &&
                    <div className="col-lg-6 col-12">
                        <Card className="mt-3" title={selectedJobType?.jobTypes_ID ? "Edit Job Type" : "Add Job Type"}>

                            <div className="form-group col-lg-6 col-12">
                                <label>Job Type</label>
                                <input maxLength="25" className="ant-input" {...register('formFields.jobType')} />
                            </div>

                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-outline m-1" onClick={() => setSelectedJobType(null)}>Cancel</button>
                                </div>
                            </div>
                        </Card>
                    </div>
                */}
            </div>


        </>
    );
}
