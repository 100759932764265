
import Axios from '../config/axios';
import { atom } from 'recoil';

export function getWorkflowByOrganization(org_ID) {
    return Axios.get('/api/GetWorkflowByOrganization', {
        params: {
            org_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function getArchivedWorkflowItemsByOrganization(org_ID) {
    return Axios.get('/api/GetArchivedWorkflowItemsByOrganization', {
        params: {
            org_ID
        }
    }).then(response => response.data).catch(error => error);
}

export function updateWorkflowOrder(postdata) {
    return Axios.post('/api/UpdateWorkflowOrder', postdata).then(response => response.data).catch(error => error);
}

export function updateWorkflowItem(postdata) {
    return Axios.post('/api/UpdateWorkflowItem', postdata).then(response => response.data).catch(error => error);
}

export function insertWorkflowItem(postdata) {
    return Axios.post('/api/AddWorkflowItem', postdata).then(response => response.data).catch(error => error);
}

export function archiveWorkflowItem(postdata) {
    return Axios.post('/api/ArchiveWorkflowItem', postdata).then(response => response.data).catch(error => error);
}