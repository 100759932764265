import React, { useState, useEffect } from 'react';

import { Table } from 'antd';
import Moment from 'moment';
import { getCompletedEventsByCandidate } from '../../services/CandidateService';
import { EventSlider } from '../Dashboard/EventSlider';
import { useRecoilValue } from 'recoil';
import { activeEventTypes } from '../../services/OrganizationsService';
import { activeUserInfo } from '../../services/UsersService';
import { emptyEvent } from '../../services/EventsService';

export default function EventLog(props) {

    const [isActive, setIsActive] = useState(false);
    const [eventLog, setEventLog] = useState([]);
    const [showModal, setShowModal] = useState(false);
    
    const eventTypes = useRecoilValue(activeEventTypes);
    const uInfo = useRecoilValue(activeUserInfo);
    
    const [selectedEvent, setSelectedEvent] = useState({ ...emptyEvent });

    useEffect(() => {
        setIsActive(props.isActive);

        if(props.isActive) {
            getCompletedEventsByCandidate(props.selectedCandidate.candidates.candidates_ID).then(data => {
                setEventLog(data);
            });
        }
    }, [props.isActive]);

    var eventColumns = [
        {
            title: 'Subject',
            dataIndex: ['events', 'subject'],
            key: 'subject'
        },
        {
            title: 'Details',
            dataIndex: ['events', 'details'],
            key: 'details',
            align: 'center'
        },
        {
            title: 'Priority',
            dataIndex: ['events', 'priority'],
            key: 'priority',
            align: 'center'
        },
        {
            title: 'Event Date',
            dataIndex: ['events', 'eventDateTime'],
            key: 'eventDateTime',
            render: (cell) => Moment(new Date(cell)).format('L LT'),
            align: 'center'
        }
    ];

    const columns = eventColumns.filter(c => !c.hide);

    return (
        <>
            {isActive &&
                <>
                    <Table
                        //rowKey={(record) => record.auditChange.auditChanges_ID}
                        dataSource={eventLog}
                        columns={columns}
                        size='small'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { setSelectedEvent(record); setShowModal(true); }, // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                    />
                    <EventSlider showModal={showModal} hideModal={() => setShowModal(false)} selectedEvent={selectedEvent} eventTypes={eventTypes} selectedDate={Moment(new Date)} users_ID={uInfo.users_ID} readOnly />
                </>
            }
        </>
    );
}