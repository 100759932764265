import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Descriptions, Drawer, Empty } from 'antd';
import Moment from 'moment';
import { toast, confirm } from '@rickylandino/react-messages';
import { modifyWorkHistory } from '../../../services/WorkHistoryService';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from 'react-responsive';
import Globals from '../../../config/globals';
import { getChanges } from '../../../helpers/DetectChanges';
import { activeUserInfo } from '../../../services/UsersService';
import { canSelectedCandidateBeUpdated } from '../../../services/CandidateService';

export function WorkHistoryCard(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const canUpdate = useRecoilValue(canSelectedCandidateBeUpdated);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        formFields: props.selectedCandidate.candidates,
        persistentInformation: props.selectedCandidate.candidates
    });

    const [workHistory, setWorkHistory] = useState([...props.dashboardInformation.workHistory]);

    const [stickyWorkHistory, setStickyWorkHistory] = useState([...props.dashboardInformation.workHistory]);

    const [noWorkHistory, setNoWorkHistory] = useState(false);

    const [edit, setEdit] = useState(false);
    const isDirty = useRef(false);

    const [isActive, setIsActive] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setIsActive(props.isActive);
    }, [props.isActive]);

    useEffect(() => {
        manipulateListData();
    }, []);

    function manipulateListData(data = null) {
        if (!data) {
            data = [...workHistory];
        }

        if (data.length === 0) {
            data.push({
                title: "",
                company: "",
                startDate: "",
                endDate: "",
                workType: "",
                description: ""
            });

            setNoWorkHistory(true);
        } else {
            setNoWorkHistory(false);
        }

        setWorkHistory([...data]);

        setStickyWorkHistory([...data]);
    }

    function openEdit() {
        setEdit(true);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        setEdit(false);
                        isDirty.current = false;

                        setWorkHistory([...stickyWorkHistory]);

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            setEdit(false);
            isDirty.current = false;
        }
    }

    function addOrRemoveHistory(e, i) {
        e.preventDefault();

        let wh = [...workHistory];

        isDirty.current = true;
        switch (e.target.id) {
            case 'add':

                wh.push({
                    title: "",
                    company: "",
                    startDate: "",
                    endDate: "",
                    workType: "",
                    description: ""
                });
                setWorkHistory(wh);
                break;
            case 'remove':
                var history = { ...wh[i] };

                history.delete = true;
                history.isDirty = true;

                wh[i] = { ...history };

                setWorkHistory(wh);
                break;
            default:
                break;
        }
    }

    function handleChange(event, i) {
        //event.preventDefault();
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var history = { ...workHistory[i] };
        history[name] = value;
        history.isDirty = true;
        isDirty.current = true;
        let wh = [...workHistory];
        wh[i] = history;

        setWorkHistory(wh);
    }

    function handleDateChange(date, dateName, i) {

        var history = { ...workHistory[i] };
        history[dateName] = date;
        history.isDirty = true;

        let wh = [...workHistory];
        wh[i] = history;

        setWorkHistory(wh);
    }

    function handleSubmit() {
        let wh = [...workHistory];
        let workHistoryList = [];

        for (var i = 0; i < wh.length; i++) {
            if (wh[i].isDirty) {

                wh[i].candidates_ID = state.selectedCandidate.candidates.candidates_ID;
                wh[i].startDate = wh[i].startDate ? wh[i].startDate : null;
                wh[i].endDate = wh[i].endDate ? wh[i].endDate : null;

                workHistoryList.push(wh[i]);

                wh[i].isDirty = false;
            }
        }

        let postdata = {
            id: state.selectedCandidate.candidates.candidates_ID,
            id2: uInfo.users_ID,
            workHistoryList,
            auditChangesList: []
        };

        let informationChanges = {
            oldValues: {},
            changeValues: {}
        };

        postdata.workHistoryList.forEach((item, idx) => { //loop through whatever list is posted to server
            let oldObj = { ...stickyWorkHistory[idx] }; //set this to the "sticky list item"
            let change = {};

            if (oldObj?.candidates_ID) { //This confirms that it is an update of an existing index, not an add or delete
                change = getChanges(oldObj, item, `_workHistory_ID_${oldObj.workHistory_ID}`); //The third parameter here is to make sure there are unique keys tied to each "list entity". (Work history index 0, 1, etc..)
            } else if (item?.candidates_ID) {
                change = {
                    oldValues: {},
                    changeValues: {
                        workHistoryItemAdded: item.name //create custom key name that will ultimately be used to display whether an item was added or removed
                    }
                }
            }

            if (change?.changeValues?.delete) {
                change = {
                    oldValues: {},
                    changeValues: {
                        workHistoryItemRemoved: item.name
                    }
                }
            }

            for (var values in change) {
                for (var changedItems in change[values]) {
                    informationChanges[values][changedItems] = change[values][changedItems];
                }
            }
        });

        if (informationChanges) {
            informationChanges.oldValues = JSON.stringify(informationChanges.oldValues);
            informationChanges.changeValues = JSON.stringify(informationChanges.changeValues);
            informationChanges.changeType = "WorkHistory"; //Set this to the table name that is updated
            informationChanges.changeLocation = "Work History"; //Set this to the tab name you are working in

            postdata.auditChangesList.push(informationChanges);
        }

        modifyWorkHistory(postdata).then(data => {
            if (data) {
                manipulateListData(data);

                toast.success("Work History has been updated");

                navigate(".", {
                    replace: true,
                    state: { selectedCandidate: location.state.selectedCandidate, from: location.state.from, activeTab: 'workHistory' } //set activeTab to whatever tab is currently on
                });

                setEdit(false);
                isDirty.current = false;
            } else {
                toast.error("Something went wrong")
            }
        }).catch(error => toast.error("Something went wrong"));

    }

    return (
        <>
            {isActive &&
                <>
                    {canUpdate && <i className="float-end fa-sharp fa-solid fa-pen-to-square candidate-text icon-md hover" onClick={openEdit}></i>}

                    {!noWorkHistory ?
                        <>
                            {stickyWorkHistory.map((history, i) =>
                                <div key={i}>
                                    {!history.delete &&
                                        <>
                                            <Descriptions
                                            title={i === 0 ? 'Work History' : null}
                                            bordered
                                            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                            size='small'
                                            labelStyle={{ width: 300 }}
                                            key={i}
                                            layout={isDesktop ? 'horizontal' : 'vertical'}
                                            >
                                                <Descriptions.Item label='Title'>{history.title ? history.title : <em>No title specified</em>}</Descriptions.Item>
                                                <Descriptions.Item label='Company'>{history.company}</Descriptions.Item>
                                                <Descriptions.Item label='Job Dates'>
                                                    <span>
                                                        {history.startDate ? <span>{Moment(new Date(history.startDate)).format('MM/YYYY')} - </span> : <span>N/A - </span>}
                                                    </span>
                                                    <span>
                                                        {history.isCurrent ?
                                                            <span>Current position</span>
                                                            :
                                                            history.endDate ? Moment(new Date(history.endDate)).format('MM/YYYY') : <span>N/A</span>
                                                        }
                                                    </span>
                                                </Descriptions.Item>
                                            </Descriptions>

                                            {i + 1 !== stickyWorkHistory.filter(se => !se.delete).length &&
                                                <div className="horizontal-candidates-divider" />
                                            }
                                        </>
                                    }
                                </div>
                            )}
                        </>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Work History Listed' />
                    }

                    <Drawer
                        closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                        title='Edit Education History'
                        placement={'right'}
                        onClose={handleClose}
                        open={edit}
                        key='slider'
                        size='large'
                        footer={
                            <div className="d-flex justify-content-between" key="1">
                                <div className="text-center">
                                    <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Save</button>
                                    <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        }
                    >
                        <div className="">
                            <span className="float-end"><i className="hover far fa-plus-square icon-md candidate-text" id="add" onClick={addOrRemoveHistory} /></span>
                            {workHistory.map((history, i) =>
                                <div className="row" key={i}>
                                    {!history.delete &&
                                        <>
                                            <div className="col-12">
                                                <div className="float-end">
                                                    <i className="hover far fa-trash-alt icon-md" id="remove" onClick={(e) => addOrRemoveHistory(e, i)} />
                                                </div>
                                            </div>
                                            <div className="form-group col-12">
                                                <label className="form-label">Title</label>
                                                <input maxLength="100" type="text" name="title" value={history.title || ''} onChange={(e) => handleChange(e, i)} className="ant-input" />
                                            </div>
                                            <div className="form-group col-12">
                                                <label className="form-label">Company</label>
                                                <input maxLength="100" type="text" name="company" value={history.company || ''} onChange={(e) => handleChange(e, i)} className="ant-input" />
                                            </div>

                                            <div className="form-group col-lg-6 col-12">
                                                <label className="form-label">Work Type</label>
                                                <input maxLength="50" type="text" name="workType" value={history.workType || ''} onChange={(e) => handleChange(e, i)} className="ant-input" />
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Start Date</label>
                                                <DatePicker
                                                    selected={history.startDate ? new Date(history.startDate) : null}
                                                    onChange={(date, dateName, index) => handleDateChange(date, "startDate", i)}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                    className="ant-input"
                                                />
                                            </div>

                                            {history.isCurrent ?
                                                <div className="form-group col-lg-4">
                                                    <label className="form-label">End Date</label>
                                                    <input className="ant-input" value="Current" readOnly />
                                                </div>
                                                :
                                                <div className="form-group col-lg-4">
                                                    <label className="form-label">End Date</label>
                                                    <DatePicker
                                                        selected={history.endDate ? new Date(history.endDate) : null}
                                                        onChange={(date, dateName, index) => handleDateChange(date, "endDate", i)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        className="ant-input"
                                                    />
                                                </div>
                                            }

                                            <div className="col-lg-4">
                                                <label className="d-block">Is Current?</label>
                                                <input type="checkbox" className="custom-checkbox" id="isCurrent" name="isCurrent" value={history.isCurrent} onChange={(e) => handleChange(e, i)} checked={history.isCurrent} />

                                            </div>
                                            <div className="form-group col-12">
                                                <label className="form-label">Description</label>
                                                <textarea maxLength="2000" type="text" name="description" value={history.description || ''} onChange={(e) => handleChange(e, i)} className="ant-input" />
                                            </div>
                                            {(workHistory.filter(se => !se.delete).length > 1 && i !== workHistory.filter(se => !se.delete).length - 1) && <div className="horizontal-candidates-divider" />}
                                        </>
                                    }

                                </div>
                            )}
                        </div>
                    </Drawer>
                </>
            }
        </>
    );
}