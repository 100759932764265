import Axios from '../config/axios';

export function insertPlacement(postdata) {
    return Axios.post(`/api/InsertPlacement`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updatePlacement(postdata) {
    return Axios.post(`/api/UpdatePlacement`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getEntityWithPlacementByCandidateAndJob(jobs_ID, candidates_ID, placementOwner) {
    return Axios.get('/api/GetEntityWithPlacementByCandidateAndJob', {
        params: {
            jobs_ID,
            candidates_ID,
            placementOwner
        }
    }).then(response => response.data).catch(error => error);
}