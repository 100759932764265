import React, { useEffect, useState } from 'react';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Table, Tabs, Empty, Divider, Input } from 'antd';

import SkeletonTable from '../../helpers/SkeletonTable';
import { activeUserInfo, userInfo } from '../../services/UsersService';
import { getAllOrganizations, orgTableParams } from '../../services/OrganizationsService';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddOrganization } from './AddOrganization';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useOrgs } from '../../swr/orgs';
import Moment from 'moment';

export function OrganizationsHome(props) {
    const navigate = useNavigate();

    const uInfo = useRecoilValue(activeUserInfo);
    const [loading, setLoading] = useState(false);
    const [showAddOrganization, setShowAddOrganization] = useState(false);

    const [status, setStatus] = useState('Active');

    const [tableParams, setTableParams] = useRecoilState(orgTableParams);

    const [searchValue, setSearchValue] = useState('');
    
    const [showArchived, setShowArchived] = useState(false);

    const [organizations, setOrganizations] = useState({
        data: [],
        loaded: false
    });

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });

    const { data, isLoading, error } = useOrgs(
        tableParams.pagination.pageSize,
        tableParams.pagination.current - 1,
        searchValue,
        sortStuff.sortBy,
        sortStuff.sortDirection,
        showArchived
    );

    useEffect(() => {
        if (data?.orgs) {
            setOrganizations({ data: data.orgs, loaded: true });

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data.orgs.length > 0 ? data.orgs[0].totalCount : 0
                }
            });
        }        
    }, [data]);

    useEffect(() => {
        if (uInfo?.users_ID) {
            if (uInfo.userType !== 'SuperAdmin') {
                navigate('/dashboard', { replace: true });
            }
        }
    }, [uInfo]);

    // useEffect(() => {
    //     var alive = true;
    //     setLoading(true);

    //     combineLatest(
    //         userInfo
    //     ).pipe(takeWhile(() => alive)).subscribe(([userInfo]) => {

    //         if (userInfo?.users_ID) {
    //             if (!uInfo.users_ID) { //prevent reload of user info
    //                 setUInfo(userInfo);
    //             }
    //         }

    //     });

    //     return () => { alive = false; }
    // }, []);

    function navigateToOrganizationDashboard(row) {
        navigate(`/organization-dashboard/${row.organizations_ID}`,
            {
                replace: true
            });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if(sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    const columns = [
        {
            title: 'Organization',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'name' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'name' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (cell, row) => cell ? <em className="text-success">Active</em> : <em className="text-danger">Inactive</em>
        }, 
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
            key: 'dateAdded',
            render: (cell) => cell && Moment(cell).format("L"),
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'dateAdded' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'dateAdded' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        }
    ];

    return (
        <>
            <div className="d-flex justify-content-end">
                <button className="ant-btn ant-btn-primary float-end mb-4" onClick={() => { setShowAddOrganization(true) }}>
                    <i className="far fa-plus-square"></i>
                    &nbsp;
                    Add Organization
                </button>
            </div>
            <div className="card-container">
                <div>
                    <div className="row">
                        <div className="form-group col-xl-2 col-lg-3 col-6">
                            <label className="ms-1 d-block">Show Archived Organizations</label>
                            <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                        </div>
                        {tableParams.pagination.total >= 0 &&
                            <div className="form-group col-lg-2 col-6 mx-3 align-items-center">
                                Total:&nbsp;<strong>{tableParams.pagination.total} Organization(s)</strong>
                            </div>
                        }
                    </div>

                    <Divider />

                    <Input.Search
                        placeholder="Search for keywords..."
                        onSearch={setSearchValue}
                        onChange={(e) => { setSearchValue(e.target.value) }}
                        value={searchValue}
                    />
                    
                    <Divider />

                    {!organizations.loaded ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <div>
                            {organizations.data?.length > 0 ?
                                <div className="email-table">
                                    <Table
                                        rowKey='organizations_ID'
                                        dataSource={organizations.data}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { navigateToOrganizationDashboard(record) }, // click row
                                                onDoubleClick: event => { navigateToOrganizationDashboard(record) }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination} 
                                        />
                                </div>
                                :
                                <Empty description={<p>There are currently no organizations</p>} />
                            }
                        </div>
                    }
                </div>
            </div>

            <AddOrganization show={showAddOrganization} hidePane={() => setShowAddOrganization(false)} />
        </>
    );
}