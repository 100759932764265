import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Select, Tabs, Popover, Skeleton, Spin, Modal, Upload, Alert, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { toast } from '@rickylandino/react-messages';
import JoditEditor, { Jodit } from 'jodit-react';
import { activeUserInfo } from '../../services/UsersService';
import { getEmailTemplate, sendBrevoEmail } from '../../services/EmailService';
import { EmailTemplates } from './EmailTemplates';
import { Constants } from '../../config/globals';
import { fetcher } from '../../swr/fetcher';
import useSWR from 'swr';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { getClientContactsByClient } from '../../services/ClientContactsService';
import { EmailHistory } from './EmailHistory';

const { Text } = Typography;

export function EmailDashboard(props) {

    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch, formState } = useForm({
        defaultValues: {
            sendOption: 'broadcast'
        }
    });

    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [candidates, setCandidates] = useState([]);
    const [toList, setToList] = useState([]);
    const [ccList, setCcList] = useState([]);
    const [bccList, setBccList] = useState([]);
    const [orgList, setOrgList] = useState([]);
    const [availableSendList, setAvailableSendList] = useState([]);

    const uInfo = useRecoilValue(activeUserInfo);

    const [activeTab, setActiveTab] = useState('sendEmail');

    const [toSearchValue, setToSearchValue] = useState('');
    const [ccSearchValue, setCcSearchValue] = useState('');
    const [bccSearchValue, setBccSearchValue] = useState('');

    const [numberOfEmailsSentThisMonth, setNumberOfEmailsSentThisMonth] = useState(0);

    const [fileList, setFileList] = useState([]);

    const [listData, setListData] = useState({
        emailHistory: [],
        recruiters: [],
        candidates: [],
        templates: [],
        users: [],
        organizations: [],
        loaded: false
    });

    const [emailTemplates, setEmailTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState({});
    const [emailSignatures, setEmailSigantures] = useState({});

    const [showCcStuff, setShowCcStuff] = useState(false);

    const [sendAllSystemUsers, setSendAllSystemUsers] = useState(false);

    const [options, setOptions] = useState(["{{firstName}}", "{{lastName}}", "{{address}}", "{{city}}", "{{state}}", "{{zip}}"]);

    const [sendingEmail, setSendingEmail] = useState(false);

    const [showClientDropdown, setShowClientDropdown] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const { data, error, isLoading } = useSWR(`/api/GetEmailOnLoadInfo?users_ID=${uInfo.users_ID}&pageSize=${10}&offset=${0}`, fetcher);

    useEffect(() => {
        if (props.from === 'campaigns') {
            setToList(props.selectedCandidates.filter(c => c.candidates.email1 && !c.candidates.emailOptOut).map(c => c.candidates?.email1));
            setValue("emailType", "candidate");
        }
    }, [props.from]);

    useEffect(() => {
        modifyTemplates(getValues().emailType);
    }, [emailTemplates]);

    useEffect(() => {
        if (selectedClient) {
            var cc_ID = parseInt(selectedClient);
            getClientContactsByClient(cc_ID).then(data => {
                if (data?.length > 0) {
                    let modifiedContacts = data.map(c => ({
                        value: c.emailAddress,
                        label: `${c.firstName} ${c.lastName} <${c.emailAddress}>`,
                        labelvalue: `${c.firstName} ${c.lastName} <${c.emailAddress}>`,
                        name: `${c.firstName} ${c.lastName}`,
                        id: c.clientContacts_ID,
                        type: 'client contact'
                    }));
                    setAvailableSendList(modifiedContacts);
                } else {
                    setAvailableSendList([]);
                }
            });
        } else {
            setAvailableSendList([]);
        }
    }, [selectedClient]);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {

            if (name === 'emailType') {
                var toShowClients = false;

                setToList([]);
                modifyTemplates(getValues().emailType);
                switch (getValues().emailType) {
                    case 'candidate':
                        setAvailableSendList(listData.candidates);
                        setOptions(["{{firstName}}", "{{lastName}}", "{{address}}", "{{city}}", "{{state}}", "{{zip}}"]);
                        break;
                    case 'recruiter':
                        setAvailableSendList(listData.recruiters);
                        setOptions(["{{firstName}}", "{{lastName}}", "{{email}}"]);
                        break;
                    case 'user':
                        setAvailableSendList(listData.users);
                        setOptions(["{{firstName}}", "{{lastName}}", "{{email}}"]);
                        break;
                    case 'org':
                        setAvailableSendList(listData.organizations);
                        setOptions(null);
                        break;
                    case 'client':
                        toShowClients = true;
                        setAvailableSendList([]);
                        break;
                    default:
                        setAvailableSendList(listData.candidates);
                        setOptions(null);
                        break;
                }


                setShowClientDropdown(toShowClients);
            }

            if (name === 'sendOption') {
                setShowCcStuff(value.sendOption === 'single');
            }
        });

        return () => subscription.unsubscribe();

    }, [watch, listData, emailTemplates, showCcStuff]);

    useEffect(() => {
        if (uInfo.users_ID && data) {

            var modifiedRecruiters = data.recruiters?.map(rec => ({
                value: rec.emailAddress,
                label: `${rec.firstName} ${rec.lastName} <${rec.emailAddress}>`,
                labelvalue: `${rec.firstName} ${rec.lastName} <${rec.emailAddress}>`,
                id: rec.users_ID,
                name: `${rec.firstName} ${rec.lastName}`,
                email: `${rec.emailAddress}`,
                type: 'recruiter'
            }));

            var modifiedUsers = data.allUsers?.map(u => ({
                value: u.emailAddress,
                label: `${u.firstName} ${u.lastName} <${u.emailAddress}>`,
                labelvalue: `${u.firstName} ${u.lastName} <${u.emailAddress}>`,
                id: u.users_ID,
                name: `${u.firstName} ${u.lastName}`,
                email: `${u.emailAddress}`,
                type: 'user',
                orgid: u.organizations_ID
            })) || [];

            var modifiedOrganizations = data.organizations?.map(o => ({
                value: o.organizations_ID,
                label: `${o.name}`,
                labelvalue: `${o.name}`,
                id: o.organizations_ID,
                name: `${o.name}`,
                type: 'organization'
            })) || [];

            var modifiedCandidates = data.candidates?.map(cand => ({
                value: cand.email,
                label: <div>{cand.name} &#60;{cand.email}&#62; {cand.users_ID !== uInfo.users_ID &&
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This is an {Constants.companyCandidateText}</p>}>
                        &nbsp;<i className="candidate-text fa-solid fa-users-between-lines"></i>
                    </Popover>
                }</div>,
                labelvalue: `${cand.name} <${cand.email}>`,
                id: cand.id,
                name: `${cand.name}`,
                email: `${cand.email}`,
                type: `${cand.type}`
            })) || [];

            setCandidates(modifiedCandidates);

            setEmailTemplates(data.emailTemplates);

            setListData({
                emailHistory: data.emailHistory,
                recruiters: modifiedRecruiters,
                users: modifiedUsers,
                organizations: modifiedOrganizations,
                candidates: modifiedCandidates,
                clients: data.clients,
                loaded: true
            });

            setNumberOfEmailsSentThisMonth(data.countOfMonthlyEmailsSent);

            if (uInfo.userType === 'SuperAdmin') {
                setValue('emailType', 'user');
                setAvailableSendList(modifiedUsers);
            } else {
                setAvailableSendList(modifiedCandidates);
            }
        }
    }, [uInfo, data]);

    function modifyTemplates(templateType) {
        let modifiedTemplates = {};
        emailTemplates?.filter(et => et.templateType === templateType || et.templateType === 'generic')
            .forEach(et => {
                modifiedTemplates[et.emailTemplates_ID] = et.templateName
            });

        setFilteredTemplates(modifiedTemplates);

        let signatures = {};
        emailTemplates?.filter(et => et.templateType === 'signature')
            .forEach(et => {
                signatures[et.emailTemplates_ID] = et.templateName
            });

        setEmailSigantures(signatures);
    }

    function addCustomEmail(e, type) {
        let { value } = e.target;

        if (e.keyCode === 13) {
            if (value && !availableSendList.find(item => item.labelvalue.toLowerCase().includes(value.toLowerCase()))) {
                if (type === 'to') {
                    setToList([...toList, {
                        labelvalue: value,
                        value
                    }]);
                    setToSearchValue('');
                } else if (type === 'cc') {
                    setCcList([...ccList, {
                        labelvalue: value,
                        value
                    }]);
                    setCcSearchValue('');
                } else if (type === 'bcc') {
                    setBccList([...bccList, {
                        labelvalue: value,
                        value
                    }]);
                    setBccSearchValue('');
                }
            } else {
                setToSearchValue('');
                setCcSearchValue('');
                setBccSearchValue('');
            }
        }

    }

    function handleOrgChange(ld) {
        let toListCopy = [];

        ld.forEach(item => {
            let userList = availableSendList.filter(sl => sl.orgid === item);
            let useridlist = userList.map(u => u.id);

            toListCopy.push(...useridlist);
        });

        let uniqueToList = [...new Set(toListCopy)];
        setToList(uniqueToList);

        setOrgList(ld);
    }

    function handleSubmit() {
        toast.loading("Sending email...", { key: 'loading' });
        //setSendingEmail(true);
        let sendToList = [];
        let sendCcList = [];
        let sendBccList = [];

        toList.forEach(item => {
            let found = availableSendList.find(c => c.value === item);
            if (found) {
                sendToList.push(found);
            }
            else {
                if(item.value) 
                    sendToList.push({ email: item.value });
                else 
                    sendToList.push({ email: item });
            }
        });

        ccList.forEach(item => {
            let found = availableSendList.find(c => c.value === item);
            if (found) {
                sendCcList.push(found);
            }
            //  else {
            //     sendCcList.push({ email: item.value });
            // }
        });

        bccList.forEach(item => {
            let found = availableSendList.find(c => c.value === item);
            if (found) {
                sendBccList.push(found);
            }
            // else {
            //     sendBccList.push({ email: item.value });
            // }
        });

        var formData = new FormData();

        for (var x = 0; x < fileList.length; x++) {
            formData.append("files", fileList[x]);
        }

        //formData.append("files", fileList);
        formData.append("toList", JSON.stringify(sendToList));
        formData.append("ccList", JSON.stringify(sendCcList));
        formData.append("bccList", JSON.stringify(sendBccList));
        formData.append("subject", getValues().subject);
        formData.append("htmlContent", content);
        formData.append("linkedJobId", null);
        formData.append("emailType", getValues().emailType);
        formData.append("users_ID", uInfo.users_ID);
        formData.append("sendToAll", sendAllSystemUsers);
        formData.append("broadcastEmail", !showCcStuff);

        sendBrevoEmail(formData).then(data => {
            setSendingEmail(false);
            toast.destroy('loading');

            if (data?.length > 0) {
                toast.success("Your email has been sent");

                setListData({
                    ...listData,
                    emailHistory: data
                });

                setToList([]);
                setCcList([]);
                setBccList([]);
                setFileList([]);
                setOrgList([]);
                setValue('subject', '');
                setContent('');

                if (props.from === 'campaigns') {
                    props.handleClose();
                }
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.destroy('loading');
            console.log(error);
            var responseToShow;
            if(typeof error?.response?.data === 'string') {
                responseToShow = error?.response?.data;
            } else {
                responseToShow = JSON.parse(error?.response?.data);
            }
            Modal.error({
                title: 'Error',
                content: (
                    <div>
                        <p>{responseToShow}</p>
                    </div>
                ),
            })
        });
    }

    var tabs = [
        {
            label: <div className="campaign-text">Send Email</div>,
            key: 'sendEmail'
        },
        {
            label: <div className="campaign-text">Email Templates</div>,
            key: 'emailTemplates'
        },
        {
            label: <div className="campaign-text">Email History</div>,
            key: 'emailHistory'
        }
    ];

    if (uInfo.userType === 'SuperAdmin') {
        let templateIdx = tabs.findIndex(t => t.key === 'emailTemplates');
        tabs.splice(templateIdx, 1);
    }

    if (props.from === 'campaigns') {
        let templateIdx = tabs.findIndex(t => t.key === 'emailTemplates');
        tabs.splice(templateIdx, 1);

        let historyIdx = tabs.findIndex(t => t.key === 'emailHistory');
        tabs.splice(historyIdx, 1);


        // let historyOldIdx = tabs.findIndex(t => t.key === 'emailHistoryArchived');
        // tabs.splice(historyOldIdx, 1);
    }

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }

    function handleCheckboxChange(e) {
        setSendAllSystemUsers(e.target.checked);
    }

    function showEmailSendInfo() {
        Modal.info({
            title: 'Email Send Options',
            content: (
                <div>
                    <p><strong>Broadcast Email:</strong>&nbsp;This option will send your composed email to each person in your selected list, individually.</p>
                    <p><strong>One Email:</strong>&nbsp;This option will send one email, attaching all users. Please be aware that all users in the 'To' and 'Cc' group will be visible in the sent email.</p>
                </div>
            ),
            onOk() { },
        });
    }

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
        accept: "image/*,.pdf"
    };

    var joditButtons = [...Jodit.defaultOptions.buttons,
    {
        name: 'Merge Fields',
        list: options,
        exec: (ed, nodeOrFalse, control, origEvent, btn) => {
            ed.s.insertHTML(control.control.name);
        },
        template: function (ed, key, value) {
            return '<div>' + key + '</div>';
        }
    },
    {
        name: 'Templates',
        list: filteredTemplates,
        exec: (ed, nodeOrFalse, control, origEvent, btn) => {
            let foundTemp = emailTemplates.find(et => et.emailTemplates_ID === parseInt(control.control.name));
            if (foundTemp) {
                toast.loading("Fetching Template...", { key: 'loading' });
                getEmailTemplate(foundTemp.emailTemplates_ID).then(data => {
                    ed.s.insertHTML(data.templateContentString);
                    toast.destroy('loading');
                });
            }
        },
        template: function (ed, key, value) {
            return '<div>' + key + '</div>';
        }
    }, {
        name: 'Signatures',
        list: emailSignatures,
        exec: (ed, nodeOrFalse, control, origEvent, btn) => {
            let foundTemp = emailTemplates.find(et => et.emailTemplates_ID === parseInt(control.control.name));
            if (foundTemp) {
                toast.loading("Fetching Signature...", { key: 'loadingSig' });
                getEmailTemplate(foundTemp.emailTemplates_ID).then(data => {
                    ed.s.insertHTML(data.templateContentString);
                    toast.destroy('loadingSig');
                });
            }
        },
        template: function (ed, key, value) {
            return '<div>' + key + '</div>';
        }
    }];

    if (showCcStuff) {
        let mergeBtnIdx = joditButtons.findIndex(b => b.name === 'Merge Fields');
        joditButtons.splice(mergeBtnIdx, 1);
    }

    const emailMonthlyLimit = () => {
        if(uInfo?.emailMonthlyLimit) {
            return uInfo.emailMonthlyLimit;
        } else {
            return 5000;
        }
    }

    return (
        <div>
            {uInfo.brevoVerified ?
                <>
                    {listData.loaded ?
                        <>
                            <Tabs type="card" items={tabs} onChange={handleTabChange} />
                            <div className="row">
                                <div className="col-12">
                                    {activeTab === 'sendEmail' &&

                                        <div className="row justify-content-between">
                                            <div className="form-group col-lg-3 col-12">
                                                <label>Email Type</label>
                                                {uInfo.userType === 'SuperAdmin' ?
                                                    <select className="ant-input" {...register('emailType')}>
                                                        <option value='user'>User</option>
                                                    </select>
                                                    :
                                                    <select className="ant-input" {...register('emailType')} disabled={props.from === 'campaigns'}>
                                                        <option value='candidate'>Candidate</option>
                                                        <option value='client'>Client</option>
                                                        <option value='recruiter'>Recruiter</option>
                                                    </select>
                                                }

                                            </div>

                                            {showClientDropdown &&
                                                <div className="form-group col-lg-3 col-12">
                                                    <label>Select a Client</label>
                                                    <select className="ant-input" value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)}>
                                                        <option>Select a Client</option>
                                                        {listData.clients.map(client => <option key={client.clients_ID} value={client.clients_ID}>{client.name}</option>)}
                                                    </select>
                                                </div>
                                            }

                                            <div className="form-group col-lg-4 col-12">
                                                <Text>You have sent <Text mark strong>{numberOfEmailsSentThisMonth}</Text> emails this month</Text>
                                                <br />
                                                <Text >You have <Text mark strong>{emailMonthlyLimit() - numberOfEmailsSentThisMonth}</Text> emails remaining of your <Text mark strong>{emailMonthlyLimit()}/month</Text> limit.</Text>
                                                <Popover overlayInnerStyle={{ width: '200px', border: '1px solid black' }} placement="right" content={<p>If you would like to increase your limit, please contact your RecruiterBalm representative.</p>}>
                                                    &nbsp;<i className="fa-regular fa-circle-question hover ms-3"></i>
                                                </Popover>
                                            </div>

                                            <div className="horizontal-campaigns-divider"></div>

                                            <div className="form-group col-lg-4 col-12">
                                                <label className="d-block mb-3">How would you like this email to send?
                                                    {/* <i title="Ways to send email" className="fa-regular fa-circle-question hover ms-3" onClick={showEmailSendInfo}></i> */}
                                                </label>
                                                <div className="inline-custom-radio-container">
                                                    <input type="radio" className="custom-radio" {...register('sendOption')} value='broadcast' disabled={props.from === 'campaigns'} />
                                                    <label> Send as Broadcast Email</label>
                                                </div>
                                                <div className="inline-custom-radio-container">
                                                    <input type="radio" className="custom-radio" {...register('sendOption')} value='single' disabled={props.from === 'campaigns'} />
                                                    <label> Send as One Email</label>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group col-lg-8 col-12">
                                                <p><strong>Broadcast Email:</strong>&nbsp;This option will send your composed email to each person in your selected list, individually. All users in the 'To' group will not be aware of anybody else the email was sent to.</p>
                                                <p><strong>One Email:</strong>&nbsp;This option will send one email, attaching all users. Please be aware that all users in the 'To' and 'Cc' group will be visible in the sent email.</p>
                                            </div>

                                            <div className="form-group col-12">
                                                <label>To</label>
                                                <Select
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    mode="multiple"
                                                    value={toList}
                                                    allowClear
                                                    onClear={() => setToList([])}
                                                    options={availableSendList}
                                                    onChange={(ld) => { setToList(ld); setToSearchValue('') }}
                                                    disabled={sendAllSystemUsers}
                                                    filterOption={(input, option) =>
                                                        (option?.labelvalue ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    searchValue={toSearchValue}
                                                    onSearch={(value) => setToSearchValue(value)}
                                                    onInputKeyDown={(e) => showCcStuff ? addCustomEmail(e, 'to') : null}
                                                    notFoundContent={showCcStuff ? 'Press enter to add an email not found in the list' : 'No options found'}
                                                />
                                            </div>

                                            {showCcStuff &&
                                                <>
                                                    <div className="form-group col-12">
                                                        <label>Cc</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mode="multiple"
                                                            value={ccList}
                                                            allowClear
                                                            onClear={() => setCcList([])}
                                                            options={availableSendList}
                                                            onChange={(ld) => { setCcList(ld); setCcSearchValue('') }}
                                                            disabled={sendAllSystemUsers}
                                                            filterOption={(input, option) =>
                                                                (option?.labelvalue ?? '').toLowerCase().includes(input.toLowerCase())}
                                                            searchValue={ccSearchValue}
                                                            onSearch={(value) => setCcSearchValue(value)}
                                                            onInputKeyDown={(e) => addCustomEmail(e, 'cc')}
                                                            notFoundContent='Press enter to add an email not found in the list'
                                                        />
                                                    </div>

                                                    <div className="form-group col-12">
                                                        <label>Bcc</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mode="multiple"
                                                            value={bccList}
                                                            allowClear
                                                            onClear={() => setBccList([])}
                                                            options={availableSendList}
                                                            onChange={(ld) => { setBccList(ld); setBccSearchValue('') }}
                                                            disabled={sendAllSystemUsers}
                                                            filterOption={(input, option) =>
                                                                (option?.labelvalue ?? '').toLowerCase().includes(input.toLowerCase())}
                                                            searchValue={bccSearchValue}
                                                            onSearch={(value) => setBccSearchValue(value)}
                                                            onInputKeyDown={(e) => addCustomEmail(e, 'bcc')}
                                                            notFoundContent='Press enter to add an email not found in the list'
                                                        />
                                                    </div>
                                                </>
                                            }

                                            {uInfo.userType === 'SuperAdmin' &&
                                                <>
                                                    <div className="form-group col-lg-4 col-12">
                                                        <label>Add By Organization</label>
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            mode="multiple"
                                                            value={orgList}
                                                            options={listData.organizations}
                                                            onChange={handleOrgChange}
                                                            filterOption={(input, option) =>
                                                                (option?.labelvalue ?? '').toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            disabled={sendAllSystemUsers}
                                                        />
                                                    </div>

                                                    <div className="col-lg-2 col-6">
                                                        <label className="form-label">All System Users</label>
                                                        <br />
                                                        <input type="checkbox" className="custom-checkbox" name="allSystemUsers" onChange={handleCheckboxChange} />
                                                    </div>
                                                </>
                                            }

                                            <div className="form-group col-lg-8 col-12">
                                                <label>Subject</label>
                                                <input className="ant-input" {...register('subject')} />
                                            </div>

                                            <div className="form-group col-12">
                                                <Upload {...uploadProps}>
                                                    <span className="a">Add Attachment</span>
                                                </Upload>
                                            </div>

                                            <div className="col col-12" key="editor1">
                                                <JoditEditor
                                                    ref={editor}
                                                    value={content}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                    onChange={(newContent) => { }}
                                                    config={{
                                                        height: "500",
                                                        allowResizeY: true,
                                                        uploader: {
                                                            insertImageAsBase64URI: true
                                                        },
                                                        buttons: joditButtons,
                                                        removeButtons: ['source', 'about', 'video', 'file', 'fullsize', 'preview'],
                                                    }}
                                                />
                                            </div>

                                            <div className="col col-12 mt-3">
                                                {sendingEmail ?
                                                    <Spin indicator={<LoadingOutlined spin />} className="mx-3" />
                                                    :
                                                    <button className="ant-btn ant-btn-primary" onClick={handleSubmit}>Send Email</button>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {activeTab === 'emailTemplates' && <EmailTemplates templates={emailTemplates} users_ID={uInfo?.users_ID} updateTemplates={(templates) => { setEmailTemplates(templates); setValue('emailType', 'candidate') }} />}

                                    {activeTab === 'emailHistory' && <EmailHistory listData={listData} />}

                                    {/* {activeTab === 'emailHistoryArchived' && <OldEmailHistory listData={listData} />} */}

                                </div>
                            </div>
                        </>
                        :
                        <div className="row mx-auto pt-5">
                            <div className="col-lg-3 col-12 ml-5 mr-5">
                                <Skeleton.Input className="form-group w-100" active={true} size='default' />
                            </div>

                            <div className="horizontal-campaigns-divider"></div>
                            <div className="form-group col-lg-8 col-12">
                                <Skeleton.Input className="form-group w-100" active={true} size='default' />
                            </div>
                            <div className="form-group col-lg-8 col-12">
                                <Skeleton.Input className="form-group w-100" active={true} size='default' />
                            </div>

                            <div className="form-group col-lg-12 col-12">
                                <Skeleton.Input className="form-group w-100" active={true} size='default' style={{ height: '500px' }} />
                            </div>
                        </div>
                    }
                </>
                :
                <Alert
                    message="Email Authentication Required"
                    description={
                        <div>Your email is not yet authenticated with RecruiterBalm. To proceed with sending emails, please click the link below:
                            <div className="mt-4">
                            <mark><b><a onClick={() => {
                                navigate(`/account-settings`,
                                    {
                                        replace: true,
                                        state: { activeTab: 'email-domain-settings' }
                                    });
                            }}>Authenticate My Email</a></b></mark>
                            </div>
                        </div>
                    }
                    type="error"
                    showIcon
                />
            }
        </div>
    );
}