import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserOutlined, LoadingOutlined } from '@ant-design/icons';

import recruiterbalmLogo from '../../img/RecruiterBalmLogo.jpg';
import { Button, Form, Input, Modal, Typography, Spin } from 'antd';

import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { unsubscribeCandidateFromEmailCommunications } from '../../services/CandidateService';

const { Text } = Typography;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function CandidateOpt() {
    const { emailHash } = useParams();
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    let secret = "abcdefghijklmnop";

    let key = CryptoJS.enc.Utf8.parse(secret);

    var hashToDecrypt = emailHash.replaceAll("*", "/");
    let decryptedBytes = AES.decrypt(hashToDecrypt, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    function handleOptOut() {
        setLoading(true);
        let postdata = {
            email: decryptedText
        }

        unsubscribeCandidateFromEmailCommunications(postdata).then(data => {
            setLoading(false);
            if (data === true) {
                setShowSuccess(true);
            } else {
                Modal.error({
                    title: 'Error',
                    content: "Something went wrong. Please email support@recruiterbalm.com."
                })
            }
        }).catch(error => {
            setLoading(false);
            Modal.error({
                title: 'Error',
                content: "Something went wrong. Please email support@recruiterbalm.com."
            })
        });
    }

    return (
        <div>
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4 site-card-border-less-wrapper">
                    <img src={recruiterbalmLogo} height="65px" alt="Logo" aria-roledescription="logo" />
                </div>

                {
                    showSuccess ?
                        <div>
                            <hr />
                            <Text type="success">You have successfully unsubscribed from further email communications.</Text>
                        </div>
                        :
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            layout='vertical'
                            autocomplete="yes"
                        >
                            <Text type="danger">We are sad to see you go.</Text>
                            <br />
                            <Text type="danger">Are you sure you would like to unsubscribe?</Text>
                            <br />

                            <Form.Item className="mt-3">
                                <Input autocomplete prefix={<UserOutlined className="site-form-item-icon" />} value={decryptedText} disabled />
                            </Form.Item>

                            <Form.Item>
                                {loading ?
                                    <Spin indicator={antIcon} />
                                    :
                                    <Button type="primary" htmlType="submit" className="login-form-button ant-btn ant-btn-danger" onClick={handleOptOut}>
                                        Yes, Unsubscribe My Email From Further Recruiter Communications
                                    </Button>
                                }
                                
                            </Form.Item>
                        </Form>
                }


            </div>
        </div>
    )
}