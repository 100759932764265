import Axios from '../config/axios';

export function insertSpecialty(postdata) {
    return Axios.post(`/api/InsertSpecialty`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateSpecialty(postdata) {
    return Axios.post(`/api/UpdateSpecialty`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteSpecialty(postdata) {
    return Axios.post(`/api/DeleteSpecialty`, postdata
    ).then(response => response.data).catch(error => error);
}

export function getCandidatesOrJobsWithAssignedSpecialty(specialties_ID) {
    return Axios.get(`/api/GetCandidatesOrJobsWithAssignedSpecialty`, 
        { params: { specialties_ID } })
        .then(response => response.data)
        .catch(error => error);
}

export function getSpecialtiesByOrganization(org_ID) {
    return Axios.get(`/api/GetSpecialtiesByOrganization`, 
        { params: { org_ID } })
        .then(response => response.data)
        .catch(error => error);
}

export function bulkReassignSpecialties(postdata) {
    return Axios.post(`/api/BulkReassignSpecialties`, postdata
    ).then(response => response.data).catch(error => error);
}