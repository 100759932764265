import React from 'react';
import { Badge, Card, Carousel } from 'antd';
import Globals from '../../config/globals';
import { Planner } from './Planner';
import { unauth } from '../../services/TokenService';

export function Dashboard(props) {

    const contentStyle = {
        margin: 0,
        padding: 5,
        height: '160px',
        color: '#000',
        border: '1px solid',
        borderRadius: '5px'
    };

    return (
        <div>
            {/*<Badge.Ribbon text="Getting Started">*/}
            {/*    <Card title={`Welcome to ${Globals.appName}!`} size="large">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-3 col-sm-6 col-12">*/}
            {/*                <Card hoverable size="small" title="Candidates" className="candidate">*/}
            {/*                    View, Add, and Update Candidates*/}
            {/*                </Card>*/}
            {/*            </div>*/}

            {/*            <div className="col-lg-3 col-sm-6 col-12">*/}
            {/*                <Card hoverable size="small" title="Campaigns" className="campaign">*/}
            {/*                    Group your candidates together by location, specialty, or any other way that you would like. This allows for easier means of contact between the candidates that you choose.*/}
            {/*                </Card>*/}
            {/*            </div>*/}

            {/*            <div className="col-lg-3 col-sm-6 col-12">*/}
            {/*                <Card hoverable size="small" title="Texting Capabilities" className="campaign">*/}
            {/*                    Text your candidates right from {Globals.appName}! Easily get a hold of your candidates and be able to communicate with them right from here.*/}
            {/*                </Card>*/}
            {/*            </div>*/}

            {/*            <div className="col-lg-3 col-sm-6 col-12">*/}
            {/*                <Card hoverable size="small" title="Your profile" className="settings">*/}
            {/*                    Manage your profile to help customize your experience.*/}
            {/*                </Card>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Card>*/}
            {/*</Badge.Ribbon>*/}
            <Planner />
        </div>
    );
}