import Axios from '../config/axios';

export function getClientContactsByClient(clients_ID) {
    return Axios.get(`/api/GetClientContactsByClient`, {
        params: {
            clients_ID
        }
    }
    ).then(response => response.data).catch(error => error);
}

export function addClientContact(postdata) {
    return Axios.post(`/api/AddClientContact`, postdata
    ).then(response => response.data).catch(error => error);
}

export function upsertClientContact(postdata) {
    return Axios.post(`/api/UpsertClientContact`, postdata
    ).then(response => response.data).catch(error => error);
}

export function updateClientContact(postdata) {
    return Axios.post(`/api/UpdateClientContact`, postdata
    ).then(response => response.data).catch(error => error);
}

export function deleteClientContact(postdata) {
    return Axios.post(`/api/DeleteClientContact`, postdata
    ).then(response => response.data).catch(error => error);
}