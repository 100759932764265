import { Card, Col, Divider, Row, List, Typography, Table, Modal, Spin, Alert, Collapse } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { authenticateDomain, createDomain, createSender, sendSupportEmail, validateDomainConfiguration, validateSender } from '../../../services/BrevoService';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeUserInfo } from '../../../services/UsersService';
import { getDomainsByOrganization } from '../../../services/VerifiedDomainsService';
import { CopyOutlined } from '@ant-design/icons';
import { toast } from '@rickylandino/react-messages';
import EnterCode from './EnterCode';

const { Text } = Typography;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function DomainVerification() {

    const { register, getValues, setValue, control, watch, formState } = useForm();

    const [uInfo, setUInfo] = useRecoilState(activeUserInfo);
    const [domains, setDomains] = useState([]);

    const [activeKeys, setActiveKeys] = useState(['1', '2']);

    const [loading, setLoading] = useState({
        create: false,
        authenticate: false,
        createSender: false,
        code: false
    })

    useEffect(() => {
        getDomains(uInfo.organizations_ID);
    }, [uInfo.organizations_ID]);

    useEffect(() => {
        var defaultActiveKeys = ['1', '2'];

        if(domains?.length > 0 && domains[0].authenticated) {
            if (uInfo?.brevoVerified) {
                defaultActiveKeys = ['0'];
            } else {
                defaultActiveKeys = ['2'];
            }
        } else if (uInfo?.brevoVerified) {
            defaultActiveKeys = ['1'];
        }

        setActiveKeys(defaultActiveKeys);
    }, [domains, uInfo]);

    function getDomains(org_ID) {
        getDomainsByOrganization(org_ID).then(data => {
            let modifiedData = modifyList(data);

            setDomains(modifiedData);
        });
    }

    function modifyList(list) {
        let modifiedData = list.map(item => {
            if (item.dns_Records) {
                item.dns_Records_Json = JSON.parse(item.dns_Records);
            } else if (item.dns_records) {
                item.dns_Records_Json = item.dns_records;
            }
            return item;
        });

        modifiedData = modifiedData.map(item => {
            var convertedTable = [];
            if (item.dns_Records_Json) {
                for (const [key, value] of Object.entries(item.dns_Records_Json)) {
                    convertedTable.push(value);
                }
            }

            item.dnsTable = convertedTable;
            return item;
        })

        return modifiedData;
    }

    function handleDomainVerification(domainName) {
        validateDomainConfiguration(domainName, uInfo.organizations_ID).then(data => {
            console.log(data);
        });
    }

    function handleCreateDomain() {
        setLoading({
            ...loading,
            create: true
        });
        let localDomains = domains?.length > 0 ? [...domains] : [];

        let postdata = {
            domain: getValues().domainName,
            org_ID: uInfo.organizations_ID
        }

        createDomain(postdata).then(data => {
            setLoading({
                ...loading,
                create: false
            });

            if (data?.domain_name) {
                toast.success("Domain added successfully");
                var item = {
                    domain: data.domain_name,
                    verified: false,
                    authenticated: false,
                    dns_records: data.dns_records
                }
                localDomains.push(item);

                setDomains(modifyList(localDomains));
            }
        });
    }

    function handleAuthenticateDomain(domainName) {

        setLoading({
            ...loading,
            authenticate: true
        });

        let postdata = {
            domain_name: domainName,
            org_ID: uInfo.organizations_ID
        }
        authenticateDomain(postdata).then(data => {
            setLoading({
                ...loading,
                authenticate: false
            });

            if (data?.status === 500 || data?.status === 400) {
                Modal.error({
                    title: 'Error',
                    content:
                        <>
                            <div>{data.response.data}</div>
                            <Divider />
                            <div>If you have just configured these records, please try again a little later. It could take up to 2 days to propogate.</div>
                        </>
                })
            } else {
                toast.success(data);
                getDomains(uInfo.organizations_ID);
            }
        }).catch(error => {
            setLoading({
                ...loading,
                authenticate: false
            });

            Modal.error({
                title: 'Error',
                content: error.message
            });
        });
    }

    const handleCodeSubmit = async (id, code) => {
        if (loading.code) return;

        setLoading({
            ...loading,
            code: true
        });

        let postdata = {
            id,
            otp: parseInt(code),
            email: uInfo.emailAddress
        }

        validateSender(postdata).then(data => {
            if(data) {
                Modal.destroyAll();
                toast.success("Email verified successfully");
                setUInfo({
                    ...uInfo,
                    brevoVerified: true
                });
            } else {
                toast.error("Something went wrong. Please contact RecruiterBalm Support.");
            }

            setLoading({
                ...loading,
                code: false
            });
        });
    };

    function handleCreateSender() {
        let postdata = {
            name: uInfo.firstName + " " + uInfo.lastName,
            email: uInfo.emailAddress
        }

        setLoading({
            ...loading,
            createSender: true
        });

        createSender(postdata).then(data => {
            setLoading({
                ...loading,
                createSender: false
            });

            if (data?.id && (data?.dkimError || data?.spfError || domains?.length === 0 || !domains[0].authenticated)) {
                Modal.success({
                    title: 'Success',
                    content: <div>
                        <Text type="success">You should receive an email shortly.</Text>
                        You are one step closer to sending emails. You should receive an email from account-alerts@t.brevo.com with a 6 digit code. Please input it here to proceed.
                        <Divider />
                        <div style={{ "display": "flex", "flexDirection": "column", "gap": "1.5rem" }}>
                            <EnterCode isLoading={loading.code} callback={(code) => handleCodeSubmit(8, code)} />
                        </div>
                        <Divider />
                        <Text type="warning">If you do not receive an email within a few minutes, please click the button below and we will get in touch shortly.</Text>
                        <br />
                        <button className="ant-btn ant-btn-warning m-1" onClick={handleSendSupportEmail}>I Didn't Get An Email</button>
                    </div>,
                    wrapClassName: 'modal-no-footer'
                });
            } else {
                setUInfo({
                    ...uInfo,
                    brevoVerified: true
                });
                toast.success("Sender added successfully");
            }

        }).catch(error => {
            setLoading({
                ...loading,
                createSender: false
            });
            toast.error("Something went wrong. Please try again later.");
        });
    }

    function handleSendSupportEmail() {
        Modal.destroyAll();

        let postdata = {
            content: "User didn't receive an email to verify their email address.",
            initiatedUsers_ID: uInfo.users_ID
        }
        sendSupportEmail(postdata).then(data => {
            if(data) {
                toast.success("We will be in touch shortly.");
            } else {
                Modal.error({
                    title: 'Error',
                    content: "Shoot. Something went wrong. Please email us directly at support@recruiterbalm.com"
                });
            }
        }).catch(error => {
            Modal.error({
                title: 'Error',
                content: "Shoot. Something went wrong. Please email us directly at support@recruiterbalm.com"
            });
        });
    }

    var columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Host/Name',
            dataIndex: 'host_name',
            key: 'host_name',
            render: (cell, row) => {
                return (
                    <div className="hover copy" title="Copy to clipboard" onClick={() => {
                        navigator.clipboard.writeText(cell);
                        toast.success("Copied to clipboard");
                    }}>{cell} <CopyOutlined className="float-end" /></div>
                )
            }
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            className: 'wrap-table',
            render: (cell, row) => {
                return (
                    <div className="hover copy" title="Copy to clipboard" onClick={() => {
                        navigator.clipboard.writeText(cell);
                        toast.success("Copied to clipboard");
                    }}>{cell} <CopyOutlined className="float-end" /></div>
                )
            }
        },
    ];

    const RegisterNew = () => (
        <div className="m-3">
            <div className="form-group">
                <label>Submit a Domain</label>
                <input className="ant-input" {...register('domainName')} />
            </div>
            <div className="form-group">
                {loading.create ?
                    <Spin indicator={antIcon} />
                    :
                    <button className="ant-btn ant-btn-success m-1" onClick={handleCreateDomain}>Submit My Domain</button>
                }
            </div>
        </div>
    )

    function onChange(keys) {
        setActiveKeys(keys);
    }    
    
    return (
        <>
            <Collapse activeKey={activeKeys} onChange={onChange}>
                <Panel header={
                    domains?.length > 0 && domains[0].authenticated ? 
                        <div className="d-flex justify-content-between align-items-baseline"><div>Domain Verification</div><div><Typography.Title className="ms-3" level={4}><em className="text-success">Done</em></Typography.Title></div></div> 
                        : 
                        "Domain Verification"
                    } 
                    key="1">
                    <>
                        
                    <Text type="danger" strong>** This step does NOT need to be completed before your own email authentication **</Text>
                    <br />
                        {uInfo.userType === 'SuperAdmin' || uInfo.userType === 'OrgAdmin' &&
                            <>
                            <Text type="danger">Verifying a company domain only needs to be done once, by any admin in your organization.</Text>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Card title="Step 1. Submit Your Domain" bordered={false}>
                                        Begin by entering your company's domain name in the input box below and clicking "Submit My Domain". The domain name is the part of your email address that comes after the "@" symbol.
                                        <strong>For Example, you would use the highlighted portion of the following email:&nbsp;</strong> <Typography.Title level={5}>email@<strong><Text mark>company.com</Text></strong></Typography.Title>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Step 2. Verify Your Domain Keys" bordered={false}>
                                        Next, add the records that show up after you submit your domain (DKIM Record, DMARC Record and Brevo Code) to your domain registrar. Your IT Support team should be able to help with this step. These keys should show on the screen
                                        once you submit your domain. If you do not see them, please refresh the screen.
                                        <br />
                                        GoDaddy is a common DNS Host provider, here is a helpful link on how to add DNS records: <a href="https://www.godaddy.com/help/add-a-txt-record-19232" target="_blank" rel="noreferrer">GoDaddy Help</a>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card title="Step 3. Authenticate Your Domain" bordered={false}>
                                        Lastly, click the Verify Domain button to ensure your configuration is correct. It may take some time to propogate. Once you have verified your domain, you can register your email to send emails from RecruiterBalm.
                                    </Card>
                                </Col>
                            </Row>
                            </>
                        }
                        {domains.length === 0 ?
                            <>
                                <Alert className="my-2" message="Your company does not have any domains registered yet." type="error" />
                                {uInfo.userType === 'SuperAdmin' || uInfo.userType === 'OrgAdmin' ?
                                    <RegisterNew />
                                    :
                                    <Alert message="You do not have permission to add domains. Please contact your organization's Admin. Verifying a company domain will increase your email deliverability." type="warning" />
                                }
                            </>
                            :
                            <>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={domains}
                                    renderItem={(item, idx) => (
                                        <>
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={
                                                        <>
                                                            <div className="d-flex align-items-baseline">
                                                            Domain:&nbsp;<Text mark>{item.domain}</Text>
                                                            <Typography.Title className="ms-3" level={4}><em className={item.authenticated ? "text-success" : "text-danger"}>{item.authenticated ? "Verified" : "Not Verified"}</em></Typography.Title>
                                                            </div>
                                                            {!item.authenticated ?
                                                                <div className="float-end">
                                                                    {loading.authenticate ?
                                                                        <Spin indicator={antIcon} />
                                                                        :
                                                                        <button className="ant-btn ant-btn-primary m-1" onClick={() => handleAuthenticateDomain(item.domain)}>Verify Domain</button>
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="float-end">
                                                                    <Text italic>No other action is required on your part to authenticate your domain.</Text>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    description={
                                                        <>
                                                            <div>
                                                                
                                                                {!item.authenticated && (uInfo.userType === 'SuperAdmin' || uInfo.userType === 'OrgAdmin') &&
                                                                    <div className="ms-3">Domain authentication helps to increase deliverability. To use this domain, verify it by adding the records in the table below (DKIM Record, DMARC Record and Brevo Code) to your domain registrar.
                                                                        Once you have done so, you can click the Verify Domain button to verify your configuration is correct. It may take some time to propogate.
                                                                    </div>
                                                                }
                                                            </div>
                                                            {uInfo.userType === 'SuperAdmin' || uInfo.userType === 'OrgAdmin' &&
                                                                <div className="mt-3">
                                                                    <Table columns={columns} dataSource={item.dnsTable} pagination={false} />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                />
                                            </List.Item>
                                        </>
                                    )}
                                />
                            </>
                        }
                    </>
                </Panel>
                <Panel header={
                    uInfo.brevoVerified ? 
                        <div className="d-flex justify-content-between align-items-baseline"><div>Email Authentication</div><div><Typography.Title className="ms-3" level={4}><em className="text-success">Done</em></Typography.Title></div></div> 
                        : 
                        "Email Authentication"
                    }  key="2">
                    <div className="row">
                        {uInfo.brevoVerified ?
                            <div className="col-12">
                                <Alert message="Your email is verified. You can send emails from RecruiterBalm with your email as the sender." type="success" />
                            </div>
                            :

                            <>
                                {uInfo.brevoSenderId ?
                                    <div className="col-12">
                                        <Alert 
                                            message="It looks like your email has been registered but not yet authenticated. Please contact RecruiterBalm support to get this issue fixed."
                                            description="This is likely because upon creation, you didn't enter a 6 digit code sent to your email." 
                                            type="warning" />
                                    </div>
                                :
                                    <>
                                        <div className="col-lg-9 col-12">
                                            <Alert message={
                                                <div>
                                                    Clicking on the "Register My Email" button will allow you to send emails in RecruiterBalm.
                                                </div>
                                            }
                                                type="info"
                                            />
                                        </div>
                                        <div className="col-lg-3 col-12">
                                            <div className="form-group">
                                                {loading.createSender ?
                                                    <Spin indicator={antIcon} />
                                                    :
                                                    <button className="ant-btn ant-btn-success m-1" onClick={handleCreateSender}>Register My Email</button>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                            </>
                        }
                    </div>
                </Panel>
            </Collapse>
            <Divider />


        </>

    );
}
