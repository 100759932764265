import React, { useState, useEffect } from 'react';
import { Avatar, Dropdown, Space, Divider, Button, Switch } from 'antd';
import { QuestionOutlined, ToolOutlined, UserOutlined} from '@ant-design/icons';
import { useThemeSwitcher } from "react-css-theme-switcher";
import { getColorShade } from '../../helpers/ColorShades';
import { useNavigate } from "react-router-dom";

import './navigation.css';

export function TopNavProfileDropdown(props) {
    const navigate = useNavigate();

    const { switcher, currentTheme, status, themes } = useThemeSwitcher();

    const [isDarkMode, setIsDarkMode] = useState(currentTheme === 'dark' ? true : false);

    const toggleTheme = (isChecked) => {
        setIsDarkMode(isChecked);

        window.sessionStorage.setItem("theme", isChecked ? 'dark' : 'light');

        switcher({ theme: isChecked ? themes.dark : themes.light });
    };

    const items = [
        { label: <div><UserOutlined /> Profile</div>, key: 'profile', onClick: () => navigate("/account-settings") },
        { label: <div><QuestionOutlined /> FAQ</div>, key: 'faq', onClick: () => navigate("/faq") },
        { label: <div><ToolOutlined /> Submit a Ticket</div>, key: 'ticket', onClick: () => navigate("/submit-ticket") },
        { label: <div><i className="fa-solid fa-gear" id="admin-settings"></i> Admin Settings</div>, key: 'admin', onClick: () => navigate("/admin-settings") },
    ];

    if (props.uInfo?.userType !== 'SuperAdmin') {
        let adminSettingsIdx = items.findIndex(item => item.key === 'admin');
        items.splice(adminSettingsIdx, 1);
    }

    return (
        <>
            <Dropdown
                menu={{ items }}
                placement='bottom'
                dropdownRender={(menu) => (
                    <div className="dropdown-content">
                        {menu}
                        <Divider
                            style={{
                                margin: 0,
                            }}
                        />
                        <Space
                            style={{
                                padding: 8,
                            }}
                        >
                            <label>Dark Mode</label>
                            <div title='Change Contrast'>
                                <Switch checked={isDarkMode} onChange={toggleTheme} />
                            </div>
                        </Space>
                    </div>
                )}            >
                <Avatar id="settings" size='large' style={{ color: props.uInfo.color, backgroundColor: getColorShade(props.uInfo.color) }} src={props.uInfo.profilePicture ? `data:image/jpeg;base64,${props.uInfo.profilePicture}` : null} icon={<UserOutlined />}></Avatar>
            </Dropdown>
        </>    
    );
}