import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Comment, List, Divider } from 'antd';
import { toast } from '@rickylandino/react-messages';
import { getCandidateComments, insertCandidateComment } from '../../../services/CandidateService';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../../css/editor.css';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment/moment';
import { convertHtmlToText, isHTML } from '../../../helpers/ManipulateHTML';
import { activeUserInfo } from '../../../services/UsersService';

export function CommentsCard(props) {

    const uInfo = useRecoilValue(activeUserInfo);

    const [comments, setComments] = useState([]);

    const [state, setState] = useState({
        selectedCandidate: props.selectedCandidate,
        persistentInformation: props.selectedCandidate.candidates
    });

    var editorContent = EditorState.createEmpty();


    const [isActive, setIsActive] = useState(false);

    const [editorState, setEditorState] = useState(editorContent);

    useEffect(() => {
        if (props.isActive) {
            getCandidateComments(props.selectedCandidate.candidates.candidates_ID).then(data => {
                setComments(data);
            });
        }
        setIsActive(props.isActive);
    }, [props.isActive]);

    function handleSubmit() {

        let candidateCommentsModel = {
            users_ID: uInfo.users_ID,
            candidates_ID: state.persistentInformation.candidates_ID,
            comment: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
        toast.loading("Updating candidate...", { key: 'loading' });

        insertCandidateComment(candidateCommentsModel).then(data => {
            toast.destroy('loading');
            toast.success("Comment has been added");
            setComments([...comments, { ...candidateCommentsModel, recruiter: uInfo.firstName + ' ' + uInfo.lastName }]);
            setEditorState(EditorState.createEmpty());
        });
    }

    function onEditorStateChange(es) {
        setEditorState(es);
    }

    return (
        <>
            {isActive &&
                <>
                    {uInfo?.nchcrMember && <div className="d-flex justify-content-center"><strong className="candidate-text">** Comments are only seen by recruiters and NOT cross-posted to NCHCR.</strong></div>}
                    <List
                        className="comment-list"
                        header={`${comments.length} comments`}
                        itemLayout="horizontal"
                        dataSource={comments}
                        renderItem={(item) => (
                            <li>
                                <Comment
                                    author={item.recruiter}
                                    content={isHTML(item.comment) ? convertHtmlToText(item.comment) : item.comment}
                                    datetime={moment(item.dateAdded).format('L LT')}
                                />
                            </li>
                        )}
                    />

                    <Divider>Add a Comment</Divider>
                    <div className="row">
                        <div className="form-group col-12">
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="custom-editor"
                                onEditorStateChange={onEditorStateChange}
                                toolbar={{
                                    options: ['inline', 'list', 'textAlign']
                                }}
                            />
                        </div>

                        <div className="form-group col-12">
                            <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit}>Add Comment</button>
                        </div>
                    </div>
                </>
            }
        </>
    );
}